import { CSVLink } from "react-csv";
import { Box } from "@mui/material";

import { Button, FilterDropdown, SearchInput } from "@components/common";

import classes from "./styles.module.css";

export const Tools = ({
  filters,
  setFilters,
  handleSearch,
  addButtonLabel,
  onAdd,
  csv,
  csvFileName,
  csvEnabled = false,
  filterEnabled = true,
  creationEnabled = true,
}) => {
  return (
    <Box className={classes.tools}>
      <Box className={classes.filters}>
        {filterEnabled && <FilterDropdown filters={filters} setFilters={setFilters} />}
        <SearchInput onChange={handleSearch} />
      </Box>
      <Box className={classes.buttons}>
        {creationEnabled && <Button onClick={onAdd}>{addButtonLabel}</Button>}
        {csvEnabled && (
          <Button variant="csv">
            <CSVLink filename={csvFileName} data={csv}>
              CSV Download
            </CSVLink>
          </Button>
        )}
      </Box>
    </Box>
  );
};
