export const columns = [
  { label: "Vendor", field: "vendor" },
  { label: "Supported Models", field: "models" },
  { label: "Filename", field: "fileName" },
  { label: "Firmware Version", field: "version", width: "10%" },
  { label: "Created At", field: "createdAt" },
];

const filterConfig = [];

const csvConfig = {
  head: ["Vendor", "Supported Models", "Filename", "Firmware Version", "Created At"],
  body: (item) => [
    item.vendor,
    item.models.map(({ name }) => name).join(" "),
    item.version,
    item.createdAt,
  ],
};

export const sortFields = ["vendor", "version"];

const searchFields = columns.map(({ field }) => field);

export const config = { filterConfig, csvConfig, searchFields };
