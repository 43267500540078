import { object, string, number, array } from "yup";

const positiveNumberSchema = (label) => {
  return number()
    .typeError(`${label} should be a number`)
    .moreThan(0, `${label} should be greater than 0`)
    .required(`${label} is required`);
};

const connectorSchema = object().shape({
  connectorNumber: positiveNumberSchema("Connector ID"),
  connectorType: string().required("Connector Type is required"),
  powerRating: positiveNumberSchema("Nominal Power"),
});

export const validationSchema = object().shape({
  modelName: string().required("Model Name is required"),
  vendor: string().required("Vendor is required"),
  deviceType: string().required("Device Type is required"),
  powerRating: positiveNumberSchema("Power Rating"),
  connectors: array().of(connectorSchema),
});
