import { deleteHandler } from "@utils";
import { DriverAPI } from "@api";

export const actions = {
  delete: async ({ id }) => deleteHandler("Driver", DriverAPI.delete, id),
  sendOtp: ({ id }) => DriverAPI.sendOtp(id),
};

export const texts = {
  delete: ({ userName }) => `Are you sure you want to delete driver ${userName}?`,
  sendOtp: () => "Are you sure you want to send OTP?",
};
