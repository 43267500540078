import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { ConfirmationModal } from "@components/modals";
import {
  LoadingContainer,
  Button,
  Paper,
  SearchInput,
  Table,
  Link,
  Switcher,
  DateTime,
} from "@components/new";
import { useConfirmation, useTable } from "@hooks";
import { RfidAPI, RfidGroupAPI } from "@api";

import { RFIDModal } from "./RFIDModal";
import { actions, texts } from "./action-popup";
import { columns, sortFields, reducer } from "./config.js";
import { rfidMapper, groupSelectMapper } from "./mappers";
import styles from "./styles.module.scss";

export const RFIDList = ({ driverId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [statusesMap, setStatusesMap] = useState();
  const [rfids, setRfids] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const { ref, invoke } = useTable();
  const [modal, setModal] = useState({ visible: false, id: "", enabled: false });

  useEffect(() => {
    setIsLoading(true);
    Promise.all([RfidAPI.getRFIDForDriver(driverId), RfidGroupAPI.getGroupSimpleList()])
      .then(([rfidList, rfidGroups]) => {
        const mapped = rfidList.map(rfidMapper);
        setStatusesMap(mapped.reduce(reducer, new Map()));
        setRfids(mapped);
        setAvailableGroups(rfidGroups.map(groupSelectMapper));
      })
      .finally(() => setIsLoading(false));
  }, [refreshList]);

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentRFID, setCurrentRFID] = useState();
  const toggleAddModal = () => {
    setIsAddModalVisible((isVisible) => !isVisible);
    setCurrentRFID(undefined);
  };

  const closeModal = () => {
    setIsAddModalVisible(false);
  };

  const toggleEditModal = (rfid) => {
    setCurrentRFID(rfid);
    setIsAddModalVisible((isVisible) => !isVisible);
  };

  const renderItem = (item, field) => {
    const value = item[field];

    switch (field) {
      case "rfidGroups": {
        const joined = value.map((r, i, { length }) => {
          const endOFLine = length - 1 !== i ? "," : "";
          return (
            <Box key={i}>
              <Link
                text={r.name + endOFLine}
                path={`/administration/rfid-groups/${r.id}/details`}
              />
            </Box>
          );
        });
        return <Box>{joined}</Box>;
      }
      case "creationDate":
      case "activationDate":
      case "expirationDate": {
        return <DateTime value={value} twoLine={true} />;
      }
      case "enabled": {
        const { id } = item;
        const enabled = statusesMap.get(id);
        const onChange = () => setModal({ visible: true, id, enabled });
        return <Switcher checked={enabled} onChange={onChange} />;
      }
      default: {
        return value;
      }
    }
  };

  const { isVisible, open, ...rest } = useConfirmation(actions, texts);

  const onConfirm = async () => {
    const status = !modal.enabled;
    await RfidAPI.updateStatus(modal.id, status);
    setStatusesMap(new Map(statusesMap.set(modal.id, status)));
    setModal((modal) => ({ ...modal, visible: false }));
  };

  const onSave = async (rest, recordId) => {
    await (recordId ? RfidAPI.update(recordId, rest) : RfidAPI.create(rest));
    refreshDriverList();
  };

  const refreshDriverList = () => {
    setRefreshList(!refreshList);
  };
  const onReject = () => {
    setModal((modal) => ({ ...modal, visible: false }));
  };

  const tableActions = [
    (rfid) => ({ label: "Edit", onClick: () => toggleEditModal(rfid) }),
    (rfid) => ({ label: "Delete", onClick: () => open("delete", rfid, refreshDriverList) }),
  ];

  const text = `Are you sure you want to ${modal.enabled ? "Disable" : "Enable"} this RFID Card?`;

  return (
    <LoadingContainer loading={isLoading} className={styles.loading}>
      <Paper className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>Assigned RFIDs</Typography>
          <Box className={styles.tools}>
            <SearchInput placeholder={"Search by Name, Email"} onChange={invoke("search")} />
            <Button variant={"contained"} text={"Create RFID"} onClick={toggleAddModal} />
          </Box>
        </Box>
        <Table
          ref={ref}
          data={rfids}
          columns={columns}
          actions={tableActions}
          renderItem={renderItem}
          sortFields={sortFields}
          tableClassName={styles.table}
        />
      </Paper>
      <RFIDModal
        open={isAddModalVisible}
        rfid={currentRFID}
        driverId={driverId}
        groups={availableGroups}
        onSave={onSave}
        onClose={closeModal}
      />
      <ConfirmationModal open={isVisible} {...rest} />
      <ConfirmationModal
        open={modal.visible}
        text={text}
        onConfirm={onConfirm}
        onReject={onReject}
      />
    </LoadingContainer>
  );
};
