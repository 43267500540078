import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

import { DetailPageHeader, LoadingContainer } from "@components/new";
import { RfidGroupAPI } from "@api";

import { ManageModal } from "./ManageModal";
import { RfidList } from "./RfidList";
import { GeneralInfo } from "./general";
import { mapper } from "./mapper";
import styles from "./styles.module.scss";

export const RfidGroupDetails = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [group, setGroup] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    RfidGroupAPI.getGroupById(groupId)
      .then((groupResp) => {
        setGroup(mapper(groupResp));
      })
      .finally(setIsLoading(false));
  }, [groupId]);
  const onGoBack = () => {
    navigate(-1);
  };

  const init = async () => {
    RfidGroupAPI.getGroupById(groupId).then((groupResp) => {
      setGroup(mapper(groupResp));
    });
  };

  const onSave = async (data, id) => {
    try {
      await RfidGroupAPI.update(id, data);
      init();
      toast.success("Rfid has been successfully updated");
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const toggleModals = (type, mode) => {
    return () => {
      if (mode === "refresh") init();
      setIsAddModalVisible((isVisible) => !isVisible);
    };
  };

  const closeRfidGroupModal = () => {
    setIsAddModalVisible(false);
  };

  const goBackText = "Back to RFID Group List";

  return (
    <>
      <Box className={styles.container}>
        <DetailPageHeader title={group?.name} goBackText={goBackText} onGoBack={onGoBack} />
        <LoadingContainer loading={isLoading}>
          <Box className={styles.row}>
            <GeneralInfo group={group} onEdit={toggleModals("edit", "refresh")} />
            <RfidList groupId={groupId} />
          </Box>
        </LoadingContainer>
      </Box>
      <ManageModal
        open={isAddModalVisible}
        group={group}
        onSave={onSave}
        onClose={closeRfidGroupModal}
        titles={"Edit Group"}
      />
    </>
  );
};
