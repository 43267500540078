import { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthHelper } from "@utils";
import { UserAPI } from "@api";

const initials = { user: null, isAdmin: false, getUser: () => Promise.resolve(), logout: () => {} };

const AuthContext = createContext(initials);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(null);

  const navigate = useNavigate();

  const getUser = async () => {
    const user = await UserAPI.getDetails();
    setUser(user);
  };

  const token = localStorage.getItem("token");

  const isAdmin = AuthHelper.isAdmin(token);

  useEffect(() => {
    const isTokenValid = !!token && !AuthHelper.isTokenExpired(token);

    if (isTokenValid) {
      getUser();
    } else {
      navigate("/login");
      localStorage.clear();
    }

    setIsAuthorized(isTokenValid);
  }, [token]);

  const logout = () => {
    localStorage.clear();
    navigate("/login");
    setUser(null);
  };

  if (isAuthorized === null) return null;

  const value = { user, isAdmin, getUser, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
