import { useRef, useEffect, useState } from "react";

import styles from "./styles.module.scss";

const areCoordsValid = (latitude, longitude) => {
  return Math.abs(latitude) < 90 && Math.abs(longitude) < 180;
};

export const Map = ({ latitude, longitude, onAdjust, draggable = true }) => {
  const container = useRef(null);
  const map = useRef(null);

  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (!areCoordsValid(latitude, longitude)) return;

    const position = new google.maps.LatLng(latitude, longitude);

    if (map.current) {
      map.current.setCenter(position);
    } else {
      map.current = new google.maps.Map(container.current, {
        zoom: 15,
        center: position,
        gestureHandling: "none",
        disableDefaultUI: true,
      });
    }

    if (marker) {
      marker.setPosition(position);
    } else {
      setMarker(new google.maps.Marker({ map: map.current, position, draggable }));
    }
  }, [longitude, latitude, marker, draggable]);

  useEffect(() => {
    if (!marker) return;

    const dragListener = marker.addListener("dragend", ({ latLng }) => {
      onAdjust(latLng);
      map.current.setCenter(latLng);
    });

    return () => {
      dragListener?.remove();
    };
  }, [marker]);

  if (!areCoordsValid(latitude, longitude)) return null;

  return <div ref={container} className={styles.map} />;
};
