import { Box } from "@mui/material";

import { LoadingContainer } from "@components/new";

import { DriverGroupList } from "./DriverGroupList";
import { GeneralInfo } from "./GeneralInfo";
import { UserList } from "./UserList";
import styles from "./styles.module.scss";

export const GeneralTab = ({ business, loading }) => {
  return (
    <LoadingContainer loading={loading} className={styles.body}>
      <Box className={styles.container}>
        <GeneralInfo business={business} />
        <DriverGroupList businessId={business?.id} />
        <UserList businessId={business?.id} />
      </Box>
    </LoadingContainer>
  );
};
