import { Box } from "@mui/material";
import cx from "classnames";

import { ReactComponent as ArrowLeftIcon } from "@assets/icons/icon-dropleft.svg";
import { ReactComponent as ArrowRightIcon } from "@assets/icons/icon-dropright.svg";

import styles from "./styles.module.scss";

export const Handle = ({ open, onClick, className }) => {
  const classes = cx(styles.container, className);

  return (
    <Box onClick={onClick} className={classes}>
      {open ? <ArrowLeftIcon /> : <ArrowRightIcon />}
    </Box>
  );
};
