export const fields = [
  {
    name: "username",
    label: "Username",
    url: "username",
    type: "text",
  },
  {
    name: "password",
    label: "Password",
    url: "password",
    type: "password",
  },
];
