import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import cx from "classnames";

import { Filter, Link, Paper, SearchInput, Table, DateTime } from "@components/new";
import { usePagination, useTable } from "@hooks";
import { DateHelper } from "@utils";
import { TransactionAPI } from "@api";

import { Wrapper } from "../../wrapper";

import { columns, filters } from "./config";
import { transactionMapper } from "./mapper";
import styles from "./styles.module.scss";

export const Transactions = ({ driverId, className }) => {
  const classes = cx(styles.container, className);

  const { ref, invoke } = useTable();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchTransactions = (pagination) => {
    return TransactionAPI.getByUser({ userId: driverId, ...pagination });
  };

  const { fetch, ...pagination } = usePagination(fetchTransactions);

  useEffect(() => {
    if (!driverId) return;
    setIsLoading(true);

    fetch()
      .then((transactions) => {
        setTransactions(transactions.map(transactionMapper));
      })
      .finally(() => setIsLoading(false));
  }, [driverId, pagination.page]);

  const renderItem = (item, field) => {
    const value = item[field];

    switch (field) {
      case "identifier": {
        const path = `/charger-management/transaction-list/${item.id}`;
        return <Link text={value} path={path} />;
      }
      case "stationName": {
        const path = `/charger-management/charger-list/${item.stationId}/details`;
        return <Link text={value} path={path} />;
      }
      case "startDate":
      case "endDate": {
        return value ? <DateTime value={value} twoLine /> : "NA";
      }
      case "connectionTime": {
        return value ? DateHelper.getDurationStringFromSeconds(value) : "-";
      }
      case "chargingTime": {
        return DateHelper.getDurationString(value);
      }
      default: {
        return value;
      }
    }
  };

  return (
    <Wrapper loading={isLoading}>
      <Paper className={classes}>
        <Box className={styles.header}>
          <Typography className={styles.title}>Transactions</Typography>
          <Box className={styles.tools}>
            <SearchInput placeholder={"Search"} onChange={invoke("search")} />
            <Filter data={transactions} config={filters} onChange={invoke("filter")} />
          </Box>
        </Box>
        <Table
          ref={ref}
          columns={columns}
          data={transactions}
          sortFields={"all"}
          renderItem={renderItem}
          {...pagination}
        />
      </Paper>
    </Wrapper>
  );
};
