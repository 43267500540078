import { useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { Box, IconButton } from "@mui/material";
import { FaTrash } from "react-icons/fa";

import {
  RadioGroup,
  Switcher,
  LoadingContainer,
  Paper,
  TextInput,
  Button,
  Table,
} from "@components/new";
import { usePagination } from "@hooks";
import { ChargeStationAPI, LoadManagementAPI } from "@api";

import { columns, LoadManagementType } from "./config";
import { chargerMapper, loadManagementMapper } from "./mappers";
import styles from "./styles.module.scss";

const initialState = {
  config: null,
  enabled: false,
  loading: false,
  chargers: [],
  availablePower: 0,
  type: LoadManagementType.Distributed,
};

const reducer = (state, action) => ({ ...state, ...action });

export const LoadManagement = ({ siteId }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchStations = (pagination) => {
    return ChargeStationAPI.get({ siteId, ...pagination });
  };

  const { fetch, ...pagination } = usePagination(fetchStations);

  const init = async () => {
    const data = await LoadManagementAPI.getById(siteId);
    const config = data ? loadManagementMapper(data) : null;
    const type = config?.type ?? LoadManagementType.Distributed;
    const enabled = config?.status === "ENABLED";
    const availablePower = config?.totalPowerLimit ?? 0;
    dispatch({ type, enabled, availablePower, config });
  };

  useEffect(() => {
    init();
  }, [siteId]);

  useEffect(() => {
    dispatch({ loading: true });
    fetch()
      .then((data) => {
        const chargers = data.map(chargerMapper);
        dispatch({ chargers });
      })
      .finally(() => dispatch({ loading: false }));
  }, [pagination.page]);

  const toggleSwitcher = async () => {
    const { type, enabled, config } = state;
    const { id, type: configType } = config;

    if (!id || type !== configType) {
      toast.info("Save load management schema before enabling/disabling it");
      return;
    }

    dispatch({ enabled: !enabled });

    try {
      if (enabled) {
        await LoadManagementAPI.disable(id);
      } else {
        await LoadManagementAPI.enable(id);
      }
      await init();
      toast.success(
        `Load management schema has been successfully ${enabled ? "disabled" : "enabled"}`,
      );
    } catch {
      toast.error(
        `An error occured while ${enabled ? "disabling" : "enabling"} the load management schema`,
      );
      dispatch({ enabled: !enabled });
    }
  };

  const onDelete = async () => {
    try {
      await LoadManagementAPI.delete(state.config.id);
      toast.success("Load management schema has been successfully deleted");
      init();
    } catch {
      toast.error("An error occured while deleting load management schema");
    }
  };

  const onTypeChange = ({ target }) => {
    const type = target.value;
    const enabled = state.config.status === "ENABLED" && state.config.type === type;
    dispatch({ type, enabled });
  };

  const onPowerChange = (stationId) => {
    return ({ target }) => {
      const powerLimit = target.valueAsNumber;
      const distribution = state.config.distributions.get(stationId);
      const distributions = new Map(
        state.config.distributions.set(
          stationId,
          Object.assign(distribution ?? {}, { powerLimit }),
        ),
      );
      dispatch({ config: { ...state.config, distributions } });
    };
  };

  const renderItem = (item, field) => {
    const value = item[field];

    switch (field) {
      case "hardwareStatus": {
        return value ? "Online" : "Offline";
      }
      case "powerLimit": {
        const { powerLimit } = state.config.distributions.get(item.id) ?? { powerLimit: 0 };

        if (state.type === LoadManagementType.Custom) {
          return (
            <TextInput
              type={"number"}
              value={powerLimit}
              inputClassName={styles.input}
              onChange={onPowerChange(item.id)}
            />
          );
        }

        return powerLimit;
      }
      default: {
        return value;
      }
    }
  };

  const onAvailablePowerChange = ({ target }) => {
    const availablePower = target.valueAsNumber;

    dispatch({ availablePower });
  };

  const onAdd = () => {
    const config = { distributions: new Map() };
    dispatch({ enabled: false, config });
  };

  const onClear = () => {
    const distributions = [...state.config.distributions.entries()].map(([id, item]) => [
      id,
      { ...item, powerLimit: 0 },
    ]);

    dispatch({ config: { ...state.config, distributions: new Map(distributions) } });
  };

  const onSave = async () => {
    try {
      const { id, type } = state.config;

      let payload = {
        type: state.type,
        chargeSiteId: siteId,
        totalPowerLimit: state.availablePower,
      };

      if (state.type === LoadManagementType.Custom) {
        let totalDistribution = 0;

        const distributions = state.chargers.reduce((acc, item) => {
          const distribution = state.config.distributions.get(item.id);

          if (!distribution) return acc;

          totalDistribution += distribution.powerLimit;
          acc.push({ chargeStationId: item.id, powerLimit: distribution.powerLimit });

          return acc;
        }, []);

        if (totalDistribution > state.availablePower) {
          throw new Error("Total power distribution exceeds the Available Power");
        }

        payload = { ...payload, distributions };
      }

      if (id && state.type === type) {
        await LoadManagementAPI.update(id, payload);
        toast.success("Load management schema has been successfully updated");
      } else {
        await LoadManagementAPI.create(payload);
        toast.success("Load management schema has been successfully created");
      }
      init();
    } catch ({ message }) {
      toast.error(message);
    }
  };

  return (
    <LoadingContainer loading={state.loading} className={styles.container}>
      {state.config ? (
        <>
          <Paper className={styles.content}>
            <Box className={styles.left}>
              <Switcher label={"Enabled"} checked={state.enabled} onChange={toggleSwitcher} />
              <TextInput
                type={"number"}
                value={state.availablePower}
                className={styles.power}
                label={"Available Power (Amps)"}
                onChange={onAvailablePowerChange}
              />
              <RadioGroup
                value={state.type}
                onChange={onTypeChange}
                options={[
                  { label: "Equally Distributed", value: LoadManagementType.Distributed },
                  { label: "Custom", value: LoadManagementType.Custom },
                ]}
              />
            </Box>
            <Box className={styles.right}>
              {!!state.config.id && (
                <IconButton onClick={onDelete}>
                  <FaTrash size={20} color={"red"} />
                </IconButton>
              )}
              <Button
                variant={"contained"}
                text={"Save Changes"}
                onClick={onSave}
                className={styles.save}
              />
            </Box>
          </Paper>
          <Paper className={styles.paper}>
            {state.type === LoadManagementType.Custom && (
              <Button
                onClick={onClear}
                variant={"contained"}
                text={"Clear All Values"}
                className={styles.clear}
              />
            )}
            <Table data={state.chargers} columns={columns} renderItem={renderItem} />
          </Paper>
        </>
      ) : (
        <Button variant={"contained"} text={"Add Load Management"} onClick={onAdd} />
      )}
    </LoadingContainer>
  );
};
