import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { Wrapper } from "../wrapper";

import { BusinessList } from "./BusinessList";
import { RoleList } from "./RoleList";
import { GeneralInfo } from "./general";
import styles from "./styles.module.scss";

export const InfoTab = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user !== undefined) {
      setIsLoading(false);
    }
  }, [user]);

  return (
    <Wrapper loading={isLoading}>
      <Box className={styles.body}>
        <GeneralInfo user={user} />
        <Box />
        <RoleList userId={user?.id} />
        <BusinessList userId={user?.id} />
      </Box>
    </Wrapper>
  );
};
