import { ListItem, Box, Typography } from "@mui/material";
import cx from "classnames";

import { ReactComponent as ArrowDownIcon } from "@assets/icons/icon-dropdown.svg";
import { ReactComponent as ArrowUpIcon } from "@assets/icons/icon-dropup.svg";

import styles from "./styles.module.scss";

export const MenuItem = ({ open, active, hasItems, icon, title, expanded, onClick }) => {
  const containerClasses = cx(styles.container, active && styles.active);
  const leftClasses = cx(styles.left, !open && styles.closed);

  return (
    <ListItem onClick={onClick} className={containerClasses}>
      <Box className={leftClasses}>
        {icon}
        {open && <Typography className={styles.title}>{title}</Typography>}
      </Box>
      {hasItems && open && (expanded ? <ArrowUpIcon /> : <ArrowDownIcon />)}
    </ListItem>
  );
};
