import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

import { Lock, Check } from "@assets/auth-flow";
import { FormTextInput } from "@components/form/new";
import { Button } from "@components/new";
import { useForm } from "@hooks";
import { forgotSchema } from "@utils/validation";
import { AuthAPI } from "@api";

import { forgotData } from "./config";
import styles from "./styles.module.scss";

export const ForgotPage = ({ type }) => {
  const navigate = useNavigate();

  const { register, control, handleSubmit, errors } = useForm({ validationSchema: forgotSchema });

  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const { capital, action, successTitle, url } = forgotData[type];

  const onSubmit = async ({ email }) => {
    if (!loading) {
      setLoading(true);
      try {
        await AuthAPI.requestResetUsernameOrPassword(type, email);
        setSuccessful(true);
      } catch {
        toast.error("User not found for this email");
      } finally {
        setLoading(false);
      }
    }
  };

  const redirect = () => {
    navigate(url);
  };

  return (
    <Box className={styles.container}>
      {successful ? (
        <>
          <img src={Check} className={styles.image} alt="check" />
          <Box className={styles.header}>
            <Box className={styles.title}>{successTitle}</Box>
            <Box className={styles.subtitle}>
              {`${capital} ${action} request was sent successfully. Please check your email to ${action} ${type}.`}
            </Box>
          </Box>
          <Button variant="csv" onClick={redirect}>
            OK
          </Button>
        </>
      ) : (
        <>
          <img src={Lock} className={styles.image} alt="lock" />
          <Box className={styles.header}>
            <Box className={styles.title}>{`Forgot Your ${capital}?`}</Box>
            <Box className={styles.subtitle}>
              {`No worries! Enter your email and we will send you an email to ${action} your ${type}.`}
            </Box>
          </Box>
          <Box className={styles.form}>
            <FormTextInput
              placeholder={"Email"}
              control={control}
              error={errors.email}
              {...register("email")}
            />
            <Button
              type={"primary"}
              loading={loading}
              text={"Send Email"}
              variant={"contained"}
              className={styles.button}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
