import { Typography } from "@mui/material";
import cx from "classnames";

import styles from "./styles.module.scss";

export const FormLabel = ({ text, className }) => {
  const classes = cx(styles.label, className);

  return <Typography className={classes}>{text}</Typography>;
};
