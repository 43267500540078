import { forwardRef } from "react";
import { Controller } from "react-hook-form";

import { Checkbox } from "@components/new";

import { FormField } from "../FormField";

import styles from "./styles.module.scss";

const Component = ({ name, label, control, error }, _) => {
  const render = ({ field }) => {
    const { value, onChange } = field;

    return (
      <FormField label={label} error={error} className={styles.container}>
        <Checkbox checked={value} onCheck={onChange} />
      </FormField>
    );
  };

  return <Controller name={name} control={control} render={render} />;
};

export const FormCheckbox = forwardRef(Component);
