export const rfidMapper = (item) => ({
  id: item.id,
  rfid: item.rfid,
  driverId: item.driver.driverId,
  enabled: item.enabled,
  creationDate: item.creationDate,
  activationDate: item.activationDate,
  expirationDate: item.expirationDate,
  rfidGroups: item.rfidGroupIds.map(groupMapper),
});

export const groupMapper = (item) => ({
  id: item.groupId,
  name: item.groupName,
});

export const groupSelectMapper = (item) => ({
  value: item.uuid,
  label: item.name,
});
