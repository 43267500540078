import { Delete } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

import { FormTextInput } from "@components/form/new";

import styles from "./styles.module.scss";

export const PricingDetail = ({ control, name, errors, deleteEnabled, onDelete, register }) => {
  return (
    <Box className={styles.container}>
      <FormTextInput
        control={control}
        error={errors?.pricingDetail}
        placeholder={"Pricing Detail"}
        {...register(`${name}.pricingDetail`)}
      />
      <FormTextInput
        type={"number"}
        control={control}
        placeholder={"Price"}
        error={errors?.price}
        {...register(`${name}.price`)}
      />
      {deleteEnabled && (
        <IconButton onClick={onDelete} className={styles.icon}>
          <Delete />
        </IconButton>
      )}
    </Box>
  );
};
