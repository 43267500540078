import { useState } from "react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

import { FormCheckbox, FormTextInput } from "@components/form/new";
import { ConfirmationModal } from "@components/modals";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";

import styles from "./styles.module.scss";
import { getValidationSchema } from "./validation";

const initialValues = {
  userName: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  password: "",
  status: false,
};

export const DriverModal = ({ open, driver, onSave, onClose }) => {
  const title = driver ? "Edit EV Driver" : "Register EV Driver";

  const defaultValues = driver || initialValues;
  const validationSchema = getValidationSchema(driver);

  const { register, isDirty, errors, control, handleSubmit } = useForm({
    open,
    defaultValues,
    validationSchema,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);

  const handleSave = async (rest) => {
    setIsLoading(true);
    try {
      await onSave(rest, driver?.id);
    } catch (e) {
      toast.error("Invalid credentials");
    } finally {
      setIsLoading(false);
    }
  };

  const cancel = () => {
    isDirty ? setIsConfirmationModalVisible(true) : onClose();
  };

  const hideConfirmation = () => {
    setIsConfirmationModalVisible(false);
  };

  const onConfirm = () => {
    hideConfirmation();
    onClose();
  };

  return (
    <>
      <ModalContainer
        open={open}
        title={title}
        onClose={cancel}
        onCancel={cancel}
        loading={isLoading}
        onSave={handleSubmit(handleSave)}
      >
        <Box className={styles.data}>
          <FormTextInput
            control={control}
            label={"User Name"}
            error={errors.userName}
            {...register("userName")}
          />
          <FormTextInput
            control={control}
            label={"First Name"}
            error={errors.firstName}
            {...register("firstName")}
          />
          <FormTextInput
            control={control}
            label={"Last Name"}
            error={errors.lastName}
            {...register("lastName")}
          />
          <FormTextInput
            control={control}
            label={"Phone Number"}
            error={errors.phoneNumber}
            {...register("phoneNumber")}
          />
          <FormTextInput
            label={"Email"}
            control={control}
            error={errors.email}
            {...register("email")}
          />
          {!driver && (
            <FormTextInput
              control={control}
              label={"Password"}
              error={errors.password}
              {...register("password")}
            />
          )}
          <FormCheckbox label={"Active"} control={control} {...register("status")} />
        </Box>
      </ModalContainer>
      <ConfirmationModal
        open={isConfirmationModalVisible}
        text={"Are you sure you want to close?"}
        subtext={"All unsaved changes will be lost"}
        onReject={hideConfirmation}
        onConfirm={onConfirm}
      />
    </>
  );
};
