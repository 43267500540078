import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { DetailPageHeader, PageTabs } from "@components/new";
import { BusinessAPI } from "@api";

import { DetailsTab } from "./DetailsTab";
import { GeneralTab } from "./GeneralTab";
import { mapper } from "./mappers";
import styles from "./styles.module.scss";

export const BusinessDetails = () => {
  const navigate = useNavigate();
  const { businessId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [business, setBusiness] = useState(null);

  useEffect(() => {
    BusinessAPI.getByIdGeneralInfo(businessId)
      .then((data) => setBusiness(mapper(data)))
      .finally(() => setIsLoading(false));
  }, [businessId]);

  const goBackTest = "Back to Business List";

  const tabs = [
    { label: "General", component: <GeneralTab business={business} loading={isLoading} /> },
    { label: "Details", component: <DetailsTab /> },
  ];
  return (
    <Box className={styles.container}>
      <DetailPageHeader
        title={business?.name}
        goBackText={goBackTest}
        onGoBack={() => navigate(-1)}
      />
      <PageTabs tabs={tabs} />
    </Box>
  );
};
