import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import cx from "classnames";

import classes from "./styles.module.css";

export const ConfirmationModal = ({
  open,
  text,
  onReject,
  onConfirm,
  confirmButtonTitle = "Yes",
  rejectButtonTitle = "No",
}) => (
  <Dialog open={open}>
    <DialogContent className={classes.modal_content}>
      <Typography className={classes.delete_text}>{text}</Typography>
      <Box className={classes.btn_container}>
        <button className={cx(classes.btn, classes.btn_delete)} onClick={onConfirm}>
          {confirmButtonTitle}
        </button>
        <button className={cx(classes.btn, classes.btn_close)} onClick={onReject}>
          {rejectButtonTitle}
        </button>
      </Box>
    </DialogContent>
  </Dialog>
);
