import axios from "axios";

const baseURL = process.env.REACT_APP_SERVER_URL;

export const api = axios.create({ baseURL });

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Content-Type"] = "application/json";
  return config;
}, Promise.reject);

api.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    const { status } = error.response;
    if ((status === 401 || status === 0) && location.pathname !== "/login") {
      location.href = "/login";
    }
    return Promise.reject(error);
  },
);
