import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { ChargerList } from "@pages/ChargerManagement";
import { ChargeSitePricingPage } from "@pages/PricingManagement";
import { DetailPageHeader, PageTabs } from "@components/new";
import { ChargeSiteAPI } from "@api";

import { InfoTab } from "./info";
import { LoadManagement } from "./load-management";
import { mapper } from "./mapper";
import styles from "./styles.module.scss";

export const SiteDetails = () => {
  const navigate = useNavigate();

  const [site, setSite] = useState();

  const { siteId } = useParams();

  const onGoBack = () => {
    navigate(-1);
  };

  const init = async () => {
    const data = await ChargeSiteAPI.getById(siteId);
    setSite(mapper(data));
  };

  useEffect(() => {
    init();
  }, [siteId]);

  const tabs = [
    { label: "General", component: <InfoTab siteId={siteId} save={init} /> },
    { label: "Pricing", component: <ChargeSitePricingPage siteIdFromSite={siteId} /> },
    { label: "Load Management", component: <LoadManagement siteId={siteId} /> },
    {
      label: "Stations",
      component: <ChargerList siteIdFromSite={siteId} fromChargerManagement={false} />,
    },
  ];

  return (
    <Box className={styles.container}>
      <DetailPageHeader
        title={site?.siteName}
        goBackText={"Back to Site List"}
        onGoBack={onGoBack}
      />
      <PageTabs tabs={tabs} />
    </Box>
  );
};
