import { Typography } from "@mui/material";
import cx from "classnames";

import styles from "./styles.module.scss";

export const EmptyPlaceholder = ({ isEmpty, title, className, children }) => {
  if (!isEmpty) return children;

  const classes = cx(styles.container, className);

  return (
    <div className={classes}>
      <Typography className={styles.title}>{title}</Typography>
      <br />
    </div>
  );
};
