import searchIcon from "@assets/icons/icon-search.svg";

import styles from "./styles.module.css";

export const SearchInput = ({ onChange }) => {
  return (
    <div className={styles.custom_input}>
      <div className={styles.iconInput}>
        <img src={searchIcon} alt={"search icon"} className={styles.img_field} />
        <input type="text" onChange={onChange} className={styles.input_field} />
      </div>
    </div>
  );
};
