import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { DetailPageHeader, LoadingContainer } from "@components/new";
import { DriverGroupAPI } from "@api";

import { DriverList } from "./DriverList";
import { GeneralInfo } from "./general";
import { mapper } from "./mapper";
import styles from "./styles.module.scss";

export const DriverGroupDetails = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [group, setGroup] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    DriverGroupAPI.getGroupById(groupId)
      .then((groupResp) => {
        setGroup(mapper(groupResp));
      })
      .finally(setIsLoading(false));
  }, [groupId]);
  const onGoBack = () => {
    navigate(-1);
  };

  const goBackText = "Back to Driver Group List";

  return (
    <Box className={styles.container}>
      <DetailPageHeader title={group?.name} goBackText={goBackText} onGoBack={onGoBack} />
      <LoadingContainer loading={isLoading}>
        <Box className={styles.row}>
          <GeneralInfo group={group} />
          <DriverList groupId={groupId} />
        </Box>
      </LoadingContainer>
    </Box>
  );
};
