export const driverMapper = (item) => ({
  id: item.id,
  userName: item.userName,
  firstName: item.firstName,
  lastName: item.lastName,
  phoneNumber: item.phoneNumber,
  email: item.email,
  status: item.activeUser,
  isDeleted: item.deleted,
  enabled: item.enabled,
  createdAt: item.createdAt,
  updatedAt: item.updatedAt,
  lastLoginAt: item.lastLoginAt,
});
