import { api } from "./axios";

export class FirmwareAPI {
  static getAll({ page, size }) {
    return api.get("chargestationfirmware", { params: { page, size } });
  }

  static getByModel() {
    return api.get("chargestationfirmware/chargestationmodel");
  }

  static create(payload) {
    return api.post("chargestationfirmware", payload, {
      headers: { "content-type": "multipart/form-data" },
    });
  }

  static update(id, payload) {
    return api.post(`chargestationfirmware/${id}`, payload, {
      headers: { "content-type": "multipart/form-data" },
    });
  }

  static delete(id) {
    return api.delete(`chargestationfirmware/${id}`);
  }

  static applyUpdate(payload) {
    return api.put("chargestationfirmware/remote", payload);
  }

  static createByStation(id, payload) {
    return api.post(`chargestationfirmware/chargestation/${id}`, payload);
  }

  static deleteMultiple(ids) {
    return api.delete("chargestationfirmware", { params: { ids } });
  }
}
