export const mapper = (item) => ({
  ...item,
  logsType: item.logsType,
  ocppMessageType: item.ocppMessageType,
  payload: item.payload,
  time: item.timestamp,
});

export const columns = [
  {
    label: "Log Type",
    field: "logsType",
  },
  {
    label: "Ocpp Message Type",
    field: "ocppMessageType",
  },
  {
    label: "Payload",
    field: "payload",
  },
  {
    label: "Message Date",
    field: "time",
  },
];

export const filters = [
  {
    name: "Date",
    field: "date",
    options: ["Last 30 Minutes", "Last Hour", "Last 4 Hours", "Last 24 Hours"],
  },
];
