import { useEffect, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

import { BreadcrumbsContainer, LoadingContainer, PageTitle } from "@components/common";
import { Button } from "@components/new";
import { ChargeStationAPI, ConfigurationAPI } from "@api";

import { menu } from "./config";
import { mapper } from "./mappers";
import styles from "./styles.module.scss";

export const RemoteDeviceManagement = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [messages, setMessages] = useState([]);
  const [stations, setStations] = useState([]);
  const [stationId, setStationId] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    setIsLoading(true);
    ChargeStationAPI.getSimpleList()
      .then((data) => setStations(data.map(mapper)))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setMessages([]);
  }, [stationId]);

  const getSelector = (label, options, value, onChange) => {
    return (
      <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select value={value} onChange={onChange} label={label}>
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const chooseStation = ({ target: { value } }) => {
    setStationId(value);
  };

  const chooseMessage = ({ target: { value } }) => {
    setMessageType(value);
  };

  const sendMessage = async () => {
    if (messageType.includes("resetType")) {
      const [_, type] = messageType.split("=");
      const message = await ConfigurationAPI.reset(stationId, type);
      setMessages((messages) => [...messages, message]);
    }
  };

  return (
    <LoadingContainer loading={isLoading}>
      <BreadcrumbsContainer source={"Administration"} pages={["Remote Device Management"]} />
      <PageTitle text={"User Debug"} />
      <Box className={styles.body}>
        <Box className={styles.menu}>
          {getSelector("Charge Station", stations, stationId, chooseStation)}
          {getSelector("Message Type", menu, messageType, chooseMessage)}
          <Button variant={"outlined"} text={"Send Message"} onClick={sendMessage} />
        </Box>
        <TextField
          multiline
          rows={20}
          label="Charger Messages"
          value={messages.join("\n")}
          className={styles.messages}
        />
      </Box>
    </LoadingContainer>
  );
};
