import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { FormField } from "../FormField";

const Component = ({ name, label, control, error }, _) => {
  const renderInput = (params) => {
    return <TextField {...params} />;
  };

  const render = ({ field }) => {
    const { value, onChange } = field;

    return (
      <FormField label={label} error={error}>
        <DesktopDatePicker value={value} renderInput={renderInput} onChange={onChange} />
      </FormField>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller name={name} control={control} render={render} />
    </LocalizationProvider>
  );
};

export const FormDatePicker = forwardRef(Component);
