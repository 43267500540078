import { Checkbox as MUICheckbox } from "@mui/material";

import styles from "./styles.module.scss";

export const Checkbox = ({ id, label, checked, onCheck }) => {
  return (
    <>
      <MUICheckbox
        id={id}
        checked={checked}
        onChange={onCheck}
        className={styles.root}
        classes={{ root: styles.root }}
      />
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
    </>
  );
};
