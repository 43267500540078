import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { LogAPI } from "api/log";
import moment from "moment";

import { LoadingContainer } from "@components/common";
import { Filter, Paper, SearchInput, Table } from "@components/new";
import { useStationDetails } from "@providers";
import { usePagination, useTable } from "@hooks";
import { DateHelper } from "@utils";

import { columns, filters, mapper } from "./config";
import styles from "./styles.module.scss";

export const LogTab = () => {
  const { station } = useStationDetails();

  const [isLoading, setIsLoading] = useState(!station?.id || true);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [logs, setLogs] = useState([]);

  const { ref, invoke } = useTable();

  const fetchLogs = (pagination) => {
    if (!station.id) return Promise.resolve({ items: [] });

    return LogAPI.getAll({ chargeStationId: station.id, startTime, endTime, ...pagination });
  };

  const { fetch, ...pagination } = usePagination(fetchLogs);

  useEffect(() => {
    setIsLoading(true);
    fetch()
      .then((data) => setLogs(data.map(mapper)))
      .finally(() => setIsLoading(false));
  }, [station?.id, pagination.page, startTime, endTime]);

  const renderItem = (item, field) => {
    const value = item[field];

    if (field === "time") {
      return DateHelper.formatDateToLocal(value);
    }

    return value;
  };

  const callData = (filterArray) => {
    if (filterArray.length > 0) {
      const filterData = filterArray[filterArray.length - 1];
      if (filterData.field === "customDate") {
        const filterStartDate = filterData.options.startDate;
        const filterEndDate = filterData.options.endDate;
        setStartTime(filterStartDate._d.getTime() / 1000);
        setEndTime(filterEndDate._d.getTime() / 1000);
      } else if (filterData.field === "date") {
        const filterOptions = filterData.options;
        let filterStartDate = moment().unix();
        const filterEndDate = moment().unix();
        if (filterOptions["Last 30 Minutes"]) {
          filterStartDate = moment().add(-30, "minutes").unix();
        } else if (filterOptions["Last Hour"]) {
          filterStartDate = moment().add(-1, "hour").unix();
        } else if (filterOptions["Last 4 Hours"]) {
          filterStartDate = moment().add(-4, "hours").unix();
        } else if (filterOptions["Last 24 Hours"]) {
          filterStartDate = moment().add(-24, "hours").unix();
        }
        setStartTime(filterStartDate);
        setEndTime(filterEndDate);
      }
    } else {
      setStartTime(null);
      setEndTime(null);
    }
  };

  return (
    <Paper className={styles.container}>
      <Box className={styles.header}>
        <Typography className={styles.title}>Logs</Typography>
        <Box className={styles.tools}>
          <SearchInput placeholder={"Search"} onChange={invoke("search")} />
          <Filter data={logs} config={filters} onChange={callData} />
        </Box>
      </Box>
      <LoadingContainer loading={isLoading}>
        <Table
          ref={ref}
          columns={columns}
          data={logs}
          sortFields={"all"}
          renderItem={renderItem}
          {...pagination}
        />
      </LoadingContainer>
    </Paper>
  );
};
