import { TableBody, Checkbox } from "@mui/material";
import cx from "classnames";

import { Menu } from "../Menu";
import { Cell } from "../NewCell";
import { Row } from "../Row";

import classes from "./styles.module.css";

export const Body = ({
  items,
  columns,
  menuItems,
  renderCustomCell,
  onCheck,
  checkboxEnabled = true,
}) => {
  const renderCell = (item) => (column, index) => {
    const { field } = column;

    const content = renderCustomCell ? renderCustomCell(item, field) : item[field];

    return <Cell key={index}>{content}</Cell>;
  };

  const renderMenuItem = (dataItem) => (menuItem) => {
    const { disabled, onClick, ...rest } = menuItem;

    const isDisabled = disabled ? disabled(dataItem) : false;
    const clickHandler = () => onClick(dataItem);

    return { ...rest, disabled: isDisabled, onClick: clickHandler };
  };

  const renderRow = (item, index) => {
    return (
      <Row key={index}>
        {checkboxEnabled && (
          <Cell>
            <Checkbox color={"info"} checked={item.checked} onChange={onCheck(item)} />
          </Cell>
        )}
        {columns.map(renderCell(item))}
        {menuItems?.length > 0 && (
          <Cell className={cx(classes.cell, classes.menu_cell)}>
            <Menu items={menuItems.map(renderMenuItem(item))} />
          </Cell>
        )}
      </Row>
    );
  };

  return (
    <TableBody>
      {items.length > 0 ? (
        items.map(renderRow)
      ) : (
        <Row>
          <Cell colSpan={8}>No records</Cell>
        </Row>
      )}
    </TableBody>
  );
};
