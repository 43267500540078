import { useState } from "react";
import { toast } from "react-toastify";

export const useTableDelete = (tableData, key, setTableData, deleteCallback) => {
  const [idForDelete, setIdForDelete] = useState();
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);

  const toggleDeleteModal = () => {
    setIsDeleteModalShown(!isDeleteModalShown);
  };

  const handleDelete = async () => {
    try {
      const [newData, deleteIds] = tableData.reduce(
        (acc, curr) => {
          curr.checked || curr[key] === idForDelete ? acc[1].push(curr[key]) : acc[0].push(curr);
          return acc;
        },
        [[], []],
      );
      await deleteCallback(deleteIds.join(","));
      setTableData(newData);
      toast.success("Success alert: Successfully deleted");
    } catch {
      toast.error("Error alert: An error occured while deleting");
    } finally {
      toggleDeleteModal();
    }
  };

  return { isDeleteModalShown, toggleDeleteModal, handleDelete, setIdForDelete };
};
