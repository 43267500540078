import { deleteHandler } from "@utils";
import { RfidGroupAPI } from "@api";

export const actions = {
  delete: ({ id }) => deleteHandler("Rfid group", RfidGroupAPI.deleteGroup, id),
};

export const texts = {
  delete: ({ name }) => `Are you sure you want to delete group ${name}?`,
};
