import { useState } from "react";
import { toast } from "react-toastify";

import { FormSelector } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";
import { SupportAPI } from "@api";

import { statuses } from "./config";

export const UpdateStatusModal = ({ open, ticket, onClose, onSave }) => {
  const { register, control, reset, handleSubmit } = useForm({
    open,
    defaultValues: ticket,
  });

  const [isLoading, setIsLoading] = useState(false);

  const save = async ({ supportStatus }) => {
    setIsLoading(true);
    try {
      await SupportAPI.updateTicketStatus(ticket.id, { supportStatus });
      toast.success("Success alert: Successfully saved");
      onSave();
      close();
    } catch {
      toast.error("Error alert: Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const close = () => {
    onClose();
    reset();
  };

  return (
    <ModalContainer
      open={open}
      onClose={close}
      loading={isLoading}
      title={"Update Status"}
      onSave={handleSubmit(save)}
    >
      <FormSelector
        label={"Support Status"}
        control={control}
        options={statuses}
        {...register("supportStatus")}
      />
    </ModalContainer>
  );
};
