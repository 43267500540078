import { api } from "./axios";

export class DriverAPI {
  static getDrivers({ page, size }) {
    return api.get("drivers", { params: { page, size } });
  }

  static getWithDeleted({ page, size }) {
    return api.get("drivers/with-deleted", { params: { page, size } });
  }

  static async getById(driverId) {
    return api.get(`drivers/${driverId}`);
  }

  static async getSimpleList() {
    return api.get("drivers/simple-list");
  }

  static create(payload) {
    return api.post("drivers/signup", payload);
  }

  static updateDriver(id, payload) {
    return api.patch(`drivers/${id}`, payload);
  }

  static async sendOtp(id) {
    return api.get(`drivers/${id}/otp`);
  }

  static async delete(id) {
    return api.delete(`drivers/${id}`);
  }

  static async updateStatus(id, payload) {
    return api.patch(`drivers/${id}`, { status: payload });
  }

  static getDriverGroups(id) {
    return api.get(`drivers/${id}/group`);
  }
}
