import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { Wrapper } from "../wrapper";

import { GroupList } from "./GroupList";
import { RFIDList } from "./RFIDList";
import styles from "./styles.module.scss";

export const GroupTab = ({ driver }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (driver !== undefined) {
      setIsLoading(false);
    }
  }, [driver?.id]);

  return (
    <Wrapper loading={isLoading}>
      <Box className={styles.body}>
        <Box className={styles.row}>
          <RFIDList driverId={driver?.id} />
          <GroupList driverId={driver?.id} />
        </Box>
      </Box>
    </Wrapper>
  );
};
