import { passwordSchema } from "@utils/validation/password";
import { phoneNumberSchema } from "@utils/validation/phoneNumber";
import { array, object, string } from "yup";

const defaultSchema = object().shape({
  userName: string().required("User Name is required"),
  firstName: string().required("First Name is required"),
  lastName: string().required("Last Name is required"),
  email: string().email("Should be valid email").required("Email is required"),
  roles: array().min(1, "At least 1 role is required"),
  phoneNumber: phoneNumberSchema,
  businesses: array().min(1, "At least 1 business is required"),
});

export const getValidationSchema = (user) => {
  if (user) return defaultSchema;

  return defaultSchema.concat(
    object().shape({
      password: passwordSchema,
    }),
  );
};
