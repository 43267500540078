import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

import { Button, LoadingContainer } from "@components/new";
import { useForm } from "@hooks";
import { BusinessAPI } from "@api";

import { PaperForm } from "./PaperForm";
import { leftColumn, rightColumn, config } from "./config";
import { mapper } from "./mappers";
import styles from "./styles.module.scss";
import { validationSchema } from "./validation";

export const DetailsTab = () => {
  const { businessId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [business, setBusiness] = useState(null);

  const { register, errors, control, handleSubmit } = useForm({
    open: !!business,
    validationSchema,
    defaultValues: business,
  });

  useEffect(() => {
    setIsLoading(true);
    BusinessAPI.getById(businessId)
      .then((data) => setBusiness(mapper(data)))
      .finally(() => setIsLoading(false));
  }, [businessId]);

  const onSave = async ({ id, registrationStatus: _, ...rest }) => {
    try {
      await BusinessAPI.edit(id, rest);
      toast.success("Changes are successfully saved");
    } catch {
      toast.error("An error occured while saving changes");
    }
  };

  const renderForm = (name, i) => {
    const { label, fields } = config[name];

    return (
      <PaperForm
        key={i}
        name={name}
        label={label}
        fields={fields}
        errors={errors}
        control={control}
        register={register}
      />
    );
  };

  return (
    <LoadingContainer loading={isLoading}>
      <Box className={styles.row}>
        <Box className={styles.column}>{leftColumn.map(renderForm)}</Box>
        <Box className={styles.column}>{rightColumn.map(renderForm)}</Box>
      </Box>
      <Button
        variant={"contained"}
        text={"Save"}
        onClick={handleSubmit(onSave)}
        className={styles.button}
      />
    </LoadingContainer>
  );
};
