export const mapper = (item) => ({
  id: item.id,
  station: item.stationName,
  chargerId: item.chargeStationId,
  site: item.siteName,
  modelId: item.chargeStationModel.chargeStationModelId,
  model: item.chargeStationModel.modelName,
  vendor: item.chargeStationModel.vendor,
  version: item.firmwareVersion || "-",
  firmwares: item.firmwares,
});
