import { api } from "./axios";

export class ConfigurationAPI {
  static reset(stationId, resetType) {
    return api.patch(`configure/${stationId}/reset`, undefined, { params: { resetType } });
  }

  static internalReset(stationId) {
    return api.patch(`configure/${stationId}/reset/internal`);
  }

  static async getConfigurationByChargeStationId(id) {
    return api.get(`chargestation/${id}/settings`);
  }

  static async getRemoteConfigurationByChargeStationId(id) {
    return api.get(`chargestation/${id}/remote/settings`);
  }

  static async updateConfigurationKeyByChargeStationId(id, payload) {
    return api.put(`chargestation/${id}/remote/settings`, payload);
  }
}
