import { formatDTOtoScheme } from "@utils/pricing";

import { api } from "./axios";

export class PricingAPI {
  static async getByChargeGroup(id) {
    const [{ timePricingScheme = [], ...timeRest }, { dayPricingScheme = [], ...dayRest }] =
      await Promise.all([
        api.get(`chargegroup/time/${id}/pricing`),
        api.get(`chargegroup/day/${id}/pricing`),
      ]);

    return formatDTOtoScheme({ timePricingScheme, dayPricingScheme, ...timeRest, ...dayRest });
  }

  static async getByChargeSite(id) {
    const [{ timePricingScheme, ...timeRest }, { dayPricingScheme, ...dayRest }] =
      await Promise.all([
        api.get(`chargesite/time/${id}/pricing`),
        api.get(`chargesite/day/${id}/pricing`),
      ]);

    return formatDTOtoScheme({ timePricingScheme, dayPricingScheme, ...timeRest, ...dayRest });
  }

  static async getByChargeStation(id) {
    const [{ timePricingScheme, ...timeRest }, { dayPricingScheme, ...dayRest }] =
      await Promise.all([
        api.get(`chargestation/time/${id}/pricing`),
        api.get(`chargestation/day/${id}/pricing`),
      ]);

    return formatDTOtoScheme({ timePricingScheme, dayPricingScheme, ...timeRest, ...dayRest });
  }

  static createForChargeGroup(payload) {
    return api.post("chargegroup/v2/pricing", payload);
  }

  static createForChargeSite(payload) {
    return api.post("chargesite/v2/pricing", payload);
  }

  static createForChargeStation(payload) {
    return api.post("chargestation/v2/pricing", payload);
  }

  static getPriceForChargeStation(chargeStationId) {
    return api.get(`/price/chargestation/${chargeStationId}`);
  }

  static getChargeSiteFlag(id) {
    return api.get(`chargesite/${id}`);
  }

  static updateChargeSiteFlag(id, payload) {
    return api.patch(`chargesite/update-flag/${id}`, payload);
  }
}
