import { Box, Typography } from "@mui/material";
import cx from "classnames";

import styles from "./styles.module.scss";

export const FormField = ({ label, error, className, children }) => {
  const containerClasses = cx(styles.container, className);
  const classes = cx(styles.label, error && styles.error);

  return (
    <Box className={containerClasses}>
      {label && <Typography className={classes}>{label}</Typography>}
      {children}
      {error && <Typography className={styles.error}>{error}</Typography>}
    </Box>
  );
};
