import { DateHelper } from "@utils/date";

export const mapper = (item) => ({
  chargerId: item.chargerId,
  stationName: item.stationName,
  connectorNumber: item.connectorNumber,
  message: item.message ?? "-",
  connectorStatus: item.connectorStatus,
  faultTime: DateHelper.formatDateToLocal(item.faultTime),
  siteName: item.siteName,
  siteId: item.siteId,
  faultDuration: DateHelper.getDurationString(DateHelper.getDuration(0, item.totalFaultDuration)),
});
