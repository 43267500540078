import * as Yup from "yup";

import { addressSchema } from "./address";

const siteBaseSchema = Yup.object().shape({
  siteName: Yup.string().required("Site name is required"),
  businessId: Yup.string().required("Business is required"),
  cardCode: Yup.string().max(15, "Card Code shouldn't be longer than 15 symbols"),
});

export const siteSchema = (type) => siteBaseSchema.concat(addressSchema[type]);
