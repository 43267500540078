export const modelMapper = (item) => ({
  id: item.chargeStationModelId,
  modelName: item.modelName,
  vendor: item.vendor,
  deviceType: item.deviceType || "-",
  powerRating: item.powerRating,
  connectorsCount: item.connectorsCount,
  chargerCount: item.usedByChargeStationCount,
});

export const connectorTypeMapper = (item) => ({
  value: item.connectorTypeId,
  label: item.name,
  icon: process.env.REACT_APP_ASSETS_URL + item.icon,
});
