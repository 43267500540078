import { Box, Typography } from "@mui/material";
import cx from "classnames";

import styles from "./styles.module.scss";

export const days = {
  Sunday: "Sun",
  Monday: "Mon",
  Tuesday: "Tue",
  Wednesday: "Wed",
  Thursday: "Thu",
  Friday: "Fri",
  Saturday: "Sat",
};

export const PricingDay = ({ day, selected, compact = false }) => {
  const getClass = (className) => {
    return cx(
      styles[className],
      selected && styles[`${className}_selected`],
      compact && styles[`${className}_compact`],
      selected && compact && styles[`${className}_selected_compact`],
    );
  };

  return (
    <Box className={getClass("container")}>
      <Typography className={getClass("text")}>{days[day]}</Typography>
    </Box>
  );
};
