import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";

import { ConfirmationModal } from "@components/modals";
import {
  LoadingContainer,
  Table,
  Button,
  Paper,
  SearchInput,
  Filter,
  AnchorLink,
} from "@components/new";
import { usePagination, useTable } from "@hooks";
import { ModelAPI, ConnectorAPI } from "@api";

import { DetailsModal } from "./DetailsModal";
import { FormModal } from "./FormModal";
import { columns, filters, searchFields, sortFields } from "./config";
import { modelMapper, connectorTypeMapper } from "./mappers";
import styles from "./styles.module.scss";
import { useActions } from "./use-actions";

export const ChargeStationModel = () => {
  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [connectorTypes, setConnectorTypes] = useState([]);
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [isConfirmationVisble, setIsConfirmationVisble] = useState(false);
  const [type, setType] = useState("Add New");

  const { model, setModel, onEditAction, onDeleteAction } = useActions();

  const toggleFormModal = () => {
    const isVisible = !isFormModalVisible;
    setIsFormModalVisible(isVisible);
    !isVisible && setModel(null);
    setType("Add New");
  };

  const toggleDetailsModal = (model) => {
    const isVisible = !isDetailsModalVisible;
    setIsDetailsModalVisible(isVisible);
    setModel(model);
  };

  const { ref, invoke } = useTable();

  const { fetch, ...pagination } = usePagination(ModelAPI.getAll);

  useEffect(() => {
    ConnectorAPI.getAll().then((types) => {
      setConnectorTypes(types.map(connectorTypeMapper));
    });
  }, []);

  useEffect(() => {
    init();
  }, [pagination.page]);

  const init = async () => {
    setIsLoading(true);
    try {
      const models = await fetch();
      setModels(models.map(modelMapper));
    } finally {
      setIsLoading(false);
    }
  };

  const onFormSave = async (data) => {
    try {
      if (model) {
        await ModelAPI.update(model.id, data);
      } else {
        await ModelAPI.create(data);
      }

      const action = model ? "updated" : "created";
      toast.success(`Model is ${action}`);
      toggleFormModal();
      init();
    } catch {
      const action = model ? "updating" : "creating";
      toast.error(`An error occured while model ${action}`);
    }
  };

  const onDeleteConfirm = async () => {
    try {
      await ModelAPI.delete(model.id);
      toast.success("Model is deleted");
      init();
    } catch {
      toast.error("An error occured while deleting a model");
    } finally {
      setIsConfirmationVisble(false);
    }
  };

  const onDeleteReject = () => {
    setIsConfirmationVisble(false);
  };

  const doActionForModalVisible = (item) => {
    setType("Edit");
    setIsFormModalVisible(onEditAction(item));
  };

  const renderItem = (item, key) => {
    const value = item[key];
    if (key === "modelName") {
      return (
        <AnchorLink
          text={item.modelName}
          onClick={() =>
            item["chargerCount"] === 0 ? doActionForModalVisible(item) : toggleDetailsModal(item)
          }
        />
      );
    }
    return value;
  };

  const actions = [
    (model) => ({ label: "Edit", onClick: () => setIsFormModalVisible(onEditAction(model)) }),
    (model) => ({ label: "Delete", onClick: () => setIsConfirmationVisble(onDeleteAction(model)) }),
  ];

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>Charge Station Model</Typography>
          <Button variant={"contained"} text={"Add New Model"} onClick={toggleFormModal} />
        </Box>
        <Paper className={styles.paper}>
          <Box className={styles.tools}>
            <SearchInput onChange={invoke("search")} />
            <Filter data={models} config={filters} onChange={invoke("filter")} />
          </Box>
          <Table
            ref={ref}
            size={"l"}
            data={models}
            columns={columns}
            actions={actions}
            sortFields={sortFields}
            searchFields={searchFields}
            renderItem={renderItem}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <FormModal
        open={isFormModalVisible}
        onSave={onFormSave}
        modelId={model?.id}
        onClose={toggleFormModal}
        connectorTypes={connectorTypes}
        type={type}
      />
      <DetailsModal
        open={isDetailsModalVisible}
        model={model}
        onClose={() => toggleDetailsModal()}
      />
      <ConfirmationModal
        open={isConfirmationVisble}
        onConfirm={onDeleteConfirm}
        onReject={onDeleteReject}
        text={`Are you sure you want to delete model ${model?.modelName}`}
      />
    </>
  );
};
