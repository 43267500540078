import * as Yup from "yup";

import { passwordSchema } from "./password";

const userBaseSchema = Yup.object().shape({
  userName: Yup.string().required("Username is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Should be a valid email").required("Email is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
});

const userAddSchema = userBaseSchema.concat(Yup.object().shape({ password: passwordSchema }));

export const userSchema = {
  Add: userAddSchema,
  Edit: userBaseSchema,
};
