import { LoginPage, ForgotPage, ResetPassword } from "@pages/Auth";
import {
  ChargerList,
  ChargerDetails,
  ChargingRecord,
  FaultInformation,
  TransactionDetails,
} from "@pages/ChargerManagement";
import { BusinessList, BusinessDetails } from "@pages/CpoManagement";
import { QuickSight } from "@pages/Dashboard/QuickSight";
import { AllFirmware, CsFirmware } from "@pages/FirmwareManagement";
import { NotFound } from "@pages/NotFound";
import { ChargeStationModel, ConnectorTypes } from "@pages/OrderRecord";
import {
  ChargeStationPricingPage,
  ChargeSitePricingPage,
  ChargeGroupPricingPage,
} from "@pages/PricingManagement";
import { RemoteDeviceManagement, SupportTicket, FAQ } from "@pages/SettingManagement";
import { SiteList, ChargeGroup } from "@pages/SiteManagement";
import { SiteDetails } from "@pages/SiteManagement/SiteDetails";
import {
  UserList,
  PlatformUserList,
  PlatformUserDetails,
  DriverList,
  DriverDetails,
  DriverGroupList,
  DriverGroupDetails,
  UserRfid,
  UserInformation,
  NotificationManagement,
} from "@pages/UserManagement";
import { RfidGroupDetails } from "@pages/UserManagement/Group/RfidGroupDetails";
import { RfidGroupList } from "@pages/UserManagement/Group/RfidGroupList";
import { UserRfidDetails } from "@pages/UserManagement/UserRfid/UserRfidDetails";

export const publicRoutes = {
  "/login": { component: <LoginPage /> },
  "/forgot-username": { component: <ForgotPage type={"username"} /> },
  "/forgot-password": { component: <ForgotPage type={"password"} /> },
  "/reset-password": { component: <ResetPassword /> },
};

export const privateRoutes = {
  "/home": {
    component: <QuickSight key="home" id={process.env.REACT_APP_QUICKSIGHT_HOME_REPORT} />,
  },

  "/charger-management/charger-list/*": { component: <ChargerList /> },
  "/charger-management/charger-list/:stationId/details": {
    component: <ChargerDetails />,
  },
  "/charger-management/charger-list/:stationId/pricing": {
    component: <ChargeStationPricingPage />,
  },
  "/charger-management/transaction-list": { component: <ChargingRecord /> },
  "/charger-management/transaction-list/:transactionId": {
    component: <TransactionDetails />,
  },
  // "/charger-management/rfid-list": { component: <RfidPage /> },
  "/charger-management/fault-list": { component: <FaultInformation /> },

  "/site-management/site-list": { component: <SiteList /> },
  "/site-management/site-list/:siteId/details": { component: <SiteDetails /> },
  "/site-management/site-list/:siteId/pricing": {
    component: <ChargeSitePricingPage />,
  },
  "/site-management/site-group-list": { component: <ChargeGroup /> },
  "/site-management/site-group-list/:groupId/pricing": {
    component: <ChargeGroupPricingPage />,
  },

  "/firmware-management/all": { restricted: true, component: <AllFirmware /> },
  "/firmware-management/station": { component: <CsFirmware /> },

  "/order-record/model-list": { restricted: true, component: <ChargeStationModel /> },
  "/order-record/connector-list": { restricted: true, component: <ConnectorTypes /> },

  "/user-management/user-list-old": { component: <UserList /> },
  "/user-management/user-list": { component: <PlatformUserList /> },
  "/user-management/driver-list": { component: <DriverList /> },
  "/user-management/driver-list/:driverId/details": { component: <DriverDetails /> },
  "/user-management/driver-group-list": { component: <DriverGroupList /> },
  "/user-management/driver-group-list/:groupId/details": { component: <DriverGroupDetails /> },
  "/user-management/user-list/:userId/details": { component: <PlatformUserDetails /> },

  "/user-management/rfid-list": { component: <UserRfid /> },
  "/user-management/rfid-list/:rfId/details": { component: <UserRfidDetails /> },
  "/user-management/rfid-group-list": { component: <RfidGroupList /> },
  "/user-management/rfid-group-list/:groupId/details": { component: <RfidGroupDetails /> },

  "/administration/support-ticket-list": { component: <SupportTicket /> },
  "/administration/debug": { component: <RemoteDeviceManagement /> },
  "/administration/faq": { component: <FAQ /> },

  "/user-info": { component: <UserInformation /> },
  "/user-info/notification-management": { component: <NotificationManagement /> },

  "/reports/charing-stations-report": {
    component: (
      <QuickSight
        key="charing-stations-report"
        id={process.env.REACT_APP_QUICKSIGHT_STATION_REPORT}
      />
    ),
  },
  "/reports/monthly-fault-report": {
    component: (
      <QuickSight key="monthly-fault-report" id={process.env.REACT_APP_QUICKSIGHT_FAULT_REPORT} />
    ),
  },
  "/reports/monthly-transaction-report": {
    component: (
      <QuickSight
        key="monthly-transaction-report"
        id={process.env.REACT_APP_QUICKSIGHT_TRANSACTION_REPORT}
      />
    ),
  },

  "/cpo-management/business-list": { restricted: true, component: <BusinessList /> },
  "/cpo-management/business-list/:businessId/details": {
    restricted: true,
    component: <BusinessDetails />,
  },

  "*": { component: <NotFound /> },
};
