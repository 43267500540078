import { EntityList } from "@components/new";
import { ChargeSiteAPI, DriverAPI } from "@api";

import { config } from "./config";

export const DriverList = ({ siteId }) => {
  const titles = {
    sectionTitle: "Driver",
    plural: "driver(s)",
    unassignTitle: "Unassign Driver(s)",
    assignTitle: "Assign Driver",
    assignModalTitle: "Assign drivers to the group",
  };

  const functions = {
    getAssignedItems: () => ChargeSiteAPI.getByIdDrivers(siteId, "DEFER_PAYMENT"),
    getAvailableItems: () => DriverAPI.getDrivers({ page: 1, size: 4000 }),
    onAssign: (siteId, toAssign) =>
      ChargeSiteAPI.assignDrivers({ type: "DEFER_PAYMENT", siteId, driverIds: toAssign }),
    onUnassign: (siteId, toDelete) =>
      ChargeSiteAPI.unAssignDrivers(siteId, toDelete, "DEFER_PAYMENT"),
  };

  return <EntityList parentId={siteId} config={config} titles={titles} actions={functions} />;
};
