export const config = {
  reducer: (acc, { id }) => {
    return acc.set(id, false);
  },

  columns: [
    {
      label: "Assigned group(s)",
      field: "name",
    },
    /*
    {
      label: "Business",
      field: "business",
    },
    {
      label: "# of RFID's",
      field: "noOfRfids",
    },
    */
  ],

  assignedMapper: (item) => ({
    id: item.groupId,
    name: item.groupName,
    //business: "",
    //noOfRfids: 0,
  }),
  definedMapper: (item) => ({
    id: item.uuid,
    name: item.name,
  }),
};
