import { useState } from "react";
import { useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

import { FormSelector, FormTextInput } from "@components/form/new";
import { ModalContainer, Selector } from "@components/new";
import { useBusinessSites, useForm } from "@hooks";
import { ChargeStationAPI } from "@api";

import styles from "./styles.module.scss";

export const EditModal = ({ open, station, businesses, vendors, models, onClose, onSave }) => {
  const { register, control, handleSubmit } = useForm({ open, defaultValues: station });

  const [isLoading, setIsLoading] = useState(false);

  const [vendor, setVendor] = useState(station.vendor);

  const businessId = useWatch({ name: "businessId", control });

  const sites = useBusinessSites(businessId);

  const save = async ({ chargerId, name, businessId, modelId, siteId, basePrice }) => {
    setIsLoading(true);
    try {
      const payload = { chargerId, name, businessId, modelId, siteId, basePrice };
      await ChargeStationAPI.update(station.id, payload);
      toast.success("Charger has been updated successfully");
      onClose();
      onSave();
    } catch {
      toast.error("An error occured while updating the charger");
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <ModalContainer
      open={open}
      title={"Edit"}
      onClose={onClose}
      onCancel={onClose}
      loading={isLoading}
      onSave={handleSubmit(save)}
    >
      <FormTextInput label={"Charger Point ID"} control={control} {...register("chargerId")} />
      <FormTextInput label={"Charger Name"} control={control} {...register("name")} />
      <FormTextInput
        type={"number"}
        label={"Base Price"}
        control={control}
        {...register("basePrice")}
      />
      <Box className={styles.row}>
        <FormSelector
          label={"Business"}
          control={control}
          options={businesses}
          {...register("businessId")}
        />
        <FormSelector label={"Site"} control={control} options={sites} {...register("siteId")} />
      </Box>
      <Box className={styles.row}>
        <Selector label={"Vendor"} value={vendor} options={vendors} onChange={setVendor} />
        <FormSelector
          label={"Model"}
          control={control}
          options={models[vendor] || []}
          {...register("modelId")}
        />
      </Box>
    </ModalContainer>
  );
};
