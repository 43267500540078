import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import { FormControl } from "@mui/material";

import { Switcher as DefaultSwither } from "@components/common";

export const Switcher = forwardRef(({ name, control }, _) => {
  const renderSwitcher = ({ field: { value, name, onChange } }) => {
    return <DefaultSwither name={name} checked={!!value} onChange={onChange} />;
  };

  return (
    <FormControl>
      <Controller name={name} control={control} render={renderSwitcher} />
    </FormControl>
  );
});
