import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { FormField } from "@components/form/new/FormField";
import { ConfirmationModal } from "@components/modals";
import { Paper, Switcher } from "@components/new";
import { ChargeSiteAPI } from "@api";

import { DriverGroupList } from "./DriverGroupList";
import { DriverList } from "./DriverList";
import styles from "./styles.module.scss";

export const DeferPayment = ({ siteid }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deferPayment, setDeferPayment] = useState(false);

  useEffect(() => {
    if (siteid && siteid.deferPayment) {
      setDeferPayment(siteid.deferPayment);
    }
  }, [siteid]);

  const toggleModal = () => {
    setIsModalVisible((isVisible) => !isVisible);
  };

  const changeStatus = () => {
    ChargeSiteAPI.updatePrivateDeferStatus(siteid?.id, {
      type: "DEFER_PAYMENT",
      value: !deferPayment,
    });
    setDeferPayment((deferPayment) => !deferPayment);
    setIsModalVisible(false);
  };

  const modalText = `Are you sure you want to ${
    deferPayment ? "disable" : "enable"
  } defer payment?`;

  return (
    <>
      <Paper className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>Defer Payment</Typography>
        </Box>
        <Box className={styles.body}>
          <Box>
            <FormField label={"Defer Payment"} className={styles.container}>
              <Switcher checked={deferPayment} onChange={toggleModal} />
            </FormField>
          </Box>
        </Box>
        {deferPayment && (
          <Box>
            <DriverList siteId={siteid?.id} />
            <DriverGroupList siteId={siteid?.id} />
          </Box>
        )}
      </Paper>
      <ConfirmationModal
        open={isModalVisible}
        text={modalText}
        onConfirm={changeStatus}
        onReject={toggleModal}
      />
    </>
  );
};
