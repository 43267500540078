export const columns = [
  {
    label: "User Name",
    field: "name",
  },
  {
    label: "First Name",
    field: "firstName",
  },
  {
    label: "Last Name",
    field: "lastName",
  },
  {
    label: "Email",
    field: "email",
  },
];
