import { deleteHandler } from "@utils";
import { DriverGroupAPI } from "@api";

export const actions = {
  delete: ({ id }) => deleteHandler("Driver group", DriverGroupAPI.deleteGroup, id),
};

export const texts = {
  delete: ({ name }) => `Are you sure you want to delete group ${name}?`,
};
