import { api } from "./axios";

export class SupportAPI {
  static getAllTickets({ page, size }) {
    return api.get("support", { params: { page, size } });
  }

  static getTicketsByUser(id) {
    return api.get(`support/user/${id}`);
  }

  static getTicketsByDriver(id) {
    return api.get(`support/driver/${id}`);
  }

  static getTicketDetails(id) {
    return api.get(`support/${id}`);
  }

  static getAllTypes() {
    return api.get("support/types");
  }

  static createTicket(payload) {
    return api.post("support/createticket", payload);
  }

  static createTicketComment(id, message) {
    return api.post(`support/${id}/comment`, { message });
  }

  static updateTicketStatus(id, payload) {
    return api.put(`support/${id}`, payload);
  }
}
