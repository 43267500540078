import { MenuItem, OutlinedInput, Pagination, Select } from "@mui/material";
import { Box } from "@mui/system";

import classes from "./styles.module.css";

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 54,
    },
  },
};

export const CustomPagination = ({ count, page, setPage, rowsPerPage, setRowsPerPage }) => {
  const rows = [10, 25, 50, 100];

  const handleChange = (el, value) => {
    setPage(value);
  };

  return (
    <Box
      className={classes.page_section}
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={rowsPerPage}
          placeholder="Per Pages"
          input={<OutlinedInput style={{ width: "75px", height: "37px", padding: "unset" }} />}
          MenuProps={MenuProps}
        >
          {rows.map((item, index) => (
            <MenuItem
              key={index}
              value={item}
              style={{ fontSize: "12px" }}
              onClick={() => setRowsPerPage(parseInt(item))}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
        <Box style={{ marginLeft: "10px", fontSize: "12px" }}>Entries per page</Box>
      </Box>
      <Box className={classes.page_group}>
        <Pagination
          page={page}
          onChange={handleChange}
          count={count}
          boundaryCount={5}
          shape="rounded"
        />
      </Box>
    </Box>
  );
};
