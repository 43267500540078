export const fields = [
  {
    label: "Group Name",
    field: "name",
  },
  {
    label: "Business",
    field: "business",
  },
  {
    label: "Created",
    field: "createdAt",
  },
  {
    label: "Updated",
    field: "updatedAt",
  },
];
