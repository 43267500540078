import { useRef, useState } from "react";
import { forwardRef, useImperativeHandle } from "react";
import { Box, Typography } from "@mui/material";
import cx from "classnames";

import { ReactComponent as FilePlaceholder } from "@assets/icons/file-placeholder.svg";

import { FormLabel } from "../FormLabel";

import styles from "./styles.module.scss";

const Component = ({ image, label, onChange, className }, ref) => {
  const [isDragActive, setIsDragActive] = useState(false);

  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({ openFileWindow }));

  const onDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDragActive(true);
    } else if (e.type === "dragleave") {
      setIsDragActive(false);
    }
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    readFile(e.dataTransfer.files);
  };

  const onInputChange = (e) => {
    e.preventDefault();
    readFile(e.target.files);
  };

  const readFile = ([file]) => {
    file && onChange(file);
  };

  const openFileWindow = () => {
    inputRef.current.click();
  };

  const containerClasses = cx(styles.container, className);
  const wrapperClasses = cx(styles.wrapper, image && styles.with_image);

  return (
    <div className={containerClasses} onDragEnter={onDrag}>
      {label && <FormLabel text={label} />}
      <Box className={wrapperClasses}>
        <input ref={inputRef} type={"file"} onChange={onInputChange} className={styles.input} />
        {image ? (
          <img src={image} className={styles.image} />
        ) : (
          <Box className={styles.placeholder}>
            <FilePlaceholder />
            <Box className={styles.text}>
              <Typography className={styles.label}>Drag&Drop photo here or</Typography>
              <Typography className={styles.link} onClick={openFileWindow}>
                Upload it
              </Typography>
            </Box>
          </Box>
        )}
        {isDragActive && (
          <Box
            className={styles.drag_drop}
            onDragEnter={onDrag}
            onDragLeave={onDrag}
            onDragOver={onDrag}
            onDrop={onDrop}
          />
        )}
      </Box>
    </div>
  );
};

export const ImageInput = forwardRef(Component);
