export const LoadManagementType = Object.freeze({
  Distributed: "DISTRIBUTED",
  Custom: "CUSTOM",
});

export const columns = [
  { label: "Charge Point Id", field: "chargerId" },
  { label: "Charger Name", field: "name" },
  { label: "Hardware Status", field: "hardwareStatus" },
  { label: "Type", field: "type" },
  { label: "Connectors", field: "connectorsAmount" },
  { label: "Power Distribution", field: "powerLimit" },
];
