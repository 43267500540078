import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

import { ModalContainer, Paper } from "@components/new";
import { SupportAPI } from "@api";

import styles from "./styles.module.scss";

export const TicketCommentsModal = ({ open, ticket, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (ticket?.id) {
      setIsLoading(true);
      SupportAPI.getTicketDetails(ticket.id)
        .then(({ comments }) => setComments(comments))
        .finally(() => setIsLoading(false));
    }
  }, [ticket]);

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      withButtons={false}
      contentLoader={isLoading}
      title={"Support Ticket Comments"}
    >
      {comments.length ? (
        comments.map(({ message }, i) => (
          <Paper key={i} className={styles.comment}>
            <Typography>{message}</Typography>
          </Paper>
        ))
      ) : (
        <Typography>No comments for this ticket</Typography>
      )}
    </ModalContainer>
  );
};
