import { Box, TableBody, TableRow, TableCell } from "@mui/material";

import { ActionMenu } from "@components/new";

import styles from "./styles.module.scss";

const mapItemWithAction = (item) => {
  return (action) => {
    return action?.call(null, item);
  };
};

export const Body = ({ size = "m", columns, data, renderItem, actions }) => {
  const hasActions = actions?.length > 0;

  const renderCell = (item) => {
    return ({ field }, i) => {
      const withActions = hasActions && i === columns.length - 1;
      const itemActions = actions?.map(mapItemWithAction(item));

      return (
        <TableCell key={i} className={styles.cell}>
          <Box className={styles.wrapper}>
            {renderItem ? renderItem(item, field) : item[field]}
            {withActions && <ActionMenu actions={itemActions} />}
          </Box>
        </TableCell>
      );
    };
  };

  const renderRow = (item, i) => {
    return (
      <TableRow key={i} className={styles[`row_${size}`]}>
        {columns.map(renderCell(item))}
      </TableRow>
    );
  };

  return <TableBody>{data.map(renderRow)}</TableBody>;
};
