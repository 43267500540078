export const mapper = (item) => ({
  id: item.driverGroupId,
  name: item.name,
  driverCount: item.driverCount,
  createdAt: item.createdAt,
  updatedAt: item.updatedAt,
});

export const columns = [
  {
    label: "Group Name",
    field: "name",
  },
  {
    label: "Driver(s)",
    field: "driverCount",
  },
  {
    label: "Created",
    field: "createdAt",
  },
  {
    label: "Updated",
    field: "updatedAt",
  },
];

export const sortFields = ["name", "driverCount", "createdAt"];
