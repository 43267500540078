import { api } from "./axios";

export class AuthAPI {
  static async login(auth) {
    const { jwt } = await api.post("login/management", undefined, { auth });
    localStorage.setItem("token", jwt);
  }

  static async refreshBusinessToken(id) {
    const { jwt } = await api.post(`auth/business/${id}`);
    localStorage.setItem("token", jwt);
  }

  static resetPassword(payload) {
    return api.post("users/resetpassword", payload);
  }

  static requestResetUsernameOrPassword(type, email) {
    return api.post(`users/forgot${type}/${email}`);
  }
}
