export const columns = [
  {
    label: "Site Name",
    field: "siteName",
  },
  {
    label: "Connector Number",
    field: "connectorNumber",
  },
  {
    label: "Message",
    field: "message",
  },
  {
    label: "Connector Status",
    field: "connectorStatus",
  },
  {
    label: "Time of Fault",
    field: "faultTime",
  },
  {
    label: "Fault Duration",
    field: "faultDuration",
  },
];
