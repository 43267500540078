import styles from "./styles.module.scss";

export const ExternalLink = ({ text, path }) => {
  const onClick = () => window.open(path, "_blank", "noreferrer");

  return (
    <a className={styles.link} onClick={onClick}>
      {text}
    </a>
  );
};
