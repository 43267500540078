export const leftColumn = ["companyDetails", "contactInfo"];
export const rightColumn = ["ownerDetails", "billingInfo", "billingAddress"];

export const config = {
  companyDetails: {
    label: "Company Details",
    fields: [
      { label: "Company Name", name: "companyName" },
      { label: "Legal Name", name: "legalName" },
      { label: "Party ID", name: "partyID" },
      { label: "Email", name: "email" },
      { label: "Phone", name: "phone" },
      { label: "Country", name: "country" },
      { label: "State", name: "state" },
      { label: "City", name: "city" },
      { label: "Street", name: "street" },
      { label: "Building number", name: "buildingNumber" },
      { label: "Zip Code", name: "zipCode" },
      { label: "Website Link", name: "websiteLink" },
    ],
  },
  ownerDetails: {
    label: "Owner Details",
    fields: [
      { label: "First Name", name: "firstName" },
      { label: "Last Name", name: "lastName" },
      { label: "Email", name: "email" },
      { label: "Phone", name: "phone" },
    ],
  },
  contactInfo: {
    label: "Contact Info",
    fields: [
      { label: "Main contact person first name", name: "firstName" },
      { label: "Main contact person last name", name: "lastName" },
      { label: "Main contact person Email", name: "email" },
      { label: "Main contact person Phone", name: "phone" },
    ],
  },
  billingInfo: {
    label: "Billing Info",
    fields: [
      { label: "VAT-ID", name: "vatID" },
      { label: "Billing contact name", name: "name" },
      { label: "Billing contact email", name: "email" },
      { label: "Billing contact phone", name: "phone" },
    ],
  },
  billingAddress: {
    label: "Billing Address",
    fields: [
      { label: "Country", name: "country" },
      { label: "City", name: "city" },
      { label: "Street", name: "street" },
      { label: "ZIP", name: "zipCode" },
    ],
  },
};
