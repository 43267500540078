import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

import { FormTextInput, FormSelector } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";
import { supportTicketSchema } from "@utils/validation";
import { ChargeStationAPI, SupportAPI } from "@api";

import { defaultValues, fields } from "./config";
import { stationMapper, typeMapper } from "./mappers";

export const TicketModal = ({ open, onClose, onSave }) => {
  const { register, control, errors, reset, handleSubmit } = useForm({
    open,
    defaultValues,
    validationSchema: supportTicketSchema,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState({ chargeStationID: [], supportType: [] });

  useEffect(() => {
    Promise.all([ChargeStationAPI.getSimpleList(), SupportAPI.getAllTypes()]).then(
      ([stations, types]) => {
        setOptions({
          chargeStationID: stations.map(stationMapper),
          supportType: types.map(typeMapper),
        });
      },
    );
  }, []);

  const renderField = ({ type, label, name }, i) => {
    const error = errors[name];
    const props = { label, control, error, ...register(name) };

    return (
      <Box key={i}>
        {type === "select" ? (
          <FormSelector options={options[name]} {...props} />
        ) : (
          <FormTextInput {...props} />
        )}
      </Box>
    );
  };

  const save = async (data) => {
    setIsLoading(true);
    try {
      await SupportAPI.createTicket(data);
      toast.success("Success alert: Successfully saved");
      onSave();
      close();
    } catch {
      toast.error("Error alert: Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const close = () => {
    onClose();
    reset();
  };

  return (
    <ModalContainer
      open={open}
      onClose={close}
      onCancel={close}
      loading={isLoading}
      title={"Add Ticket"}
      onSave={handleSubmit(save)}
    >
      {fields.map(renderField)}
    </ModalContainer>
  );
};
