const connectorMapper = (item) => ({
  connectorNumber: item.number,
  powerRating: item.powerRating,
  connectorType: item.connectorType.connectorTypeId,
});

export const mapper = (item) => ({
  vendor: item.vendor,
  modelName: item.modelName,
  deviceType: item.deviceType,
  powerRating: item.powerRating,
  connectors: item.connectors.map(connectorMapper),
});
