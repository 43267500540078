import { Box } from "@mui/material";

import { GeneralInfo } from "./general";
import styles from "./styles.module.scss";
import { SupportTicketList } from "./supportTicketList";
import { Transactions } from "./transactions";

export const InfoTab = ({ driver }) => {
  return (
    <Box className={styles.body}>
      <Box className={styles.row}>
        <GeneralInfo driver={driver} />
        <SupportTicketList driverId={driver?.id} />
      </Box>
      <Transactions driverId={driver?.id} />
    </Box>
  );
};
