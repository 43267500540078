import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  Alert,
  AlertTitle,
  styled,
} from "@mui/material";

import { PageTitle } from "@components/common";
import { LoadingContainer } from "@components/new";
import { PricingComponent } from "@components/pricing";
import { DriverGroupAPI, PricingAPI } from "@api";

import styles from "./styles.module.scss";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export const ChargeGroupPricingPage = () => {
  const { groupId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [pricing, setPricing] = useState(null);
  const [driverGroups, setDriverGroups] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const groupPricingRef = useRef();

  const triggerGroupPricing = () => {
    groupPricingRef.current.create();
    handleClose();
  };

  useEffect(() => {
    groupId && init();
  }, [groupId]);

  const init = async () => {
    setIsLoading(true);
    try {
      const groupPricing = await PricingAPI.getByChargeGroup(groupId);
      const driverGroups = await DriverGroupAPI.getDriverGroups();
      setPricing(groupPricing);
      setDriverGroups(driverGroups);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingContainer loading={isLoading}>
      <PageTitle text={pricing?.chargeSiteGroupName} subtitle={"Pricing"} />
      <Alert style={{ marginTop: 0 }} className={styles.info} severity="info">
        <AlertTitle>Pricing Rules Info</AlertTitle>
        <b>Displayed Pricing Rules</b>: You will see only the pricing rules that are relevant to
        this specific charge station.
        <br />
        <b>Order of Evaluation</b>: Rules are evaluated in top-down order. The first matching rule
        sets the price, progressing from precise to broad.
        <br />
        <b>Site or Group Rules</b>: When you manage a pricing rule for a Site or a Group, it applies
        to all the charge stations within that Site or Group.
      </Alert>
      <div className={styles.containCreatePricingRuleButton}>
        <Button
          id="demo-customized-button"
          className={styles.dropdown}
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDown />}
        >
          Create Associated Pricing Rule
        </Button>
      </div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={triggerGroupPricing}
          className={styles.createPricingMenuItem}
          disableRipple
        >
          <ListItemText>Group Pricing Rule</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {pricing?.chargeSiteGroupName}
          </Typography>
        </MenuItem>
      </StyledMenu>
      <PricingComponent
        ref={groupPricingRef}
        onSave={init}
        type={"Group"}
        pricing={pricing}
        driverGroups={driverGroups}
        nameKey={"chargeSiteGroupName"}
        editCallback={PricingAPI.createForChargeGroup}
      />
    </LoadingContainer>
  );
};
