import { CircularProgress, Button as MUIButton } from "@mui/material";
import cx from "classnames";

import styles from "./styles.module.scss";

export const Button = ({
  type = "success",
  loading,
  disabled,
  variant,
  text,
  onClick,
  className,
  startIcon,
}) => {
  const classes = cx(styles.button, className);

  return (
    <MUIButton
      color={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled || loading}
      classes={{
        root: classes,
        outlined: styles.outlined,
        outlinedSuccess: styles.outlined_success,
        containedSuccess: styles.contained_success,
        outlinedPrimary: styles.outlined_primary,
        containedPrimary: styles.contained_primary,
        outlinedWarning: styles.outlined_warning,
        containedWarning: styles.contained_warning,
        outlinedError: styles.outlined_error,
        containedError: styles.contained_error,
      }}
      startIcon={startIcon}
    >
      {loading ? <CircularProgress size={14} classes={{ root: styles.spiner }} /> : text}
    </MUIButton>
  );
};
