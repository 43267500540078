import { LoadingContainer } from "@components/new";

import styles from "./styles.module.scss";

export const Wrapper = ({ loading, children }) => {
  return (
    <LoadingContainer loading={loading} className={styles.container}>
      {children}
    </LoadingContainer>
  );
};
