import { useEffect, useState } from "react";

import { AddressHelper } from "@utils/address";

import { useForm } from "./use-form";

const initialManualData = {
  administrativeArea: "",
  country: "",
  latitude: "",
  longitude: "",
  postCode: "",
  streetAddress: "",
  subAdministrativeArea: "",
  suggested: "",
};

export const useAddressForm = ({ open, defaultAddress, defaultValues, validationSchema }) => {
  const [isAddressManual, setIsAddressManual] = useState(false);
  const [address, setAddress] = useState(null);

  const fullAddressStr = AddressHelper.getFullAddress(defaultAddress);

  const [fullAddress, setFullAddress] = useState(fullAddressStr);

  const data = useForm({
    open,
    defaultValues: {
      ...defaultValues,
      ...(isAddressManual ? initialManualData : { address: fullAddressStr }),
    },
    validationSchema: validationSchema(isAddressManual ? "manual" : "auto"),
  });

  useEffect(() => {
    !open && setAddress(null);
  }, [open]);

  useEffect(() => {
    setAddress(defaultAddress);
  }, [defaultAddress]);

  const updateAddress = (update) => {
    setAddress((address) => ({ ...address, ...update }));
  };

  const toggleAddress = () => {
    const { setValue } = data;

    if (isAddressManual) {
      setValue("address", fullAddress);
    } else {
      setFullAddress(data.values.address);
      address && Object.entries(address).forEach(([field, value]) => setValue(field, value));
    }

    setIsAddressManual((manual) => !manual);
  };

  return { ...data, isAddressManual, address, updateAddress, toggleAddress };
};
