import { toast } from "react-toastify";

export const deleteHandler = async (entity, deletePromise, params) => {
  try {
    await deletePromise(params);
    toast.success(`${entity} has been successfully deleted`);
  } catch {
    toast.error(`An error occured while deleting ${entity.toLowerCase()}`);
  }
};
