export const fields = [
  { label: "Company Name", field: "companyName" },
  { label: "Status", field: "status" },
  { label: "Contact Name", field: "contactName" },
  { label: "Contact Email", field: "email" },
  { label: "Contact Phone", field: "phone" },
  { label: "Company City", field: "companyCity" },
  { label: "Company Country", field: "companyCountry" },
  { label: "Website", field: "websiteLink" },
  { label: "Number of Sites", field: "siteAmount" },
  { label: "Number of Charging Stations", field: "chargerAmount" },
  { label: "Number of Transactions", field: "transactionAmount" },
  { label: "Creation Date", field: "createdAt" },
];
