import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";

import { ConfirmationModal } from "@components/modals";
import { Button, Link, LoadingContainer, Paper, Table } from "@components/new";
import { usePagination } from "@hooks";
import { DateHelper } from "@utils/date";
import { BusinessAPI } from "@api";

import { AddModal } from "./AddModal";
import { columns } from "./config";
import { mapper } from "./mappers";
import styles from "./styles.module.scss";

export const BusinessList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [business, setBusiness] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmationVisble, setIsConfirmationVisble] = useState(false);

  const toggleModal = () => {
    setIsModalVisible((isVisible) => !isVisible);
  };

  const { fetch, ...pagination } = usePagination(BusinessAPI.get);

  const init = async () => {
    setIsLoading(true);
    try {
      const businesses = await fetch();
      setBusinesses(businesses.map(mapper));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [pagination.page]);

  const renderItem = (item, field) => {
    const value = item[field];

    if (!value) return "-";

    switch (field) {
      case "name": {
        return <Link text={value} path={`${item.id}/details`} />;
      }
      case "createdAt": {
        return DateHelper.formatDateToLocal(value);
      }
      default: {
        return value;
      }
    }
  };

  const onSave = async (data) => {
    try {
      await BusinessAPI.create(data);
      toast.success("Business has been successfully created");
      toggleModal();
      init();
    } catch {
      toast.success("An error occured while creating a business");
    }
  };

  const handleDelete = (item) => {
    if (item.transactionAmount) {
      toast.error(
        `This business's stations are already in use. 
        Deleting this business is not allowed.`,
      );
    } else {
      setBusiness(item);
      setIsConfirmationVisble(true);
    }
  };

  const onDeleteConfirm = async () => {
    try {
      await BusinessAPI.delete(business.id);
      toast.success("Business has been successfully deleted");
      init();
    } catch {
      toast.error("An error occured while deleting the business");
    } finally {
      setIsConfirmationVisble(false);
    }
  };

  const onDeleteReject = () => {
    setIsConfirmationVisble(false);
  };

  const actions = [(item) => ({ label: "Delete", onClick: () => handleDelete(item) })];

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>Business List</Typography>
          <Button variant={"contained"} text={"Add New Business"} onClick={toggleModal} />
        </Box>
        <Paper>
          <Table
            data={businesses}
            columns={columns}
            renderItem={renderItem}
            actions={actions}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <AddModal open={isModalVisible} onSave={onSave} onClose={toggleModal} />
      <ConfirmationModal
        open={isConfirmationVisble}
        onConfirm={onDeleteConfirm}
        onReject={onDeleteReject}
        text={`Are you sure you want to delete business ${business?.name}`}
      />
    </>
  );
};
