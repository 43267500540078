import { AddressHelper } from "@utils";

export const siteMapper = (item) => ({
  id: item.siteId,
  siteName: item.siteName,
  createdAt: item.createdAt,
  addressDetails: item.addressDetails,
  businessId: item.business?.businessId,
  cardCode: item.cardCode,
  address: AddressHelper.getFullAddress(item.addressDetails),
  privateSite: item.privateSite,
  assignedDriverGroups: item.driverGroups,
  assignedDrivers: item.drivers,
});
