const statuses = {
  REGISTERED: "Active",
  WAITING_CONFIRM: "Pending",
};

export const mapper = (item) => ({
  id: item.businessId,
  name: item.businessName,
  status: statuses[item.registrationStatus] || "-",
  contactName: (item.contact?.firstName || " ") + " " + (item.contact?.lastName || " "),
  email: item.contact?.email || "-",
  phone: item.contact?.phone || "-",
  companyName: item.companyName,
  companyCity: item.companyCity,
  companyCountry: item.companyCountry,
  websiteLink: item.websiteLink,
  createdAt: item.createdAt,
  siteAmount: item.sitesCount,
  chargerAmount: item.stationsCount,
  transactionAmount: item.transactionsCount,
});
