import { Box } from "@mui/material";

import SearchIcon from "@assets/icons/search.svg";
import { debounce } from "@utils/debounce";

import styles from "./styles.module.scss";

export const SearchInput = ({ placeholder, onChange }) => {
  const onSearch = ({ target: { value: text } }) => {
    onChange(text);
  };

  return (
    <Box className={styles.container}>
      <img src={SearchIcon} />
      <input placeholder={placeholder} onChange={debounce(onSearch)} className={styles.input} />
    </Box>
  );
};
