import { Box, Typography, Divider, IconButton } from "@mui/material";

import EditIcon from "@assets/icons/edit.svg";
import { Paper } from "@components/new";
import { DateHelper } from "@utils";

import { fields } from "./config";
import styles from "./styles.module.scss";

export const GeneralInfo = ({ group, onEdit }) => {
  const renderContent = (field) => {
    const value = group[field];
    switch (field) {
      case "createdAt":
      case "updatedAt": {
        return (
          <Typography className={styles.value}>{DateHelper.formatDateToLocal(value)}</Typography>
        );
      }
      default: {
        return <Typography className={styles.value}>{value}</Typography>;
      }
    }
  };

  const renderField = ({ label, field }, i) => {
    return (
      <Box key={i} className={styles.cell}>
        <Typography className={styles.label}>{label}</Typography>
        {renderContent(field)}
      </Box>
    );
  };

  return (
    <>
      <Paper className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>General Info</Typography>
          <IconButton onClick={onEdit}>
            <img src={EditIcon} />
          </IconButton>
        </Box>
        <Box className={styles.header}>
          <Typography className={styles.title}>General Info</Typography>
        </Box>
        <Box className={styles.body}>
          <Box className={styles.grid}>{fields.map(renderField)}</Box>
        </Box>
        <Divider className={styles.divider} />
      </Paper>
    </>
  );
};
