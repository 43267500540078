import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { Button, LoadingContainer, Paper, Table } from "@components/new";
import { ConnectorAPI } from "@api";

import { columns } from "./config";
import { mapper } from "./mappers";
import { FormModal } from "./modal";
import styles from "./styles.module.scss";

export const ConnectorTypes = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [connectors, setConnectors] = useState([]);

  const [modal, setModal] = useState({
    title: "",
    open: false,
    onSave: () => {},
    data: undefined,
  });

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setIsLoading(true);
    try {
      const data = await ConnectorAPI.getAll();
      setConnectors(data.map(mapper));
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = (type, data) => {
    const title = `${type === "add" ? "Add" : "Edit"} Connector`;
    const onSave = type === "add" ? handleAdd : handleEdit(data.id);
    setModal({ title, open: true, onSave, data });
  };

  const closeModal = () => {
    setModal({ title: "", open: false, onSave: () => {}, data: undefined });
  };

  const handleAdd = async (addData) => {
    await ConnectorAPI.create(addData);
    await init();
  };

  const handleEdit = (id) => {
    return async (editData) => {
      await ConnectorAPI.update(id, editData);
      init();
    };
  };

  const actions = [(item) => ({ label: "Edit", onClick: () => openModal("edit", item) })];

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>Connector Types</Typography>
          <Button text={"Add Conenctor"} variant={"contained"} onClick={() => openModal("add")} />
        </Box>
        <Paper>
          <Table data={connectors} columns={columns} actions={actions} filterEnabled={false} />
        </Paper>
      </LoadingContainer>
      <FormModal {...modal} onClose={closeModal} />
    </>
  );
};
