import { Edit, Delete, NotInterestedOutlined } from "@mui/icons-material";
import { Box, Typography, IconButton, Chip } from "@mui/material";
import cx from "classnames";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

import { Paper } from "@components/new";
import { DateHelper } from "@utils";

import { PricingDay, days } from "../Day";

import styles from "./styles.module.scss";

export const PricingCard = ({ pricingItem, onUp, onDown, onEdit, onDelete }) => {
  const { id, enabled, name, price, dayScheme, timeScheme, assignedDriverGroups } = pricingItem;

  const renderTimeScheme = ({ weekdays, startHour, startMin, endMin, endHour }, i) => {
    const startDate = DateHelper.format24to12(startHour, startMin);
    const endDate = DateHelper.format24to12(endHour, endMin);

    return (
      <Box key={i} className={styles.time_item}>
        <Box className={styles.days}>
          {Object.keys(days).map((day, i) => (
            <PricingDay key={i} compact day={day} selected={weekdays.includes(day)} />
          ))}
        </Box>
        <Typography className={styles.time}>{`${startDate} - ${endDate}`}</Typography>
      </Box>
    );
  };

  const renderDayScheme = ({ startTime, endTime }, i) => {
    return (
      <Typography key={i}>
        {`${DateHelper.formatDateToLocal(startTime)}`}
        <span>&nbsp;&nbsp;{"to"}&nbsp;&nbsp;</span>
        {`${DateHelper.formatDateToLocal(endTime)}`}
      </Typography>
    );
  };

  const renderDriverGroup = (name, i) => {
    return <Chip key={i} label={name.split("###")[1]} />;
  };

  const onEditPress = () => {
    onEdit(pricingItem);
  };

  const onDeletePress = () => {
    onDelete(id);
  };

  const clasess = cx(styles.container, !enabled && styles.container_disabled);

  return (
    <Paper className={clasess}>
      <Box className={styles.price}>
        {!enabled && <NotInterestedOutlined fontSize={"small"} />}
        <Typography>{`₪${parseFloat(price).toFixed(2)}`}</Typography>
      </Box>
      <Typography className={styles.name}>{name}</Typography>
      <Box className={styles.body}>
        {!!timeScheme.length && (
          <Box className={styles.block}>{timeScheme.map(renderTimeScheme)}</Box>
        )}
        {!!dayScheme.length && <Box className={styles.block}>{dayScheme.map(renderDayScheme)}</Box>}
      </Box>
      <Box>
        {onUp && <AiOutlineArrowUp size={30} />}
        {onDown && <AiOutlineArrowDown size={30} />}
      </Box>
      <Box>{assignedDriverGroups?.map(renderDriverGroup)}</Box>
      <Box>
        <IconButton className={styles.button} size={"small"} onClick={onEditPress}>
          <Edit fontSize={"inherit"} />
        </IconButton>
        <IconButton className={styles.button} size={"small"} onClick={onDeletePress}>
          <Delete fontSize={"inherit"} />
        </IconButton>
      </Box>
    </Paper>
  );
};
