import * as Yup from "yup";

import { passwordSchema } from "./password";

export const resetSchema = Yup.object().shape({
  otp: Yup.string()
    .required("OTP is required")
    .test("type", "OTP should be a number", (val) => Number.isInteger(+val))
    .test("length", "Length should be 6 symbols", (val) => val.length === 6),
  newPassword: passwordSchema,
  confirmPassword: Yup.string().oneOf([Yup.ref("newPassword")], "Passwords must match"),
});
