import { useState } from "react";
import { toast } from "react-toastify";

import { FormTextInput } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";
import { connectorTypeSchema } from "@utils/validation";

export const FormModal = ({ open, onClose, onSave, title, data }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { register, errors, control, handleSubmit } = useForm({
    open,
    defaultValues: data || { name: "" },
    validationSchema: connectorTypeSchema,
  });

  const save = async (data) => {
    setIsLoading(true);
    try {
      await onSave(data);
      onClose();
      toast.success("Successfully saved");
    } catch {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer
      open={open}
      title={title}
      onClose={onClose}
      onCancel={onClose}
      loading={isLoading}
      onSave={handleSubmit(save)}
    >
      <FormTextInput label={"Name"} control={control} error={errors.name} {...register("name")} />
    </ModalContainer>
  );
};
