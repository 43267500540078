export const columns = [
  { label: "Charge Point Id", field: "chargerId" },
  { label: "Charger Name", field: "name" },
  { label: "Business", field: "business" },
  { label: "Site Name", field: "site" },
  { label: "Hardware Status", field: "hardwareStatus" },
  { label: "Operational Status", field: "operationalStatusName" },
  { label: "Connectors", field: "connectors" },
  { label: "Vendor", field: "vendor" },
  { label: "Model", field: "model" },
  { label: "Installation Date", field: "createdAt" },
  { label: "Last Heartbeat", field: "lastHeartbeat" },
];

export const filters = [
  { name: "Site Name", field: "site" },
  { name: "Station Hardware Status", field: "hardwareStatus" },
  { name: "Operational Status", field: "operationalStatusName" },
  { name: "Station Vendor", field: "vendor" },
  { name: "Station Model", field: "model" },
  { name: "Connector Type", field: "connectorNames" },
];

export const sortFields = [
  "chargerId",
  "name",
  "business",
  "site",
  "hardwareStatus",
  "operationalStatusName",
  "vendor",
  "model",
  "createdAt",
  "lastHeartbeat",
];
