import { InputLabel } from "@mui/material";
import cx from "classnames";

import classes from "./styles.module.css";

export const Label = (props) => {
  const { className, ...rest } = props;

  const classNames = cx(classes.label, className);

  return <InputLabel shrink className={classNames} {...rest} />;
};
