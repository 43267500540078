import { TableRow } from "@mui/material";
import cx from "classnames";

import classes from "./styles.module.css";

export const Row = ({ className, children }) => {
  const classNames = cx(classes.row, className);

  return <TableRow className={classNames}>{children}</TableRow>;
};
