export const mapper = (item) => ({
  id: item.userId,
  userName: item.userName,
  firstName: item.firstName,
  lastName: item.lastName,
  phoneNumber: item.phoneNumber,
  email: item.email,
  checked: false,
});

export const columns = [
  {
    name: "USER NAME",
    field: "userName",
    width: "13%",
  },
  {
    name: "FIRST NAME",
    field: "firstName",
    width: "10%",
  },
  {
    name: "LAST NAME",
    field: "lastName",
    width: "10%",
  },
  {
    name: "PHONE NUMBER",
    field: "phoneNumber",
    width: "13%",
  },
  {
    name: "EMAIL",
    field: "email",
    width: "18%",
  },
];

const csvConfig = {
  head: ["User Name", "First Name", "Last Name", "Phone Number", "Email"],
  body: (item) => [item.userName, item.firstName, item.lastName, item.phoneNumber, item.email],
};

const searchFields = columns.map(({ field }) => field);

export const config = { csvConfig, searchFields };
