export const connector = { connectorNumber: "", connectorType: "", powerRating: "" };

export const defaultValues = {
  vendor: "",
  modelName: "",
  powerRating: "",
  deviceType: "",
  connectors: [connector],
};

const options = [
  { label: "AC", value: "AC" },
  { label: "DC", value: "DC" },
  { label: "Mini DC", value: "Mini DC" },
];

export const config = [
  { label: "Vendor", name: "vendor" },
  { label: "Model Name", name: "modelName" },
  { label: "Device Type", name: "deviceType", options },
  { type: "number", label: "Power Rating kWh", name: "powerRating" },
];
