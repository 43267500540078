import { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";

import { ConfirmationModal } from "@components/modals";
import { Paper, Switcher } from "@components/new";
import { useAuth } from "@providers";
import { DateHelper } from "@utils";
import { UserAPI } from "@api";

import { fields } from "./config";
import styles from "./styles.module.scss";

export const GeneralInfo = ({ user }) => {
  const [status, setStatus] = useState(user.status);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible((isVisible) => !isVisible);
  };

  const changeStatus = () => {
    UserAPI.toggleStatus(user.id, !status);
    setStatus((status) => !status);
    setIsModalVisible(false);
  };

  const { user: currentUser, isAdmin } = useAuth();

  const renderContent = (field) => {
    const value = user[field];
    switch (field) {
      case "status": {
        return isAdmin && user.id !== currentUser?.userId ? (
          <Switcher checked={status} onChange={toggleModal} />
        ) : (
          <Typography className={styles.value}>{value ? "Enabled" : "Disabled"}</Typography>
        );
      }
      case "registrationComplete": {
        return <Typography className={styles.value}>{value ? "Enabled" : "Disabled"}</Typography>;
      }
      case "createdAt":
      case "updatedAt":
      case "lastLoginAt": {
        return value ? (
          <Typography className={styles.value}>{DateHelper.formatDateToLocal(value)}</Typography>
        ) : (
          "-"
        );
      }
      default: {
        return <Typography className={styles.value}>{value}</Typography>;
      }
    }
  };

  const renderField = ({ label, field }, i) => {
    return (
      <Box key={i} className={styles.cell}>
        <Typography className={styles.label}>{label}</Typography>
        {renderContent(field)}
      </Box>
    );
  };

  const modalText = `Are you sure you want to ${status ? "disable" : "enable"} this user?`;
  return (
    <>
      <Paper className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>General Info</Typography>
        </Box>
        <Box className={styles.body}>
          <Box className={styles.grid}>{fields.map(renderField)}</Box>
        </Box>
        <Divider className={styles.divider} />
      </Paper>
      <ConfirmationModal
        open={isModalVisible}
        text={modalText}
        onConfirm={changeStatus}
        onReject={toggleModal}
      />
    </>
  );
};
