import { useNavigate } from "react-router-dom";
import { ErrorOutline } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import styles from "./styles.module.scss";

export const NotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Box className={styles.container}>
      <ErrorOutline className={styles.icon} />
      <Typography className={styles.title}>Page Not Found</Typography>
      <Box onClick={goBack}>
        <Typography className={styles.link}>Go Back</Typography>
      </Box>
    </Box>
  );
};
