import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";

import { ConfirmationModal } from "@components/modals";
import {
  LoadingContainer,
  Button,
  Paper,
  SearchInput,
  Table,
  Switcher,
  DateTime,
  Link,
} from "@components/new";
import { useConfirmation, usePagination, useTable } from "@hooks";
import { RfidAPI } from "@api";

import { FileUpload } from "./UserRfidModal/FileUpload";
import { UserRfidModal } from "./UserRfidModal";
import { actions, texts } from "./action-popup";
import { columns, config, userRfidMapper, sortFields, reducer } from "./config";
import styles from "./styles.module.scss";

export const UserRfid = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [refreshList, setRefreshList] = useState(false);
  const [statusesMap, setStatusesMap] = useState(new Map());
  const [rfids, setRfids] = useState([]);
  const [modal, setModal] = useState({ visible: false, id: "", enabled: false });
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentRfid, setCurrentRfid] = useState();

  const { invoke } = useTable();

  const toggleAddModal = () => {
    setIsAddModalVisible((isVisible) => !isVisible);
    setCurrentRfid(undefined);
  };

  const refresh = () => {
    setRefreshList(!refreshList);
  };

  const closeRfidModal = () => {
    setIsAddModalVisible(false);
  };

  const toggleEditModal = (rfid) => {
    setCurrentRfid(rfid);
    setIsAddModalVisible((isVisible) => !isVisible);
  };

  const fetchRfids = (pagination) => {
    return RfidAPI.getAll({ ...pagination });
  };

  const { fetch, ...pagination } = usePagination(fetchRfids);

  useEffect(() => {
    setIsLoading(true);
    fetch()
      .then((rfids) => {
        const mappedRfids = rfids.map(userRfidMapper);
        setStatusesMap(mappedRfids.reduce(reducer, new Map()));
        setRfids(mappedRfids);
      })
      .finally(() => setIsLoading(false));
  }, [refreshList, pagination.page]);

  const { isVisible, open, ...rest } = useConfirmation(actions, texts);

  const onConfirm = async () => {
    const status = !modal.enabled;
    await RfidAPI.updateStatus(modal.id, status);
    setStatusesMap(new Map(statusesMap.set(modal.id, status)));
    setModal((modal) => ({ ...modal, visible: false }));
  };

  const downloadFile = async () => {
    RfidAPI.downloadCsv()
      .then((response) => {
        const href = window.URL.createObjectURL(response);

        const anchorElement = document.createElement("a");

        anchorElement.href = href;
        anchorElement.download = "rfid_upload";

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch(() => {
        toast.error("Some thing went wrong");
      });
  };

  const onSave = async (data, id) => {
    try {
      if (id) {
        await RfidAPI.updateRfid(id, data);
        toast.success("Rfid has been successfully updated");
      } else {
        await RfidAPI.createRfid(data);
        toast.success("Rfid has been successfully created");
      }

      refresh();
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const onReject = () => {
    setModal((modal) => ({ ...modal, visible: false }));
  };

  const render = (item, field) => {
    const value = item[field];
    if (field === "rfid" && !item["isDefault"]) {
      return <Link text={value} path={`${item.id}/details`} />;
    }
    if (["creationDate", "activationDate", "expirationDate"].includes(field)) {
      return <DateTime twoLine value={value / 1000} />;
    }

    if (field === "enabled" && !item["isDefault"]) {
      const { id } = item;
      const enabled = statusesMap.get(id);
      const onChange = () => setModal({ visible: true, id, enabled });
      return <Switcher checked={enabled} onChange={onChange} />;
    } else if (field === "enabled") {
      const { id } = item;
      const enabled = statusesMap.get(id);
      return enabled ? "Active" : "InActive";
    }

    return value;
  };

  const tableActions = [
    (rfid) =>
      !rfid.isDefault
        ? { label: "Edit", onClick: () => !rfid.isDefault && toggleEditModal(rfid) }
        : { onClick: () => !rfid.isDefault },
    (rfid) => ({
      label: "Delete",
      onClick: () => !rfid.isDefault && open("delete", rfid, refresh),
    }),
  ];

  const text = `Are you sure you want to ${modal.enabled ? "Disable" : "Enable"} this RFID Card?`;

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>User RFID List</Typography>
          <Box className={styles.buttons}>
            <FileUpload setIsLoading={setIsLoading} />
            <Button variant={"outlined"} text={"Template Download"} onClick={downloadFile} />
            <Button variant={"contained"} text={"Add RFID Card"} onClick={toggleAddModal} />
          </Box>
        </Box>
        <Paper className={styles.body}>
          <Box className={styles.tools}>
            <SearchInput
              placeholder={"Search by EV driver name, RFID number"}
              onChange={invoke("search")}
            />
          </Box>
          <Table
            data={rfids}
            columns={columns}
            config={config}
            actions={tableActions}
            renderItem={render}
            sortFields={sortFields}
            tableClassName={styles.table}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <UserRfidModal
        open={isAddModalVisible}
        rfidDetails={currentRfid}
        onSave={onSave}
        onClose={closeRfidModal}
      />
      <ConfirmationModal open={isVisible} {...rest} />
      <ConfirmationModal
        open={modal.visible}
        text={text}
        onConfirm={onConfirm}
        onReject={onReject}
      />
    </>
  );
};
