import { useFormContext } from "react-hook-form";

import { FormTextInput } from "@components/form/new";

import { fields } from "./config";

export const AddressForm = () => {
  const { register, control, errors, updateAddress } = useFormContext();

  const setField = (name) => {
    const { onChange, ...rest } = register(name);

    return {
      ...rest,
      onChange: (e) => {
        onChange(e);
        updateAddress({ [name]: e.target.value });
      },
    };
  };

  return (
    <>
      {fields.map(({ label, name }, i) => (
        <FormTextInput
          key={i}
          label={label}
          control={control}
          error={errors[name]}
          {...setField(name)}
        />
      ))}
    </>
  );
};
