export const chargerMapper = (item) => ({
  id: item.id,
  chargerId: item.chargeStationId,
  name: item.stationName,
  business: item.business,
  site: item.siteName,
  hardwareStatus: item.hardwareStatus ? "Online" : "Offline",
  operationalStatusName: item.operationalStatus ? "Enabled" : "Disabled",
  operationalStatusValue: item.operationalStatus,
  connectors: item.hardwareStatus
    ? item.connectors.map(({ icon, connectorStatus: status }) => ({ icon, status }))
    : null,
  connectorNames: item.connectors.map(({ connectorTypeName }) => connectorTypeName),
  vendor: item.chargeStationModel.vendor,
  model: item.chargeStationModel.modelName,
  createdAt: item.createdDate,
  lastHeartbeat: item.lastHeartbeat,
});

export const modelMapper = (options) => {
  const vendors = [...new Set(options.map(({ vendor }) => vendor))].map((vendor) => ({
    label: vendor,
    value: vendor,
  }));

  const models = options.reduce((acc, { vendor, modelName, chargeStationModelId }) => {
    const value = { label: modelName, value: chargeStationModelId };

    if (acc[vendor]) acc[vendor].push(value);
    else acc[vendor] = [value];

    return acc;
  }, {});

  return { vendors, models };
};

export const connectorMapper = (item) => item.name;
