export const columns_back = [
  { label: "Session Id", field: "sessionId" },
  { label: "Username", field: "user" },
  { label: "Station Name", field: "stationName" },
  { label: "Charge Cost", field: "cost" },
  { label: "Energy (kWh)", field: "energy" },
  { label: "Vehicle", field: "vehicle" },
  { label: "Number Plate", field: "numberPlate" },
  { label: "Charge Start Time", field: "startTimeOfCharge" },
  { label: "Charge End Time", field: "endTimeOfCharge" },
];

export const columns = [
  {
    label: "Transaction ID",
    field: "identifier",
  },
  {
    label: "Station Name",
    field: "stationName",
  },
  {
    label: "Driver",
    field: "username",
  },
  {
    label: "Vehicle",
    field: "vehicle",
  },
  {
    label: "Transaction Start",
    field: "startDate",
  },
  {
    label: "Transaction End",
    field: "endDate",
  },
  {
    label: "Connection Time",
    field: "connectionTime",
  },
  {
    label: "Charging Time",
    field: "chargingTime",
  },
  {
    label: "Consumed kWh",
    field: "consumed",
  },
  {
    label: "Transaction Total",
    field: "cost",
  },
  {
    label: "Billed Amount",
    field: "billed",
  },
  {
    label: "Billed Status",
    field: "status",
  },
  {
    label: "Connector",
    field: "connectorNumber",
  },
  {
    label: "Payment Method",
    field: "paymentMethod",
  },
];

export const filters = [
  { name: "Billed Status", field: "status" },
  {
    name: "Date",
    field: "date",
    options: ["Current Day", "Last Week", "Last 30 Days", "Custom"],
  },
];
