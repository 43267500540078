import { EntityList } from "@components/new";
import { BusinessAPI, UserAPI } from "@api";

import { config } from "./config";

export const UserList = ({ businessId }) => {
  const titles = {
    sectionTitle: "User",
    plural: "user(s)",
    unassignTitle: "Unassign User(s)",
    assignTitle: "Assign User(s)",
    assignModalTitle: "Assign User(s) to the business",
  };

  const functions = {
    getAssignedItems: (businessId) => UserAPI.getUsersByBusiness(businessId),
    getAvailableItems: () => UserAPI.getUsersSimpleList(),
    onAssign: (businessId, toAssign) => BusinessAPI.assignUsers(businessId, toAssign),
    onUnassign: (businessId, toDelete) => BusinessAPI.unassignUsers(businessId, toDelete),
  };

  return <EntityList parentId={businessId} config={config} titles={titles} actions={functions} />;
};
