import { useState } from "react";
import { toast } from "react-toastify";

import { ChargeStationAPI, ConfigurationAPI } from "@api";

const actions = {
  delete: async ({ id }) => await ChargeStationAPI.deleteMultiple(id),
  softReset: async ({ id }) => await ConfigurationAPI.reset(id, "Soft"),
  hardReset: async ({ id }) => await ConfigurationAPI.reset(id, "Hard"),
  toggle: async ({ id, operationalStatusValue }) => {
    await ChargeStationAPI.update(id, { operationalStatus: !operationalStatusValue });
  },
};

const toasts = {
  delete: {
    success: "Charger is deleted",
    error: "Error occured while deleting the charger",
  },
  softReset: {
    success: "Soft reset is done",
    error: "An error occured while soft reset",
  },
  hardReset: {
    success: "Hard reset is done",
    error: "An error occured while hard reset",
  },
  toggle: {
    success: "Opearational status is updated",
    error: "An error occured while updating operational status",
  },
};

const texts = {
  delete: ({ name }) => `Are you sure you want to delete station ${name}?`,
  softReset: () => "Are you sure you want to Soft Reset this station?",
  hardReset: () => "Are you sure you want to Hard Reset this station?",
  toggle: ({ operationalStatusValue }) => {
    const action = operationalStatusValue ? "Disable" : "Enable";
    return `Are you sure you want to ${action} this station?`;
  },
};

const subtexts = {
  softReset: () => "If any activities are in progress, they will be terminated",
  hardReset: () => "If any activities are in progress, they will be terminated",
};

export const useConfirmation = () => {
  const [[type, station, callback], setModal] = useState([]);

  const open = (type, station, callback) => {
    setModal([type, station, callback]);
  };

  const close = () => {
    setModal([]);
  };

  const text = texts[type]?.call(null, station);
  const subtext = subtexts[type]?.call(null);

  const onConfirm = async () => {
    close();
    try {
      await actions[type]?.call(null, station);
      toast.success(toasts[type].success);
      callback?.call(null, station);
    } catch {
      toast.error(toasts[type].error);
    }
  };

  return { isVisible: !!type, text, subtext, open, onConfirm, onReject: close };
};
