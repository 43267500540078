import { EntityList } from "@components/new";
import { ChargeSiteAPI, DriverGroupAPI } from "@api";

import { config } from "./config";

export const DriverGroupList = ({ siteId }) => {
  const titles = {
    sectionTitle: "Driver Groups",
    plural: "driver group(s)",
    unassignTitle: "Unassign Driver Group(s)",
    assignTitle: "Assign Driver Group",
    assignModalTitle: "Assign driver group",
  };

  const functions = {
    getAssignedItems: () => ChargeSiteAPI.getByIdDriverGroups(siteId, "DEFER_PAYMENT"),
    getAvailableItems: () => DriverGroupAPI.getDriverGroups(),
    onAssign: (siteId, toAssign) =>
      ChargeSiteAPI.assignDriverGroups({ type: "DEFER_PAYMENT", siteId, driverGroupIds: toAssign }),
    onUnassign: (siteId, toDelete) =>
      ChargeSiteAPI.unAssignDriverGroups(siteId, toDelete, "DEFER_PAYMENT"),
  };

  return <EntityList parentId={siteId} config={config} titles={titles} actions={functions} />;
};
