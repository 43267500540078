import { useEffect, useState } from "react";

import { ModalContainer } from "@components/common";
import { Table } from "@components/common/Table/table";
import { SupportAPI } from "@api";

import { mapper, columns, config, render } from "./config";

export const TicketModal = ({ open, userName, id, onClose, isUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    if (open && id) {
      setIsLoading(true);
      const ticketPromise = isUser
        ? SupportAPI.getTicketsByUser(id)
        : SupportAPI.getTicketsByDriver(id);
      ticketPromise.then((data) => setTickets(data.map(mapper))).finally(() => setIsLoading(false));
    }
  }, [open, id]);

  return (
    <ModalContainer
      open={open}
      title={`${userName} tickets`}
      onClose={onClose}
      contentLoader={isLoading}
      maxWidth={"lg"}
    >
      <Table
        data={tickets}
        setData={setTickets}
        columns={columns}
        toolsEnabled={false}
        deletionEnabled={false}
        renderCustomCell={render}
        config={config}
      />
    </ModalContainer>
  );
};
