import { api } from "./axios";

export class BusinessAPI {
  static get({ page, size }) {
    return api.get("business", { params: { page, size } });
  }

  static getForCurrentUser() {
    return api.get("business/users");
  }

  static getByUserId(userId) {
    return api.get(`business/users/${userId}`);
  }

  static getById(id) {
    return api.get(`business/${id}`);
  }

  static getByIdGeneralInfo(id) {
    return api.get(`business/${id}/general-info`);
  }

  static create(payload) {
    return api.post("business", payload);
  }

  static edit(id, payload) {
    return api.put(`business/${id}`, payload);
  }

  static delete(id) {
    return api.delete(`business/${id}`);
  }

  static assignBusinesses(userId, payload) {
    return api.patch(`users/${userId}/business`, { list: payload });
  }
  static deleteBusinesses(userId, payload) {
    return api.delete(`users/${userId}/business`, { params: { businesses: payload } });
  }

  static assignUsers(businessId, payload) {
    return api.patch(`business/${businessId}/associations`, { usersToAssign: payload });
  }
  static unassignUsers(businessId, payload) {
    return api.patch(`business/${businessId}/associations`, { userToUnAssign: payload.split(",") });
  }

  static assignDriverGroups(businessId, payload) {
    return api.patch(`business/${businessId}/associations`, { driverGroupsToAssign: payload });
  }
  static unassignDriverGroups(businessId, payload) {
    return api.patch(`business/${businessId}/associations`, { driverGroupsToUnAssign: payload });
  }
}
