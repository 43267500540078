import { useState } from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";

import { Label, Switcher } from "@components/form";
import { FormDatePicker } from "@components/form/new";
import { AssignModal } from "@components/modals";
import { Button, ModalContainer, TextInput } from "@components/new";
import { useForm, usePagination } from "@hooks";
import { DriverAPI } from "@api";

import { mapper } from "../../../UserRfidModal/mappers";

const columns = [
  {
    label: "User Name",
    field: "name",
  },
  {
    label: "First Name",
    field: "firstName",
  },
  {
    label: "Last Name",
    field: "lastName",
  },
  {
    label: "Email",
    field: "email",
  },
];

export const EditModal = ({ open, rfid, onClose, onSave }) => {
  const [drivers, setDrivers] = useState([]);
  const defaultValues = {
    activationDate: rfid?.activationDate ? rfid?.activationDate * 1000 : null,
    expirationDate: rfid?.expirationDate ? rfid?.expirationDate * 1000 : null,
    status: rfid?.status,
    driverDetails: rfid?.driverName,
    enabled: rfid?.status,
  };
  const [driverDetails, setDriverDetails] = useState(rfid?.driverName);
  const [availableList, setAvailableList] = useState([]);
  const [assignedList, setAssignedList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [driverId, setDriverId] = useState(rfid?.driverId);
  const [refresh, setRefresh] = useState(false);

  const { register, control, handleSubmit } = useForm({ open, defaultValues });

  const { fetch, ...pagination } = usePagination(DriverAPI.getDrivers);

  useEffect(() => {
    fetch().then((data) => setDrivers(data.map(mapper)));
  }, [pagination.page]);

  const handleSave = async ({ activationDate, expirationDate, enabled }) => {
    try {
      let modExpirationDate = null;
      let modActivationDate = null;
      if (typeof expirationDate === "string") {
        modExpirationDate = new Date(expirationDate).getTime() / 1000;
      } else if (expirationDate !== null) {
        modExpirationDate = expirationDate / 1000;
      }
      if (typeof activationDate === "string") {
        modActivationDate = new Date(activationDate).getTime() / 1000;
      } else if (activationDate !== null) {
        modActivationDate = activationDate / 1000;
      }

      if (rfid?.id) {
        await onSave({
          id: rfid?.id,
          rfid: rfid?.rfid,
          activationDate: modActivationDate,
          expirationDate: modExpirationDate,
          enabled,
          driverId,
        });
      }
    } finally {
      onClose();
    }
  };

  const definedMapper = (item) => ({
    id: item.id,
    name: item.userName,
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.email,
  });

  useEffect(() => {
    Promise.all([fetch()]).then(([defined]) => {
      setAssignedList([]);
      setAvailableList(defined.map(definedMapper));
    });
  }, [refresh]);

  const assignModalToggle = () => {
    setShowModal(!showModal);
  };

  const refreshData = () => {
    setRefresh(!refresh);
  };

  const assignItem = ([driverId]) => {
    const { name } = drivers.find(({ id }) => id === driverId);
    setDriverId(driverId);
    setDriverDetails(`UserName: ${name}`);
    assignModalToggle();
    refreshData();
  };

  return (
    <ModalContainer
      open={open}
      title={"Edit"}
      onClose={onClose}
      onCancel={onClose}
      loading={false}
      onSave={handleSubmit(handleSave)}
    >
      <Box>
        <Label>Enable Status</Label>
        <Switcher control={control} {...register("enabled")} />
      </Box>
      <FormDatePicker label={"Activation Date"} control={control} {...register("activationDate")} />
      <FormDatePicker label={"Expiration Date"} control={control} {...register("expirationDate")} />
      <Button variant={"contained"} text={"Assign Driver"} onClick={assignModalToggle} />
      <TextInput value={driverDetails} placeholder={"Diver Name"} disabled={true} />
      <AssignModal
        visible={showModal}
        assigned={assignedList}
        fullList={availableList}
        title={"Assign Driver"}
        columns={columns}
        onSave={assignItem}
        onCancel={assignModalToggle}
        multiple={false}
      />
    </ModalContainer>
  );
};
