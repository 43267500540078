import { useMemo, useState } from "react";
import { Box } from "@mui/material";

import { FormCheckbox, FormSelector, FormTextInput } from "@components/form/new";
import { ConfirmationModal } from "@components/modals";
import { ModalContainer } from "@components/new";
import { useBusiness } from "@providers";
import { useForm } from "@hooks";

import styles from "./styles.module.scss";
import { getValidationSchema } from "./validation";

export const UserModal = ({ open, user, roles, onSave, onClose, titles }) => {
  const title = user ? titles.edit : titles.add;
  const validationSchema = getValidationSchema(user);

  const { business, options } = useBusiness();

  const defaultValues = useMemo(() => {
    if (user) return user;

    return {
      userName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: "",
      businesses: [business],
      roles: [],
      status: false,
    };
  }, [user, business]);

  const { register, isDirty, errors, control, handleSubmit } = useForm({
    open,
    validationSchema,
    defaultValues,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);

  const handleSave = async (rest) => {
    setIsLoading(true);
    try {
      await onSave(rest, user?.id);
    } finally {
      setIsLoading(false);
    }
  };

  const cancel = () => {
    isDirty ? setIsConfirmationModalVisible(true) : onClose();
  };

  const hideConfirmation = () => {
    setIsConfirmationModalVisible(false);
  };

  const onConfirm = () => {
    hideConfirmation();
    onClose();
  };

  return (
    <>
      <ModalContainer
        open={open}
        title={title}
        onClose={cancel}
        onCancel={cancel}
        loading={isLoading}
        onSave={handleSubmit(handleSave)}
      >
        <Box className={styles.data}>
          <FormTextInput
            control={control}
            label={"User Name"}
            error={errors.userName}
            {...register("userName")}
          />
          <FormTextInput
            control={control}
            label={"First Name"}
            error={errors.firstName}
            {...register("firstName")}
          />
          <FormTextInput
            control={control}
            label={"Last Name"}
            error={errors.lastName}
            {...register("lastName")}
          />
          <FormTextInput
            control={control}
            label={"Phone Number"}
            error={errors.phoneNumber}
            {...register("phoneNumber")}
          />
          <FormTextInput
            label={"Email"}
            control={control}
            error={errors.email}
            {...register("email")}
          />
          {!user && (
            <>
              <FormTextInput
                control={control}
                label={"Password"}
                error={errors.password}
                {...register("password")}
              />

              <FormSelector
                multiple
                control={control}
                label={"Business"}
                options={options}
                error={errors.businesses}
                {...register("businesses")}
              />
              <FormSelector
                multiple
                label={"Roles"}
                options={roles}
                control={control}
                error={errors.roles}
                {...register("roles")}
              />
            </>
          )}
          <FormCheckbox label={"Active"} control={control} {...register("status")} />
        </Box>
      </ModalContainer>
      <ConfirmationModal
        open={isConfirmationModalVisible}
        text={"Are you sure you want to close?"}
        subtext={"All unsaved changes will be lost"}
        onReject={hideConfirmation}
        onConfirm={onConfirm}
      />
    </>
  );
};
