export const mapper = (item) => ({
  id: item.id,
  rfid: item.rfid,
  driverId: item?.driver?.driverId,
  driverName: item?.driver?.driverName,
  status: item.enabled,
  rfidGroupIds: item.rfidGroupIds,
  activationDate: item.activationDate,
  expirationDate: item.expirationDate,
  creationDate: item.creationDate,
});

export const driverMapper = (item) => ({
  id: item.id,
  name: item.userName,
  firstName: item.firstName,
  lastName: item.lastName,
  email: item.email,
});
