import { api } from "./axios";

export class DriverGroupAPI {
  static getDriverGroups() {
    return api.get("driver/groups");
  }

  static getGroupById(id) {
    return api.get(`driver/groups/${id}`);
  }

  static create(payload) {
    payload.groupName = payload.name;
    return api.post("driver/groups", payload);
  }

  static update(id, payload) {
    return api.patch(`driver/groups/${id}`, payload);
  }

  static async getDriverGroupForBusiness(id) {
    return api.get(`driver/groups/business/${id}`);
  }

  static async assignedDrivers(id) {
    return api.get(`driver/groups/${id}/driver`);
  }

  static assignDriversToGroup(ids, drivers) {
    return api.post("driver/group/association", { groupIds: ids, driverIds: drivers });
  }

  static removeFromGroup(ids, drivers) {
    return api.delete("driver/group/association", {
      params: { groupIds: ids, driverIds: drivers },
    });
  }

  static addDriverToGroups(driver, groups) {
    return api.post("driver/group/association", { groupIds: groups, driverIds: driver });
  }

  static removeDriverFromGroups(driver, groups) {
    return api.delete("driver/group/association", {
      params: { groupIds: groups, driverIds: driver },
    });
  }

  static deleteGroup(id) {
    return api.delete(`driver/groups/${id}`);
  }
}
