import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, IconButton, Stack, Typography } from "@mui/material";

import ChevronLeft from "@assets/icons/chevron-left.svg";
import { Button, CountdownTimer } from "@components/new";

import styles from "./styles.module.scss";

export const Header = ({
  title,
  selectedTabIndex,
  onGoBack,
  onSoftReset,
  onHardReset,
  dateTimer,
  onRefresh,
}) => {
  return (
    <Box className={styles.header}>
      <Box className={styles.top} onClick={onGoBack}>
        <img src={ChevronLeft} />
        <Typography className={styles.back}>Back to Chargers List</Typography>
      </Box>
      {(selectedTabIndex === 0 || selectedTabIndex === "0") && (
        <Stack direction="row" className={styles.timer}>
          <CountdownTimer targetDate={dateTimer} />
          <IconButton onClick={onRefresh}>
            <RefreshIcon />
          </IconButton>
        </Stack>
      )}
      <Box className={styles.bottom}>
        <Typography className={styles.title}>{title}</Typography>
        <Box className={styles.buttons}>
          <Button variant={"outlined"} text={"Soft Reset"} onClick={onSoftReset} />
          <Button variant={"contained"} text={"Hard Reset"} onClick={onHardReset} />
        </Box>
      </Box>
    </Box>
  );
};
