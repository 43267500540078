export const reducer = (acc, { id, enabled }) => {
  return acc.set(id, enabled);
};

export const columns = [
  {
    label: "RFID",
    field: "rfid",
  },
  {
    label: "Groups",
    field: "rfidGroups",
  },
  {
    label: "Status",
    field: "enabled",
  },
  {
    label: "Creation Date",
    field: "creationDate",
  },
  {
    label: "Activation Date",
    field: "activationDate",
  },
  {
    label: "Expiration Date",
    field: "expirationDate",
  },
];

export const sortFields = ["rfid", "creationDate", "activationDate", "expirationDate"];
