export const mapper = (item) => ({
  id: item.siteId,
  siteName: item.siteName,
  businessName: item.business.businessName,
  businessId: item.business.businessId,
  activationDate: item.activationDate,
  addressDetails: item.addressDetails,
  creationDate: item.createdAt,
  privateSite: item.privateSite,
  deferPayment: item.deferPayment,
  privateNetworkDrivers: item.privateNetworkDrivers,
  privateNetworkDriverGroups: item.privateNetworkDriverGroups,
  deferPaymentDrivers: item.deferPaymentDrivers,
  deferPaymentDriverGroups: item.deferPaymentDriverGroups,
});

export const driverMapper = (item) => ({
  id: item.id,
  name: item.userName,
  firstName: item.firstName,
  lastName: item.lastName,
  email: item.email,
});
