const statuses = {
  REGISTERED: "Active",
  WAITING_CONFIRM: "Pending",
};

export const mapper = (item) => ({
  id: item.businessId,
  name: item.name,
  status: statuses[item.registrationStatus],
  email: item.email,
  createdAt: item.createdAt,
  siteAmount: item.sitesCount,
  chargerAmount: item.stationsCount,
  transactionAmount: item.transactionsCount,
});
