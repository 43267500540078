const mapper = (item) => ({
  id: item.roleId,
  name: item.roleName.replace("ROLE_", ""),
});

export const config = {
  reducer: (acc, { id }) => {
    return acc.set(id, false);
  },

  columns: [
    {
      label: "Assigned Role(s)",
      field: "name",
    },
  ],

  assignedMapper: mapper,
  definedMapper: mapper,
};
