import { isAnyChecked, getChecked } from "./check";

export const setFiltersOptions = (arr, filters) => {
  return filters.map((item) => {
    const fields = item.options.length
      ? item.options
      : [...new Set(arr.flatMap((x) => x[item.field]))];
    const options = fields.map((label) => ({ label, checked: false }));
    return { ...item, options };
  });
};

export const filter = (data, filters) => {
  const checkedFilters = filters
    .filter(({ options, field }) => field !== "customDate" && isAnyChecked(options))
    .reduce((acc, { field, options }) => {
      const newFilter = { field, options: getChecked(options).map(({ label }) => label) };
      return [...acc, newFilter];
    }, []);

  const newData = data.filter((item) => {
    return checkedFilters.reduce((acc, { field, options }) => {
      const data = item[field];
      if (Array.isArray(data) && data.length) {
        return acc && data.some((x) => options.includes(x));
      }
      return acc && options.includes(data);
    }, true);
  });

  const dateFilter = filters.filter(({ field, checked }) => field === "customDate" && checked);
  if (dateFilter && dateFilter.length > 0) {
    const { options } = dateFilter[0];
    const { startDate, endDate } = options;
    const startDateLong = startDate._d.getTime() / 1000;
    const endDateLong = endDate._d.getTime() / 1000;
    const newDateFilteredData = newData.filter((item) => {
      const date = item["startTimeOfCharge"];
      if (startDateLong <= date && date <= endDateLong) {
        return true;
      } else {
        return false;
      }
    });
    return newDateFilteredData;
  }
  return newData;
};

export const search = (data, searchFields, text) => {
  return data.filter((item) => {
    return Object.entries(item)
      .filter(([key]) => searchFields.includes(key))
      .map(([_, value]) => value?.toString().toLowerCase())
      .some((item) => item?.includes(text.toLowerCase()));
  });
};
