import { useEffect, useState } from "react";
import {
  Menu as MuiMenu,
  MenuItem,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { FaSearchengin } from "react-icons/fa";

import dropdownIcon from "@assets/icons/icon-dropdown.svg";
import dropupIcon from "@assets/icons/icon-dropup.svg";
import filterIcon from "@assets/icons/icon-filter.svg";
import { Button } from "@components/common";
import { DateRangePicker } from "@components/new/DateRangePicker";
import { checkAll, checkItem, isAnyChecked } from "@utils";

import classes from "./styles.module.css";

const Menu = styled(MuiMenu)(() => ({
  "& .MuiPaper-root": {
    width: "550px",
  },
}));

export const FilterDropdown = ({ filters, setFilters }) => {
  const [items, setItems] = useState(filters);
  const [isDirty, setIsDirty] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setItems(filters);
    setIsDirty(filters.some((item) => item.field !== "customDate" && isAnyChecked(item.options)));
  }, [filters]);

  const toggleOptions = (checked, field) => {
    const newItems = checkItem(items, checked, (item) => item.field === field);
    setItems(newItems);
  };

  const searchStartDate = () => {
    if (startDate && endDate) {
      const newFilters = items.map((item) =>
        item.field === "customDate" ? { ...item, options: { startDate, endDate } } : item,
      );
      setFilters(newFilters);
    }
  };

  const onChange = (field, label) => {
    return ({ target: { checked } }) => {
      const newFilters = items.map((item) =>
        item.field === field
          ? { ...item, options: checkItem(item.options, checked, (x) => x.label === label) }
          : item,
      );
      setFilters(newFilters);
    };
  };

  const resetFilters = () => {
    const newItems = items.map((item) => ({
      ...item,
      checked: false,
      options: checkAll(item.options, false),
    }));
    setFilters(newItems);
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <button className={classes.filter_btn} {...bindTrigger(popupState)}>
            <img src={filterIcon} alt="filter icon" />
            <span>Filters</span>
          </button>
          <Menu {...bindMenu(popupState)}>
            {isDirty && (
              <MenuItem onClick={resetFilters}>
                <Typography>Clear All</Typography>
              </MenuItem>
            )}
            {items.map(({ field, name, checked, options }) => (
              <MenuItem key={field} className={classes.menu_item}>
                <Box className={classes.filter} onClick={() => toggleOptions(!checked, field)}>
                  <Typography>{name}</Typography>
                  <img src={checked ? dropupIcon : dropdownIcon} alt="arrow icon" />
                </Box>
                {checked && field !== "customDate" && (
                  <Box className={classes.options_list}>
                    {options.map(({ label, checked }) => (
                      <FormControlLabel
                        key={label}
                        label={label}
                        control={
                          <Checkbox
                            color="info"
                            checked={checked}
                            onChange={onChange(field, label)}
                          />
                        }
                      />
                    ))}
                  </Box>
                )}
                {checked && field === "customDate" && (
                  <Box className={classes.options_list}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateRangePicker
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        startDate={startDate}
                        endDate={endDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <Button onClick={searchStartDate}>
                        <FaSearchengin />
                        Search
                      </Button>
                    </LocalizationProvider>
                  </Box>
                )}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </PopupState>
  );
};
