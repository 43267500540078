import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import {
  LoadingContainer,
  Paper,
  Table,
  DateTime,
  Link,
  SearchInput,
  Filter,
} from "@components/new";
import { usePagination, useTable } from "@hooks";
import { DateHelper } from "@utils/date";
import { TransactionAPI } from "@api";

import { columns, filters } from "./config";
import { mapper } from "./mappers";
import styles from "./styles.module.scss";

export const ChargingRecord = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [records, setRecords] = useState([]);

  const { fetch, ...pagination } = usePagination(TransactionAPI.getAll);

  useEffect(() => {
    setIsLoading(true);
    fetch()
      .then((data) => setRecords(data.map(mapper)))
      .finally(() => setIsLoading(false));
  }, [pagination.page]);

  const { ref, invoke } = useTable();

  const renderItem = (item, field) => {
    const value = item[field];

    switch (field) {
      case "identifier": {
        const path = `/charger-management/transaction-list/${item.id}`;

        return <Link text={value} path={path} />;
      }
      case "username": {
        const path = `/user-management/driver-list/${item.userId}/details`;

        return <Link text={value} path={path} />;
      }
      case "stationName": {
        const path = `/charger-management/charger-list/${item.stationId}/details`;
        return <Link text={value} path={path} />;
      }
      case "startDate":
      case "endDate": {
        return value ? <DateTime value={value} twoLine /> : "-";
      }
      case "connectionTime": {
        return value ? DateHelper.getDurationStringFromSeconds(value) : "-";
      }
      case "chargingTime": {
        return DateHelper.getDurationString(value);
      }
      default: {
        return value;
      }
    }
  };

  const actions = [({ id }) => ({ label: "Details", onClick: () => navigate(id) })];

  return (
    <LoadingContainer loading={isLoading} className={styles.container}>
      <Box className={styles.header}>
        <Typography className={styles.title}>Charging Record</Typography>
        <Box className={styles.tools}>
          <SearchInput placeholder={"Search"} onChange={invoke("search")} />
          <Filter data={records} config={filters} onChange={invoke("filter")} />
        </Box>
      </Box>
      <Paper>
        <Table
          ref={ref}
          data={records}
          columns={columns}
          actions={actions}
          renderItem={renderItem}
          {...pagination}
        />
      </Paper>
    </LoadingContainer>
  );
};
