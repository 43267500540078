const mapper = (item) => ({
  id: item.id,
  name: item.name,
});

export const config = {
  reducer: (acc, { id }) => {
    return acc.set(id, false);
  },

  columns: [
    {
      label: "Assigned Business(es)",
      field: "name",
    },
  ],

  assignedMapper: mapper,
  definedMapper: mapper,
};
