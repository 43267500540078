import { Radio } from "@mui/material";

import styles from "./styles.module.scss";

export const RadioButton = ({ checked, onChange, ...rest }) => {
  return (
    <Radio
      checked={checked}
      onChange={onChange}
      classes={{ checked: styles.checked, disabled: styles.disabled }}
      {...rest}
    />
  );
};
