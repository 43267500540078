import { useState, useEffect, useMemo, useImperativeHandle, forwardRef } from "react";
import { TableContainer, Table as MUITable } from "@mui/material";
import cx from "classnames";

import { Body } from "./Body";
import { Head } from "./Head";
import { Pagination } from "./Pagination";
import { filter, search } from "./helpers";
import styles from "./styles.module.scss";

const Component = (
  {
    data,
    page,
    pageCount,
    onPageChange,
    columns,
    sortFields,
    searchFields,
    renderItem,
    actions,
    size = "l",
    withPagination = true,
    tableClassName,
  },
  ref,
) => {
  const sortConfig = useMemo(() => {
    if (!sortFields) return {};

    if (sortFields === "all") {
      return columns.reduce((acc, { field }) => ({ ...acc, [field]: true }), {});
    } else {
      return sortFields.reduce((acc, field) => ({ ...acc, [field]: true }), {});
    }
  }, [columns, sortFields]);

  const [tableData, setTableData] = useState(data);

  const [filters, setFilters] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sortColumns, setSortColumns] = useState(sortConfig);

  useEffect(() => {
    const filtered = filter(data, filters);
    const searched = search(filtered, searchValue, searchFields);

    setTableData(searched);
  }, [data, page, filters, searchFields, searchValue]);

  useImperativeHandle(ref, () => ({ filter: setFilters, search: setSearchValue }));

  const sort = (field) => {
    const isAscending = sortColumns[field];
    const sortedData = tableData.sort((a, b) => {
      return (a[field] > b[field] ? 1 : -1) * (isAscending ? 1 : -1);
    });
    setTableData(sortedData);
    setSortColumns((columns) => ({ ...columns, [field]: !isAscending }));
  };

  const tableClasses = cx(styles.table, tableClassName);

  return (
    <TableContainer classes={{ root: styles.container }}>
      <MUITable stickyHeader classes={{ root: tableClasses }}>
        <Head size={size} columns={columns} sortColumns={sortColumns} onSort={sort} />
        <Body
          size={size}
          columns={columns}
          data={tableData}
          renderItem={renderItem}
          actions={actions}
        />
      </MUITable>
      {withPagination && pageCount > 1 && (
        <Pagination count={pageCount} page={page} setPage={onPageChange} />
      )}
    </TableContainer>
  );
};

export const Table = forwardRef(Component);
