import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import cx from "classnames";

import styles from "./styles.module.css";

export const Button = ({ variant = "primary", loading, className, children, ...rest }) => {
  const classes = cx(styles.custom_btn, styles[variant], className);

  return (
    <LoadingButton
      loading={loading}
      className={classes}
      loadingIndicator={<CircularProgress sx={{ color: "white" }} size={20} />}
      {...rest}
    >
      {loading ? null : children}
    </LoadingButton>
  );
};
