import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

import { LoadingContainer, Paper, Table } from "@components/new";
import { usePagination } from "@hooks";
import { FaultAPI } from "@api";

import { columns } from "./config";
import { mapper } from "./mappers";
import styles from "./styles.module.scss";

export const FaultInformation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [faults, setFaults] = useState([]);

  const { fetch, ...pagination } = usePagination(FaultAPI.getAll);

  useEffect(() => {
    setIsLoading(true);
    fetch()
      .then((data) => setFaults(data.map(mapper)))
      .finally(() => setIsLoading(false));
  }, [pagination.page]);

  return (
    <LoadingContainer loading={isLoading} className={styles.container}>
      <Typography className={styles.title}>Fault Information</Typography>
      <Paper>
        <Table data={faults} columns={columns} {...pagination} />
      </Paper>
    </LoadingContainer>
  );
};
