import { EntityList } from "@components/new";
import { DriverAPI, DriverGroupAPI } from "@api";

import { config } from "./config";

export const GroupList = ({ driverId }) => {
  const titles = {
    sectionTitle: "Group",
    plural: "group(s)",
    unassignTitle: "Unassign Group",
    assignTitle: "Assign Group",
    assignModalTitle: "Assign group to user",
  };

  const functions = {
    getAssignedItems: (parentId) => DriverAPI.getDriverGroups(parentId),
    getAvailableItems: () => DriverGroupAPI.getDriverGroups(),
    onAssign: (driverId, toAssign) => DriverGroupAPI.addDriverToGroups([driverId], toAssign),
    onUnassign: (driverId, toDelete) => DriverGroupAPI.removeDriverFromGroups(driverId, toDelete),
  };

  return <EntityList parentId={driverId} config={config} titles={titles} actions={functions} />;
};
