import { number, object } from "yup";

export const refundSchema = (amount) =>
  object().shape({
    amount: number()
      .typeError("Should be a valid number")
      .positive("Refund amount should be greater than 0.00")
      .test("more", `Cannot refund more than ${amount.toFixed(2)}`, (value) => value <= amount)
      .required("Amount is required"),
  });
