import moment from "moment";

export class DateHelper {
  static convertDate(date) {
    return new Date(date * 1e3);
  }

  static formatDateToLocal(date, format = "MM/DD/YYYY, h:mm:ss a") {
    return moment.utc(this.convertDate(date)).local().format(format);
  }

  static format24to12(hour, min) {
    return moment(`${hour}:${min}`, ["HH:mm"]).format("hh:mm a");
  }

  static addTimeToDate(dateStamp, timeStamp) {
    let time;
    if (typeof timeStamp === "number") {
      time = new Date(timeStamp * 1000);
    } else {
      time = new Date(timeStamp);
    }
    const date = new Date(dateStamp);
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    return Math.floor(date.getTime() / 1e3);
  }

  static getDayBeginning(dateStamp) {
    return moment(this.convertDate(dateStamp)).startOf("day").toDate().getTime();
  }

  static getDuration(startDate, endDate) {
    if (!endDate) {
      return null;
    }

    const start = moment(startDate * 1e3);
    const end = moment(endDate * 1e3);

    return moment.duration(end.diff(start));
  }

  static getDurationString(duration) {
    if (!duration) return "-";

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const result = [];
    days && result.push(`${days} day${days !== 1 ? "s" : ""}`);
    hours && result.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
    minutes && result.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
    result.push(`${seconds} second${seconds !== 1 ? "s" : ""}`);

    return result.join(" ");
  }

  static getDurationStringFromSeconds(durationInSeconds) {
    const duration = moment.duration(durationInSeconds, "seconds");
    return DateHelper.getDurationString(duration);
  }

  static isCurrentMoment(date, dateMoment) {
    return moment(this.convertDate(date)).isSame(moment(), dateMoment);
  }

  static isLastMoment(date, dateMoment) {
    const startOfLastMoment = moment().subtract(1, dateMoment).startOf(dateMoment);
    const endOfLastMoment = moment().subtract(1, dateMoment).endOf(dateMoment);

    return moment(this.convertDate(date)).isBetween(startOfLastMoment, endOfLastMoment, null, "[]");
  }

  static addMinutesToDate(date, minutes) {
    return moment(date).add(minutes, "minutes");
  }

  static formatDate(date, format) {
    return moment(date).format(format);
  }
}
