import { DateHelper } from "@utils";

const statusMap = {
  new: "New",
  inreview: "In Review",
  inprogress: "In Progress",
  closed: "Closed",
};

export const mapper = (item) => ({
  id: item.supportReference,
  numericId: item.numericId,
  role: item.role,
  description: item.description,
  station: item.chargeStation?.chargeStationName,
  supportTypeName: item.supportType?.name,
  siteName: item.chargeStation?.siteName,
  supportStatus: item.supportStatus,
  userName: item.user?.userName,
  createdAt: item.creationTime ? DateHelper.formatDateToLocal(item.creationTime) : "-",
  modifiedAt: item.modificationDate ? DateHelper.formatDateToLocal(item.modificationDate) : "-",
});

export const render = (item, key) => {
  const value = item[key];

  switch (key) {
    /*
    case "createdAt":
      return DateHelper.formatDateToLocal(value);
    case "modifiedAt":
      return value ? DateHelper.formatDateToLocal(value) : "-";

    */
    case "supportStatus":
      return statusMap[value] || "-";
    default:
      return value || "-";
  }
};

export const columns = [
  { label: "Support Reference", field: "numericId" },
  { label: "Username", field: "userName" },
  { label: "Roles", field: "role" },
  { label: "Description", field: "description" },
  { label: "Site Name", field: "siteName" },
  { label: "Station Name", field: "station" },
  { label: "Support Status", field: "supportStatus" },
  { label: "Support Type", field: "supportTypeName" },
  { label: "Creation Time", field: "createdAt" },
  { label: "Modified Time", field: "modifiedAt" },
];

export const filters = [
  { name: "Username", field: "userName" },
  { name: "Station Name", field: "station" },
  { name: "Support Status", field: "supportStatus" },
  { name: "Support Type", field: "supportTypeName" },
  { name: "Creation Time", field: "createdAt" },
  { name: "Modified Time", field: "modifiedAt" },
];

export const sortFields = [
  "numericId",
  "userName",
  "role",
  "description",
  "siteName",
  "station",
  "supportStatus",
  "supportTypeName",
  "createdAt",
  "modifiedAt",
];

const searchFields = columns.map(({ field }) => field);

export const config = { searchFields };
