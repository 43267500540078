import { Box, Dialog, DialogContent, Typography } from "@mui/material";

import { Button } from "@components/new";

import styles from "./styles.module.scss";

export const ConfirmationModal = ({
  open,
  text,
  subtext,
  onReject,
  onConfirm,
  confirmButtonTitle = "Yes",
  rejectButtonTitle = "No",
}) => (
  <Dialog open={open}>
    <DialogContent className={styles.content}>
      <Box className={styles.top}>
        <Typography className={styles.text}>{text}</Typography>
        <Typography className={styles.subtext}>{subtext}</Typography>
      </Box>
      <Box className={styles.buttons}>
        <Button
          type={"error"}
          variant={"contained"}
          text={confirmButtonTitle}
          onClick={onConfirm}
        />
        <Button variant={"contained"} text={rejectButtonTitle} onClick={onReject} />
      </Box>
    </DialogContent>
  </Dialog>
);
