import { Box, Typography, Menu } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import ChevronDown from "@assets/icons/chevron-down.svg";
import { Button, Checkbox } from "@components/new";
import { DateRangePicker } from "@components/new/DateRangePicker";

import styles from "./styles.module.scss";

export const Options = ({
  filters,
  anchor,
  onToggle,
  onClear,
  onApply,
  onChoose,
  onClose,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setShowDateFields,
  showDateFields,
}) => {
  const renderFilter = ([field, { name, expanded, options }], i) => {
    const filterOptions = [...options.entries()];

    return (
      <Box key={i}>
        <Box className={styles.filter} onClick={() => onToggle(field)}>
          <Typography className={styles.label}>{name}</Typography>
          <img src={ChevronDown} />
        </Box>
        {expanded && <Box className={styles.options}>{filterOptions.map(renderOption(field))}</Box>}
      </Box>
    );
  };

  const renderOption = (field) => {
    return ([label, checked], i) => {
      if (!label) return null;

      return (
        <Box key={i} className={styles.option} onClick={onOptionPress(field, label)}>
          <Checkbox checked={checked} />
          <Typography>{label}</Typography>
        </Box>
      );
    };
  };

  const onOptionPress = (field, option) => {
    const { options } = filters.get(field);
    const checked = options.get("Custom");
    if (checked) {
      setShowDateFields(true);
    } else {
      setShowDateFields(false);
    }
    return () => onChoose(field, option);
  };

  return (
    <Menu
      anchorEl={anchor}
      open={!!anchor}
      onClose={onClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: 40 }}
      classes={{ paper: styles.paper, list: styles.list }}
    >
      <Typography className={styles.title}>Filters</Typography>
      {[...filters.entries()].map(renderFilter)}
      {showDateFields && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateRangePicker
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}
      <Box className={styles.footer}>
        <Button variant={"outlined"} text={"Clear"} onClick={onClear} />
        <Button variant={"contained"} text={"Apply"} onClick={onApply} />
      </Box>
    </Menu>
  );
};
