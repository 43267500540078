import { useEffect, useState } from "react";
import Iframe from "react-iframe";

import { LoadingContainer } from "@components/new";
import { QuickSightAPI } from "@api";

import styles from "./styles.module.scss";

export const QuickSight = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");

  const init = async () => {
    setIsLoading(true);
    try {
      Promise.all([QuickSightAPI.getUrlById(id)]).then(([url]) => {
        setUrl(url.url);
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        {url && (
          <Iframe
            url={url}
            width="100%"
            height={`${window.innerHeight}px`}
            loading={"lazy"}
            referrerpolicy={"no-referrer-when-downgrade"}
            allowFullScreen
            display="block"
            position="relative"
          />
        )}
      </LoadingContainer>
    </>
  );
};
