import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { useBusiness, useStationDetails } from "@providers";
import { usePagination, useVendorModels } from "@hooks";
import { ChargeStationAPI, TransactionAPI } from "@api";

import { Wrapper } from "../wrapper";

import { Connectors } from "./connectors";
import { GeneralInfo, EditModal, PhotoModal } from "./general";
import { imageMapper, transactionMapper } from "./mappers";
import styles from "./styles.module.scss";
import { Transactions } from "./transactions";

export const InfoTab = ({ onRefresh, refreshFlag }) => {
  const [images, setImages] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [modals, setModals] = useState({ edit: false, photo: false });
  const [isLoading, setIsLoading] = useState({ images: true, transactions: true });

  const { station } = useStationDetails();
  const { options: businesses } = useBusiness();
  const { vendors, models } = useVendorModels();

  const fetchTransactions = (pagination) => {
    return TransactionAPI.getAll({ chargeStationId: station.id, ...pagination });
  };

  const { fetch, ...pagination } = usePagination(fetchTransactions);

  const toggleModals = (type) => {
    return () => setModals((modals) => ({ ...modals, [type]: !modals[type] }));
  };

  useEffect(() => {
    if (!station?.id) return;

    setIsLoading((prev) => ({ ...prev, images: true }));
    ChargeStationAPI.getImages(station.id)
      .then((images) => {
        setImages(images.map(imageMapper));
      })
      .finally(() => {
        setIsLoading((prev) => ({ ...prev, images: false }));
      });
  }, [refreshFlag, station?.id]);

  useEffect(() => {
    if (!station?.id) return;

    setIsLoading((prev) => ({ ...prev, transactions: true }));
    fetch()
      .then((transactions) => {
        setTransactions(transactions.map(transactionMapper));
      })
      .finally(() => {
        setIsLoading((prev) => ({ ...prev, transactions: false }));
      });
  }, [refreshFlag, station?.id, pagination.page]);

  const onImageUpload = (file) => {
    const imageId = images[0]?.id;
    const payload = { file };

    if (imageId) {
      ChargeStationAPI.editImage(station.id, imageId, payload);
    } else {
      ChargeStationAPI.uploadImage(station.id, payload);
    }
  };

  const onImageDelete = (imageId) => {
    const newImages = images.filter(({ id }) => id !== imageId);
    setImages(newImages);
  };

  const isSpinnerVisible = !station?.id || isLoading.images || isLoading.transactions;

  return (
    <Wrapper loading={isSpinnerVisible}>
      <Box className={styles.body}>
        <Box className={styles.row}>
          <GeneralInfo
            station={station}
            images={images}
            onEdit={toggleModals("edit")}
            onImageEdit={toggleModals("photo")}
            onImageUpload={onImageUpload}
          />
          <Connectors />
        </Box>
        <Transactions transactions={transactions} pagination={pagination} />
      </Box>
      <EditModal
        open={modals.edit}
        station={station}
        vendors={vendors}
        models={models}
        businesses={businesses}
        onSave={onRefresh}
        onClose={toggleModals("edit")}
      />
      <PhotoModal
        open={modals.photo}
        onClose={toggleModals("photo")}
        images={images}
        onUpload={onImageUpload}
        onDelete={onImageDelete}
      />
    </Wrapper>
  );
};
