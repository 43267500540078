import { EntityList } from "@components/new";
import { RfidAPI, RfidGroupAPI } from "@api";

import { config } from "./config";

export const RfidList = ({ groupId }) => {
  const titles = {
    sectionTitle: "RFID",
    plural: "RFID(s)",
    unassignTitle: "Unassign RFID(s)",
    assignTitle: "Assign RFID",
    assignModalTitle: "Assign RFID to the group",
  };

  const functions = {
    getAssignedItems: (groupId) => RfidGroupAPI.assignedRfids(groupId),
    getAvailableItems: () => RfidAPI.getAll({ page: 1, size: 4000 }),
    onAssign: (groupId, toAssign) => RfidGroupAPI.assignRfidsToGroup([groupId], toAssign),
    onUnassign: (groupId, toDelete) => RfidGroupAPI.removeFromGroup([groupId], toDelete),
  };

  return <EntityList parentId={groupId} config={config} titles={titles} actions={functions} />;
};
