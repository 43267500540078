import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { ModalContainer, Selector, Switcher } from "@components/new";
import { DriverAPI, TransactionAPI } from "@api";

export const TransactionModal = ({ visible, connectorId, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentUser, setIsCurrentUser] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [driverId, setDriverId] = useState("");

  useEffect(() => {
    DriverAPI.getSimpleList().then((data) => {
      setDrivers(data.map(({ id, name }) => ({ label: name, value: id })));
    });
  }, []);

  const onToggle = () => {
    setIsCurrentUser((isCurrent) => !isCurrent);
  };

  const onSave = async () => {
    setIsLoading(true);
    try {
      if (isCurrentUser) {
        await TransactionAPI.start(undefined, undefined, connectorId);
      } else {
        const { label } = drivers.find(({ value }) => value === driverId);

        if (!label) {
          throw Error("Driver should be assigned");
        }

        await TransactionAPI.start(driverId, label, connectorId);
      }
      toast.success("Session has been successfully started");
      onClose();
    } catch ({ message }) {
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer
      open={visible}
      title={"Select User"}
      onClose={onClose}
      onCancel={onClose}
      onSave={onSave}
      saveTitle={"Start"}
      loading={isLoading}
    >
      <Switcher label={"Current User"} checked={isCurrentUser} onChange={onToggle} />
      <Selector
        disabled={isCurrentUser}
        label={"Select Driver"}
        options={drivers}
        onChange={setDriverId}
      />
    </ModalContainer>
  );
};
