export const reducer = (acc, { id, enabled }) => {
  return acc.set(id, enabled);
};

export const userRfidMapper = (item) => ({
  id: item.id,
  rfid: item.rfid,
  enabled: item.enabled,
  creationDate: item.creationDate ? item.creationDate * 1000 : null,
  activationDate: item.activationDate ? item.activationDate * 1000 : null,
  expirationDate: item.expirationDate ? item.expirationDate * 1000 : null,
  driverName: item.driver?.driverName,
  driverId: item.driver?.driverId,
  isDefault: item.isDefault,
});

export const rfidMapper = (item) => ({
  id: item.id,
  rfid: item.rfid,
  userId: item.user.userId,
  userName: item.user.userName,
  enabled: item.enabled,
  checked: false,
});

export const userMapper = (item) => ({
  value: item.userId,
  label: item.userName,
});

export const columns = [
  {
    label: "Rfid",
    field: "rfid",
  },
  {
    label: "Driver Name",
    field: "driverName",
  },
  {
    label: "Creation Date",
    field: "creationDate",
  },
  {
    label: "Activation Date",
    field: "activationDate",
  },
  {
    label: "Expiration Date",
    field: "expirationDate",
  },
  {
    label: "Enabled",
    field: "enabled",
  },
];

const csvConfig = {
  head: ["Rfid", "Driver Name", "Creation Date", "Activation Date", "Expiration Date", "Enabled"],
  body: (item) => [
    item.rfid,
    item.driver?.driverName,
    item.creationDate,
    item.activationDate,
    item.expirationDate,
    item.enabled,
  ],
};

const searchFields = columns.map(({ field }) => field);

export const sortFields = [
  "rfid",
  "driverName",
  "creationDate",
  "activationDate",
  "expirationDate",
  "enabled",
];

export const config = { csvConfig, searchFields };
