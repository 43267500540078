import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

import { Lock } from "@assets/auth-flow";
import { FormTextInput } from "@components/form/new";
import { Button } from "@components/new";
import { useForm } from "@hooks";
import { resetSchema } from "@utils/validation";
import { AuthAPI } from "@api";

import { fields } from "./config";
import styles from "./styles.module.scss";

export const ResetPassword = () => {
  const navigate = useNavigate();

  const { register, errors, control, isValid, handleSubmit } = useForm({
    validationSchema: resetSchema,
  });

  const [isLoading, setIsLoading] = useState(false);

  const renderField = ({ name, placeholder }, i) => {
    return (
      <FormTextInput
        key={i}
        placeholder={placeholder}
        error={errors[name]}
        control={control}
        {...register(name)}
      />
    );
  };

  const onConfirm = async (data) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await AuthAPI.resetPassword(data);
        toast.success("Password has been successfully changed");
        navigate("/");
      } catch {
        toast.error("Something went wrong. Try again");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Box className={styles.container}>
      <img src={Lock} className={styles.icon} alt="lock" />
      <Box className={styles.title}>Reset Password</Box>
      <Box className={styles.form}>
        {fields.map(renderField)}
        <Button
          type={"primary"}
          text={"Confirm"}
          disabled={!isValid}
          variant={"contained"}
          className={styles.button}
          onClick={handleSubmit(onConfirm)}
        />
      </Box>
    </Box>
  );
};
