import { ListItem, Typography } from "@mui/material";
import cx from "classnames";

import styles from "./styles.module.scss";

export const SubMenuItem = ({ active, title, onClick }) => {
  const classes = cx(styles.container, active && styles.active);

  return (
    <ListItem className={classes} onClick={onClick}>
      <Typography className={styles.title}>{title}</Typography>
    </ListItem>
  );
};
