import { object, string } from "yup";

export const manualSchema = object().shape({
  latitude: string()
    .matches(/^[-]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/, "Invalid latitude")
    .required("Latitude is required"),
  longitude: string()
    .matches(/^[-]?((1[0-7]\d(\.\d+)?)|(180(\.0+)?)|(\d{1,2}(\.\d+)?))$/, "Invalid longitude")
    .required("Longitude is required"),
});

export const autoSchema = object().shape({
  address: string().required("Address is required"),
});

export const addressSchema = {
  auto: autoSchema,
  manual: manualSchema,
};
