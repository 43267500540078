export const config = {
  reducer: (acc, { id }) => {
    return acc.set(id, false);
  },

  columns: [
    {
      label: "RFID",
      field: "name",
    },
    {
      label: "Driver User Name",
      field: "driverUserName",
    },
    {
      label: "Driver First Name",
      field: "driverFirstName",
    },
    {
      label: "Driver Last Name",
      field: "driverLastName",
    },
  ],

  assignedMapper: (item) => ({
    id: item.id,
    name: item.rfid,
    driverUserName: item.driverUsername,
    driverFirstName: item.driverFirstName,
    driverLastName: item.driverLastName,
  }),

  definedMapper: (item) => ({
    id: item.id,
    name: item.rfid,
    driverUserName: item?.driver?.driverName,
    driverFirstName: item?.driver?.driverFirstName,
    driverLastName: item?.driver?.driverLastName,
  }),
};
