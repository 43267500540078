import { api } from "./axios";

export class TransactionAPI {
  static getAll({ page, size, chargeStationId }) {
    return api.get("transactions", { params: { page, size, chargeStationId } });
  }

  static getDetails(id) {
    return api.get(`transactions/${id}`);
  }

  static getRunningByStation(chargeStationId) {
    return api.get(`transactions/running/${chargeStationId}`);
  }

  static getByUser({ userId, page, size }) {
    return api.get("transactions", { params: { userId, page, size } });
  }

  static getByIdTag({ idTag, page, size }) {
    return api.get("transactions", { params: { idTag, page, size } });
  }

  static getByStationConnector(chargeStationId, connectorId) {
    return api.get("transactions", { params: { chargeStationId, connectorId } });
  }

  static start(driverId, driverName, connectorId) {
    return api.post("platform/start-transaction", {
      driverId,
      driverName,
      connectorId,
    });
  }

  static stop(connectorId) {
    return api.post(`platform/stop-transaction/${connectorId}`);
  }
}
