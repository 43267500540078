export const fields = [
  {
    label: "Charge Name",
    value: "name",
  },
  {
    label: "Charge Point ID",
    value: "chargerId",
  },
  {
    label: "Business",
    value: "businessName",
  },
  {
    label: "Site",
    value: "siteName",
  },
  {
    label: "Vendor",
    value: "vendor",
  },
  {
    label: "Model",
    value: "modelName",
  },
  {
    label: "Hardware Status",
    value: "hardwareStatus",
  },
  {
    label: "Installation Date",
    value: "createdAt",
  },
  {
    label: "Last Modified Date",
    value: "lastModified",
  },
  {
    label: "Last Heartbeat Date",
    value: "lastHeartBeat",
  },
  {
    label: "Base Price",
    value: "basePrice",
  },
  {
    label: "Operational Status",
    value: "operationalStatus",
  },
];
