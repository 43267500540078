import { EntityList } from "@components/new";
import { DriverAPI, DriverGroupAPI } from "@api";

import { config } from "./config";

export const DriverList = ({ groupId }) => {
  const titles = {
    sectionTitle: "Driver",
    plural: "driver(s)",
    unassignTitle: "Unassign Driver(s)",
    assignTitle: "Assign Driver",
    assignModalTitle: "Assign drivers to the group",
  };

  const functions = {
    getAssignedItems: (groupId) => DriverGroupAPI.assignedDrivers(groupId),
    getAvailableItems: () => DriverAPI.getDrivers({ page: 1, size: 4000 }),
    onAssign: (groupId, toAssign) => DriverGroupAPI.assignDriversToGroup([groupId], toAssign),
    onUnassign: (groupId, toDelete) => DriverGroupAPI.removeFromGroup(groupId, toDelete),
  };

  return <EntityList parentId={groupId} config={config} titles={titles} actions={functions} />;
};
