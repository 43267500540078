import { EntityList } from "@components/new";
import { useAuth } from "@providers";
import { BusinessAPI } from "@api";

import { config } from "./config";

export const BusinessList = ({ userId }) => {
  const titles = {
    sectionTitle: "Business",
    plural: "business(es)",
    unassignTitle: "Unassign Business",
    assignTitle: "Assign Business",
    assignModalTitle: "Assign business to user",
  };

  const functions = {
    getAssignedItems: (userId) => BusinessAPI.getByUserId(userId),
    getAvailableItems: () => BusinessAPI.getForCurrentUser(),
    onAssign: (userId, toAssign) => BusinessAPI.assignBusinesses(userId, toAssign),
    onUnassign: (userId, toDelete) => BusinessAPI.deleteBusinesses(userId, toDelete),
  };

  const { user, isAdmin } = useAuth();

  return (
    <EntityList
      parentId={userId}
      config={config}
      titles={titles}
      actions={functions}
      disabled={!isAdmin || userId === user?.userId}
    />
  );
};
