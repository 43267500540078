import { useState } from "react";
import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";

import styles from "./styles.module.scss";

export const ActionMenu = ({ actions }) => {
  const [anchor, setAnchor] = useState(null);

  const handleOpen = ({ currentTarget }) => {
    setAnchor(currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const renderItem = ({ label, onClick, disabled }, i) => {
    const handleClick = () => {
      onClick();
      setAnchor(null);
    };

    return (
      <MenuItem key={i} onClick={handleClick} className={styles.item} disabled={disabled}>
        <Typography className={styles.text}>{label}</Typography>
      </MenuItem>
    );
  };

  return (
    <>
      <IconButton classes={{ root: styles.root }} onClick={handleOpen}>
        <MoreVert />
      </IconButton>
      <Menu
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        classes={{ paper: styles.menu, list: styles.list }}
      >
        {actions.map(renderItem)}
      </Menu>
    </>
  );
};
