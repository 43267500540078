import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

import { CustomAddress } from "@components/common";
import { FormSelector, FormTextInput } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useAddressForm } from "@hooks";
import { siteSchema } from "@utils/validation";
import { ChargeSiteAPI } from "@api";

const defaults = {
  siteName: "",
  address: "",
  businessId: "",
  cardCode: "",
};

export const FormModal = ({ open, type, businesses, onClose, site, save }) => {
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = site || defaults;

  const data = useAddressForm({
    open,
    defaultValues,
    defaultAddress: defaultValues.addressDetails,
    validationSchema: siteSchema,
  });

  const { register, control, errors, address, handleSubmit } = data;

  const onSave = async ({ siteName, businessId, cardCode }) => {
    setIsLoading(true);

    const payload = { siteName, businessId, address, cardCode };

    try {
      if (type === "Add") await ChargeSiteAPI.create(payload);
      else await ChargeSiteAPI.update(site.id, payload);
      toast.success("Success alert: Successfully saved.");
      onClose();
      save();
    } catch {
      toast.error("Error alert: Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...data}>
      <ModalContainer
        open={open}
        title={`${type} Site`}
        onClose={onClose}
        loading={isLoading}
        onCancel={onClose}
        onSave={handleSubmit(onSave)}
      >
        <FormTextInput
          label={"Site Name"}
          control={control}
          error={errors.siteName}
          {...register("siteName")}
        />
        <CustomAddress />
        <FormTextInput
          label={"Card Code"}
          control={control}
          error={errors.cardCode}
          {...register("cardCode")}
        />
        <FormSelector
          label={"Business"}
          control={control}
          options={businesses}
          error={errors.businessId}
          {...register("businessId")}
        />
      </ModalContainer>
    </FormProvider>
  );
};
