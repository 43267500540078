import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { useBusiness } from "@providers";
import { ChargeSiteAPI } from "@api";

import { FormModal } from "../../FormModal";
import { mapper } from "../mapper";
import { Wrapper } from "../wrapper";

import { DeferPayment } from "./defer-payment";
import { GeneralInfo } from "./general";
import { PrivateNetwork } from "./private-network";
import styles from "./styles.module.scss";

export const InfoTab = ({ siteId, save }) => {
  const [site, setSite] = useState();
  const [modals, setModals] = useState({ edit: false });
  const [isLoading, setIsLoading] = useState(true);

  const { options: businesses } = useBusiness();

  const init = async () => {
    const site = await ChargeSiteAPI.getById(siteId);
    setSite(mapper(site));
  };

  const toggleModals = (type, mode) => {
    return () => {
      if (mode === "refresh") refresh();
      setModals((modals) => ({ ...modals, [type]: !modals[type] }));
    };
  };

  useEffect(() => {
    setIsLoading(true);
    init().finally(() => setIsLoading(false));
  }, [siteId]);

  const refresh = () => {
    init();
    save();
  };

  const onSave = async (data) => {
    if (data?.id) {
      await ChargeSiteAPI.update(data?.id, data);
    }
    refresh();
  };

  return (
    <Wrapper loading={isLoading}>
      <Box className={styles.body}>
        <GeneralInfo site={site} onEdit={toggleModals("edit", "refresh")} />
        <Box className={styles.row}>
          <PrivateNetwork siteid={site} />
          <DeferPayment siteid={site} />
        </Box>
      </Box>
      <FormModal
        type={"Edit"}
        open={modals.edit}
        site={site}
        save={onSave}
        businesses={businesses}
        onClose={toggleModals("edit")}
      />
    </Wrapper>
  );
};
