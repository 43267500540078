export const checkAll = (data, checked) => {
  return data.map((item) => ({ ...item, checked }));
};

export const checkItem = (data, checked, filter) => {
  return data.map((item) => (filter(item) ? { ...item, checked } : item));
};

export const getChecked = (data) => {
  return data.filter(({ checked }) => checked);
};

export const isAllChecked = (data) => {
  return data.every(({ checked }) => checked);
};

export const isAnyChecked = (data) => {
  return data.some(({ checked }) => checked);
};
