import { Box, RadioGroup as MUIRadioGroup, FormControlLabel } from "@mui/material";
import cx from "classnames";

import { FormLabel, RadioButton } from "@components/new";

import styles from "./styles.module.scss";

export const RadioGroup = ({ label, value, onChange, disabled, options, className }) => {
  const control = <RadioButton className={styles.button} />;

  const renderOption = ({ label, value }, i) => {
    return (
      <FormControlLabel
        key={i}
        value={value}
        label={label}
        control={control}
        disabled={disabled}
        classes={{ root: styles.option, label: styles.label }}
      />
    );
  };

  const classes = cx(styles.container, className);

  return (
    <Box className={classes}>
      {label && <FormLabel text={label} />}
      <MUIRadioGroup value={value} onChange={onChange} className={styles.group}>
        {options.map(renderOption)}
      </MUIRadioGroup>
    </Box>
  );
};
