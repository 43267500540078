import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { DetailPageHeader } from "@components/new";
import { UserAPI } from "@api";

import { InfoTab } from "./InfoTab";
import { mapper } from "./mapper";
import styles from "./styles.module.scss";

export const PlatformUserDetails = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [user, setUser] = useState();

  useEffect(() => {
    UserAPI.getById(userId).then((user) => {
      setUser(mapper(user));
    });
  }, [userId]);
  const onGoBack = () => {
    navigate(-1);
  };

  const goBackText = "Back to Platform User List";

  return (
    <Box className={styles.container}>
      <DetailPageHeader title={user?.userName} goBackText={goBackText} onGoBack={onGoBack} />
      <InfoTab user={user} />
    </Box>
  );
};
