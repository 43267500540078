import { CustomControlAction } from "@components/common";

import classes from "./styles.module.css";

export const Menu = ({ items }) => {
  const data = items.map(({ icon, ...rest }) => ({
    icon: icon({ className: classes.icon }),
    ...rest,
  }));

  return <CustomControlAction data={data} />;
};
