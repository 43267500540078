import { Box, IconButton } from "@mui/material";

import { ReactComponent as CrossIcon } from "@assets/icons/cross.svg";
import { FormSelector, FormTextInput } from "@components/form/new";

import styles from "./styles.module.scss";

export const Connector = ({ name, error, register, control, types, onRemove }) => {
  return (
    <Box className={styles.container}>
      <FormTextInput
        type={"number"}
        control={control}
        label={"Connector ID"}
        error={error?.connectorNumber}
        {...register(`${name}.connectorNumber`)}
      />
      <FormSelector
        options={types}
        control={control}
        label={"Connector Type"}
        error={error?.connectorType}
        {...register(`${name}.connectorType`)}
      />
      <FormTextInput
        type={"number"}
        control={control}
        label={"Nominal Power kW"}
        error={error?.powerRating}
        {...register(`${name}.powerRating`)}
      />
      {onRemove && (
        <IconButton onClick={onRemove}>
          <CrossIcon />
        </IconButton>
      )}
    </Box>
  );
};
