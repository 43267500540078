export const columns = [
  { label: "Charger ID", field: "chargerId" },
  { label: "Station Name", field: "station" },
  { label: "Site Name", field: "site" },
  { label: "Vendor", field: "vendor" },
  { label: "Model", field: "model" },
  { label: "Current Firmware Version", field: "version" },
  { label: "Avilable Firmware Updates", field: "firmwares" },
];

const filterConfig = [];

const csvConfig = {
  head: ["Charger Id", "Station Name", "Site Name", "Vendor", "Model", "Current Version"],
  body: (item) => [item.chargerId, item.station, item.site, item.vendor, item.model, item.version],
};

const searchFields = columns.map(({ field }) => field);

export const config = { filterConfig, csvConfig, searchFields };
