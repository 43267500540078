import { toast } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import cx from "classnames";

import { RfidAPI } from "@api";

import styles from "./styles.module.scss";

export const FileUpload = ({ setIsLoading }) => {
  const classes = cx(styles.button);

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    try {
      await RfidAPI.uploadCsv(formData);
    } catch (error) {
      toast.error("Some thing went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileSelect = async (event) => {
    await handleSubmit(event);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <Button
        component="label"
        color={"success"}
        variant={"contained"}
        classes={{
          root: classes,
          outlined: styles.outlined,
          outlinedSuccess: styles.outlined_success,
          containedSuccess: styles.contained_success,
          outlinedPrimary: styles.outlined_primary,
          containedPrimary: styles.contained_primary,
          outlinedWarning: styles.outlined_warning,
          containedWarning: styles.contained_warning,
          outlinedError: styles.outlined_error,
          containedError: styles.contained_error,
        }}
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput type="file" onChange={handleFileSelect} />
      </Button>
    </>
  );
};
