import * as Yup from "yup";

export const stationSchema = Yup.object().shape({
  chargerId: Yup.string().required("Charger id is required"),
  name: Yup.string().required("Station name is required"),
  siteId: Yup.string().required("Site name is required"),
  chargingRate: Yup.number()
    .integer("Charging rate should be an integer number")
    .typeError("Charging rate should be a number")
    .required("Charging rate is required"),
  basePrice: Yup.number()
    .typeError("Base price should be a number")
    .required("Charging rate is required"),
  description: Yup.string().required("Description is required"),
  supportEmail: Yup.string().required("Support email is required"),
  connectorTypes: Yup.array()
    .of(Yup.string().required("Connector type is required"))
    .min(1, "Connector types are required")
    .max(2, "Only 2 connector types allowed"),
  chargerStationModelId: Yup.string().required("Charge station model is required"),
});
