import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";

import { Button, LoadingContainer, Paper, Selector, Table } from "@components/new";
import { usePagination } from "@hooks";
import { DateHelper } from "@utils";
import { ChargeStationAPI, FirmwareAPI } from "@api";

import { columns } from "./config";
import { mapper } from "./mappers";
import styles from "./styles.module.scss";

export const CsFirmware = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [firmwareUpdate, setFirmwareUpdate] = useState({});
  const [firmwareUpdatedStation, setFirmwareUpdatedStation] = useState("");

  const { fetch, ...pagination } = usePagination(ChargeStationAPI.get);

  useEffect(() => {
    setIsLoading(true);
    fetch()
      .then((data) => setData(data.map(mapper)))
      .finally(() => setIsLoading(false));
  }, [pagination.page]);

  const updateFirmware = async () => {
    try {
      const date = DateHelper.addMinutesToDate(new Date(), 10);

      if (!firmwareUpdate || !firmwareUpdatedStation) {
        toast.error("Please select one station");
        return false;
      }

      const data = {
        ...JSON.parse(firmwareUpdate[firmwareUpdatedStation]),
        retrieve: DateHelper.formatDate(date, "YYYY-MM-DDTHH:mm:ss:SSSZ"),
      };

      await FirmwareAPI.applyUpdate(data);

      toast.success("Request send to update firmware sucessfully");
    } catch {
      toast.error("An error occured while updating firmware");
    }
  };

  const updateFirmwareUpdate = (id, val) => {
    setFirmwareUpdate({ [id]: val });
    setFirmwareUpdatedStation(id);
  };

  const renderItem = (item, key) => {
    const value = item[key];

    if (key !== "firmwares") return value;

    if (!value?.length) return "-";

    const options = value.map(({ version, location }) => {
      return {
        label: version,
        value: JSON.stringify({ chargeStationId: item.id, location }),
      };
    });

    return (
      <Selector
        id={item.id}
        options={options}
        value={firmwareUpdate[item.id] ?? ""}
        label={"Please select firmware version"}
        onChange={(val) => updateFirmwareUpdate(item.id, val)}
      />
    );
  };

  return (
    <LoadingContainer loading={isLoading} className={styles.container}>
      <Box className={styles.header}>
        <Typography className={styles.title}>Charge Station Firmwares</Typography>
        <Button variant={"contained"} text={"Update Firmware"} onClick={updateFirmware} />
      </Box>
      <Paper>
        <Table data={data} columns={columns} renderItem={renderItem} {...pagination} />
      </Paper>
    </LoadingContainer>
  );
};
