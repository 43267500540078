import { useState, useRef } from "react";
import { Box, IconButton } from "@mui/material";
import cx from "classnames";
import { BiPencil } from "react-icons/bi";

import { ImageInput } from "@components/new";

import styles from "./styles.module.scss";

export const Image = ({ images, onUpload }) => {
  const initialImage = images[0]?.url ?? null;

  const [image, setImage] = useState(initialImage);

  const ref = useRef(null);

  const onChange = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const [_, image] = reader.result.split(",");
      setImage(`data:image/png;base64, ${image}`);
    };
    reader.readAsDataURL(file);
    onUpload(file);
  };

  const renderOverlay = () => {
    if (!image) return null;

    return (
      <Box className={styles.overlay}>
        <IconButton onClick={() => ref.current?.openFileWindow()}>
          <Box className={styles.button}>
            <BiPencil className={styles.icon} />
          </Box>
        </IconButton>
      </Box>
    );
  };

  const classes = cx(styles.image, image && styles.transition);

  return (
    <Box className={classes}>
      <ImageInput ref={ref} image={image} onChange={onChange} className={styles.input} />
      {renderOverlay()}
    </Box>
  );
};
