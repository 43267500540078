import { api } from "./axios";

export class ConnectorAPI {
  static getAll() {
    return api.get("connector/types");
  }

  static create(payload) {
    return api.post("connector/types", payload);
  }

  static update(id, payload) {
    return api.post(`connector/types/${id}`, payload);
  }

  static updateStatus(id, operationalStatus) {
    return api.patch(`connector/${id}`, { operationalStatus });
  }

  static deleteMultiple(ids) {
    return api.delete("connector/types", { params: { ids } });
  }
}
