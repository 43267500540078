import { api } from "./axios";

export class UserAPI {
  static getUsers({ page, size }) {
    return api.get("users", { params: { page, size } });
  }

  static getUsersByBusiness(businessId) {
    return api.get(`users/business/${businessId}`);
  }

  static getUsersSimpleList() {
    return api.get("users/simple-list");
  }

  static getById(id) {
    return api.get(`users/${id}`);
  }

  static getDetails() {
    return api.get("users/current/details");
  }

  static toggleStatus(id, status) {
    return api.patch(`users/${status ? "" : "de"}activate/${id}`);
  }

  static createAdmin(payload) {
    return api.post("users/admincreate", payload);
  }

  static updateAdmin(id, payload) {
    return api.patch(`users/${id}`, payload);
  }

  static deleteMultiple(ids) {
    return api.delete("users", { params: { ids } });
  }
}
