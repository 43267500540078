import { Box, CircularProgress } from "@mui/material";
import cx from "classnames";

import styles from "./styles.module.scss";

export const LoadingContainer = ({ loading, children, className }) => {
  const classes = cx(styles.container, className, loading && styles.loading);

  return (
    <Box className={classes}>{loading ? <CircularProgress color={"success"} /> : children}</Box>
  );
};
