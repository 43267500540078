import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import styles from "./styles.module.css";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    maxHeight: "70vh",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#989898",
      borderRadius: "20px",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const ModalContainer = ({
  open,
  title,
  onSave,
  onClose,
  children,
  contentLoader,
  saveDisabled,
  loading,
  maxWidth,
}) => {
  return (
    <CustomDialog onClose={onClose} open={open} fullWidth maxWidth={maxWidth}>
      <Box className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>{title}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <DialogContent className={styles.body}>
          {contentLoader ? (
            <Box className={styles.loader}>
              <CircularProgress />
            </Box>
          ) : (
            children
          )}
        </DialogContent>
        <Divider />
        {onSave && (
          <DialogActions className={styles.footer}>
            <LoadingButton
              disabled={saveDisabled}
              loading={loading}
              variant="outline"
              className={styles.save_button}
              onClick={onSave}
              loadingIndicator={<CircularProgress size={20} sx={{ color: "white" }} />}
            >
              Save
            </LoadingButton>
          </DialogActions>
        )}
      </Box>
    </CustomDialog>
  );
};
