import { useEffect, useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

import { search, filter, debounce, setFiltersOptions } from "@utils";

export const useTableData = (
  data,
  rowsPerPage,
  { filterConfig, csvConfig, searchFields },
  readOnly = false,
) => {
  const [filters, setFilters] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [tableData, setTableData] = useState(data);
  const [count, setCount] = useState(0);
  const [csv, setCsv] = useState([]);
  const [sorting, setSorting] = useState(
    searchFields.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
  );

  useEffect(() => {
    const newData = readOnly ? data : search(filter(data, filters), searchFields, searchValue);
    setTableData(newData);
    setCount(Math.ceil(newData.length / rowsPerPage));
    csvConfig && setCsv([csvConfig.head, ...newData.map(csvConfig.body)]);
  }, [data, filters, searchValue, readOnly]);

  useEffect(() => {
    !readOnly && filterConfig && setFilters(setFiltersOptions(data, filterConfig));
  }, [data, readOnly]);

  const handleSearch = debounce(({ target: { value } }) => setSearchValue(value));

  const sort = (field) => {
    const isAsc = sorting[field];
    const sortedData = tableData.sort((a, b) => {
      return (a[field] > b[field] ? 1 : -1) * (isAsc ? 1 : -1);
    });
    setTableData(sortedData);
    setSorting({ ...sorting, [field]: !isAsc });
  };

  const getOrderIcon = (field) => {
    return sorting[field] ? <FaCaretDown color="#8E8EA9" /> : <FaCaretUp color="#8E8EA9" />;
  };

  const resetFilters = () => {
    setFilters([]);
    setSearchValue("");
  };

  return {
    tableData,
    csv,
    count,
    filters,
    sort,
    getOrderIcon,
    setFilters,
    resetFilters,
    handleSearch,
  };
};
