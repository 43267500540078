import { useState } from "react";

export const useConfirmation = (actions, texts) => {
  const [[type, item, callback], setModal] = useState([]);

  const open = (type, item, callback) => {
    setModal([type, item, callback]);
  };

  const close = () => {
    setModal([]);
  };

  const text = texts[type]?.call(null, item);

  const onConfirm = async () => {
    await Promise.resolve(actions[type]?.call(null, item));
    close();
    callback?.call(null, item);
  };

  return { isVisible: !!type, text, open, onConfirm, onReject: close };
};
