import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import { Box, InputLabel, Divider } from "@mui/material";

import { Paper, Button } from "@components/new";
import { useAuth } from "@providers";
import { UserAPI } from "@api";

import { UserForm } from "../UserForm";

import { info } from "./config";
import styles from "./styles.module.scss";

export const UserInformation = () => {
  const navigate = useNavigate();

  const { user, logout, getUser } = useAuth();

  const [open, setOpen] = useState(false);

  const onSave = async (data) => {
    try {
      await UserAPI.updateAdmin(user.userId, data);
      await getUser();
      toast.success("Success alert: Successfully saved");
      setOpen(false);
    } catch {
      toast.error("Error alert: Something went wrong");
    }
  };

  const toggleModal = () => {
    setOpen((open) => !open);
  };

  const showNotifications = () => {
    navigate("notification-management");
  };

  return (
    <>
      <Box className={styles.container}>
        <Box className={styles.content}>
          <Paper>
            <Box className={styles.header}>
              <Box className={styles.header_part}>
                <AccountCircleIcon />
                User Information
              </Box>
              <Box className={styles.header_part}>
                <Button text={"Edit"} variant={"contained"} onClick={toggleModal} />
                <Button text={"Logout"} variant={"contained"} type={"error"} onClick={logout} />
              </Box>
            </Box>
            <Box className={styles.body}>
              <Box>
                {info.map(({ label, name }, i) => (
                  <Fragment key={i}>
                    <Box className={styles.row}>
                      <InputLabel className={styles.label}>{label}</InputLabel>
                      <InputLabel className={styles.data}>{user?.[name]}</InputLabel>
                    </Box>
                    <Divider />
                  </Fragment>
                ))}
                <Button
                  text={" Notification Management"}
                  variant={"contained"}
                  className={styles.notification_btn}
                  onClick={showNotifications}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
      <UserForm open={open} type={"Edit"} user={user} onClose={toggleModal} onSave={onSave} />
    </>
  );
};
