import { useEffect, useState } from "react";

import { ChargeStationPricingPage } from "@pages/PricingManagement/ChargeStationPricing";
import { useStationDetails } from "@providers";
import { PricingAPI } from "@api";

import { Wrapper } from "../wrapper";

export const PricingTab = () => {
  const { station } = useStationDetails();

  const [isLoading, setIsLoading] = useState(!station?.id || true);

  useEffect(() => {
    station?.id && init();
  }, [station?.id]);

  const init = async () => {
    setIsLoading(true);
    try {
      await PricingAPI.getByChargeStation(station.id);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper loading={isLoading}>
      <ChargeStationPricingPage />
      {/* <PricingComponent
        onSave={init}
        type={"Station"}
        pricing={pricing}
        nameKey={"chargeStationName"}
        editCallback={PricingAPI.createForChargeStation}
      /> */}
    </Wrapper>
  );
};
