import { api } from "./axios";

export class RoleAPI {
  static getAll() {
    return api.get("userroles");
  }

  static getDefined() {
    return api.get("userroles/defined");
  }

  static getRolesForUser(userId) {
    return api.get(`userroles/${userId}`);
  }

  static assignRoles(userId, payload) {
    return api.post(`userroles/${userId}`, { list: payload });
  }

  static create(payload) {
    return api.post("userroles", payload);
  }

  static deleteRolesForUser(userId, roles) {
    return api.delete(`userroles/${userId}`, { params: { roles } });
  }

  static deleteMultiple(ids) {
    return api.delete("userroles", { params: { ids } });
  }
}
