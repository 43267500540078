import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";

import { LoadingContainer, Button, Paper, Table, Switcher } from "@components/new";
import { useAuth } from "@providers";
import { FaultAPI } from "@api";

import { FormModal } from "./FormModal";
import { columns } from "./config";
import styles from "./styles.module.scss";

export const NotificationManagement = () => {
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [faults, setFaults] = useState([]);
  const [userFaults, setUserFaults] = useState({});

  const [modal, setModal] = useState({ open: false, title: "", fault: { name: "" } });
  const toggleModal = (title, fault) => {
    setModal({ open: !modal.open, title, fault: fault || { name: "" } });
  };

  const init = async () => {
    if (!user?.userId) return;

    setIsLoading(true);

    try {
      const [allFaults, { activeGenericFaults, inActiveGenericFaults }] = await Promise.all([
        FaultAPI.getGeneric(),
        FaultAPI.getGenericByUser(user.userId),
      ]);

      const active = new Map(activeGenericFaults.map(({ genericFaultId }) => [genericFaultId]));
      const inActive = new Map(inActiveGenericFaults.map(({ genericFaultId }) => [genericFaultId]));
      setUserFaults({ active, inActive });

      const faults = allFaults.map(({ genericFaultId, faultName }) => ({
        faultName,
        genericFaultId,
        enabled: active.has(genericFaultId),
      }));
      setFaults(faults);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [user]);

  const renderItem = (item, field) => {
    const value = item[field];

    if (field === "enabled") {
      return <Switcher checked={value} onChange={onSubscribe(item.id)} />;
    }

    return value;
  };

  const onSubscribe = (id) => {
    return async ({ target: { checked: enabled } }) => {
      const { active, inActive } = userFaults;
      if (enabled) {
        active.set(id);
        inActive.delete(id);
      } else {
        inActive.set(id);
        active.delete(id);
      }
      setUserFaults({ active, inActive });
      const payload = {
        activeUserFaultIds: [...active.keys()],
        inActiveUserFaultIds: [...inActive.keys()],
      };
      try {
        const oldFault = faults.get(id);
        setFaults(faults.set(id, { ...oldFault, enabled }));
        await FaultAPI.updateUserGeneric(payload);
        toast.success("Successfully saved!");
      } catch {
        toast.error("Something went wrong!");
      }
    };
  };

  const actions = [
    (item) => ({ label: "Edit", onClick: () => toggleModal("Edit Notiffication", item) }),
  ];

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>Notification Management</Typography>
          <Button
            text={"Add Notification"}
            variant={"contained"}
            onClick={() => toggleModal("Add Notification")}
          />
        </Box>
        <Paper>
          <Table columns={columns} data={faults} renderItem={renderItem} actions={actions} />
        </Paper>
      </LoadingContainer>
      <FormModal {...modal} onClose={() => toggleModal()} onSave={init} />
    </>
  );
};
