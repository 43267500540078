import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

import { DetailPageHeader } from "@components/new";
import { RfidAPI } from "@api";

import { GroupList } from "./GroupList";
import { GeneralInfo } from "./general";
import { mapper } from "./mapper";
import { EditModal } from "./modals";
import styles from "./styles.module.scss";
import { Transactions } from "./transactions";
import { Wrapper } from "./wrapper";

export const UserRfidDetails = () => {
  const navigate = useNavigate();
  const { rfId } = useParams();
  const [rfid, setRfid] = useState();
  const [modals, setModals] = useState({ edit: false });
  const [isLoading, setIsLoading] = useState(true);

  const getRfid = () => {
    RfidAPI.getById(rfId).then((rfid) => setRfid(mapper(rfid)));
  };

  const toggleModals = (type) => {
    return () => setModals((modals) => ({ ...modals, [type]: !modals[type] }));
  };

  useEffect(() => {
    setIsLoading(true);
    RfidAPI.getById(rfId)
      .then((rfid) => {
        setRfid(mapper(rfid));
      })
      .finally(() => setIsLoading(false));
  }, [rfId]);

  const onGoBack = () => {
    navigate(-1);
  };

  const refresh = () => {
    getRfid();
  };

  const onSave = async (rest) => {
    try {
      if (rest?.id) {
        await RfidAPI.updateRfid(rest?.id, rest);
        toast.success("Rfid has been successfully updated");
      }
      refresh();
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const goBackText = "Back to User RFID List";

  return (
    <Wrapper loading={isLoading}>
      <Box className={styles.container}>
        <DetailPageHeader title={rfid?.userName} goBackText={goBackText} onGoBack={onGoBack} />
        <Box className={styles.body}>
          <Box className={styles.row}>
            <GeneralInfo rfid={rfid} onEdit={toggleModals("edit")} />
            <GroupList rfidId={rfid?.id} />
          </Box>
          <Transactions rfid={rfid?.rfid} />
        </Box>
      </Box>
      <EditModal open={modals.edit} rfid={rfid} onSave={onSave} onClose={toggleModals("edit")} />
    </Wrapper>
  );
};
