import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, FormHelperText, InputLabel, TextField, Typography } from "@mui/material";

import { Background, Logo } from "@assets/auth-flow";
import { Button } from "@components/new";
import { useForm } from "@hooks";
import { authSchema } from "@utils/validation";
import { AuthAPI } from "@api";

import { fields } from "./config";
import styles from "./styles.module.scss";

export const LoginPage = () => {
  const navigate = useNavigate();

  const { register, handleSubmit, isValid, errors } = useForm({ validationSchema: authSchema });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const onKeyPress = ({ key }) => {
      !isLoading && key === "Enter" && handleSubmit(onSubmit)();
    };

    document.addEventListener("keypress", onKeyPress);

    return () => {
      document.removeEventListener("keypress", onKeyPress);
    };
  }, []);

  const renderField = ({ label, name, type, url }, i) => {
    const onForgotPress = () => {
      navigate(`/forgot-${url}`);
    };

    return (
      <Box key={i} className={styles.field}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <TextField
          id={name}
          type={type}
          className={styles.input}
          error={!!errors[name]}
          {...register(name)}
        />
        <Box className={styles.helper}>
          {!!errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
          <Typography onClick={onForgotPress} className={styles.forgot_link}>
            Forgot {label}
          </Typography>
        </Box>
      </Box>
    );
  };

  const onSubmit = async (formData) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await AuthAPI.login(formData);
        navigate("/home");
      } catch {
        toast.error("Invalid credentials");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <img height={100} src={Logo} alt="logo" />
        <Box>
          <Typography className={styles.title}>Smart EV Charger</Typography>
          <Typography className={styles.title}>Management System</Typography>
        </Box>
      </Box>
      <Box className={styles.body}>
        <img className={styles.image} src={Background} />
        <Box className={styles.right_part}>
          <Box className={styles.form}>{fields.map(renderField)}</Box>
          <Button
            type={"warning"}
            text={"Sign In"}
            disabled={!isValid}
            loading={isLoading}
            variant={"contained"}
            className={styles.button}
            onClick={handleSubmit(onSubmit)}
          />
        </Box>
      </Box>
    </Box>
  );
};
