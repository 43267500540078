export const mapper = (item) => ({
  id: item.userId,
  userName: item.userName,
  firstName: item.firstName,
  lastName: item.lastName,
  phoneNumber: item.phoneNumber,
  email: item.email,
  status: item.activeUser,
  enabled: item.enabled,
  createdAt: item.createdAt,
  updatedAt: item.updatedAt,
  lastLoginAt: item.lastLoginAt,
  roles: item.roles,
  businesses: item.businesses,
});
