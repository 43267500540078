export const loadManagementMapper = (item) => ({
  id: item.id,
  status: item.status,
  type: item.type,
  totalPowerLimit: item.totalPowerLimit,
  chargeSiteId: item.chargeSiteId,
  distributions: item.distributions.reduce(distributionReducer, new Map()),
});

const distributionReducer = (acc, { id, chargeStationId, powerLimit }) => {
  return acc.set(chargeStationId, { id, powerLimit });
};

export const chargerMapper = (item) => ({
  id: item.id,
  chargerId: item.chargeStationId,
  name: item.stationName,
  hardwareStatus: item.hardwareStatus,
  type: item.chargeStationModel.deviceType,
  connectorsAmount: item.connectors.length,
});
