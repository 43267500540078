import { Box, Typography, Dialog, IconButton } from "@mui/material";

import CrossIcon from "@assets/icons/cross.svg";

import { Button } from "../Button";

import styles from "./styles.module.scss";

export const ModalContainer = ({
  open,
  title,
  maxWidth,
  loading,
  onCancel,
  onSave,
  onClose,
  saveDisabled,
  children,
  withButtons = true,
  cancelTitle = "Cancel",
  saveTitle = "Save",
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth}>
      <Box className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>{title}</Typography>
          <IconButton onClick={onClose}>
            <img src={CrossIcon} />
          </IconButton>
        </Box>
        <Box className={styles.body}>{children}</Box>
        {withButtons && (
          <Box className={styles.footer}>
            <Button variant={"outlined"} text={cancelTitle} onClick={onCancel} />
            <Button
              disabled={saveDisabled}
              variant={"contained"}
              text={saveTitle}
              onClick={onSave}
              loading={loading}
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
