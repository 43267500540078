import { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

import { FormCheckbox, FormDatePicker, FormTextInput } from "@components/form/new";
import { AssignModal } from "@components/modals";
import { Button, ModalContainer } from "@components/new";
import { useForm, usePagination } from "@hooks";
import { DriverAPI } from "@api";

import { columns } from "./config";
import { mapper } from "./mappers";
import styles from "./styles.module.scss";
import { validationSchema } from "./validation";

const initialValues = {
  rfid: "",
  driverName: "",
  driverId: "",
  activationDate: null,
  expirationDate: null,
  enabled: false,
};

export const UserRfidModal = ({ open, rfidDetails, onSave, onClose }) => {
  const defaultValues = useMemo(() => {
    if (!rfidDetails) return initialValues;

    const { activationDate, expirationDate, ...rest } = rfidDetails;

    return {
      ...rest,
      activationDate: new Date(activationDate),
      expirationDate: expirationDate ? new Date(expirationDate) : null,
    };
  }, [rfidDetails]);

  const [isLoading, setIsLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [isAssignModalVisible, setAssignIsModalVisible] = useState(false);

  const title = rfidDetails ? "Edit User RFID" : "Register User RFID";

  const { register, control, isValid, trigger, setValue, errors, handleSubmit } = useForm({
    open,
    defaultValues,
    validationSchema,
  });

  const { fetch, ...pagination } = usePagination(DriverAPI.getDrivers);

  useEffect(() => {
    fetch().then((data) => setDrivers(data.map(mapper)));
  }, [pagination.page]);

  const toggleAssignModal = () => {
    setAssignIsModalVisible((isVisible) => !isVisible);
  };

  const handleSave = async ({ rfid, driverId, activationDate, expirationDate, enabled }) => {
    setIsLoading(true);
    try {
      const id = rfidDetails?.id || undefined;

      const payload = {
        rfid,
        enabled,
        driverId,
        activationDate: activationDate ? Math.round(activationDate?.getTime() / 1e3) : null,
        expirationDate: expirationDate ? Math.round(expirationDate?.getTime() / 1e3) : null,
      };

      await onSave(payload, id);
      onClose();
    } catch ({ response }) {
      toast.error(response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const assignItem = ([driverId]) => {
    const { name } = drivers.find(({ id }) => id === driverId);
    setValue("driverId", driverId, { shouldValidate: true });
    setValue("driverName", name);
    toggleAssignModal();
  };

  return (
    <>
      <ModalContainer
        open={open}
        title={title}
        loading={isLoading}
        onClose={onClose}
        onCancel={onClose}
        saveDisabled={!isValid}
        onSave={handleSubmit(handleSave)}
      >
        <Box className={styles.form}>
          <FormTextInput
            label={"Rfid"}
            control={control}
            error={errors.rfid}
            {...register("rfid")}
          />
          <FormDatePicker
            control={control}
            label={"Activation Date"}
            error={errors.activationDate}
            {...register("activationDate")}
          />
          <FormDatePicker
            control={control}
            label={"Expiration Date"}
            error={errors.expirationDate}
            {...register("expirationDate", { onChange: () => trigger("activationDate") })}
          />
          <Button
            variant={"contained"}
            text={"Assign Driver"}
            className={styles.button}
            onClick={toggleAssignModal}
          />
          <FormTextInput
            disabled
            control={control}
            error={errors.driverId}
            placeholder={"Driver Name"}
            {...register("driverName")}
          />
          <FormCheckbox label={"Active"} control={control} {...register("enabled")} />
        </Box>
      </ModalContainer>
      <AssignModal
        multiple={false}
        visible={isAssignModalVisible}
        assigned={[]}
        fullList={drivers}
        title={"Assign Driver"}
        columns={columns}
        onSave={assignItem}
        pagination={pagination}
        onCancel={toggleAssignModal}
      />
    </>
  );
};
