import { Box, Typography } from "@mui/material";

import ChevronLeft from "@assets/icons/chevron-left.svg";

import styles from "./styles.module.scss";

export const DetailPageHeader = ({ title, goBackText, onGoBack }) => {
  return (
    <Box className={styles.header}>
      <Box className={styles.top} onClick={onGoBack}>
        <img src={ChevronLeft} />
        <Typography className={styles.back}>{goBackText}</Typography>
      </Box>
      <Box className={styles.bottom}>
        <Typography className={styles.title}>{title}</Typography>
        <Box className={styles.buttons}></Box>
      </Box>
    </Box>
  );
};
