export const mapper = (item) => ({
  id: item.faqId,
  question: item.question,
  answer: item.answer,
  language: item.language,
});

export const columns = [
  { label: "Question", field: "question" },
  { label: "Answer", field: "answer" },
  { label: "Language", field: "language" },
];

export const languages = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "he",
    label: "Hebrew",
  },
  {
    value: "ru",
    label: "Russian",
  },
];

export const sortFields = ["question", "answer", "language"];

export const searchFields = columns.map(({ field }) => field);
