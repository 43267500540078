import { useLayoutEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Drawer as MuiDrawer, List } from "@mui/material";

import { Selector } from "@components/new";
import { useAuth, useBusiness } from "@providers";

import { Footer } from "./Footer";
import { Handle } from "./Handle";
import { Logo } from "./Logo";
import { MenuItem } from "./MenuItem";
import { SubMenuItem } from "./SubMenuItem";
import { config } from "./config";
import styles from "./styles.module.scss";

export const Drawer = ({ handleDrawer, withRestricted }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { user, logout } = useAuth();
  const { business, options, select } = useBusiness();

  const [open, setOpen] = useState(true);
  const [menu, setMenu] = useState(config);

  useLayoutEffect(() => {
    handleDrawer && handleDrawer(true);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
    handleDrawer && handleDrawer(!open);
  };

  const onUserPress = () => {
    navigate("/user-info");
  };

  const toggle = (path) => {
    const { expanded, ...rest } = menu.get(path);
    setMenu(new Map(menu.set(path, { ...rest, expanded: !expanded })));
  };

  const renderMenuItem = ([key, item], i) => {
    const { icon, title, expanded, items, restricted } = item;

    if (!withRestricted && restricted) return null;

    const hasItems = items?.length > 0;
    const active = pathname.includes(key);
    const onClick = () => (items ? toggle : navigate)(key);

    const props = { open, icon, hasItems, title, active, expanded, onClick };

    return (
      <Box key={i}>
        <MenuItem {...props} />
        {open && expanded && <List className={styles.subnav}>{items.map(renderSubMenuItem)}</List>}
      </Box>
    );
  };

  const renderSubMenuItem = ({ title, path, restricted }, i) => {
    if (!withRestricted && restricted) return null;

    const onClick = () => navigate(path);
    const isActive = pathname === path;

    return <SubMenuItem key={i} active={isActive} title={title} onClick={onClick} />;
  };

  const width = open ? 270 : 68;

  return (
    <MuiDrawer
      open
      variant="permanent"
      PaperProps={{ className: styles.paper, sx: { width } }}
      sx={{ width }}
    >
      <Handle open={open} onClick={handleDrawerOpen} className={styles.handle} />
      <Box className={styles.content}>
        <Box className={styles.top}>
          <Logo open={open} />
          {open && (
            <Selector value={business} isBusiness={true} options={options} onChange={select} />
          )}
          <List className={styles.navbar}>{[...menu.entries()].map(renderMenuItem)}</List>
        </Box>
        <Footer open={open} user={user} onUserPress={onUserPress} onLogout={logout} />
      </Box>
    </MuiDrawer>
  );
};
