import { useEffect, useMemo } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { FormSelector, FormSwitcher, FormTextInput } from "@components/form/new";
import { ModalContainer, Button } from "@components/new";
import { useForm } from "@hooks";
import { pricingSchema } from "@utils/validation";

import { PricingDetail } from "./PricingDetail";
import { PricingRule } from "./PricingRule";
import { initialData, initialRule, initialPricingDetail, mapSchemeToState } from "./config";
import styles from "./styles.module.scss";

export const PricingModal = ({ type, open, title, pricingScheme, driverGroups, save, onClose }) => {
  const defaultValues = useMemo(
    () => (pricingScheme ? mapSchemeToState(pricingScheme) : initialData),
    [pricingScheme],
  );

  const driverGroupsOptions = useMemo(() => {
    if (!driverGroups) return [];

    return driverGroups.map(({ driverGroupId, name }) => ({
      label: name,
      value: `${driverGroupId}###${name}`,
    }));
  }, [driverGroups]);

  const { register, control, errors, isValid, handleSubmit, setValue } = useForm({
    open,
    defaultValues,
    validationSchema: pricingSchema,
  });

  const {
    fields: details,
    append: appendDetail,
    remove: removeDetail,
  } = useFieldArray({ control, name: "pricingDetails" });

  const {
    fields: rules,
    append: appendRule,
    remove: removeRule,
  } = useFieldArray({ control, name: "rules" });

  const prices = useWatch({ control, name: "pricingDetails" });
  const price = useWatch({ control, name: "price" });

  useEffect(() => {
    if (!prices) return;

    const total = prices.reduce((acc, { price }) => acc + (parseFloat(price) || 0), 0);

    setValue("price", total);
  }, [prices]);

  const renderDetail = ({ id }, i) => {
    return (
      <PricingDetail
        key={id}
        control={control}
        register={register}
        name={`pricingDetails.${i}`}
        errors={errors.pricingDetails?.[i]}
        deleteEnabled={details.length > 1}
        onDelete={() => removeDetail(i)}
      />
    );
  };

  const renderRule = ({ id }, i) => {
    return (
      <PricingRule
        key={id}
        control={control}
        error={errors.rules?.[i]}
        onDelete={() => removeRule(i)}
        deleteEnabled={rules.length > 1}
        {...register(`rules.${i}`)}
      />
    );
  };

  const onSave = ({ id, price, enabled, name, assignedDriverGroups, pricingDetails, rules }) => {
    const driverGroups = assignedDriverGroups.map((assignedDriverGroup) => {
      const [id, name] = assignedDriverGroup.split("###");

      return { id, name };
    });

    const timeScheme = rules
      .filter(({ type }) => type === "Recurring")
      .map(({ weekdays, startTime, endTime }) => ({
        weekdays,
        startHour: new Date(startTime).getHours(),
        startMin: new Date(startTime).getMinutes(),
        endHour: new Date(endTime).getHours(),
        endMin: new Date(endTime).getMinutes(),
      }));

    const dayScheme = rules
      .filter(({ type }) => type === "Time")
      .map(({ type: _type, weekdays: _weekdays, ...rest }) => rest);

    save({
      id,
      name,
      price,
      enabled,
      dayScheme,
      timeScheme,
      pricingDetails,
      assignedDriverGroups: driverGroups,
    });
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      onCancel={onClose}
      saveDisabled={!isValid}
      onSave={handleSubmit(onSave)}
      title={`Charge ${type} Pricing: ${title}`}
    >
      <Box className={styles.row}>
        <FormTextInput
          control={control}
          error={errors.name}
          label={"Rule Name:"}
          {...register("name")}
        />
        <FormSwitcher control={control} label={"Enabled:"} {...register("enabled")} />
      </Box>
      <Box>
        <Typography className={styles.label}>{`Price (₪ per kWh): ₪ ${price?.toFixed(
          2,
        )}`}</Typography>
        <Box className={styles.column}>{details.map(renderDetail)}</Box>
      </Box>
      <Button
        text={"Add Pricing Detail"}
        variant={"contained"}
        className={styles.button}
        onClick={() => appendDetail(initialPricingDetail)}
      />
      <Box>
        <Typography className={styles.label}>Rules:</Typography>
        <Box className={styles.column}>{rules.map(renderRule)}</Box>
      </Box>
      <Button
        text={"Add Rule"}
        variant={"contained"}
        className={styles.button}
        onClick={() => appendRule(initialRule)}
      />
      <FormSelector
        multiple
        control={control}
        options={driverGroupsOptions}
        label={"Assign To Driver Group(s):"}
        {...register("assignedDriverGroups")}
      />
    </ModalContainer>
  );
};
