export const menu = [
  { value: "Cancel Reservation", label: "CancelReservation" },
  { value: "ChangeAvailability", label: "Change Availability" },
  { value: "ChangeConfiguration", label: "Change Configuration" },
  { value: "ClearCache", label: "Clear Cache" },
  { value: "ClearCacheProfiel", label: "Clear Cache Profile" },
  { value: "DataTransfer", label: "Data Transfer" },
  { value: "GetCompositeSchedule", label: "Get Composite Schedule" },
  { value: "GetConfiguration", label: "Get Configuration" },
  { value: "GetDiagnostice", label: "Get Diagnostic" },
  { value: "GetLocalListVersioin", label: "Get Local List Version" },
  { value: "RemoteStartTransaction", label: "Remote Start Transaction" },
  { value: "RemoteStopTransaction", label: "Remote Stop Transaction" },
  { value: "ReserveNow", label: "Reserve Now" },
  { value: "resetType=soft", label: "Reset (Soft)" },
  { value: "resetType=hard", label: "Reset (Hard)" },
  { value: "SendLocalList", label: "Send Local List" },
  { value: "SetChargingProfile", label: "Set Charging Profile" },
  { value: "TriggerMessage", label: "Trigger Message" },
  { value: "UnlockConnector", label: "Unlock Connector" },
  { value: "UpdateFirmware", label: "Update Firmware" },
];
