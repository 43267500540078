import { Box, CircularProgress } from "@mui/material";
import cx from "classnames";

import classes from "./styles.module.css";

export const LoadingContainer = ({ loading, children }) => {
  const className = cx(classes.container, loading && classes.loading);

  return (
    <Box className={className}>{loading ? <CircularProgress color="success" /> : children}</Box>
  );
};
