/// <reference types="@types/google.maps" />

export class AddressHelper {
  #autocompleteService = null;
  #geocoder = null;

  constructor() {
    this.#init();
  }

  #init = async () => {
    try {
      this.#geocoder = new google.maps.Geocoder();

      const { AutocompleteService } = await google.maps.importLibrary("places");

      this.#autocompleteService = new AutocompleteService();
    } catch {}
  };

  getSuggestions = async (input) => {
    if (!this.#autocompleteService) return [];

    const { predictions } = await this.#autocompleteService.getPlacePredictions({ input });

    return predictions;
  };

  geocode = async (placeId) => {
    if (!this.#geocoder) return null;

    const { results } = await this.#geocoder.geocode({ placeId });

    return this.#extractAddress(results);
  };

  reverseGeocode = async (location) => {
    if (!this.#geocoder) return null;

    const { results } = await this.#geocoder.geocode({ location });

    return this.#extractAddress(results);
  };

  #extractAddress = ([place]) => {
    const { address_components, formatted_address, geometry } = place;

    const address = address_components.reduce((acc, { short_name, types }) => {
      types.forEach((type) => (acc[type] = short_name));
      return acc;
    }, {});

    const streetAddress = [address.street_number, address.route].filter(Boolean).join(" ");

    const result = {
      streetAddress,
      administrativeArea: address.administrative_area_level_1,
      subAdministrativeArea: address.administrative_area_level_2,
      postCode: address.postal_code,
      locality: address.locality,
      country: address.country,
      latitude: geometry.location.lat(),
      longitude: geometry.location.lng(),
    };

    return { name: formatted_address, result };
  };

  static getFullAddress = (address) => {
    if (!address) return "";

    const fullAddress = [
      address.streetAddress,
      address.subAdministrativeArea,
      address.administrativeArea,
      address.country,
    ];

    if (!fullAddress.every(Boolean)) {
      if (address.locality) {
        fullAddress.push(address.locality);
      } else {
        fullAddress.push(address.latitude, address.longitude);
      }
    }

    return fullAddress.filter(Boolean).join(", ");
  };
}
