import { EntityList } from "@components/new";
import { RfidAPI, RfidGroupAPI } from "@api";

import { config } from "./config";

export const GroupList = ({ rfidId }) => {
  const titles = {
    sectionTitle: "Group",
    plural: "group(s)",
    unassignTitle: "Unassign Group",
    assignTitle: "Assign Group",
    assignModalTitle: "Assign group to RFID",
  };

  const functions = {
    getAssignedItems: (parentId) => RfidAPI.getByIdWithItems(parentId),
    getAvailableItems: () => RfidGroupAPI.getGroupList(),
    onAssign: (rfidId, toAssign) => RfidGroupAPI.assignRfidsToGroup(toAssign, [rfidId]),
    onUnassign: (rfidId, toDelete) => RfidGroupAPI.removeFromGroup(toDelete, [rfidId]),
  };

  return <EntityList parentId={rfidId} config={config} titles={titles} actions={functions} />;
};
