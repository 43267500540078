import { useRef } from "react";

export const useTable = () => {
  const ref = useRef(null);

  const invoke = (method) => {
    return (value) => ref.current?.[method]?.call(null, value);
  };

  return { ref, invoke };
};
