import { useState } from "react";
import { Tab, Tabs as MUITabs } from "@mui/material";

import styles from "./styles.module.scss";

export const Tabs = ({ tabs, onChange, setSelectedTabIndex }) => {
  const [index, setIndex] = useState(0);

  const onTabChange = (_, index) => {
    setIndex(index);
    onChange(index);
    setSelectedTabIndex?.call(null, index);
  };

  const renderTab = (label, i) => {
    return (
      <Tab
        key={i}
        label={label}
        value={i.toString()}
        classes={{
          root: styles.tab,
          selected: styles.selected,
        }}
      />
    );
  };

  return (
    <MUITabs
      value={index.toString()}
      onChange={onTabChange}
      classes={{ indicator: styles.indicator }}
    >
      {tabs.map(renderTab)}
    </MUITabs>
  );
};
