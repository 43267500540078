import { deleteHandler } from "@utils";
import { FirmwareAPI } from "@api";

export const actions = {
  delete: async ({ id }) => deleteHandler("Firmware", FirmwareAPI.delete, id),
};

export const texts = {
  delete: () => "Are you sure you want to delete Firmware?",
};
