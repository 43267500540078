import { api } from "./axios";

export class FaultAPI {
  static getAll({ chargeStationId, connectorId, page, size }) {
    return api.get("faults", { params: { chargeStationId, connectorId, page, size } });
  }

  static getGeneric() {
    return api.get("faults/generic");
  }

  static getGenericByUser(id) {
    return api.get(`faults/generic/userfaults/${id}`);
  }

  static createGeneric(payload) {
    return api.post("faults/generic", payload);
  }

  static updateGeneric(id, payload) {
    return api.put(`faults/generic/${id}`, payload);
  }

  static updateUserGeneric(payload) {
    return api.post("faults/generic/userfaults", payload);
  }

  static deleteMultiple(ids) {
    return api.delete("faults/generic", { params: { ids } });
  }
}
