export const columns = [
  {
    label: "Transaction ID",
    field: "identifier",
  },
  {
    label: "Station Name",
    field: "stationName",
  },
  {
    label: "Driver",
    field: "username",
  },
  {
    label: "Vehicle",
    field: "vehicle",
  },
  {
    label: "Transaction Start",
    field: "startDate",
  },
  {
    label: "Transaction End",
    field: "endDate",
  },
  {
    label: "Connection Time",
    field: "connectionTime",
  },
  {
    label: "Charging Time",
    field: "charingTime",
  },
  {
    label: "Consumed kWh",
    field: "consumed",
  },
  {
    label: "Transaction Total",
    field: "cost",
  },
  {
    label: "Billed Amount",
    field: "billed",
  },
  {
    label: "Billed Status",
    field: "status",
  },
];

export const filters = [
  { name: "Billed Status", field: "status" },
  {
    name: "Date",
    field: "date",
    options: ["Current Day", "Last Week", "Last 30 Days", "Custom"],
  },
];
