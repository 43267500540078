import { api } from "./axios";

export class RfidGroupAPI {
  static getGroupList() {
    return api.get("rfid/group");
  }

  static getGroupSimpleList() {
    return api.get("rfid/group/simple-list");
  }

  static getGroupById(id) {
    return api.get(`rfid/group/${id}`);
  }

  static create(payload) {
    payload.groupName = payload.name;
    payload.enabled = true;
    return api.post("rfid/group", payload);
  }

  static update(id, payload) {
    return api.patch(`rfid/group/${id}`, payload);
  }

  static deleteGroup(id) {
    return api.delete(`rfid/group/${id}`);
  }

  static async assignedRfids(id) {
    return api.get(`rfid/by-group/${id}`);
  }

  static assignRfidsToGroup(id, rfIds) {
    return api.post(
      "rfid/association",
      {},
      { params: { groupIds: id.toString(), rfIds: rfIds.toString() } },
    );
  }

  static removeFromGroup(id, rfIds) {
    return api.delete("rfid/association", {
      params: { groupIds: id.toString(), rfIds: rfIds.toString() },
    });
  }
}
