import { useState } from "react";

import { FormTextInput } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";
import { userSchema } from "@utils/validation";

import { addConfig, editConfig } from "./config";

export const UserForm = ({ open, type, user, onSave, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const config = type === "Add" ? addConfig : editConfig;

  const defaultValues = user || config.reduce((acc, { name }) => ({ ...acc, [name]: "" }), {});

  const { register, control, errors, handleSubmit } = useForm({
    open,
    defaultValues,
    validationSchema: userSchema[type],
  });

  const renderField = ({ name, label }, i) => {
    return (
      <FormTextInput
        key={i}
        label={label}
        control={control}
        error={errors[name]}
        {...register(name)}
      />
    );
  };

  const save = async (data) => {
    setIsLoading(true);
    await onSave(data);
    setIsLoading(false);
  };

  return (
    <ModalContainer
      open={open}
      title={`${type} User`}
      onClose={onClose}
      onCancel={onClose}
      loading={isLoading}
      onSave={handleSubmit(save)}
    >
      {config.map(renderField)}
    </ModalContainer>
  );
};
