import { useState, useMemo } from "react";
import { TabContext, TabPanel } from "@mui/lab";

import { Tabs } from "../Tabs";

import styles from "./styles.module.scss";

export const PageTabs = ({ tabs, setSelectedTabIndex }) => {
  const [index, setIndex] = useState(0);

  const labels = useMemo(() => {
    return tabs.map(({ label }) => label);
  }, [tabs]);

  const renderContent = ({ component }, i) => {
    return (
      <TabPanel key={i} value={i.toString()} classes={{ root: styles.content }}>
        {component}
      </TabPanel>
    );
  };

  return (
    <TabContext value={index.toString()}>
      <Tabs tabs={labels} onChange={setIndex} setSelectedTabIndex={setSelectedTabIndex} />
      {tabs.map(renderContent)}
    </TabContext>
  );
};
