import { useMemo, useEffect } from "react";
import { useForm as useFormLib } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const parseError = (errorObj) => {
  if (!errorObj) return {};

  if (errorObj.message) return errorObj.message;

  return Object.entries(errorObj).reduce(
    (acc, [field, value]) => ({ ...acc, [field]: parseError(value) }),
    {},
  );
};

export const useForm = ({ open, defaultValues, validationSchema }) => {
  const { reset, getValues, clearErrors, formState, ...rest } = useFormLib({
    mode: "onChange",
    resolver: validationSchema ? yupResolver(validationSchema) : null,
  });

  useEffect(() => {
    open ? reset(defaultValues) : resetForm();
  }, [open]);

  const values = getValues();

  const errors = useMemo(() => {
    if (!formState.errors) return {};

    return Object.entries(formState.errors).reduce((acc, [field, errorObj]) => {
      let error;

      if (Array.isArray(errorObj)) {
        error = errorObj.map(parseError);
      } else if (typeof errorObj === "object") {
        error = parseError(errorObj);
      } else {
        error = errorObj.message;
      }

      return { ...acc, [field]: error };
    }, {});
  }, [formState]);

  const isDirty = formState.isDirty;
  const isValid = !Object.entries(errors).length;

  const setValues = (values) => {
    reset(values);
  };

  const resetForm = () => {
    reset();
    clearErrors();
  };

  return { reset: resetForm, values, setValues, errors, isDirty, isValid, ...rest };
};
