import React, { useState } from "react";
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import styles from "./styles.module.scss";

export const DateRangePicker = ({ renderInput, setStartDate, setEndDate, startDate, endDate }) => {
  const [actualEndDate, setActualEndDate] = useState(endDate);
  return (
    <div className={styles.calendar}>
      <DatePicker
        value={moment(startDate)}
        onChange={(date) => {
          setStartDate(date);
        }}
        closeOnSelect={true}
        renderInput={renderInput}
      />
      <Box className={styles.to}> to </Box>
      <DatePicker
        value={moment(actualEndDate)}
        onChange={(date) => {
          setActualEndDate(date);
          setEndDate(moment(date).add(1, "day"));
        }}
        closeOnSelect={true}
        renderInput={renderInput}
        shouldDisableDate={(date) => {
          if (date <= startDate) {
            return true;
          }
          return false;
        }}
      />
    </div>
  );
};
