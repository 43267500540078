import { date, object, string } from "yup";

const dateSchema = (name) => {
  return date().nullable().typeError(`${name} Date should be valid date`);
};

export const validationSchema = object().shape({
  rfid: string().required("Rfid is required"),
  driverId: string().required("Driver is required"),
  activationDate: dateSchema("Activation").test(
    "Date",
    "Activation Date should be less than Expiration Date",
    function (value) {
      const { expirationDate } = this.parent;

      if (!value || !expirationDate) return true;

      return value.getTime() < expirationDate.getTime();
    },
  ),
  expirationDate: dateSchema("Expiration"),
});
