import { Box, IconButton } from "@mui/material";
import { FaTrash } from "react-icons/fa";

import { ImageInput, ModalContainer } from "@components/new";

import styles from "./styles.module.scss";

export const PhotoModal = ({ open, onClose, images, onUpload, onDelete }) => {
  const renderImage = ({ id, url }) => {
    return (
      <Box key={id} className={styles.item}>
        <img src={url} className={styles.image} />
        <IconButton className={styles.trash} onClick={() => onDelete(id)}>
          <FaTrash size={20} color={"red"} />
        </IconButton>
      </Box>
    );
  };

  return (
    <ModalContainer open={open} title={"Edit Photos"} onClose={onClose} onCancel={onClose}>
      <Box className={styles.grid}>{images.map(renderImage)}</Box>
      <ImageInput onChange={onUpload} />
    </ModalContainer>
  );
};
