import { object, number, string, date, lazy, ref, array } from "yup";

const timeSchema = object().shape({
  startDate: date()
    .max(ref("endDate"), "Start date must be less than or equal to end date")
    .typeError("Start date is required")
    .required("Start date is required"),
  startTime: date()
    .when(["startDate", "endDate"], (startDate, endDate, schema) => {
      return startDate?.getTime() === endDate?.getTime()
        ? schema.max(ref("endTime"), "Start time must be less than end time")
        : schema;
    })
    .typeError("Start time is required")
    .required("Start time is required"),
  endDate: date().typeError("End date is required").required("End date is required"),
  endTime: date().typeError("End time is required").required("End time is required"),
});

const recurringSchema = object().shape({
  weekdays: array().of(string()).min(1, "At least 1 weekday is required"),
  startTime: date()
    .max(ref("endTime"), "Start time must be less than end time")
    .typeError("Start time is required")
    .required("Start time is required"),
  endTime: date().typeError("Start time is required").required("End time is required"),
});

const ruleSchema = lazy(({ type }) => {
  switch (type) {
    case "Time":
      return timeSchema;
    case "Recurring":
      return recurringSchema;
  }
});

const priceSchema = number()
  .typeError("Price is required")
  .min(0, "Price must be greater than or equal to 0");

const priceDetailSchema = object().shape({
  pricingDetail: string().required("Pricing Detail is required"),
  price: priceSchema,
});

export const pricingSchema = object().shape({
  price: priceSchema,
  rules: array().of(ruleSchema),
  pricingDetails: array().of(priceDetailSchema),
});
