import { DateHelper } from "@utils/date";

export const mapper_back = (item) => ({
  id: item.transactionId,
  sessionId: item.transactionIdentifier.toString(),
  user: item?.user?.userName,
  stationName: item.stationName,
  startTimeOfCharge: item.startTimeOfCharge,
  endTimeOfCharge: item.endTimeOfCharge,
  cost: (item.cost ?? 0).toFixed(2),
  energy: item.energy,
  vehicle: item.vehicleManufacturerName,
  numberPlate: item.vehicleRegistration,
});

export const mapper = (item) => ({
  id: item.transactionId,
  identifier: item.transactionIdentifier,
  stationName: item.stationName,
  stationId: item.stationId,
  cost: (item.cost ?? 0).toFixed(2),
  billed: (item.billed ?? 0).toFixed(2),
  status: item.paid,
  consumed: (item.energy ?? 0).toFixed(2),
  userId: item.user?.userId,
  userFirstName: item.user?.firstName,
  userLastName: item.user?.lastName,
  username: item.user?.userName,
  startDate: item.startTimeOfCharge,
  endDate: item.endTimeOfCharge ?? "",
  vehicle: item.vehicleManufacturerName,
  connectionTime: item.connectionTime,
  chargingTime: DateHelper.getDuration(0, item.chargingTime),
  connectorNumber: item.connectorNumber,
  paymentMethod: item.paymentMethod,
});
