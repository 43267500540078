export const fields = [
  { label: "Street", name: "streetAddress" },
  { label: "City / Town", name: "locality" },
  { label: "State / Region", name: "administrativeArea" },
  { label: "County", name: "subAdministrativeArea" },
  { label: "Country", name: "country" },
  { label: "Post Code", name: "postCode" },
  { label: "Latitude", name: "latitude" },
  { label: "Longitude", name: "longitude" },
];
