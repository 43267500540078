import { api } from "./axios";

export class QuickSightAPI {
  static getUrl() {
    return api.get("quickSight/url");
  }

  static getUrlById(id) {
    return api.get(`quickSight/url/${id}`);
  }
}
