import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { DetailPageHeader, PageTabs } from "@components/new";
import { DriverAPI } from "@api";

import { InfoTab } from "./InfoTab";
import { GroupTab } from "./groupTab";
import { mapper } from "./mapper";
import styles from "./styles.module.scss";
import { Wrapper } from "./wrapper";

export const DriverDetails = () => {
  const navigate = useNavigate();
  const { driverId } = useParams();
  const [driver, setDriver] = useState();
  const [tabs, setTabs] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    DriverAPI.getById(driverId)
      .then((driver) => {
        const driverMapped = mapper(driver);
        setDriver(driverMapped);
        const tabsData = [{ label: "Info", component: <InfoTab driver={driverMapped} /> }];
        if (!driverMapped.isDeleted) {
          tabsData.push({ label: "RFID/Groups", component: <GroupTab driver={driverMapped} /> });
        }
        setTabs(tabsData);
      })
      .finally(() => setIsLoading(false));
  }, [driverId]);

  const onGoBack = () => {
    navigate(-1);
  };

  const goBackText = "Back to Drivers List";

  return (
    <Wrapper loading={isLoading}>
      <Box className={styles.container}>
        <DetailPageHeader title={driver?.userName} goBackText={goBackText} onGoBack={onGoBack} />
        <PageTabs tabs={tabs} />
      </Box>
    </Wrapper>
  );
};
