import { useState } from "react";
import { toast } from "react-toastify";
import { FormHelperText } from "@mui/material";

import { FormSelector, FormTextInput } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";
import { siteGroupSchema } from "@utils/validation";
import { ChargeGroupAPI } from "@api";

const initialValues = {
  name: "",
  chargeSites: [],
};

const getGroupValues = ({ name, chargeSites }) => ({
  name,
  chargeSites: chargeSites.map(({ siteId }) => siteId),
});

export const FormModal = ({ open, type, group, options, onSave, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = group ? getGroupValues(group) : initialValues;

  const { register, errors, control, handleSubmit } = useForm({
    open,
    defaultValues,
    validationSchema: siteGroupSchema,
  });

  const save = async (data) => {
    setIsLoading(true);
    try {
      if (type === "Add") await ChargeGroupAPI.create(data);
      else await ChargeGroupAPI.update(group.id, data);
      onClose();
      onSave();
      toast.success("Success alert: Successfully saved");
    } catch {
      toast.error("Error alert: Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer
      open={open}
      loading={isLoading}
      title={`${type} Group`}
      onClose={onClose}
      onCancel={onClose}
      onSave={handleSubmit(save)}
    >
      <FormTextInput
        control={control}
        label={"Group Name"}
        error={errors.name}
        {...register("name")}
      />
      <FormSelector
        multiple
        label={"Site Names"}
        options={options}
        control={control}
        error={!!errors.chargeSites}
        {...register("chargeSites")}
      />
      {!!errors.chargeSites && <FormHelperText error>{errors.chargeSites}</FormHelperText>}
    </ModalContainer>
  );
};
