import { object, string } from "yup";

import { config } from "./config";

const schema = Object.entries(config).reduce((acc, [key, { fields }]) => {
  const rules = fields.reduce((acc, { label, name }) => {
    const required = `${label} is required`;

    const rule =
      name === "email"
        ? string().email(`${label} should be a valid email`).required(required)
        : string().required(required);

    return { ...acc, [name]: rule };
  }, {});

  return { ...acc, [key]: object().shape(rules) };
}, {});

export const validationSchema = object().shape(schema);
