import { useState } from "react";
import { Box } from "@mui/material";

import { FormTextInput } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";

import styles from "./styles.module.scss";

const initialValues = {
  name: "",
};

export const ManageModal = ({ open, group, onSave, onClose, titles }) => {
  const defaultValues = group || initialValues;
  const title = group ? titles.edit : titles.add;
  const { register, control, handleSubmit } = useForm({ open, defaultValues });

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (rest) => {
    setIsLoading(true);
    try {
      group?.id ? await onSave(rest, group?.id) : await onSave(rest);
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer
      open={open}
      title={title}
      onClose={onClose}
      onCancel={onClose}
      loading={isLoading}
      onSave={handleSubmit(handleSave)}
    >
      <Box className={styles.data}>
        <FormTextInput label={"Group Name"} control={control} {...register("name")} />
      </Box>
    </ModalContainer>
  );
};
