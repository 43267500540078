import { TableHead, TableRow, TableCell } from "@mui/material";
import cx from "classnames";

import CarretIcon from "@assets/icons/carret.svg";

import styles from "./styles.module.scss";

export const Head = ({ size = "m", columns, sortColumns, onSort }) => {
  const classes = cx(styles.row, styles[`row_${size}`]);

  const onCellClick = (sortable, field) => {
    return () => sortable && onSort(field);
  };

  const renderCell = ({ label, field }, i) => {
    const isAscending = sortColumns[field];
    const sortable = isAscending !== undefined;

    const cellClasses = cx(styles.cell, sortable && styles.sortable);
    const iconClasses = cx(styles.icon, !isAscending && styles.descending);

    return (
      <TableCell key={i} className={cellClasses} onClick={onCellClick(sortable, field)}>
        {label}
        {sortable && <img src={CarretIcon} className={iconClasses} />}
      </TableCell>
    );
  };

  return (
    <TableHead>
      <TableRow className={classes}>{columns.map(renderCell)}</TableRow>
    </TableHead>
  );
};
