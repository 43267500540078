import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";

import { ConfirmationModal } from "@components/modals";
import {
  LoadingContainer,
  Button,
  Paper,
  SearchInput,
  Table,
  Link,
  Switcher,
} from "@components/new";
import { useAuth } from "@providers";
import { useConfirmation, usePagination, useTable } from "@hooks";
import { DateHelper } from "@utils/date";
import { RoleAPI, UserAPI } from "@api";

import { UserModal } from "./UserModal";
import { actions, texts } from "./action-popup";
import { columns, sortFields, reducer } from "./config.js";
import { roleMapper, userMapper } from "./mappers";
import styles from "./styles.module.scss";

export const PlatformUserList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [statusesMap, setStatusesMap] = useState();
  const [users, setUsers] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const { ref, invoke } = useTable();
  const [modal, setModal] = useState({ visible: false, id: "", enabled: false });

  const { fetch, ...pagination } = usePagination(UserAPI.getUsers);

  useEffect(() => {
    RoleAPI.getDefined().then((data) => {
      setRoleList(data.map(roleMapper));
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetch()
      .then((users) => {
        const mapped = users.map(userMapper);
        setStatusesMap(mapped.reduce(reducer, new Map()));
        setUsers(mapped);
      })
      .finally(() => setIsLoading(false));
  }, [refreshList, pagination.page]);

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const toggleAddModal = () => {
    setIsAddModalVisible((isVisible) => !isVisible);
    setCurrentUser(undefined);
  };

  const closeDriverModal = () => {
    setIsAddModalVisible(false);
  };

  const toggleEditModal = (user) => {
    setCurrentUser(user);
    setIsAddModalVisible((isVisible) => !isVisible);
  };

  const { user: loggedUser, isAdmin } = useAuth();

  const renderItem = (item, field) => {
    const value = item[field];

    switch (field) {
      case "userName": {
        return <Link text={value} path={`${item.id}/details`} />;
      }
      case "createdAt":
      case "lastLoginAt":
      case "updatedAt": {
        if (!value) {
          return "-";
        }
        const [date, time] = DateHelper.formatDateToLocal(value).split(", ");

        return (
          <Box className={styles.date}>
            <Box>{date}</Box>
            <Box>{time}</Box>
          </Box>
        );
      }
      case "status": {
        const { id } = item;
        const enabled = statusesMap.get(id);
        return isAdmin && id !== loggedUser?.userId ? (
          <Switcher checked={enabled} onChange={() => setModal({ visible: true, id, enabled })} />
        ) : (
          <Box>{value ? "Enabled" : "Disabled"}</Box>
        );
      }
      case "businesses":
      case "roles": {
        const joined = value.map((item, i, { length }) => (
          <Box key={i}>
            {item}
            {length - 1 !== i && ","}
          </Box>
        ));
        return <Box>{joined}</Box>;
      }
      default: {
        return value;
      }
    }
  };

  const { isVisible, open, ...rest } = useConfirmation(actions, texts);

  const onConfirm = async () => {
    const status = !modal.enabled;
    await UserAPI.toggleStatus(modal.id, status);
    setStatusesMap(new Map(statusesMap.set(modal.id, status)));
    setModal((modal) => ({ ...modal, visible: false }));
  };

  const onSave = async (data, userId) => {
    try {
      if (userId) {
        await UserAPI.updateAdmin(userId, data);
        toast.success("User has been successfully updated");
      } else {
        await UserAPI.createAdmin(data);
        toast.success("User has been successfully added");
      }

      closeDriverModal();
      refreshDriverList();
    } catch ({ response }) {
      toast.error(response.data.message);
    }
  };

  const refreshDriverList = () => {
    setRefreshList(!refreshList);
  };

  const onReject = () => {
    setModal((modal) => ({ ...modal, visible: false }));
  };

  const tableActions = [
    (user) => ({ label: "Edit", onClick: () => toggleEditModal(user), disabled: !isAdmin }),
    (user) => ({
      label: "Delete",
      onClick: () => open("delete", user, refreshDriverList),
      disabled: !isAdmin || user?.id === loggedUser?.userId,
    }),
  ];

  const text = `Are you sure you want to ${modal.enabled ? "Disable" : "Enable"} this user?`;
  const titles = {
    add: "Add Platform User",
    edit: "Edit Platform User",
  };

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>User List</Typography>
          <Box className={styles.buttons}>
            <Button variant={"contained"} text={"Add Platform User"} onClick={toggleAddModal} />
          </Box>
        </Box>
        <Paper className={styles.body}>
          <Box className={styles.tools}>
            <SearchInput placeholder={"Search by Name, Email"} onChange={invoke("search")} />
          </Box>
          <Table
            ref={ref}
            data={users}
            columns={columns}
            actions={tableActions}
            renderItem={renderItem}
            sortFields={sortFields}
            tableClassName={styles.table}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <UserModal
        open={isAddModalVisible}
        user={currentUser}
        roles={roleList}
        onSave={onSave}
        onClose={closeDriverModal}
        titles={titles}
      />
      <ConfirmationModal open={isVisible} {...rest} />
      <ConfirmationModal
        open={modal.visible}
        text={text}
        onConfirm={onConfirm}
        onReject={onReject}
      />
    </>
  );
};
