import { Box } from "@mui/material";

import { FormCheckbox, FormDatePicker, FormSelector, FormTextInput } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";

import { rfidEditMapper } from "./mapper";
import styles from "./styles.module.scss";

export const RFIDModal = ({ open, rfid, driverId, groups, onSave, onClose }) => {
  const initialValues = {
    rfid: "",
    driverId: driverId,
    enabled: false,
    activationDate: Date.now(),
    expirationDate: Date.now(),
    rfidGroupIds: [],
  };

  const defaultValues = rfid ? rfidEditMapper(rfid) : initialValues;
  const title = rfid ? "Edit RFID" : "Add RFID";
  const { register, control, handleSubmit } = useForm({ open, defaultValues });

  const handleSave = async (rest) => {
    rest.activationDate /= 1000;
    rest.expirationDate /= 1000;
    rfid?.id ? await onSave(rest, rfid?.id) : await onSave(rest);
    onClose();
  };

  return (
    <ModalContainer
      open={open}
      title={title}
      onClose={onClose}
      onCancel={onClose}
      onSave={handleSubmit(handleSave)}
    >
      <Box className={styles.data}>
        {!rfid && <FormTextInput label={"RFID"} control={control} {...register("rfid")} />}
        <FormDatePicker
          label={"Activation Date"}
          control={control}
          {...register("activationDate")}
        />
        <FormDatePicker
          label={"Expiration Date"}
          control={control}
          {...register("expirationDate")}
        />
        <FormSelector
          multiple
          label={"Group"}
          options={groups}
          control={control}
          {...register("rfidGroupIds")}
        />
        {rfid && <Box />}
        <FormCheckbox label={"Active"} control={control} {...register("enabled")} />
      </Box>
    </ModalContainer>
  );
};
