import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FaEdit } from "react-icons/fa";

import { ConfirmationModal } from "@components/modals";
import { Button, LoadingContainer, Paper, Selector, Table } from "@components/new";
import { useConfirmation, usePagination, useTable } from "@hooks";
import { deleteHandler } from "@utils/delete";
import { FAQAPI } from "@api";

import { FAQModal } from "./FAQModal";
import { mapper, columns, sortFields, searchFields, languages } from "./config";
import styles from "./styles.module.scss";

export const FAQ = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [language, setLanguage] = useState("en");
  const [currentFaq, setCurrentFaq] = useState();
  const [faqs, setFaqs] = useState([]);
  const { ref } = useTable();

  const titles = {
    managePopup: {
      add: "Add FAQ",
      edit: "Edit FAQ",
    },
  };

  const fetchFaqs = (pagination) => {
    return FAQAPI.getAllFaqs({ lang: language, ...pagination });
  };

  const { fetch, ...pagination } = usePagination(fetchFaqs);

  const toggleAddModal = () => {
    setIsModalVisible((isVisible) => !isVisible);
    setCurrentFaq(undefined);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const toggleEditModal = (faq) => {
    setCurrentFaq(faq);
    setIsModalVisible((isVisible) => !isVisible);
  };

  const init = async () => {
    setIsLoading(true);
    try {
      const data = await fetch();
      setFaqs(data.map(mapper));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [pagination.page, language]);

  const menu = [
    (faq) => ({
      label: "Edit FAQ",
      icon: FaEdit,
      onClick: () => toggleEditModal(faq),
    }),
    (faq) => ({ label: "Delete", onClick: () => open("delete", faq, init) }),
  ];

  const actions = {
    delete: ({ id }) => deleteHandler("FAQ", FAQAPI.deleteFaq, id),
  };

  const texts = {
    delete: () => "Are you sure you want to delete FAQ?",
  };

  const { isVisible, open, ...confirmation } = useConfirmation(actions, texts);

  const onLanguageChange = (value) => {
    setLanguage(value);
  };

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>FAQ</Typography>
          <Box className={styles.data}>
            <Selector value={language} options={languages} onChange={onLanguageChange} />
            <Button variant={"contained"} text={"Add FAQ"} onClick={toggleAddModal} />
          </Box>
        </Box>
        <Paper className={styles.body}>
          <Table
            ref={ref}
            data={faqs}
            columns={columns}
            actions={menu}
            sortFields={sortFields}
            config={searchFields}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <FAQModal
        open={isModalVisible}
        faq={currentFaq}
        onClose={closeModal}
        onSave={init}
        titles={titles.managePopup}
      />
      <ConfirmationModal open={isVisible} {...confirmation} />
    </>
  );
};
