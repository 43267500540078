import { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Divider, Stack } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
} from "@mui/x-data-grid";

import { Button } from "@components/new";
import { useStationDetails } from "@providers";
import { DateHelper } from "@utils/date";
import { ConfigurationAPI } from "@api";

import styles from "../styles.module.scss";
import { Wrapper } from "../wrapper";

export const ConfigurationTab = () => {
  const { station } = useStationDetails();

  const [isLoading, setIsLoading] = useState(!station?.id || true);

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [status, setStatus] = useState("SUCCESS");
  const timer = 1 * 10 * 1000;

  useEffect(() => {
    station?.id && init();
  }, [station?.id]);

  const init = async () => {
    setIsLoading(true);
    try {
      const configurationData = await ConfigurationAPI.getConfigurationByChargeStationId(
        station.id,
      );
      if (configurationData.settings) {
        const settings = configurationData.settings;
        let checkStatus = true;
        settings.forEach(({ status }) => {
          if (status === "IN_PROGRESS") {
            checkStatus = false;
          }
        });
        if (!checkStatus) {
          setStatus("IN_PROGRESS");
        } else {
          setStatus("SUCCESS");
        }
      }
      setRows(configurationData.settings);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (status === "IN_PROGRESS") {
      init();
      const interval = setInterval(() => {
        init();
      }, timer);
      return () => clearInterval(interval);
    }
  }, [status]);

  const updateConfig = async (payload) => {
    setIsLoading(true);
    try {
      await ConfigurationAPI.updateConfigurationKeyByChargeStationId(station.id, payload);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const onInternalReset = () => {
    ConfigurationAPI.internalReset(station.id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    updateConfig({ confKey: newRow.key, confValue: newRow.value });
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: "key", headerName: "Configuration Setting Name", width: 400, editable: false },
    {
      field: "value",
      headerName: "Configuration Value",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "updateAt",
      headerName: "Last Updated",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: true,
      valueGetter: (params) => {
        if (params.row.updateAt) {
          return `${DateHelper.formatDateToLocal(new Date(params.row.updateAt))}`;
        } else {
          return "";
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "lastChargerMessage",
      headerName: "Charger Message",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={`${id}-1`}
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        if (!row.readonly) {
          return [
            <GridActionsCellItem
              key={`${id}-2`}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
          ];
        } else {
          return [];
        }
      },
    },
  ];

  return (
    <Wrapper loading={isLoading}>
      <Divider className={styles.divider} />
      <Stack direction="row" spacing={4}>
        <Button
          variant={"outlined"}
          text={"Refresh Station Configuration "}
          onClick={init}
          startIcon={<RefreshIcon />}
        />
        <Button variant={"outlined"} text={"Reset "} onClick={onInternalReset} />
      </Stack>
      <Box>
        <Stack direction="column" spacing={2}>
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "key", sort: "asc" }],
              },
            }}
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
          />
        </Stack>
      </Box>
    </Wrapper>
  );
};
