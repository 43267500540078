import { useEffect, useState } from "react";

import { ModelAPI } from "@api";

export const useVendorModels = () => {
  const [vendors, setVendors] = useState([]);
  const [models, setModels] = useState({});

  useEffect(() => {
    ModelAPI.getSimpleList().then((data) => {
      const vendors = [...new Set(data.map(({ vendor }) => vendor))].map((vendor) => ({
        label: vendor,
        value: vendor,
      }));

      const models = data.reduce((acc, { vendor, modelName, chargeStationModelId }) => {
        const value = { label: modelName, value: chargeStationModelId };

        if (acc[vendor]) acc[vendor].push(value);
        else acc[vendor] = [value];

        return acc;
      }, {});

      setVendors(vendors);
      setModels(models);
    });
  }, []);

  return { vendors, models };
};
