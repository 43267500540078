import { v4 as uuid } from "uuid";

import { DateHelper } from "./date";

const formatDTOtoTimeScheme = (timePricingScheme) => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const timeScheme = timePricingScheme.reduce(
    (acc, { startHour, startMin, endHour, endMin, weekDay }) => {
      const key = `${startHour}:${startMin}-${endHour}:${endMin}`;
      acc[key] = acc[key] ? [...acc[key], weekDay] : [weekDay];
      return acc;
    },
    {},
  );

  return Object.entries(timeScheme).map(([key, value]) => {
    const [[startHour, startMin], [endHour, endMin]] = key
      .split("-")
      .map((part) => part.split(":").map(Number));
    const weekdays = value.sort((a, b) => (days.indexOf(a) > days.indexOf(b) ? 1 : -1));

    return {
      startHour,
      startMin,
      endHour,
      endMin,
      weekdays,
    };
  }, {});
};

const formatDTOtoDayScheme = (dayPricingScheme) => {
  return dayPricingScheme.map(({ startTime, endTime }) => ({
    startTime,
    endTime,
    startDate: DateHelper.getDayBeginning(startTime),
    endDate: DateHelper.getDayBeginning(endTime),
  }));
};

const formatTimeSchemeToDTO = (timeScheme) => {
  return timeScheme.flatMap(({ weekdays, ...rest }) =>
    weekdays.map((weekDay) => ({ weekDay, ...rest })),
  );
};

const formatDaySchemeToDTO = (dayScheme) => {
  return dayScheme.map(
    ({
      startDate,
      startTime,
      endDate,
      endTime,
      enabled,
      name,
      price,
      pricingDetails,
      assignedDriverGroups,
    }) => ({
      startTime: DateHelper.addTimeToDate(startDate, startTime),
      endTime: DateHelper.addTimeToDate(endDate, endTime),
      price,
      enabled,
      name,
      pricingDetails,
      assignedDriverGroups,
    }),
  );
};

const formatSchemeToDTO = ({
  enabled,
  name,
  price,
  timeScheme,
  dayScheme,
  pricingDetails,
  assignedDriverGroups,
}) => ({
  timePricingScheme: formatTimeSchemeToDTO(
    timeScheme.map((item) => ({
      ...item,
      enabled,
      name,
      price,
      pricingDetails,
      assignedDriverGroups,
    })),
  ),
  dayPricingScheme: formatDaySchemeToDTO(
    dayScheme.map((item) => ({
      ...item,
      enabled,
      name,
      price,
      pricingDetails,
      assignedDriverGroups,
    })),
  ),
});

const pricingRuleKeyFromObject = ({ enabled, name, price }) =>
  `${enabled}|${name?.split("|")[0]}|${price}`;

export const formatDTOtoScheme = ({ timePricingScheme, dayPricingScheme, ...rest }) => {
  const pricingRuleKeys = [
    ...new Set([
      ...timePricingScheme.map((x) => pricingRuleKeyFromObject(x)),
      ...dayPricingScheme.map((x) => pricingRuleKeyFromObject(x)),
    ]),
  ];

  const pricingSchemes = pricingRuleKeys.map((key) => {
    const timeScheme = timePricingScheme.filter((x) => pricingRuleKeyFromObject(x) === key);
    const dayScheme = dayPricingScheme.filter((x) => pricingRuleKeyFromObject(x) === key);
    const priceRule = [...timeScheme, ...dayScheme][0];
    return {
      id: uuid(),
      enabled: priceRule.enabled,
      name: priceRule.name,
      price: priceRule.price,
      pricingDetails: priceRule.pricingDetails,
      assignedDriverGroups: convertToDriverGroupFormat(priceRule.assignedDriverGroups),
      timeScheme: formatDTOtoTimeScheme(timeScheme),
      dayScheme: formatDTOtoDayScheme(dayScheme),
    };
  });

  return { pricingSchemes, ...rest };
};

const convertToDriverGroupFormat = (assignedDriverGroups) => {
  return assignedDriverGroups ? assignedDriverGroups.map(({ id, name }) => `${id}###${name}`) : [];
};

export const formatPricingSchemesToDTO = (pricingSchemes) => {
  return pricingSchemes.reduce(
    (acc, item) => {
      const { timePricingScheme, dayPricingScheme } = formatSchemeToDTO(item);
      return {
        timePricingScheme: [...acc.timePricingScheme, ...timePricingScheme],
        dayPricingScheme: [...acc.dayPricingScheme, ...dayPricingScheme],
      };
    },
    { timePricingScheme: [], dayPricingScheme: [] },
  );
};

export const mergePricingSchemes = (schemeArray, { id, ...rest }) => {
  const mergeArray = !id
    ? schemeArray.concat(rest)
    : schemeArray.map((item) => (item.id === id ? rest : item));

  return formatPricingSchemesToDTO(mergeArray);
};
