import { DateHelper } from "@utils/date";

export const mapper = (item) => ({
  connectorNumber: item.connectorNumber,
  message: item.message,
  connectorStatus: item.connectorStatus,
  faultTime: item.faultTime,
  siteName: item.siteName,
  faultDuration: DateHelper.getDurationString(DateHelper.getDuration(0, item.totalFaultDuration)),
});
