import { useState } from "react";
import { Box, Typography, Divider, IconButton } from "@mui/material";

import EditIcon from "@assets/icons/edit.svg";
import { ConfirmationModal } from "@components/modals";
import { Link, Paper, Switcher } from "@components/new";
import { DateHelper } from "@utils";
import { RfidAPI } from "@api";

import { fields } from "./config";
import styles from "./styles.module.scss";

export const GeneralInfo = ({ rfid, onEdit }) => {
  const [status, setStatus] = useState(rfid?.status);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible((isVisible) => !isVisible);
  };

  const changeStatus = () => {
    RfidAPI.updateStatus(rfid.id, !status);
    setStatus((status) => !status);
    setIsModalVisible(false);
  };
  //TODO add edit button
  const renderContent = (field) => {
    const value = rfid[field];
    switch (field) {
      case "status": {
        return <Switcher checked={status} onChange={toggleModal} />;
      }
      case "driverName": {
        return <Link text={value} path={`/user-management/driver-list/${rfid.driverId}/details`} />;
      }
      case "activationDate":
      case "expirationDate":
      case "creationDate": {
        if (value) {
          return (
            <Typography className={styles.value}>{DateHelper.formatDateToLocal(value)}</Typography>
          );
        } else {
          return <Typography className={styles.value}>-</Typography>;
        }
      }
      default: {
        return <Typography className={styles.value}>{value}</Typography>;
      }
    }
  };

  const renderField = ({ label, field }, i) => {
    return (
      <Box key={i} className={styles.cell}>
        <Typography className={styles.label}>{label}</Typography>
        {renderContent(field)}
      </Box>
    );
  };

  const modalText = `Are you sure you want to ${status ? "disable" : "enable"} this RFID?`;

  return (
    <>
      <Paper className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>General Info</Typography>
          <IconButton onClick={onEdit}>
            <img src={EditIcon} />
          </IconButton>
        </Box>
        <Box className={styles.body}>
          <Box className={styles.grid}>{fields.map(renderField)}</Box>
        </Box>
        <Divider className={styles.divider} />
      </Paper>
      <ConfirmationModal
        open={isModalVisible}
        text={modalText}
        onConfirm={changeStatus}
        onReject={toggleModal}
      />
    </>
  );
};
