import { Box, Typography } from "@mui/material";
import cx from "classnames";
import { BiLogOut } from "react-icons/bi";

import PlaceholderIcon from "@assets/icons/icon-profile.svg";

import styles from "./styles.module.scss";

export const Footer = ({ open, user, className, onUserPress, onLogout }) => {
  const classes = cx(styles.container, !open && styles.closed, className);

  return (
    <Box className={classes}>
      <Box className={styles.user} onClick={onUserPress}>
        <img src={user?.userImage || PlaceholderIcon} className={styles.image} />
        {open && user?.firstName && user?.lastName && (
          <Typography className={styles.name}>{`${user?.firstName} ${user?.lastName}`}</Typography>
        )}
      </Box>
      {open && <BiLogOut className={styles.button} onClick={onLogout} />}
    </Box>
  );
};
