import { Typography } from "@mui/material";

import { FormTextInput } from "@components/form/new";
import { Paper } from "@components/new";

import styles from "./styles.module.scss";

export const PaperForm = ({ name, label, fields, control, errors, register }) => {
  const renderField = ({ label, name: fieldName }) => {
    const key = `${name}.${fieldName}`;

    return (
      <FormTextInput
        key={key}
        label={label}
        control={control}
        error={errors[name]?.[fieldName]}
        {...register(key)}
      />
    );
  };

  return (
    <Paper className={styles.container}>
      <Typography className={styles.label}>{label}</Typography>
      {fields.map(renderField)}
    </Paper>
  );
};
