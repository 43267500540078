import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";

import { ConfirmationModal } from "@components/modals";
import {
  LoadingContainer,
  Button,
  Paper,
  SearchInput,
  Table,
  Link,
  Switcher,
  DateTime,
} from "@components/new";
import { useConfirmation, usePagination, useTable } from "@hooks";
import { DriverAPI } from "@api";

import { DriverModal } from "./DriverModal";
import { actions, texts } from "./action-popup";
import { columns, sortFields, reducer } from "./config.js";
import { driverMapper } from "./mappers";
import styles from "./styles.module.scss";

export const DriverList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [statusesMap, setStatusesMap] = useState();
  const [drivers, setDrivers] = useState([]);
  const { ref, invoke } = useTable();
  const [modal, setModal] = useState({ visible: false, id: "", enabled: false });

  const { fetch, ...pagination } = usePagination(DriverAPI.getWithDeleted);

  useEffect(() => {
    setIsLoading(true);
    fetch()
      .then((drivers) => {
        const mappedDrivers = drivers.map(driverMapper);
        setStatusesMap(mappedDrivers.reduce(reducer, new Map()));
        setDrivers(mappedDrivers);
      })
      .finally(() => setIsLoading(false));
  }, [refreshList, pagination.page]);

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentDriver, setCurrentDriver] = useState();
  const toggleAddModal = () => {
    setIsAddModalVisible((isVisible) => !isVisible);
    setCurrentDriver(undefined);
  };

  const closeDriverModal = () => {
    setIsAddModalVisible(false);
  };

  const toggleEditModal = (driver) => {
    setCurrentDriver(driver);
    setIsAddModalVisible((isVisible) => !isVisible);
  };

  const renderItem = (item, field) => {
    const value = item[field];

    switch (field) {
      case "userName": {
        return <Link text={value} path={`${item.id}/details`} />;
      }
      case "createdAt":
      case "lastLoginAt":
      case "updatedAt": {
        return <DateTime value={value} twoLine={true} />;
      }
      case "status": {
        const { id } = item;
        const enabled = statusesMap.get(id);
        const onChange = () => setModal({ visible: true, id, enabled });
        return <Switcher checked={enabled} onChange={onChange} />;
      }
      case "enabled": {
        return item[field] ? "Finished" : "Not confirmed";
      }
      case "isDeleted": {
        return item[field] ? "Deleted" : "";
      }
      default: {
        return value;
      }
    }
  };

  const { isVisible, open, ...rest } = useConfirmation(actions, texts);

  const onConfirm = async () => {
    const status = !modal.enabled;
    await DriverAPI.updateStatus(modal.id, status);
    setStatusesMap(new Map(statusesMap.set(modal.id, status)));
    setModal((modal) => ({ ...modal, visible: false }));
  };

  const onSave = async (data, driverId) => {
    try {
      if (driverId) {
        await DriverAPI.updateDriver(driverId, data);
        toast.success("Driver has been successfully edited");
      } else {
        await DriverAPI.create(data);
        toast.success("Driver has been successfully added");
      }

      closeDriverModal();
      refreshDriverList();
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const refreshDriverList = () => {
    setRefreshList(!refreshList);
  };

  const onReject = () => {
    setModal((modal) => ({ ...modal, visible: false }));
  };

  const tableActions = [
    (driver) => ({
      label: "Edit",
      onClick: () => toggleEditModal(driver),
      disabled: driver.isDeleted,
    }),
    (driver) => ({
      label: "Delete",
      onClick: () => open("delete", driver, refreshDriverList),
      disabled: driver.isDeleted,
    }),
    (driver) => ({
      label: "Send OTP",
      onClick: () => open("sendOtp", driver),
      disabled: driver.enabled,
    }),
  ];

  const text = `Are you sure you want to ${modal.enabled ? "Disable" : "Enable"} this EV driver?`;

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>EV Driver List</Typography>
          <Box className={styles.buttons}>
            <Button variant={"contained"} text={"Add EV Driver"} onClick={toggleAddModal} />
          </Box>
        </Box>
        <Paper className={styles.body}>
          <Box className={styles.tools}>
            <SearchInput placeholder={"Search by Name, Email"} onChange={invoke("search")} />
          </Box>
          <Table
            ref={ref}
            data={drivers}
            columns={columns}
            actions={tableActions}
            renderItem={renderItem}
            sortFields={sortFields}
            tableClassName={styles.table}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <DriverModal
        open={isAddModalVisible}
        driver={currentDriver}
        onSave={onSave}
        onClose={closeDriverModal}
      />
      <ConfirmationModal open={isVisible} {...rest} />
      <ConfirmationModal
        open={modal.visible}
        text={text}
        onConfirm={onConfirm}
        onReject={onReject}
      />
    </>
  );
};
