import { Box, Typography } from "@mui/material";

import FilterIcon from "@assets/icons/icon-filter.svg";

import styles from "./styles.module.scss";

export const Button = ({ onClick }) => {
  return (
    <Box className={styles.button} onClick={onClick}>
      <img src={FilterIcon} />
      <Typography className={styles.label}>Filters</Typography>
    </Box>
  );
};
