import { useState } from "react";
import { toast } from "react-toastify";
import { Box, FormHelperText, TextareaAutosize } from "@mui/material";

import { Label } from "@components/form";
import { ModalContainer } from "@components/new";
import { SupportAPI } from "@api";

import styles from "./styles.module.scss";

export const CommentModal = ({ open, ticket, onClose }) => {
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onTextChange = ({ target }) => {
    setText(target.value);
  };

  const close = () => {
    setText("");
    onClose();
  };

  const onSave = async () => {
    setIsLoading(true);
    try {
      await SupportAPI.createTicketComment(ticket.id, text);
      toast.success("Success alert: Successfully saved");
      close();
    } catch {
      toast.error("Error alert: Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer
      open={open}
      onSave={onSave}
      onClose={close}
      onCancel={close}
      loading={isLoading}
      saveDisabled={!text}
      title={"Add Comment"}
    >
      <Box>
        <Label required>Comment</Label>
        <TextareaAutosize
          minRows={8}
          maxLength={200}
          onChange={onTextChange}
          className={styles.textarea}
        />
        <FormHelperText>{`${text.length || 0} / 200`}</FormHelperText>
      </Box>
    </ModalContainer>
  );
};
