import cx from "classnames";

import { LogoMin, LogoMax } from "@assets/drawer";

import styles from "./styles.module.scss";

export const Logo = ({ open }) => {
  const classes = cx(open ? styles.big : styles.small);

  const src = open ? LogoMax : LogoMin;

  return <img src={src} className={classes} alt={"logo"} />;
};
