import { Box, Typography, Divider, Chip } from "@mui/material";
import cx from "classnames";

import { Paper } from "@components/new";

import { config } from "./config";
import styles from "./styles.module.scss";

export const Connector = ({ connector, selected }) => {
  const { connectorStatus, connectorNumber, icon, connectorTypeName } = connector;

  const renderField = ({ name, field }, i) => {
    const value = connector[field];
    let data = "N/A";

    switch (field) {
      case "cost":
      case "energyInkWh":
        data = value?.toFixed(2) ?? data;
        break;
      default:
        data = Array.isArray(value) ? value.length : value ?? data;
    }

    return (
      <Box key={i} className={styles.field}>
        <Typography className={styles.text}>{name}:</Typography>
        <Typography className={styles.text}>{data}</Typography>
      </Box>
    );
  };

  const getConnectorColor = () => {
    switch (connectorStatus) {
      case "Unavailable": {
        return "default";
      }
      case "Faulted": {
        return "error";
      }
      default: {
        return "success";
      }
    }
  };

  const classNames = cx(selected && styles.selected);

  return (
    <Paper className={classNames}>
      <Box className={styles.header}>
        <Box className={styles.left}>
          <Typography className={styles.title}>{`Connector ${connectorNumber}`}</Typography>
          <Typography className={styles.text}>•</Typography>
          <Typography className={styles.text}>Current Details</Typography>
        </Box>
        <Chip label={connectorStatus} color={getConnectorColor()} />
      </Box>
      <Divider className={styles.divider} />
      <Box className={styles.body}>
        <Box className={styles.connector}>
          <img width={70} height={70} src={process.env.REACT_APP_ASSETS_URL + icon} />
          <Typography className={styles.name}>{connectorTypeName}</Typography>
        </Box>
        <Box className={styles.info}>{config.map(renderField)}</Box>
      </Box>
    </Paper>
  );
};
