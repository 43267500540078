export const fields = [
  {
    label: "User Name:",
    name: "userName",
  },
  {
    label: "First Name:",
    name: "firstName",
  },
  {
    label: "Last Name:",
    name: "lastName",
  },
  {
    label: "Email:",
    name: "email",
  },
  {
    label: "Password:",
    name: "password",
  },
  {
    label: "Phone Number:",
    name: "phoneNumber",
  },
];

export const info = fields.filter(({ name }) => name !== "password");
