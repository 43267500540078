import { useState } from "react";
import { FaTrash } from "react-icons/fa";

import { ConfirmationModal } from "@components/modals";
import { useTableData, useTableCheck, useTableDelete } from "@hooks";
import { getChecked, isAnyChecked } from "@utils/check";

import { CustomPagination } from "../CustomPagination";

import { Container } from "./Container";
import { Body } from "./NewBody";
import { Head } from "./NewHead";
import { Tools } from "./Tools";

export const Table = ({
  data,
  setData,
  columns,
  primaryKey,
  renderCustomCell,
  menuItems,
  deleteCallback,
  config,

  csvFileName,
  addButtonLabel,
  onAddButtonClick,

  csvEnabled = false,
  toolsEnabled = true,
  filterEnabled = true,
  sortEnabled = true,
  pagesEnabled = true,
  creationEnabled = true,
  deletionEnabled = true,
}) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { tableData, count, sort, getOrderIcon, ...toolsProps } = useTableData(
    data,
    rowsPerPage,
    config,
    !toolsEnabled,
  );

  const { selectAll, toggleCheckedAll, handleCheckRow } = useTableCheck(data, primaryKey, setData);

  const onRowCheck = (item) => (e) => {
    handleCheckRow(e, item);
  };

  const { isDeleteModalShown, toggleDeleteModal, handleDelete, setIdForDelete } = useTableDelete(
    tableData,
    primaryKey,
    setData,
    deleteCallback,
  );

  const getMenuItems = () => {
    const items = menuItems || [];

    if (!deletionEnabled) return items;

    const deleteItem = {
      label: "Delete",
      icon: FaTrash,
      onClick: (item) => {
        setIdForDelete(item[primaryKey]);
        toggleDeleteModal();
      },
    };

    return [...items, deleteItem];
  };

  const allMenuItems = getMenuItems();

  const pageData = pagesEnabled
    ? tableData.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    : tableData;

  return (
    <>
      {toolsEnabled && (
        <Tools
          {...toolsProps}
          csvFileName={csvFileName}
          onAdd={onAddButtonClick}
          csvEnabled={csvEnabled}
          filterEnabled={filterEnabled}
          creationEnabled={creationEnabled}
          addButtonLabel={addButtonLabel}
        />
      )}
      <Container>
        <Head
          sort={sort}
          columns={columns}
          onDelete={toggleDeleteModal}
          hasMenu={allMenuItems.length > 0}
          sortEnabled={sortEnabled}
          getOrderIcon={getOrderIcon}
          hasChecked={isAnyChecked(data)}
          checkedAmount={getChecked(data).length}
          checkboxEnabled={deletionEnabled}
          onCheck={() => toggleCheckedAll(!selectAll)}
        />
        <Body
          items={pageData}
          columns={columns}
          onCheck={onRowCheck}
          menuItems={allMenuItems}
          renderCustomCell={renderCustomCell}
          checkboxEnabled={deletionEnabled}
        />
      </Container>
      {pagesEnabled && tableData.length > 0 && (
        <CustomPagination
          page={page}
          setPage={setPage}
          count={count}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}
      <ConfirmationModal
        open={isDeleteModalShown}
        text={"Are you sure want to delete?"}
        onConfirm={handleDelete}
        onReject={toggleDeleteModal}
      />
    </>
  );
};
