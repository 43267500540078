import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import { LoadingContainer } from "@components/common";
import { PricingComponent } from "@components/pricing";
import { DriverGroupAPI, PricingAPI } from "@api";

import classes from "./styles.module.css";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export const ChargeStationPricingPage = () => {
  const { stationId } = useParams();
  const [stationPricing, setStationPricing] = useState(null);
  const [sitePricing, setSitePricing] = useState(null);
  const [groupPricing, setGroupPricing] = useState(null);
  const [driverGroups, setDriverGroups] = useState(null);
  const [hasSite, setHasSite] = useState(false);
  const [hasGroup, setHasGroup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    stationId && init();
  }, [stationId]);

  const stationPricingRef = useRef();
  const sitePricingRef = useRef();
  const groupPricingRef = useRef();

  const triggerStationPricing = () => {
    stationPricingRef.current.create();
    handleClose();
  };

  const triggerSitePricing = () => {
    sitePricingRef.current.create();
    handleClose();
  };

  const triggerGroupPricing = () => {
    groupPricingRef.current.create();
    handleClose();
  };

  const init = async () => {
    setIsLoading(true);
    try {
      const driverGroups = await DriverGroupAPI.getDriverGroups();
      setDriverGroups(driverGroups);

      const stationPricing = await PricingAPI.getByChargeStation(stationId);
      setStationPricing(stationPricing);

      if (!stationPricing.chargeSiteId) return;

      const sitePricing = await PricingAPI.getByChargeSite(stationPricing.chargeSiteId);
      setSitePricing(sitePricing);
      setHasSite(sitePricing?.chargeSiteName != null);

      if (!sitePricing.chargeSiteGroupId) return;

      const groupPricing = await PricingAPI.getByChargeGroup(sitePricing.chargeSiteGroupId);
      setHasGroup(groupPricing?.chargeSiteGroupName != null);
      setGroupPricing(groupPricing);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingContainer loading={isLoading}>
      <Alert style={{ marginTop: 0 }} className={classes.info} severity="info">
        <AlertTitle>Pricing Rules Info</AlertTitle>
        <b>Displayed Pricing Rules</b>: You will see only the pricing rules that are relevant to
        this specific charge station.
        <br />
        <b>Order of Evaluation</b>: Rules are evaluated in top-down order. The first matching rule
        sets the price, progressing from precise to broad.
        <br />
        <b>Site or Group Rules</b>: When you manage a pricing rule for a Site or a Group, it applies
        to all the charge stations within that Site or Group.
      </Alert>
      <div className={classes.containCreatePricingRuleButton}>
        <Button
          id="demo-customized-button"
          className={classes.dropdown}
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Create Associated Pricing Rule
        </Button>
      </div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={triggerStationPricing}
          className={classes.createPricingMenuItem}
          disableRipple
        >
          <ListItemText>Station Pricing Rule</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {stationPricing?.chargeStationName}
          </Typography>
        </MenuItem>
        <MenuItem
          disabled={!hasSite}
          onClick={triggerSitePricing}
          className={classes.createPricingMenuItem}
          disableRipple
        >
          <ListItemText>Site Pricing Rule</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {sitePricing?.chargeSiteName}
          </Typography>
        </MenuItem>
        <MenuItem
          disabled={!hasGroup}
          onClick={triggerGroupPricing}
          className={classes.createPricingMenuItem}
          disableRipple
        >
          <ListItemText>Group Pricing Rule</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {groupPricing?.chargeSiteGroupName}
          </Typography>
        </MenuItem>
      </StyledMenu>
      <Box className={classes.pricings}>
        <Box>
          <PricingComponent
            ref={stationPricingRef}
            onSave={init}
            type={"Station"}
            pricing={stationPricing}
            driverGroups={driverGroups}
            nameKey={"chargeStationName"}
            editCallback={PricingAPI.createForChargeStation}
          />
        </Box>
        {hasSite && (
          <Box>
            <PricingComponent
              ref={sitePricingRef}
              onSave={init}
              type={"Site"}
              pricing={sitePricing}
              driverGroups={driverGroups}
              nameKey={"chargeSiteName"}
              editCallback={PricingAPI.createForChargeSite}
            />
          </Box>
        )}
        {hasGroup ? (
          <Box>
            <PricingComponent
              ref={groupPricingRef}
              onSave={init}
              type={"Group"}
              pricing={groupPricing}
              driverGroups={driverGroups}
              nameKey={"chargeSiteGroupName"}
              editCallback={PricingAPI.createForChargeGroup}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {/* </TabPanel>
      <TabPanel value={value} index={1}>
        TODO
      </TabPanel>
      </Box> */}
    </LoadingContainer>
  );
};
