export const fields = [
  {
    label: "Site name",
    field: "siteName",
  },
  {
    label: "Business Name",
    field: "businessName",
  },
  {
    label: "Address",
    field: "addressDetails",
  },
  {
    label: "Creation Date",
    field: "creationDate",
  },
  {
    label: "Last Modified Date",
    field: "creationDate",
  },
];
