import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import FileUpload from "react-material-file-upload";

const Component = ({ name, control, disabled, maxFiles, maxSize }, _) => {
  const renderFileInput = ({ field }) => {
    const { value, onChange } = field;
    return (
      <FileUpload
        value={value}
        maxSize={maxSize}
        maxFiles={maxFiles}
        onChange={onChange}
        disabled={disabled}
      />
    );
  };

  return <Controller name={name} control={control} render={renderFileInput} />;
};

export const FileInput = forwardRef(Component);
