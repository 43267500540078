import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { ConfirmationModal } from "@components/modals";
import { AnchorLink, Button, LoadingContainer, Paper, Table } from "@components/new";
import { useConfirmation, usePagination, useTable } from "@hooks";
import { DateHelper } from "@utils/date";
import { FirmwareAPI, ModelAPI } from "@api";

import { FirmwareModal } from "./FirmwareModal";
import { actions, texts } from "./action-popup";
import { columns, sortFields } from "./config";
import { firmwareMapper, optionsMapper } from "./mappers";
import styles from "./styles.module.scss";

export const AllFirmware = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [firmwares, setFirmwares] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentFirmware, setCurrentFirmware] = useState();
  const [options, setOptions] = useState({ vendors: [], models: {} });
  const { ref } = useTable();

  const toggleModal = () => {
    setIsAddModalVisible((isVisible) => !isVisible);
    setCurrentFirmware(undefined);
  };

  const { fetch, ...pagination } = usePagination(FirmwareAPI.getAll);

  const { isVisible, open, ...rest } = useConfirmation(actions, texts);

  useEffect(() => {
    ModelAPI.getSimpleList().then((models) => {
      setOptions(optionsMapper(models));
    });
  }, []);

  useEffect(() => {
    init();
  }, [refreshList, pagination.page]);

  const init = async () => {
    setIsLoading(true);
    try {
      const firmwares = await fetch();
      setFirmwares(firmwares.map(firmwareMapper));
    } finally {
      setIsLoading(false);
    }
  };

  const toggleEditModal = (firmware) => {
    setCurrentFirmware(firmware);
    setIsAddModalVisible((isVisible) => !isVisible);
  };

  const refreshFirmwareList = () => {
    setRefreshList(!refreshList);
  };

  const closeFirmwareModal = () => {
    setIsAddModalVisible(false);
  };

  const onSave = async (rest, id) => {
    await (id ? FirmwareAPI.update(id, rest) : FirmwareAPI.create(rest));
    refreshFirmwareList();
  };

  const tableActions = [
    (firmware) => ({ label: "Edit", onClick: () => toggleEditModal(firmware) }),
    (firmware) => ({
      label: "Delete",
      onClick: () => open("delete", firmware, refreshFirmwareList),
    }),
  ];

  const render = (item, key) => {
    const value = item[key];

    if (key === "createdAt") {
      return DateHelper.formatDateToLocal(value);
    }
    if (key === "version") {
      return <AnchorLink text={value} onClick={() => toggleEditModal(item)} />;
    }
    switch (key) {
      case "createdAt": {
        return DateHelper.formatDateToLocal(value);
      }
      case "models": {
        return value.map(({ name }) => name).join(", ");
      }
      default: {
        return value;
      }
    }
  };

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>All Firmwares</Typography>
          <Button variant={"contained"} text={"Add Firmware"} onClick={toggleModal} />
        </Box>
        <Paper>
          <Table
            ref={ref}
            data={firmwares}
            columns={columns}
            renderItem={render}
            actions={tableActions}
            sortFields={sortFields}
            tableClassName={styles.table}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <FirmwareModal
        open={isAddModalVisible}
        firmwareDetails={currentFirmware}
        options={options}
        onSave={onSave}
        onClose={closeFirmwareModal}
      />
      <ConfirmationModal open={isVisible} {...rest} />
    </>
  );
};
