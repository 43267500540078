import { api } from "./axios";

export class ModelAPI {
  static getAll({ page, size }) {
    return api.get("models/chargestations", { params: { page, size } });
  }

  static getSimpleList() {
    return api.get("models/chargestations/simple-list");
  }

  static getById(id) {
    return api.get(`models/chargestations/${id}`);
  }

  static create(payload) {
    return api.post("models/chargestations", payload);
  }

  static update(id, payload) {
    return api.patch(`models/chargestations/${id}`, payload);
  }

  static delete(id) {
    const ids = [id].join(",");
    return api.delete("models/chargestations", { params: { ids } });
  }
}
