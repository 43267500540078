import { DateHelper } from "@utils";

export const mapper = (item) => ({
  id: item.transactionIdentifier,
  station: item.stationName,
  cost: Number.isInteger(item.cost) ? item.cost : +item.cost.toFixed(2),
  energy: item.energy,
  startTime: item.startTimeOfCharge,
  endTime: item.endTimeOfCharge,
});

export const render = (item, key) => {
  const value = item[key];

  if (["startTime", "endTime"].includes(key)) {
    return DateHelper.formatDateToLocal(value);
  }

  return value;
};

export const columns = [
  {
    name: "SESSION ID",
    field: "id",
  },
  {
    name: "STATION NAME",
    field: "station",
  },
  {
    name: "COST",
    field: "cost",
  },
  {
    name: "ENERGY (kWH)",
    field: "energy",
  },
  {
    name: "START TIME",
    field: "startTime",
  },
  {
    name: "END TIME",
    field: "endTime",
  },
];

const csvConfig = {
  head: ["Transaction Identifier", "Station Name", "Cost", "Energy(kWh)", "Start Time", "End Time"],
  body: (item) => [
    item.id,
    item.station,
    item.cost,
    item.energy,
    DateHelper.formatDateToLocal(item.startTime),
    DateHelper.formatDateToLocal(item.endTime),
  ],
};

const filterConfig = [
  {
    name: "Station Name",
    field: "station",
    options: [],
  },
];

export const searchFields = columns.map(({ field }) => field);

export const config = { filterConfig, csvConfig, searchFields };
