import { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import cx from "classnames";

import EditIcon from "@assets/icons/edit.svg";
import { ConfirmationModal } from "@components/modals";
import { Paper, Switcher, Image } from "@components/new";
import { DateHelper } from "@utils";
import { ChargeStationAPI } from "@api";

import { fields } from "./config";
import styles from "./styles.module.scss";

export const GeneralInfo = ({ station, images, className, onEdit, onImageUpload }) => {
  const [status, setStatus] = useState(station.operationalStatus);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible((isVisible) => !isVisible);
  };

  const changeStatus = () => {
    setStatus((status) => !status);
    ChargeStationAPI.update(station.id, { operationalStatus: !status });
    setIsModalVisible(false);
  };

  const renderContent = (field) => {
    const value = station[field];

    switch (field) {
      case "hardwareStatus": {
        return <Typography className={styles.value}>{value ? "Online" : "Offline"}</Typography>;
      }
      case "operationalStatus": {
        return <Switcher checked={status} onChange={toggleModal} />;
      }
      case "createdAt":
      case "lastModified":
      case "lastHeartBeat": {
        return (
          <Typography className={styles.value}>
            {value ? DateHelper.formatDateToLocal(value, "MM/DD/YYYY") : "-"}
          </Typography>
        );
      }
      default: {
        return <Typography className={styles.value}>{value}</Typography>;
      }
    }
  };

  const renderField = ({ label, value }, i) => {
    return (
      <Box key={i} className={styles.cell}>
        <Typography className={styles.label}>{label}</Typography>
        {renderContent(value)}
      </Box>
    );
  };

  const modalText = `Are you sure you want to ${status ? "disable" : "enable"} this station?`;

  const classes = cx(styles.container, className);

  return (
    <>
      <Paper className={classes}>
        <Box className={styles.header}>
          <Typography className={styles.title}>General Info</Typography>
          <IconButton onClick={onEdit}>
            <img src={EditIcon} />
          </IconButton>
        </Box>
        <Box className={styles.body}>
          <Image images={images} onUpload={onImageUpload} />
          <Box className={styles.grid}>{fields.map(renderField)}</Box>
        </Box>
      </Paper>
      <ConfirmationModal
        open={isModalVisible}
        text={modalText}
        onConfirm={changeStatus}
        onReject={toggleModal}
      />
    </>
  );
};
