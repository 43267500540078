import { api } from "./axios";

export class LoadManagementAPI {
  static async getById(siteId) {
    return api.get(`loadManagement/chargeSite/${siteId}`);
  }

  static create(payload) {
    return api.post("loadManagement/chargeSite", payload);
  }

  static enable(id) {
    return api.put(`loadManagement/enable/${id}`);
  }

  static disable(id) {
    return api.put(`loadManagement/disable/${id}`);
  }

  static update(id, payload) {
    return api.put(`loadManagement/${id}`, payload);
  }

  static delete(id) {
    return api.delete(`loadManagement/${id}`);
  }
}
