import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

export const Link = ({ text, path }) => {
  const navigate = useNavigate();

  const onClick = () => navigate(path);

  return (
    <div className={styles.link} onClick={onClick}>
      {text}
    </div>
  );
};
