import { DateTime, Link } from "@components/new";

export const columns = [
  {
    label: "Group Name",
    field: "name",
  },
  {
    label: "Driver(s)",
    field: "driverCount",
  },
  {
    label: "Business",
    field: "business",
  },
];

export const mapper = (item) => ({
  id: item.driverGroupId,
  name: item.name,
  business: item.business,
  createdAt: item.createdAt,
  driverCount: item.driverCount,
});

export const render = (item, field) => {
  const value = item[field];

  switch (field) {
    case "name": {
      return <Link text={value} path={`/user-management/driver-group-list/${item.id}/details`} />;
    }
    case "createdAt": {
      return <DateTime value={value} twoLine={true} />;
    }
    default: {
      return value;
    }
  }
};
