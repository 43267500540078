export const columns = [
  {
    label: "Vendor",
    field: "vendor",
  },
  {
    label: "Model Name",
    field: "modelName",
  },
  {
    label: "Device Type",
    field: "deviceType",
  },
  {
    label: "Device Power Rating (KW)",
    field: "powerRating",
  },
  {
    label: "# of Connectors",
    field: "connectorsCount",
  },
  {
    label: "# of Assigned Stations",
    field: "chargerCount",
  },
];

export const searchFields = ["vendor", "modelName", "powerRating"];

export const filters = [
  {
    name: "Vendor",
    field: "vendor",
  },
  {
    name: "Device Type",
    field: "deviceType",
  },
  {
    name: "Device Power Rating",
    field: "powerRating",
  },
];

export const sortFields = ["vendor", "modelName", "deviceType", "powerRating", "connectorsCount"];
