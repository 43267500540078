import { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { FormSelector, FormTextInput } from "@components/form/new";
import { ConfirmationModal } from "@components/modals";
import { Button, ModalContainer } from "@components/new";
import { useForm } from "@hooks";
import { ModelAPI } from "@api";

import { Connector } from "./Connector";
import { defaultValues, config, connector } from "./config";
import { mapper } from "./mappers";
import styles from "./styles.module.scss";
import { validationSchema } from "./validation";

export const FormModal = ({ open, modelId, connectorTypes, onSave, onClose, type }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { register, isDirty, control, setValues, errors, handleSubmit } = useForm({
    open: open,
    validationSchema,
  });

  useEffect(() => {
    !open && setValues(defaultValues);
  }, [open]);

  useEffect(() => {
    if (modelId) {
      ModelAPI.getById(modelId).then((value) => setValues(mapper(value)));
    } else {
      setValues(defaultValues);
    }
  }, [modelId]);

  const { fields, append, remove } = useFieldArray({ control, name: "connectors" });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const addConnector = () => {
    append(connector);
  };

  const removeConnector = (index) => {
    return () => remove(index);
  };

  const renderField = ({ type, label, name, options }, i) => {
    const error = errors[name];
    const props = { key: i, control, label, error, ...register(name) };

    if (options) {
      return <FormSelector options={options} {...props} />;
    }

    return <FormTextInput type={type} {...props} />;
  };

  const renderConnector = (field, i, { length }) => {
    return (
      <Connector
        key={field.id}
        control={control}
        register={register}
        types={connectorTypes}
        name={`connectors.${i}`}
        error={errors.connectors?.[i]}
        onRemove={length > 1 && removeConnector(i)}
      />
    );
  };

  const save = async (data) => {
    setIsLoading(true);
    await onSave(data);
    setIsLoading(false);
  };

  const cancel = () => {
    isDirty ? setIsModalVisible(true) : onClose();
  };

  const onReject = () => {
    setIsModalVisible(false);
  };

  const onConfirm = () => {
    setIsModalVisible(false);
    onClose();
  };

  return (
    <>
      <ModalContainer
        open={open}
        maxWidth={"md"}
        loading={isLoading}
        title={`${type} Model`}
        onClose={cancel}
        onCancel={cancel}
        onSave={handleSubmit(save)}
      >
        {config.map(renderField)}
        <Box className={styles.connectors}>
          {fields.map(renderConnector)}
          <Button variant={"contained"} text={"Add Connector"} onClick={addConnector} />
          {typeof errors.connectors === "string" && (
            <Typography className={styles.error}>{errors.connectors}</Typography>
          )}
        </Box>
      </ModalContainer>
      <ConfirmationModal
        open={isModalVisible}
        text={"Are you sure you want to close?"}
        subtext={"All unsaved changes will be lost"}
        onReject={onReject}
        onConfirm={onConfirm}
      />
    </>
  );
};
