import { Box, IconButton, Typography } from "@mui/material";

import EditIcon from "@assets/icons/edit.svg";
import { Paper, Map } from "@components/new";
import { AddressHelper, DateHelper } from "@utils";

import { fields } from "./config";
import styles from "./styles.module.scss";

export const GeneralInfo = ({ site, onEdit }) => {
  const renderContent = (field) => {
    const value = site[field];

    if (!value) return null;

    switch (field) {
      case "addressDetails": {
        return (
          <Typography className={styles.value}>{AddressHelper.getFullAddress(value)}</Typography>
        );
      }
      case "creationDate": {
        return (
          <Typography className={styles.value}>{DateHelper.formatDateToLocal(value)}</Typography>
        );
      }
      default: {
        return <Typography className={styles.value}>{value}</Typography>;
      }
    }
  };

  const renderField = ({ label, field }, i) => {
    return (
      <Box key={i} className={styles.cell}>
        <Typography className={styles.label}>{label}:</Typography>
        {renderContent(field)}
      </Box>
    );
  };

  return (
    <Paper className={styles.container}>
      <Box className={styles.header}>
        <Typography className={styles.title}>General Info</Typography>
        <IconButton onClick={onEdit}>
          <img src={EditIcon} />
        </IconButton>
      </Box>
      <Box className={styles.row}>
        <Box className={styles.grid}>{fields.map(renderField)}</Box>
        <Map
          draggable={false}
          latitude={site?.addressDetails?.latitude}
          longitude={site?.addressDetails?.longitude}
        />
      </Box>
    </Paper>
  );
};
