import { TableCell } from "@mui/material";
import cx from "classnames";

import classes from "./styles.module.css";

export const Cell = ({ className, ...rest }) => {
  const classNames = cx(classes.container, className);

  return <TableCell className={classNames} {...rest} />;
};
