import { api } from "./axios";

export class RfidAPI {
  //NEW API
  static getRFIDForDriver(driverId) {
    return api.get(`rfid/driver/${driverId}`);
  }

  static getAll({ page, size }) {
    return api.get("rfid", { params: { page, size } });
  }

  static async getById(rfid) {
    return api.get(`rfid/${rfid}`);
  }

  static async getByIdWithItems(rfid) {
    const rfidInfo = await api.get(`rfid/${rfid}`);
    const data = {};
    data["items"] = rfidInfo?.rfidGroupIds;
    return data;
  }

  static createRfid(payload) {
    return api.post("rfid", payload);
  }

  static updateRfid(id, payload) {
    return api.put(`rfid/${id}`, payload);
  }

  static deleteRfid(ids) {
    return api.delete("rfid", { params: { ids } });
  }

  static async updateStatus(id, status) {
    return api.patch("rfid", status, { params: { ids: id } });
  }

  static delete(id) {
    return api.delete("rfid", { params: { ids: id } });
  }

  static create(payload) {
    return api.post("rfid", payload);
  }

  static update(id, payload) {
    return api.patch(`rfid/${id}`, payload);
  }

  //LEGACY API
  static getForChargeStations() {
    return api.get("chargestation/rfid");
  }

  static getForUsers() {
    return api.get("users/rfid/all");
  }

  static createForChargeStation(payload) {
    return api.post("chargestation/rfid", payload);
  }

  static createForUser(payload) {
    return api.post("users/rfid", payload);
  }

  static updateForChargeStation(id, payload) {
    return api.patch(`chargestation/rfid/${id}`, payload);
  }

  static fullUpdateForChargeStation(id, payload) {
    return api.put(`chargestation/rfid/${id}`, payload);
  }

  static updateForUser(id, payload) {
    return api.put(`users/rfid/${id}`, payload);
  }

  static deleteMultipleForChargeStations(ids) {
    return api.delete("chargestation/rfid", { params: { ids } });
  }

  static deleteMultipleForUsers(ids) {
    return api.delete("users/rfid", { params: { ids } });
  }

  static downloadCsv() {
    return api.get("rfid/download/file", {
      responseType: "blob",
    });
  }

  static uploadCsv(payload) {
    return api.post("rfid/upload/file", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
