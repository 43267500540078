import { useMemo, useState } from "react";
import { Box } from "@mui/material";

import { Checkbox, Table, ModalContainer, SearchInput } from "@components/new";
import { useTable } from "@hooks";

import styles from "./styles.module.scss";

export const AssignModal = ({
  visible,
  assigned,
  fullList,
  columns,
  title,
  onSave,
  onCancel,
  multiple = true,
  pageSize,
}) => {
  const { ref, invoke } = useTable();

  const [selectedItems, setSelectedItems] = useState(new Map());
  const [page, setPage] = useState(1);
  const [availablePage, setAvailablePage] = useState([]);

  const { availableList, initialMap } = useMemo(() => {
    const filterBy = assigned.map(({ id }) => id);
    const availableList = fullList.filter(({ id }) => !filterBy.includes(id));
    setAvailablePage(availableList.slice(0, pageSize));
    const initialMap = new Map(availableList.map(({ id }) => [id, false]));
    setSelectedItems(initialMap);

    return { availableList, initialMap };
  }, [assigned, fullList]);

  const onPageChange = (pageNo) => {
    setPage(pageNo);
    setAvailablePage(availableList.slice((pageNo - 1) * pageSize, pageNo * pageSize));
  };

  const pagination = {
    pageCount: Math.ceil(availableList.length / pageSize),
    page: page,
    onPageChange: onPageChange,
    withPagination: true,
  };

  const selectItem = (id) => {
    const isSelected = selectedItems.get(id);
    const newSelectedItems = new Map(multiple ? selectedItems : initialMap);
    newSelectedItems.set(id, !isSelected);
    setSelectedItems(newSelectedItems);
  };

  const render = (item, key) => {
    const value = item[key];

    if (key !== "name") return value;

    return (
      <Box className={styles.cell}>
        <Checkbox
          label={value}
          id={item.id}
          checked={selectedItems.get(item.id)}
          onCheck={() => selectItem(item.id)}
        />
      </Box>
    );
  };

  const save = () => {
    const chosenItems = [...selectedItems.entries()].reduce((acc, [key, value]) => {
      value && acc.push(key);
      return acc;
    }, []);

    onSave(chosenItems);
  };

  const onCloseModal = () => {
    setSelectedItems(initialMap);
    onCancel();
  };

  const isSaveEnabled = [...selectedItems.values()].some(Boolean);

  return (
    <ModalContainer
      open={visible}
      onSave={save}
      title={title}
      onClose={onCloseModal}
      onCancel={onCloseModal}
      saveDisabled={!isSaveEnabled}
    >
      <Box className={styles.tools}>
        <SearchInput placeholder={"Filter..."} onChange={invoke("search")} />
      </Box>
      <Table
        ref={ref}
        {...pagination}
        columns={columns}
        data={availablePage}
        renderItem={render}
        tableClassName={styles.table}
      />
    </ModalContainer>
  );
};
