export const config = {
  reducer: (acc, { id }) => {
    return acc.set(id, false);
  },

  columns: [
    {
      label: "User Name",
      field: "name",
    },
    {
      label: "First Name",
      field: "firstName",
    },
    {
      label: "Last Name",
      field: "lastName",
    },
    {
      label: "Email",
      field: "email",
    },
  ],

  assignedMapper: (item) => ({
    id: item.userId,
    name: item.userName,
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.email,
  }),

  definedMapper: (item) => ({
    id: item.id,
    name: item.userName,
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.email,
  }),
};
