import { useEffect, useState } from "react";

import { ChargeSiteAPI } from "@api";

export const useBusinessSites = (businessId) => {
  const [sites, setSites] = useState([]);

  useEffect(() => {
    if (!businessId) return;

    ChargeSiteAPI.getByBusiness(businessId).then((data) => {
      const options = data.map(({ siteId, siteName }) => ({
        label: siteName,
        value: siteId,
      }));

      setSites(options);
    });
  }, [businessId]);

  return sites;
};
