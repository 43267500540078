import * as Yup from "yup";

export const modelSchema = Yup.object().shape({
  modelName: Yup.string().required("Model name is required"),
  vendor: Yup.string().required("Vendor is required"),
  deviceType: Yup.string().required("Device type is required"),
  powerRating: Yup.string()
    .test("type", "Power rating should be an integer number", (val) => Number.isInteger(+val))
    .required("Power rating is required"),
});
