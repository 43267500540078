import { AccountTree, Assessment } from "@mui/icons-material";

import { ReactComponent as ChargerIcon } from "@assets/icons/icon-charger.svg";
import { ReactComponent as FirmwareIcon } from "@assets/icons/icon-fw.svg";
import { ReactComponent as HomeIcon } from "@assets/icons/icon-home.svg";
import { ReactComponent as OrderIcon } from "@assets/icons/icon-order.svg";
import { ReactComponent as SettingsIcon } from "@assets/icons/icon-settings.svg";
import { ReactComponent as SiteIcon } from "@assets/icons/icon-site.svg";
import { ReactComponent as UserIcon } from "@assets/icons/icon-user.svg";

const menu = {
  "/home": {
    title: "Home",
    icon: <HomeIcon />,
  },
  "/site-management": {
    title: " Site Management",
    icon: <SiteIcon />,
    expanded: false,
    items: [
      {
        title: "Site List",
        path: "/site-management/site-list",
      },
      {
        title: "Charge Site Group",
        path: "/site-management/site-group-list",
      },
    ],
  },
  "/charger-management": {
    title: "Charger Management",
    icon: <ChargerIcon />,
    expanded: false,
    items: [
      {
        title: "Charger List",
        path: "/charger-management/charger-list",
      },
      {
        title: "Fault Information",
        path: "/charger-management/fault-list",
      },
      {
        title: "Charging Record",
        path: "/charger-management/transaction-list",
      },
    ],
  },
  "/firmware-management": {
    title: " Firmware Management",
    icon: <FirmwareIcon />,
    expanded: false,
    items: [
      {
        title: "All Firmware",
        restricted: true,
        path: "/firmware-management/all",
      },
      {
        title: "CS Firmware",
        path: "/firmware-management/station",
      },
    ],
  },
  "/order-record": {
    title: "Order Record",
    restricted: true,
    icon: <OrderIcon />,
    expanded: false,
    items: [
      {
        title: "Charge Station Model ",
        path: "/order-record/model-list",
      },
      {
        title: "Connector Type",
        path: "/order-record/connector-list",
      },
    ],
  },
  "/user-management": {
    title: "User Management",
    icon: <UserIcon />,
    expanded: false,
    items: [
      {
        title: "Platform Users",
        path: "/user-management/user-list",
      },
      {
        title: "EV Drivers",
        path: "/user-management/driver-list",
      },
      {
        title: "EV Driver Groups",
        path: "/user-management/driver-group-list",
      },
      {
        title: "User RFID",
        path: "/user-management/rfid-list",
      },
      {
        title: "RFID Groups",
        path: "/user-management/rfid-group-list",
      },
    ],
  },
  "/reports": {
    title: " Reports",
    icon: <Assessment htmlColor={"#9090B0"} />,
    expanded: false,
    items: [
      {
        title: "Station Report",
        path: "/reports/charing-stations-report",
      },
      {
        title: "Fault Report",
        path: "/reports/monthly-fault-report",
      },
      {
        title: "Transaction Report",
        path: "/reports/monthly-transaction-report",
      },
    ],
  },
  "/administration": {
    title: "Administration",
    icon: <SettingsIcon />,
    expanded: false,
    items: [
      {
        title: "Support Tickets",
        path: "/administration/support-ticket-list",
      },
      // {
      //   title: "Operation Log",
      //   path: "/administration/operation-log",
      // },
      {
        title: "Remote Device Management",
        path: "/administration/debug",
      },
      {
        title: "FAQ Management",
        path: "/administration/faq",
      },
    ],
  },
  "/cpo-management": {
    title: "CPO Management",
    icon: <AccountTree htmlColor={"#9090B0"} />,
    restricted: true,
    expanded: false,
    items: [
      {
        title: "Business List",
        path: "/cpo-management/business-list",
      },
    ],
  },
};

export const config = new Map(Object.entries(menu));
