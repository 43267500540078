import { Box } from "@mui/material";
import cx from "classnames";

import { DateHelper } from "@utils/date";

import styles from "./styles.module.scss";

export const DateTime = ({ value, twoLine, className }) => {
  const [date, time] = twoLine
    ? DateHelper.formatDateToLocal(value).split(", ")
    : DateHelper.formatDateToLocal(value);

  const classes = cx(styles.date, className);

  if (!value) {
    return <Box className={classes}> - </Box>;
  }
  return (
    <Box className={classes}>
      <Box>{date}</Box>
      {twoLine && <Box>{time}</Box>}
    </Box>
  );
};
