import { Box, Typography } from "@mui/material";

import { ExternalLink, Paper } from "@components/new";
import { DateHelper } from "@utils";

import { fields } from "./config";
import styles from "./styles.module.scss";

export const GeneralInfo = ({ business }) => {
  const renderContent = (field) => {
    const value = business[field];

    switch (field) {
      case "createdAt": {
        return (
          <Typography className={styles.value}>
            {DateHelper.formatDateToLocal(value, "MM/DD/YYYY")}
          </Typography>
        );
      }
      case "websiteLink": {
        return <ExternalLink text={value} path={value} />;
      }
      default: {
        return <Typography className={styles.value}>{value}</Typography>;
      }
    }
  };

  const renderField = ({ label, field }, i) => {
    return (
      <Box key={i} className={styles.cell}>
        <Typography className={styles.label}>{label}</Typography>
        {renderContent(field)}
      </Box>
    );
  };

  return (
    <>
      <Paper className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>General Info</Typography>
        </Box>
        <Box className={styles.body}>
          <Box className={styles.grid}>{fields.map(renderField)}</Box>
        </Box>
      </Paper>
    </>
  );
};
