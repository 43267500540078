export const mapper = (item) => ({
  id: item.chargeSiteGroupId,
  name: item.name ?? "-",
  siteNames: item.chargeSites.length
    ? item.chargeSites.map(({ siteName }) => siteName).join(", ")
    : "-",
  chargeSites: item.chargeSites,
  email: item.createdBy.email,
  checked: false,
});
