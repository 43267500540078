import { api } from "./axios";

export class ChargeSiteAPI {
  static get({ page, size }) {
    return api.get("chargesite", { params: { page, size } });
  }

  static getByBusiness(businessId) {
    return api.get(`chargesite/business/${businessId}`);
  }

  static assignDrivers(payload) {
    return api.post("chargesite/driver/association", payload);
  }
  static assignDriverGroups(payload) {
    return api.post("chargesite/driver-group/association", payload);
  }

  static unAssignDrivers(siteId, toDelete, type) {
    return api.delete("chargesite/driver/association", {
      params: { driverIds: toDelete, siteId, type },
    });
  }

  static unAssignDriverGroups(siteId, toDelete, type) {
    return api.delete("chargesite/driver-group/association", {
      params: { driverGroupIds: toDelete, siteId, type },
    });
  }

  static async getById(siteid) {
    return api.get(`chargesite/${siteid}`);
  }

  static async getByIdDrivers(siteid, type) {
    const drivers = await api.get(`chargesite/${siteid}`);
    if (type == "PRIVATE_NETWORK") {
      return drivers.privateNetworkDrivers;
    } else {
      return drivers.deferPaymentDrivers;
    }
  }

  static async getByIdDriverGroups(siteid, type) {
    const drivers = await api.get(`chargesite/${siteid}`);
    if (type == "PRIVATE_NETWORK") {
      return drivers.privateNetworkDriverGroups;
    } else {
      return drivers.deferPaymentDriverGroups;
    }
  }

  static getSimpleList() {
    return api.get("chargesite/simple-list");
  }

  static create(payload) {
    return api.post("chargesite", payload);
  }

  static update(id, payload) {
    return api.put(`chargesite/${id}`, payload);
  }

  static unassignMultiple(ids) {
    return api.patch(`chargesite/${ids}`);
  }

  static deleteMultiple(ids) {
    return api.delete("chargesite", { params: { ids } });
  }

  static async updatePrivateDeferStatus(chargeSiteId, payload) {
    return api.patch(`chargesite/update-flag/${chargeSiteId}`, payload);
  }
}
