export const userMapper = (item) => ({
  id: item.userId,
  userName: item.userName,
  firstName: item.firstName,
  lastName: item.lastName,
  phoneNumber: item.phoneNumber,
  email: item.email,
  status: item.activeUser,
  createdAt: item.createdAt,
  updatedAt: item.updatedAt,
  lastLoginAt: item.lastLoginAt,
  roles: item.roles.map((item) => item.replace("ROLE_", "")),
  businesses: item.businesses,
});

export const roleMapper = (item) => ({
  value: item.roleId,
  label: item.roleName.replace("ROLE_", ""),
});
