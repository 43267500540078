export const fields = [
  {
    type: "select",
    label: "Charge Station",
    name: "chargeStationID",
  },
  {
    type: "input",
    label: "Description",
    name: "description",
  },
  {
    type: "select",
    label: "Support Type",
    name: "supportType",
  },
  {
    type: "textarea",
    label: "Comment",
    name: "comment",
  },
];

export const defaultValues = {
  chargeStationID: "",
  description: "",
  supportType: "",
  commet: "",
};
