import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { ModalContainer, Paper } from "@components/new";
import { ModelAPI } from "@api";

import { config } from "./config";
import styles from "./styles.module.scss";

export const DetailsModal = ({ open, onClose, model }) => {
  const [connectors, setConnectors] = useState([]);

  useEffect(() => {
    if (!model) return;

    ModelAPI.getById(model.id).then(({ connectors }) => setConnectors(connectors));
  }, [model]);

  const renderConnector = ({ id, number, connectorType, powerRating }) => {
    const { name, icon } = connectorType;

    return (
      <Paper key={id} className={styles.connector}>
        <Typography className={styles.data}>{number}</Typography>
        <Box className={styles.type}>
          <Typography className={styles.data}>{name}</Typography>
          <img src={process.env.REACT_APP_ASSETS_URL + icon} className={styles.image} />
        </Box>
        <Typography className={styles.data}>{`${powerRating} kW`}</Typography>
      </Paper>
    );
  };

  const renderField = ({ label, name }, i) => {
    const isConnectors = name === "connectors";

    const data = isConnectors ? (
      <Box className={styles.connectors}>{connectors.map(renderConnector)}</Box>
    ) : (
      <Typography className={styles.data}>{model?.[name]}</Typography>
    );

    return (
      <Box key={i}>
        <Typography className={styles.label}>{label}</Typography>
        {data}
      </Box>
    );
  };

  return (
    <ModalContainer open={open} onClose={onClose} title={model?.modelName} withButtons={false}>
      {config.map(renderField)}
    </ModalContainer>
  );
};
