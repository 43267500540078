import { useState, useLayoutEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";

import { useAuth, useBusiness } from "@providers";

import { Drawer } from "../Drawer";

import { publicRoutes, privateRoutes } from "./config";

export const Router = () => {
  const { pathname } = useLocation();

  const { isAdmin } = useAuth();
  const { updateKey } = useBusiness();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerShown, setIsDrawerShown] = useState(false);

  useLayoutEffect(() => {
    setIsDrawerShown(!publicRoutes[pathname]);
  }, [pathname]);

  const renderRoute = ([path, { restricted, component }]) => {
    if (!isAdmin && restricted) return null;

    return <Route key={path} path={path} element={component} />;
  };

  const indent = isDrawerShown ? (isDrawerOpen ? 270 : 68) : 0;
  const style = {
    marginLeft: `${indent}px`,
    height: "100vh",
    maxWidth: `calc(100% - ${indent}px)`,
  };

  return (
    <>
      {isDrawerShown && <Drawer withRestricted={isAdmin} handleDrawer={setIsDrawerOpen} />}
      <Box key={updateKey} sx={style}>
        <Routes>
          {[...Object.entries(publicRoutes)].map(renderRoute)}
          {[...Object.entries(privateRoutes)].map(renderRoute)}
        </Routes>
      </Box>
    </>
  );
};
