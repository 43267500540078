import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { RefundModal } from "@components/modals";
import { LoadingContainer, Paper, Table } from "@components/new";
import { DateHelper } from "@utils";
import { TransactionAPI } from "@api";

import { columns } from "./config";
import { mapper } from "./mappers";
import styles from "./styles.module.scss";

export const TransactionDetails = () => {
  const { transactionId } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [transaction, setTransaction] = useState(null);

  const [paymentResult, setPaymentResult] = useState(null);
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false);

  const onRefundRequest = (paymentResult) => {
    setPaymentResult(paymentResult);
    setIsRefundModalVisible((isVisible) => !isVisible);
  };

  const onRefundClose = () => {
    setPaymentResult(null);
    setIsRefundModalVisible(false);
  };

  useEffect(() => {
    setIsLoading(true);
    TransactionAPI.getDetails(transactionId)
      .then((data) => setTransaction(mapper(data)))
      .finally(() => setIsLoading(false));
  }, [transactionId]);

  const renderItem = (item, key) => {
    const value = item[key];

    if (key === "timestamp") {
      return DateHelper.formatDateToLocal(value);
    }

    if (key === "cost") {
      return value.toFixed(2);
    }

    return value;
  };

  const actions = [
    (item) => ({
      label: "Refund",
      disabled: !item.refundable,
      onClick: () => onRefundRequest(item),
    }),
  ];

  return (
    <LoadingContainer loading={isLoading}>
      {transaction && (
        <Box className={styles.content}>
          <Typography className={styles.title}>{`Transaction ${transactionId}`}</Typography>
          <Paper className={styles.info}>
            <Box>
              <Typography>Session Id:</Typography>
              <Typography>Username:</Typography>
              <Typography>Station:</Typography>
              <Typography>Cost:</Typography>
              <Typography>Billed:</Typography>
              <Typography>Start Time:</Typography>
              <Typography>End Time:</Typography>
              <Typography>Pricing Rule:</Typography>
              <Typography>Price/kWh:</Typography>
              <Typography>RFID #:</Typography>
            </Box>
            <Box>
              <Typography>{transaction.sessionId}</Typography>
              <Typography>{transaction.user}</Typography>
              <Typography>{transaction.station}</Typography>
              <Typography>{transaction.cost}</Typography>
              <Typography>{transaction.billed}</Typography>
              <Typography>{transaction.start}</Typography>
              <Typography>{transaction.end}</Typography>
              <Typography>{transaction.pricingRuleName}</Typography>
              <Typography>{transaction.kwhPrice}</Typography>
              <Typography>{transaction.rfid}</Typography>
            </Box>
          </Paper>
          <Paper className={styles.history}>
            <Typography className={styles.title}>History</Typography>
            <Table
              data={transaction.history}
              columns={columns}
              renderItem={renderItem}
              actions={actions}
            />
          </Paper>
        </Box>
      )}
      <RefundModal
        open={isRefundModalVisible}
        transaction={paymentResult}
        onClose={onRefundClose}
      />
    </LoadingContainer>
  );
};
