export const config = {
  reducer: (acc, { id }) => {
    return acc.set(id, false);
  },

  columns: [
    {
      label: "Group Name",
      field: "name",
    },
  ],

  assignedMapper: (item) => ({
    id: item.id,
    name: item.name,
  }),

  definedMapper: (item) => ({
    id: item.driverGroupId,
    name: item.name,
  }),
};
