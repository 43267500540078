import { useEffect, useMemo, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import cx from "classnames";

import { ConfirmationModal } from "@components/modals";
import {
  LoadingContainer,
  Button,
  Paper,
  SearchInput,
  Table,
  Filter,
  Link,
  CountdownTimer,
  DateTime,
} from "@components/new";
import { usePagination, useQuery, useTable } from "@hooks";
import { ChargeStationAPI, ConnectorAPI, ModelAPI } from "@api";

import { AddModal } from "./AddModal";
import { columns, filters, sortFields } from "./config";
import { chargerMapper, modelMapper, connectorMapper } from "./mappers";
import styles from "./styles.module.scss";
import { useConfirmation } from "./use-confirmation";

const TIMER = 60e3;

export const ChargerList = ({ siteIdFromSite, fromChargerManagement = true }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chargers, setChargers] = useState([]);
  const [connectors, setConnectors] = useState([]);
  const [options, setOptions] = useState({ vendors: [], models: [] });
  const [dateTimer, setDateTimer] = useState(new Date().getTime() + TIMER);

  const { ref, invoke } = useTable();
  let siteId = siteIdFromSite;
  if (!siteIdFromSite) {
    siteId = useQuery().get("siteId");
  }
  const fetchStations = (pagination) => {
    return ChargeStationAPI.get({ siteId, ...pagination });
  };

  const { isVisible, open, ...confirmation } = useConfirmation();
  const { fetch, ...pagination } = usePagination(fetchStations);

  const init = () => {
    setIsLoading(true);
    setDateTimer(new Date().getTime() + TIMER);
    Promise.all([fetch(), ModelAPI.getSimpleList(), ConnectorAPI.getAll()])
      .then(([stations, models, connectors]) => {
        setChargers(stations.map(chargerMapper));
        setOptions(modelMapper(models));
        setConnectors(connectors.map(connectorMapper));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    init();

    const interval = setInterval(init, TIMER);

    return () => clearInterval(interval);
  }, [siteId, pagination.page]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleModal = () => {
    setIsModalVisible((isVisible) => !isVisible);
  };

  const refreshChargers = async () => {
    setIsLoading(true);
    try {
      const stations = await fetch();
      setChargers(stations.map(chargerMapper));
    } finally {
      setIsLoading(false);
    }
  };

  const filterConfig = useMemo(() => {
    return filters.map((item) => {
      if (item.field === "connectorNames") {
        return { ...item, options: connectors };
      }

      return item;
    });
  }, [connectors, filters]);

  const renderConnector = ({ icon, status }, i) => {
    const url = process.env.REACT_APP_ASSETS_URL + icon;
    const classes = cx(styles.connector, styles[status.toLowerCase()]);

    return <img key={i} src={url} title={status} className={classes} />;
  };

  const renderItem = (item, field) => {
    const value = item[field];

    switch (field) {
      case "chargerId": {
        const path = fromChargerManagement
          ? `${item.id}/details`
          : `../../charger-management/charger-list/${item.id}/details`;
        return <Link text={value} path={path} />;
      }
      case "connectors": {
        if (!value) return "Unavailable";

        return <Box className={styles.connectors}>{value.map(renderConnector)}</Box>;
      }
      case "createdAt":
      case "lastHeartbeat": {
        return <DateTime twoLine value={value} />;
      }
      default: {
        return value;
      }
    }
  };

  const toggleStation = (station) => {
    const newChargers = chargers.map((item) => {
      if (item.id === station.id) {
        const operationalStatus = !item.operationalStatusValue;
        return {
          ...item,
          operationalStatusValue: operationalStatus,
          operationalStatusName: operationalStatus ? "Enabled" : "Disabled",
        };
      }
      return item;
    });
    setChargers(newChargers);
  };

  const actions = [
    (station) => ({ label: "Delete", onClick: () => open("delete", station, refreshChargers) }),
    (station) => ({ label: "Soft Reset", onClick: () => open("softReset", station) }),
    (station) => ({ label: "Hard Reset", onClick: () => open("hardReset", station) }),
    (station) => {
      const label = station.operationalStatusValue ? "Disable" : "Enable";
      const onClick = () => open("toggle", station, toggleStation);

      return { label, onClick };
    },
  ];

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Stack direction="row" className={styles.timer}>
          <CountdownTimer targetDate={dateTimer} />
          <IconButton onClick={init}>
            <RefreshIcon />
          </IconButton>
        </Stack>
        <Box className={styles.header}>
          <Typography className={styles.title}>Chargers List</Typography>
          <Box className={styles.buttons}>
            <Button variant={"contained"} text={"Add New Charger"} onClick={toggleModal} />
          </Box>
        </Box>
        <Paper className={styles.body}>
          <Box className={styles.tools}>
            <SearchInput placeholder={"Search by ID, Name, Site"} onChange={invoke("search")} />
            <Filter data={chargers} config={filterConfig} onChange={invoke("filter")} />
          </Box>
          <Table
            ref={ref}
            size={"l"}
            data={chargers}
            columns={columns}
            actions={actions}
            renderItem={renderItem}
            sortFields={sortFields}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <AddModal
        open={isModalVisible}
        vendors={options.vendors}
        models={options.models}
        onSave={refreshChargers}
        onClose={toggleModal}
      />
      <ConfirmationModal open={isVisible} {...confirmation} />
    </>
  );
};
