import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { PageTabs } from "@components/new";
import { StationDetailsProvider, useStationDetails } from "@providers";
import { ConfigurationAPI } from "@api";

import { ConfigurationTab } from "./configuration";
import { Header } from "./header";
import { InfoTab } from "./info";
import { LogTab } from "./logs";
import { PerformanceTab } from "./performance";
import { PricingTab } from "./pricing";
import styles from "./styles.module.scss";

const Component = () => {
  const navigate = useNavigate();

  const { stationId } = useParams();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [reloadChargerDetails, setReloadChargerDetails] = useState(false);

  const { station, timer, refresh } = useStationDetails();

  const onGoBack = () => {
    navigate(-1);
  };

  const onReset = (type) => {
    return () => ConfigurationAPI.reset(stationId, type);
  };

  const onInternalReset = () => {
    ConfigurationAPI.internalReset(stationId);
  };

  const onRefresh = () => {
    refresh();
    setReloadChargerDetails((refreshFlag) => !refreshFlag);
  };

  const tabs = [
    {
      label: "Info",
      component: <InfoTab onRefresh={onRefresh} refreshFlag={reloadChargerDetails} />,
    },
    { label: "Performance", component: <PerformanceTab /> },
    { label: "Pricing", component: <PricingTab /> },
    { label: "Configuration", component: <ConfigurationTab /> },
    { label: "Logs", component: <LogTab /> },
  ];

  return (
    <Box className={styles.container}>
      <Header
        title={station?.name}
        onGoBack={onGoBack}
        onInternalReset={onInternalReset}
        onSoftReset={onReset("Soft")}
        onHardReset={onReset("Hard")}
        dateTimer={timer}
        onRefresh={onRefresh}
        selectedTabIndex={selectedTabIndex}
      />
      <PageTabs tabs={tabs} setSelectedTabIndex={setSelectedTabIndex} />
    </Box>
  );
};

export const ChargerDetails = () => {
  return (
    <StationDetailsProvider>
      <Component />
    </StationDetailsProvider>
  );
};
