import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Stack, Typography } from "@mui/material";

import { ConfirmationModal } from "@components/modals/new/ConfirmationModal";
import {
  LoadingContainer,
  Button,
  Table,
  Paper,
  SearchInput,
  Filter,
  AnchorLink,
} from "@components/new";
import { useConfirmation, usePagination, useTable } from "@hooks";
import { ChargeGroupAPI, ChargeSiteAPI } from "@api";

import { columns, filters } from "./config";
import { mapper } from "./mappers";
import { FormModal } from "./modal";
import styles from "./styles.module.scss";

const texts = {
  delete: ({ name }) => `Are you sure you want to delete group ${name}?`,
};

export const ChargeGroup = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const [groups, setSiteGroups] = useState([]);
  const [sites, setSites] = useState([]);

  const [form, setForm] = useState({ open: false, type: "", group: null });

  const refresh = () => {
    setShouldRefresh((refresh) => !refresh);
  };

  const toggleForm = (type, group) => {
    setForm((form) => ({ open: !form.open, type: type || "", group }));
  };

  const { ref, invoke } = useTable();

  const deleteGroup = async ({ id, chargeSites }) => {
    try {
      const siteIds = chargeSites.map(({ siteId }) => siteId);
      await ChargeSiteAPI.unassignMultiple(siteIds.join(","));
      await ChargeGroupAPI.deleteMultiple(id);
      toast.success("Group has been sucessfully deleted");
      refresh();
    } catch {
      toast.error("An error occured while deleting a group");
    }
  };

  const { isVisible, open, ...confirmation } = useConfirmation({ delete: deleteGroup }, texts);

  const { fetch, ...pagination } = usePagination(ChargeGroupAPI.getAll);

  useEffect(() => {
    ChargeSiteAPI.getSimpleList().then(setSites);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetch()
      .then((data) => setSiteGroups(data.map(mapper)))
      .finally(() => setIsLoading(false));
  }, [shouldRefresh, pagination.page]);

  const availableSites = useMemo(() => {
    const takenSites = groups
      .filter(({ id }) => id !== form.group?.id)
      .flatMap(({ chargeSites }) => chargeSites.map(({ siteId }) => siteId));

    return sites
      .filter(({ siteId }) => !takenSites.includes(siteId))
      .map(({ siteId, siteName }) => ({ value: siteId, label: siteName }));
  }, [groups, form]);

  const renderItem = (item, key) => {
    const value = item[key];
    if (key === "name") {
      return <AnchorLink text={item.name} onClick={() => toggleForm("Edit", item)} />;
    }
    return value;
  };

  const menuItems = [
    (item) => ({ label: "Edit", onClick: () => toggleForm("Edit", item) }),
    ({ id }) => ({ label: "Pricing", onClick: () => navigate(`${id}/pricing`) }),
    (item) => ({ label: "Delete", onClick: () => open("delete", item) }),
  ];

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.row}>
          <Typography className={styles.title}>Charge Site Group</Typography>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Button
              variant={"contained"}
              text={"Add New Group"}
              onClick={() => toggleForm("Add")}
            />
          </Stack>
        </Box>
        <Paper className={styles.paper}>
          <Box className={styles.row}>
            <SearchInput onChange={invoke("search")} />
            <Filter data={groups} config={filters} onChange={invoke("filter")} />
          </Box>
          <Table
            ref={ref}
            data={groups}
            columns={columns}
            actions={menuItems}
            renderItem={renderItem}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <ConfirmationModal open={isVisible} {...confirmation} />
      <FormModal {...form} options={availableSites} onSave={refresh} onClose={() => toggleForm()} />
    </>
  );
};
