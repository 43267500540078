import { api } from "./axios";

export class FAQAPI {
  static getAllFaqs({ page, size, lang }) {
    return api.get("faq", { params: { page, size, lang } });
  }

  static createFaq(payload) {
    return api.post("faq", payload);
  }

  static update(id, payload) {
    return api.patch(`faq/${id}`, payload);
  }

  static deleteFaq(id) {
    return api.delete(`faq/${id}`);
  }
}
