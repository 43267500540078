import { useState } from "react";
import { MoreVert } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";

const ITEM_HEIGHT = 68;

export const CustomControlAction = ({ data, className }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton className={className} onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {data.map(({ icon, label, onClick, disabled }, i) => (
          <MenuItem key={i} onClick={onClick} disabled={disabled}>
            <Box className="edit_delete">
              <Box className="edit_box">
                {icon}
                <span>{label}</span>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
