import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, FormHelperText } from "@mui/material";

import { FileInput } from "@components/form";
import { FormTextInput } from "@components/form/new";
import { ConfirmationModal } from "@components/modals";
import { ModalContainer, Selector } from "@components/new";
import { useForm } from "@hooks";
import { convertFileToBase64 } from "@utils/file";

const initialValues = {
  files: [],
  version: "",
  vendor: "",
  models: [],
};

const getModels = (models) => {
  if (!models) {
    return [];
  }
  const modelIds = models.map(({ id }) => id);
  return modelIds;
};

export const FirmwareModal = ({ open, firmwareDetails, options, onSave, onClose }) => {
  const defaultValues = { ...firmwareDetails, files: [] } || initialValues;
  const [vendor, setVendor] = useState(firmwareDetails?.vendor);
  const [models, setModels] = useState(
    firmwareDetails?.models ? getModels(firmwareDetails?.models) : [],
  );
  const title = firmwareDetails ? "Edit Firmware" : "Register Firmware";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { register, errors, values, control, resetField, handleSubmit } = useForm({
    open,
    defaultValues,
  });

  const handleSave = async ({ files, version }) => {
    try {
      const [file] = files;
      const fileName = file.path;
      const binaryFile = await convertFileToBase64(file);
      const chargeStationModelIds = models.join(",");
      const payload = { file: binaryFile, fileName, chargeStationModelIds, vendor, version };
      firmwareDetails?.id ? await onSave(payload, firmwareDetails?.id) : await onSave(payload);
      onClose();
      toast.success("Firmware is added");
    } catch {
      toast.error("An error occured while adding firmware");
    }
  };

  useEffect(() => {
    setVendor(firmwareDetails && firmwareDetails.vendor ? firmwareDetails.vendor : "");
    setModels(firmwareDetails && firmwareDetails.models ? getModels(firmwareDetails.models) : []);
  }, [firmwareDetails]);

  const onVendorChange = (value) => {
    setVendor(value);
    resetField("models");
  };

  const onModelChange = (value) => {
    setModels(typeof value === "string" ? value.split(",") : value);
  };

  const isFileInputDisabled = values.files?.length === 1;

  const onReject = () => {
    setIsModalVisible(false);
  };

  const onConfirm = () => {
    setIsModalVisible(false);
    onClose();
  };

  return (
    <>
      <ModalContainer
        open={open}
        title={title}
        onClose={onClose}
        onCancel={onClose}
        onSave={handleSubmit(handleSave)}
      >
        <Box>
          <FileInput
            maxFiles={1}
            control={control}
            error={!!errors.files}
            disabled={isFileInputDisabled}
            {...register("files")}
          />
          {!!errors.files && <FormHelperText error>{errors.files}</FormHelperText>}
        </Box>
        <FormTextInput
          label={"Version"}
          control={control}
          error={errors.version}
          {...register("version")}
        />
        <Selector
          label={"Vendor"}
          value={vendor}
          options={options.vendors}
          onChange={onVendorChange}
        />
        <Selector
          multiple={true}
          label={"Model"}
          value={models}
          options={options.models[vendor] || []}
          onChange={onModelChange}
        />
      </ModalContainer>
      <ConfirmationModal
        open={isModalVisible}
        text={"Are you sure you want to close?"}
        subtext={"All unsaved changes will be lost"}
        onReject={onReject}
        onConfirm={onConfirm}
      />
    </>
  );
};
