import { v4 as uuid } from "uuid";

export const initialPricingDetail = {
  id: uuid(),
  pricingDetail: "",
  price: 0,
};

export const initialRule = {
  weekdays: [],
  type: "Recurring",
  startTime: null,
  endTime: null,
  startDate: null,
  endDate: null,
};

export const initialData = {
  enabled: true,
  name: "",
  price: 0,
  pricingDetails: [initialPricingDetail],
  assignedDriverGroups: [],
  rules: [initialRule],
};

export const mapSchemeToState = ({
  id,
  enabled,
  name,
  price,
  pricingDetails,
  assignedDriverGroups,
  dayScheme,
  timeScheme,
}) => {
  const dayPrices = dayScheme.map((item) => {
    const startDate = new Date(item.startDate);
    const startTime = new Date(item.startTime);
    const endDate = new Date(item.endDate);
    const endTime = new Date(item.endDate);

    return { ...initialRule, startDate, startTime, endDate, endTime, type: "Time" };
  });

  const timePrices = timeScheme.map(({ startHour, startMin, endHour, endMin, weekdays }) => {
    const startTime = new Date(new Date().setHours(startHour, startMin));
    const endTime = new Date(new Date().setHours(endHour, endMin));

    return { ...initialRule, weekdays, startTime, endTime };
  });

  return {
    id,
    enabled,
    name,
    price,
    pricingDetails,
    assignedDriverGroups,
    rules: dayPrices.concat(timePrices),
  };
};
