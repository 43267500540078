import { useState } from "react";
import { toast } from "react-toastify";

import { FormTextInput } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";
import { PaymentAPI } from "@api";

import { ConfirmationModal } from "../ConfirmationModal";

import { refundSchema } from "./validation";

export const RefundModal = ({ open, transaction, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationShown, setIsConfirmationShown] = useState(false);

  const toggleConfirmation = () => {
    setIsConfirmationShown((isShown) => !isShown);
  };

  const { register, isValid, control, errors, handleSubmit } = useForm({
    open,
    validationSchema: refundSchema(transaction?.cost || 0),
  });

  const onConfirmRefund = async (data) => {
    setIsConfirmationShown(false);
    setIsLoading(true);
    try {
      const { amount, reason } = data;
      const payload = Object.assign({ amount }, reason && { reason });
      await PaymentAPI.refund(transaction.id, payload);
      onClose();
      toast.success("Refund request has been sent");
    } catch {
      toast.error("An error occured while sending refund request");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalContainer
        open={open}
        title={"Refund"}
        onCancel={onClose}
        onClose={onClose}
        loading={isLoading}
        saveDisabled={!isValid}
        onSave={handleSubmit(toggleConfirmation)}
      >
        <FormTextInput
          type={"number"}
          control={control}
          error={errors.amount}
          label={"Refund Amount"}
          {...register("amount")}
        />
        <FormTextInput label={"Reason"} control={control} {...register("reason")} />
      </ModalContainer>
      <ConfirmationModal
        open={isConfirmationShown}
        text={"Please confirm the refund amount and select OK to proceed or Cancel to edit/exit"}
        confirmButtonTitle={"Ok"}
        rejectButtonTitle={"Cancel"}
        onReject={toggleConfirmation}
        onConfirm={handleSubmit(onConfirmRefund)}
      />
    </>
  );
};
