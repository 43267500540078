import { object, array, string } from "yup";

const nameTest = (files) => {
  return files[0]?.path.match(/^.+\.bin$/);
};

export const firmwareSchema = object().shape({
  files: array().min(1, "File is required").test("name", "Extension must be .bin", nameTest),
  version: string().required("Version is required"),
  models: array().min(1, "At least 1 model is required"),
});
