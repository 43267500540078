import { Box, Table as MUITable } from "@mui/material";
import cx from "classnames";

import classes from "./styles.module.css";

export const Container = ({ className, children }) => {
  const classNames = cx(classes.container, className);

  return (
    <Box className={classNames}>
      <MUITable>{children}</MUITable>
    </Box>
  );
};
