import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { faqSchema } from "@utils/validation/faq";

import { FormTextAreaInput, FormSelector } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";
import { FAQAPI } from "@api";

import { fields, languages } from "./config";

const initialValues = {
  question: "",
  answer: "",
  language: "",
};

export const FAQModal = ({ open, faq, onSave, onClose, titles }) => {
  const defaultValues = faq || initialValues;
  const title = faq ? titles.edit : titles.add;

  const { register, control, errors, reset, handleSubmit } = useForm({
    open,
    defaultValues,
    validationSchema: faqSchema,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState({ language: [] });

  useEffect(() => {
    setOptions({
      language: languages,
    });
  }, []);

  const renderField = ({ type, label, name }, i) => {
    const error = errors[name];
    const props = { label, control, error, ...register(name) };

    return (
      <Box key={i}>
        {type === "select" ? (
          <FormSelector options={options[name]} {...props} />
        ) : (
          <FormTextAreaInput {...props} />
        )}
      </Box>
    );
  };

  const save = async (data) => {
    setIsLoading(true);
    try {
      if (faq && faq.id) {
        await FAQAPI.update(faq.id, data);
        toast.success("Success alert: Successfully updated");
      } else {
        await FAQAPI.createFaq(data);
        toast.success("Success alert: Successfully saved");
      }
      onSave();
      close();
    } catch {
      toast.error("Error alert: Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const close = () => {
    onClose();
    reset();
  };

  return (
    <ModalContainer
      open={open}
      onClose={close}
      onCancel={close}
      loading={isLoading}
      title={title}
      onSave={handleSubmit(save)}
    >
      {fields.map(renderField)}
    </ModalContainer>
  );
};
