import { forwardRef } from "react";
import { Controller } from "react-hook-form";

import { ImageInput } from "@components/new";

import { FormField } from "../FormField";

const Component = ({ name, control, label, error }, _) => {
  const render = ({ field }) => {
    const { value, onChange } = field;
    const image = value ? `data:image/png;base64, ${value}` : null;

    return (
      <FormField label={label} error={error}>
        <ImageInput image={image} onChange={onChange} />
      </FormField>
    );
  };

  return <Controller name={name} control={control} render={render} />;
};

export const FormImageInput = forwardRef(Component);
