import { DateHelper } from "@utils/date";

const getDate = (date) => {
  const filters = {
    "Current Day": DateHelper.isCurrentMoment(date, "day"),
    "Last Week": DateHelper.isLastMoment(date, "week"),
    "Last 30 Days": DateHelper.isLastMoment(date, "month"),
    "Current Year": DateHelper.isCurrentMoment(date, "year"),
  };

  return Object.entries(filters)
    .filter((_, value) => value)
    .map(([key]) => key);
};

export const transactionMapper = (item) => ({
  id: item.transactionId,
  identifier: item.transactionIdentifier,
  cost: (item.cost ?? 0).toFixed(2),
  billed: (item.billed ?? 0).toFixed(2),
  status: item.paid,
  consumed: (item.energy ?? 0).toFixed(2),
  userId: item.user?.userId,
  userFirstName: item.user?.firstName,
  userLastName: item.user?.lastName,
  username: item.user?.userName,
  startDate: item.startTimeOfCharge,
  endDate: item.endTimeOfCharge ?? "",
  vehicle: item.vehicleManufacturerName,
  connectionTime: item.connectionTime,
  chargingTime: DateHelper.getDuration(0, item.chargingTime),
  date: getDate(item.startTimeOfCharge),
  connectorNumber: item.connectorNumber,
  paymentMethod: item.paymentMethod,
});

export const imageMapper = (item) => ({
  id: item.uuid,
  url: `${process.env.REACT_APP_IMAGES_URL}/${item.imagePath}`,
});
