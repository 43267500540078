import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Box, Typography } from "@mui/material";
import cx from "classnames";

import classes from "./styles.module.css";

export const BreadcrumbsContainer = ({ source, pages, className, withBottomInset = true }) => {
  const classNames = cx(withBottomInset && classes.container, className);

  return (
    <Breadcrumbs separator={<NavigateNext />} className={classNames}>
      <Box className={classes.source}>{source}</Box>
      {pages.map((page, index) => (
        <Typography key={index} color="text.primary" fontSize="14px">
          {page}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};
