import { Box, Select, MenuItem, Typography } from "@mui/material";
import { ArrowDropDown } from "@material-ui/icons";
import cx from "classnames";

import { ReactComponent as ChevronDownIcon } from "@assets/icons/chevron-down.svg";
import { FormLabel } from "@components/new";

import styles from "./styles.module.scss";

export const Selector = ({
  disabled,
  className,
  options,
  value,
  label,
  onChange,
  multiple,
  isBusiness,
}) => {
  const containerClasses = cx(styles.container, disabled && styles.disabled);
  const selectorClasses = cx(isBusiness ? styles.selectBusiness : styles.select, className);

  const renderItem = ({ label, value: dropdownValue, icon }, i) => {
    const isSelected = value ? value.includes(dropdownValue) : false;
    return (
      <MenuItem key={i} value={dropdownValue} className={styles.item}>
        {icon && <img src={icon} className={styles.icon} />}
        <Typography className={styles.label}>{label}</Typography>
        {multiple && isSelected && <div className={styles.checked}>{"\u2713"}</div>}
      </MenuItem>
    );
  };

  const handleChange = (e) => {
    onChange?.call(null, e.target.value);
  };

  const onMenuClick = (e) => {
    e.preventDefault();
  };

  return (
    <Box className={containerClasses}>
      {label && <FormLabel text={label} />}
      <Select
        fullWidth
        disabled={disabled}
        multiple={multiple}
        value={value}
        disableUnderline
        variant={"standard"}
        IconComponent={isBusiness ? ArrowDropDown : ChevronDownIcon}
        onChange={handleChange}
        classes={{
          select: selectorClasses,

          standard: isBusiness ? styles.standardBusiness : styles.standard,
          icon: isBusiness ? styles.iconBusiness : styles.icon,
        }}
        MenuProps={{
          onClick: onMenuClick,
          classes: { root: styles.menu, paper: styles.paper, list: styles.list },
        }}
      >
        {options.map(renderItem)}
      </Select>
    </Box>
  );
};
