import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import {
  Box,
  Typography,
  TextField,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { LocalizationProvider, DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { Paper, Button, RadioButton } from "@components/new";

import { days, PricingDay } from "../../Day";

import styles from "./styles.module.scss";

const Component = ({ rule, error, onChange, onDelete, deleteEnabled = true }) => {
  const onDateChange = (key) => {
    return (value) => onChange({ ...rule, [key]: value });
  };

  const onDayChange = (day) => {
    const weekdays = rule.weekdays.includes(day)
      ? rule.weekdays.filter((x) => x !== day)
      : rule.weekdays.concat(day);

    return () => onChange({ ...rule, weekdays });
  };

  const changeType = (type) => {
    return () => onChange({ ...rule, type });
  };

  const renderInput = (params) => {
    return <TextField {...params} />;
  };

  const timePickers = (
    <>
      <Box className={styles.fullwidth}>
        <TimePicker
          label={"Start time"}
          value={rule.startTime}
          renderInput={renderInput}
          className={styles.fullwidth}
          onChange={onDateChange("startTime")}
        />
        {!!error?.startTime && <FormHelperText error>{error.startTime}</FormHelperText>}
      </Box>
      <Box className={styles.fullwidth}>
        <TimePicker
          label={"End time"}
          value={rule.endTime}
          renderInput={renderInput}
          className={styles.fullwidth}
          onChange={onDateChange("endTime")}
        />
        {!!error?.endTime && <FormHelperText error>{error.endTime}</FormHelperText>}
      </Box>
    </>
  );

  const getSelector = () => {
    switch (rule.type) {
      case "Recurring":
        return (
          <>
            <Box className={styles.day_selector}>
              <Typography>Days of Week:</Typography>
              <Box>
                <Box className={styles.days}>
                  {Object.keys(days).map((day, i) => (
                    <Box key={i} onClick={onDayChange(day)}>
                      <PricingDay day={day} selected={rule.weekdays.includes(day)} />
                    </Box>
                  ))}
                </Box>
                {!!error?.weekdays && <FormHelperText error>{error.weekdays}</FormHelperText>}
              </Box>
            </Box>
            <Box className={styles.time}>{timePickers}</Box>
          </>
        );
      case "Time":
        return (
          <Box className={styles.date_selector}>
            <Box className={styles.fullwidth}>
              <DesktopDatePicker
                label={"Start date"}
                value={rule.startDate}
                renderInput={renderInput}
                className={styles.fullwidth}
                onChange={onDateChange("startDate")}
              />
              {!!error?.startDate && <FormHelperText error>{error.startDate}</FormHelperText>}
            </Box>
            <Box className={styles.fullwidth}>
              <DesktopDatePicker
                label={"End date"}
                value={rule.endDate}
                renderInput={renderInput}
                className={styles.fullwidth}
                onChange={onDateChange("endDate")}
              />
              {!!error?.endDate && <FormHelperText error>{error.endDate}</FormHelperText>}
            </Box>
            {timePickers}
          </Box>
        );
    }
  };

  return (
    <Paper variant="outlined" className={styles.container}>
      <Box className={styles.switcher_wrapper}>
        <RadioGroup row aria-label="switcher" name="switcher" value={rule.type}>
          <FormControlLabel
            value="Recurring"
            onClick={changeType("Recurring")}
            control={<RadioButton />}
            label="Recurring"
          />
          <FormControlLabel
            value="Time"
            onClick={changeType("Time")}
            control={<RadioButton />}
            label="Time"
          />
        </RadioGroup>
      </Box>
      <Box className={styles.selector}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>{getSelector()}</LocalizationProvider>
      </Box>
      {deleteEnabled && (
        <Button
          text={"Delete Rule"}
          variant={"contained"}
          onClick={onDelete}
          className={styles.button}
        />
      )}
    </Paper>
  );
};

const ControlledComponent = ({ name, control, error, deleteEnabled = true, onDelete }, _) => {
  const render = ({ field: { value, onChange } }) => {
    return (
      <Component
        rule={value}
        error={error}
        onChange={onChange}
        onDelete={onDelete}
        deleteEnabled={deleteEnabled}
      />
    );
  };

  return <Controller name={name} control={control} render={render} />;
};

export const PricingRule = forwardRef(ControlledComponent);
