import { deleteHandler } from "@utils";
import { UserAPI } from "@api";

export const actions = {
  delete: ({ id }) => deleteHandler("User", UserAPI.deleteMultiple, id),
};

export const texts = {
  delete: ({ userName }) => `Are you sure you want to delete user ${userName}?`,
};
