import { Box, Typography } from "@mui/material";
import cx from "classnames";

import { FormLabel } from "@components/new";

import styles from "./styles.module.scss";

export const TextAreaInput = ({
  type,
  value,
  label,
  onChange,
  autoFocus,
  placeholder,
  optional = false,
  className,
  onFocus,
  inputClassName,
  disabled = false,
}) => {
  const containerClasses = cx(styles.container, className);
  const inputClasses = cx(styles.input, inputClassName);

  return (
    <Box className={containerClasses}>
      {label && (
        <Box className={styles.label}>
          <FormLabel text={label} />
          {optional && <Typography className={styles.optional}>(Optional)</Typography>}
        </Box>
      )}
      <textarea
        type={type}
        rows={"3"}
        value={value}
        onFocus={onFocus}
        onChange={onChange}
        autoFocus={autoFocus}
        placeholder={placeholder}
        className={inputClasses}
        disabled={disabled}
      />
    </Box>
  );
};
