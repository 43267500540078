import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { AssignModal, ConfirmationModal } from "@components/modals";
import { Button, Checkbox, Paper, SearchInput, Table } from "@components/new";
import { useConfirmation, useTable } from "@hooks";

import { actions, texts } from "./confirm-actions";
import styles from "./styles.module.scss";

const PAGE_SIZE = 10;
export const EntityList = ({ parentId, config, titles, actions: functions, disabled = false }) => {
  const { ref, invoke } = useTable();

  const [availableList, setAvailableList] = useState([]);
  const [assignedList, setAssignedList] = useState([]);
  const [assignedPage, setAssignedPage] = useState([]);
  const [checkedMap, setCheckedMap] = useState(new Map());
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState(0);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const { reducer, columns, assignedMapper, definedMapper } = config;

  useEffect(() => {
    Promise.all([functions.getAssignedItems(parentId), functions.getAvailableItems()]).then(
      ([assigned, defined]) => {
        const mapped = assigned.items
          ? assigned.items.map(assignedMapper)
          : assigned.map(assignedMapper);
        setAssignedList(mapped);
        setAssignedPage(mapped.slice(0, PAGE_SIZE));
        let definedItems;
        if (defined.items) {
          definedItems = defined.items.filter(({ isDefault }) => !isDefault);
        } else {
          definedItems = defined.filter(({ isDefault }) => !isDefault);
        }
        setAvailableList(definedItems.map(definedMapper));
        setCheckedMap(mapped.reduce(reducer, new Map()));
      },
    );
  }, [parentId, refresh]);

  const onPageChange = (pageNo) => {
    setPage(pageNo);
    setAssignedPage(assignedList.slice((pageNo - 1) * PAGE_SIZE, pageNo * PAGE_SIZE));
  };

  const pagination = {
    pageCount: Math.ceil(assignedList.length / PAGE_SIZE),
    page: page,
    onPageChange: onPageChange,
    withPagination: true,
  };

  const refreshData = () => {
    setRefresh(!refresh);
  };

  const selectItem = (id) => {
    setSelected(!checkedMap.get(id) ? selected + 1 : selected - 1);
    setCheckedMap(new Map(checkedMap.set(id, !checkedMap.get(id))));
  };

  const assignItems = async (toAssign) => {
    await functions.onAssign(parentId, toAssign);
    assignModalToggle();
    refreshData();
  };

  const unassignItem = async () => {
    const toDelete = [...checkedMap]
      .filter(([, v]) => v)
      .map(([k]) => k)
      .join(",");
    await functions.onUnassign(parentId, toDelete);
    refreshData();
  };

  const { isVisible, open, ...rest } = useConfirmation(actions, texts);

  const assignModalToggle = () => {
    setShowModal(!showModal);
  };

  const render = (item, key) => {
    const value = item[key];
    if (key === "name") {
      return (
        <Box className={styles.cell}>
          {disabled ? (
            value
          ) : (
            <Checkbox
              label={value}
              id={item.id}
              checked={checkedMap.get(item.id)}
              onCheck={() => selectItem(item.id)}
            />
          )}
        </Box>
      );
    }
    return value;
  };

  return (
    <>
      <Paper className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>{titles.sectionTitle}</Typography>
          <Box className={styles.tools}>
            <SearchInput placeholder={"Filter..."} onChange={invoke("search")} />
            <Button
              disabled={disabled || selected < 1}
              variant={"outlined"}
              text={titles.unassignTitle}
              onClick={() => open("unassign", titles.plural, unassignItem)}
            />
            <Button
              disabled={disabled}
              variant={"contained"}
              text={titles.assignTitle}
              onClick={assignModalToggle}
            />
          </Box>
        </Box>
        <Table
          ref={ref}
          columns={columns}
          data={assignedPage}
          renderItem={render}
          tableClassName={styles.table}
          {...pagination}
        />
      </Paper>
      <ConfirmationModal open={isVisible} {...rest} />
      <AssignModal
        visible={showModal}
        assigned={assignedList}
        fullList={availableList}
        title={titles.assignModalTitle}
        columns={columns}
        onSave={assignItems}
        onCancel={assignModalToggle}
        pageSize={PAGE_SIZE}
      />
    </>
  );
};
