import { Pagination as MUIPagination } from "@mui/material";
import { Box } from "@mui/system";

import styles from "./styles.module.scss";

export const Pagination = ({ count, page, setPage }) => {
  const handleChange = (_, value) => {
    setPage(value);
  };

  return (
    <Box className={styles.container}>
      <MUIPagination
        className={styles.pages}
        classes={{ text: styles.text }}
        page={page}
        onChange={handleChange}
        count={count}
        boundaryCount={5}
        shape={"rounded"}
      />
    </Box>
  );
};
