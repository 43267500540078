import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { LoadingContainer, Paper, SearchInput, Table } from "@components/new";
import { useTable } from "@hooks";
import { DriverGroupAPI } from "@api";

import { columns, mapper, render } from "./config";
import styles from "./styles.module.scss";

export const DriverGroupList = ({ businessId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const { ref, invoke } = useTable();

  useEffect(() => {
    setIsLoading(true);
    DriverGroupAPI.getDriverGroupForBusiness(businessId)
      .then((data) => setGroups(data.map(mapper)))
      .finally(() => setIsLoading(false));
  }, [businessId]);

  return (
    <Paper className={styles.container}>
      <Box className={styles.header}>
        <Typography className={styles.title}>EV Driver Groups</Typography>
        <Box className={styles.tools}>
          <SearchInput placeholder={"Search"} onChange={invoke("search")} />
        </Box>
      </Box>
      <LoadingContainer loading={isLoading} className={styles.wrapper}>
        <Table ref={ref} columns={columns} data={groups} sortFields={"all"} renderItem={render} />
      </LoadingContainer>
    </Paper>
  );
};
