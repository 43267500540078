import { useState } from "react";
import { toast } from "react-toastify";

const EDIT_STR = "stations inherit the structure of this model. Editing this model is not allowed.";

const DELETE_STR = `stations inherit the structure of this model. 
                    Deleting this model is not allowed.
                    To delete this model remove the station dependencies.`;

export const useActions = () => {
  const [model, setModel] = useState(null);

  const onAction = (model, str) => {
    const isUsed = model.chargerCount > 0;

    if (isUsed) {
      const title = `${model.chargerCount} ${str}`;
      toast.error(title);
    } else {
      setModel(model);
    }

    return !isUsed;
  };

  const onEditAction = (model) => onAction(model, EDIT_STR);

  const onDeleteAction = (model) => onAction(model, DELETE_STR);

  return { model, setModel, onEditAction, onDeleteAction };
};
