import { useState } from "react";

import { FormTextInput } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";

import { defaultValues, fields } from "./config";
import { validationSchema } from "./validation";

export const AddModal = ({ open, onClose, onSave }) => {
  const { register, errors, handleSubmit, control } = useForm({
    open,
    defaultValues,
    validationSchema,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (data) => {
    setIsLoading(true);
    try {
      await onSave(data);
    } finally {
      setIsLoading(false);
    }
  };

  const renderField = ({ label, name }, i) => {
    return (
      <FormTextInput
        key={i}
        label={label}
        error={errors[name]}
        control={control}
        {...register(name)}
      />
    );
  };

  return (
    <ModalContainer
      open={open}
      loading={isLoading}
      onClose={onClose}
      onCancel={onClose}
      title={"Add New Business"}
      onSave={handleSubmit(handleSave)}
    >
      {fields.map(renderField)}
    </ModalContainer>
  );
};
