import { config } from "./config";

const defaults = Object.entries(config).reduce((acc, [key, { fields }]) => {
  const value = fields.reduce((acc, { name }) => {
    return { ...acc, [name]: "" };
  }, {});

  return { ...acc, [key]: value };
}, {});

const getCompanyDetails = (item, defaults) => {
  if (item.companyDetails) {
    return {
      ...item.companyDetails,
      country: item.address.country,
      state: item.address.administrativeArea,
      city: item.address.locality,
      street: item.address.streetAddress,
      buildingNumber: item.address.buildingNumber,
      zipCode: item.address.postCode,
    };
  } else {
    return defaults.companyDetails;
  }
};
export const mapper = (item) => ({
  id: item.businessId,
  companyDetails: getCompanyDetails(item, defaults),
  ownerDetails: item.ownerDetails ?? defaults.ownerDetails,
  contactInfo: item.contactInfo ?? defaults.contactInfo,
  billingInfo: item.billingInfo ?? defaults.billingInfo,
  billingAddress: item.billingAddress ?? defaults.billingAddress,
  registrationStatus: item.registrationStatus,
});
