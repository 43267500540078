export class AuthHelper {
  static parseToken(token) {
    const data = token?.split(".")[1];

    return data ? JSON.parse(atob(data)) : null;
  }

  static getBusiness(token) {
    const data = this.parseToken(token);

    return data?.user.contextBusinessId ?? "";
  }

  static isAdmin(token) {
    const data = this.parseToken(token);

    return data?.role.includes("ROLE_ADMIN");
  }

  static isTokenExpired(token) {
    const data = this.parseToken(token);

    return data ? data.exp * 1000 < Date.now() : true;
  }
}
