import { useState, forwardRef, useImperativeHandle } from "react";
import { toast } from "react-toastify";

import { EmptyPlaceholder, PricingCard, PricingModal } from "@components/pricing";
import { formatPricingSchemesToDTO, mergePricingSchemes } from "@utils/pricing";

import styles from "./styles.module.scss";

const Component = ({ type, pricing, driverGroups, nameKey, editCallback, onSave }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableRule, setEditableRule] = useState(null);

  useImperativeHandle(ref, () => ({ create: () => setIsModalOpen(true) }), []);

  const toggleEditModal = (editItem) => {
    setIsModalOpen(!isModalOpen);
    setEditableRule(editItem);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableRule(null);
  };

  const onDelete = async (itemId) => {
    try {
      const { pricingSchemes, ...rest } = pricing;
      const newSchemes = formatPricingSchemesToDTO(
        pricingSchemes.filter(({ id }) => id !== itemId),
      );
      await editCallback({ ...rest, ...newSchemes });
      onSave();
      toast.success("Success alert: Successfully saved");
    } catch {
      toast.error("Error alert: Something went wrong");
    }
  };

  const convertToDriverGroupFormat = (assignedDriversGroups) => {
    const convertedDriverGroups = assignedDriversGroups.map((assignedDriverGroup) => {
      const driverGroup = assignedDriverGroup.split("###");
      return {
        id: driverGroup[0],
        name: driverGroup[1],
      };
    });
    return convertedDriverGroups;
  };

  const save = async (newData) => {
    try {
      const { pricingSchemes, ...rest } = pricing;
      const pricingSchemesConv = pricingSchemes.map((pricingScheme) => {
        return {
          ...pricingScheme,
          assignedDriverGroups: convertToDriverGroupFormat(pricingScheme.assignedDriverGroups),
        };
      });
      const data = mergePricingSchemes(pricingSchemesConv, newData);
      await editCallback({ ...rest, ...data });
      onSave();
      toast.success("Success alert: Successfully saved");
    } catch (e) {
      if (e?.response?.data?.message?.indexOf("Duplicate entry") >= 0) {
        toast.error("Error alert: Duplicate Rule Name. Rule Name should be Unique");
      } else {
        toast.error("Error alert: Something went wrong");
      }
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <fieldset className={styles.container}>
        <legend className={styles.legend}>
          {type} - {pricing[nameKey]}
        </legend>
        <EmptyPlaceholder
          className={styles.body}
          onClick={() => setIsModalOpen(true)}
          title={"No associated pricing rules applied"}
          isEmpty={pricing?.pricingSchemes.length === 0}
        >
          {pricing?.pricingSchemes
            .sort((a, b) => a.price - b.price)
            .map((item, i) => (
              <PricingCard
                key={i}
                pricingItem={item}
                onEdit={toggleEditModal}
                onDelete={onDelete}
              />
            ))}
        </EmptyPlaceholder>
      </fieldset>
      <PricingModal
        type={type}
        save={save}
        open={isModalOpen}
        onClose={closeModal}
        pricingScheme={editableRule}
        driverGroups={driverGroups}
        title={pricing ? pricing[nameKey] : ""}
      />
    </>
  );
};

export const PricingComponent = forwardRef(Component);
