import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { StyledEngineProvider } from "@mui/material/styles";
import { createRoot } from "react-dom/client";

import { Router } from "@navigation";
import { AuthProvider, BusinessProvider } from "@providers";

import "react-toastify/dist/ReactToastify.css";

import "~styles/global.scss";

const root = createRoot(document.getElementById("root"));
root.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <AuthProvider>
        <BusinessProvider>
          <Router />
        </BusinessProvider>
      </AuthProvider>
      <ToastContainer />
    </BrowserRouter>
  </StyledEngineProvider>,
);
