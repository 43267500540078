export const fields = [
  {
    type: "textarea",
    label: "Question",
    name: "question",
  },
  {
    type: "textarea",
    label: "Answer",
    name: "answer",
  },
  {
    type: "select",
    label: "Language",
    name: "language",
  },
];

export const languages = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "he",
    label: "Hebrew",
  },
  {
    value: "ru",
    label: "Russian",
  },
];

export const defaultValues = {
  question: "",
  answer: "",
  language: "",
};
