import { Box } from "@mui/material";

import { Paper, Table, Tabs } from "@components/new";
import { DateHelper } from "@utils";

import { columns } from "./config";
import styles from "./styles.module.scss";

export const Faults = ({ data, connectors, pagination, onConnectorPress }) => {
  const renderItem = (item, field) => {
    const value = item[field];

    if (field === "faultTime") {
      return DateHelper.formatDateToLocal(item[field]);
    }

    return value;
  };

  const tabs = connectors?.map(({ connectorNumber }) => `Connector ${connectorNumber}`);

  return (
    <Box className={styles.column}>
      <Tabs tabs={tabs} onChange={onConnectorPress} />
      <Paper className={styles.column}>
        <Box className={styles.title}>Fault Information</Box>
        <Table data={data} columns={columns} renderItem={renderItem} {...pagination} />
      </Paper>
    </Box>
  );
};
