export const forgotData = {
  username: {
    capital: "Username",
    action: "remind",
    successTitle: "Username reminder",
    url: "/",
  },
  password: {
    capital: "Password",
    action: "reset",
    successTitle: "One Time Password",
    url: "/reset-password",
  },
};
