import { api } from "./axios";

export class ChargeStationAPI {
  static get({ siteId, page, size }) {
    return api.get("chargestation", { params: { siteId, page, size } });
  }

  static getSimpleList() {
    return api.get("chargestation/simple-list");
  }

  static getInUse() {
    return api.get("chargestation/inuse");
  }

  static getById(id) {
    return api.get(`/chargestation/${id}/details`);
  }

  static getConfigById(id) {
    return api.get(`/chargestation/config/${id}`);
  }

  static getModels() {
    return api.get("chargestation/models");
  }

  static async getImages(id) {
    return api.get(`chargestation/${id}/images`);
  }

  static uploadImage(id, payload) {
    return api.post(`chargestation/${id}/images`, payload, {
      headers: { "content-type": "multipart/form-data" },
    });
  }

  static editImage(stationId, imageId, payload) {
    return api.put(`chargestation/${stationId}/images/${imageId}`, payload, {
      headers: { "content-type": "multipart/form-data" },
    });
  }

  static async create(payload) {
    const chargeStationConfigId = await this.createConfig();

    const body = { chargeStationConfigId, ...payload };

    return api.post("chargestation", body);
  }

  static createConfig() {
    const config = {
      password: "",
      ip: "",
      gateway: "",
      mac: "",
      mask: "",
      dns: "",
      host: "",
      version: "",
      dynamicLoad: true,
      g_4GAPN: "",
      g_4GPassword: "",
      g_4GUserName: "",
      g_Authentication: "",
      g_AutoChargeTime: "",
      g_BleName: "",
      g_BlePassword: "",
      g_CardPin: "",
      g_ChargerLanguage: "",
      g_ChargerMode: "",
      g_ExternalLimitPower: "",
      g_ExternalLimitPowerEnable: "",
      g_ExternalSamplingCurWing: "",
      g_HeartbeatInterval: "",
      g_LCDCLoseEnable: "",
      g_LowPowerReserveEnable: "",
      g_MaxCurrent: "",
      g_MaxTemperature: "",
      g_MeterValueInterval: "",
      g_NetType: "",
      g_NetworkMode: "",
      g_OffPeakEnable: "",
      g_PeakValleyEnable: "",
      g_PeriodTime: "",
      g_PowerMeterAddr: "",
      g_PowerMeterType: "",
      g_RCDProtection: "",
      g_SolarBoost: "",
      g_SolarLimitPower: "",
      g_SolarMode: "",
      g_TimeZone: "",
      g_WebsocketPingInterval: "",
      g_WifiSSID: "",
    };

    return api.post("chargestation/config", config);
  }

  static createImage(payload) {
    return api.post("chargestation/images", payload, {
      headers: { "content-type": "multipart/form-data" },
    });
  }

  static createModel(payload) {
    return api.post("chargestation/model", payload);
  }

  static async update(id, payload) {
    return api.patch(`chargestation/${id}`, payload);
  }

  static updateConfig(id, payload) {
    return api.patch(`chargestation/config/${id}`, payload);
  }

  static updateModel(id, payload) {
    return api.put(`chargestation/model/${id}`, payload);
  }

  static deleteMultiple(ids) {
    return api.delete("chargestation", { params: { ids } });
  }

  static deleteImages(id, imagesIds) {
    return api.delete(`chargestation/${id}/images`, {
      params: { ids: imagesIds.join(",") },
    });
  }

  static deleteModelMultiple(ids) {
    return api.delete("chargestation/model", { params: { ids } });
  }
}
