export const firmwareMapper = ({ chargeStationList, ...rest }) => {
  const vendor = chargeStationList[0]?.vendor;
  const models = chargeStationList.map(modelMapper);

  return {
    id: rest.firmwareId,
    fileName: rest.fileName,
    version: rest.version,
    createdAt: rest.createdAt,
    vendor,
    models,
    checked: false,
  };
};

const modelMapper = (item) => ({
  id: item.modelId,
  name: item.modelName,
});

export const optionsMapper = (options) => {
  const vendors = [...new Set(options.map(({ vendor }) => vendor))].map((vendor) => ({
    label: vendor,
    value: vendor,
  }));

  const models = options.reduce((acc, { vendor, modelName, chargeStationModelId }) => {
    const value = { label: modelName, value: chargeStationModelId };

    if (acc[vendor]) acc[vendor].push(value);
    else acc[vendor] = [value];

    return acc;
  }, {});

  return { vendors, models };
};
