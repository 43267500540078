import { useState } from "react";
import { toast } from "react-toastify";

import { FormTextInput } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";

import { validationSchema } from "./validation";

const initialValues = {
  name: "",
};

export const ManageModal = ({ open, group, onSave, onClose, titles }) => {
  const defaultValues = group || initialValues;
  const title = group ? titles.edit : titles.add;

  const { register, errors, isValid, control, handleSubmit } = useForm({
    open,
    defaultValues,
    validationSchema,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (data) => {
    setIsLoading(true);
    try {
      await onSave(data, group?.id);
      onClose();
    } catch ({ response }) {
      toast.error(response.data);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer
      open={open}
      title={title}
      onClose={onClose}
      onCancel={onClose}
      loading={isLoading}
      saveDisabled={!isValid}
      onSave={handleSubmit(handleSave)}
    >
      <FormTextInput
        label={"Group Name"}
        control={control}
        error={errors.name}
        {...register("name")}
      />
    </ModalContainer>
  );
};
