import { Box, Typography } from "@mui/material";

import classes from "./styles.module.css";

export const PageTitle = ({ text, subtitle }) => {
  return (
    <Box className={classes.page_title}>
      <Typography className={classes.main_title}>{text}</Typography>
      <Typography className={classes.subtitle}>{subtitle}</Typography>
    </Box>
  );
};
