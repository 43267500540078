import { DateHelper } from "@utils";

export const mapper = (item) => ({
  id: item.transactionId,
  sessionId: item.transactionIdentifier.toString(),
  user: item?.user?.userName,
  station: item.stationName,
  start: item.startTimeOfCharge ? DateHelper.formatDateToLocal(item.startTimeOfCharge) : "NA",
  end: item.endTimeOfCharge ? DateHelper.formatDateToLocal(item.endTimeOfCharge) : "NA",
  cost: (item.cost || 0).toFixed(2),
  billed: (item.billed || 0).toFixed(2),
  energy: (item.energy || 0).toFixed(2),
  vehicle: item.vehicleManufacturerName,
  numberPlate: item.vehicleRegistration,
  history: item.paymentResults.map(historyItemMapper),
  kwhPrice: item.kwhPrice ? item.kwhPrice : 0.0,
  pricingRuleName: item.pricingRuleName ? item.pricingRuleName : "Base Price",
  rfid: item.paymentMethod === "RFID" ? item.rfid : "NA",
});

const historyItemMapper = (item) => ({
  id: item.resultId,
  cost: item.amount,
  status: item.status,
  operation: item.operation,
  initiatorId: item.initiatorId,
  timestamp: item.timestamp,
  refundable: item.refundable,
});
