export const mapper = (item) => ({
  id: item.uuid,
  name: item.name,
  createdAt: item.createdAt,
  updatedAt: item.updatedAt,
});

export const columns = [
  {
    label: "Group Name",
    field: "name",
  },
  {
    label: "Created",
    field: "createdAt",
  },
  {
    label: "Updated",
    field: "updatedAt",
  },
];

export const sortFields = ["name", "createdAt"];
