export const fields = [
  {
    label: "User Name",
    field: "userName",
  },
  {
    label: "Email",
    field: "email",
  },
  {
    label: "First Name",
    field: "firstName",
  },
  {
    label: "Phone Number",
    field: "phoneNumber",
  },
  {
    label: "Last Name",
    field: "lastName",
  },
  {
    label: "Registration",
    field: "registrationComplete",
  },
  {
    label: "Creation Date",
    field: "createdAt",
  },
  {
    label: "Last Modified Date",
    field: "updatedAt",
  },
  {
    label: "Last Login Date",
    field: "lastLoginAt",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Registration Status",
    field: "isDeleted",
  },
];
