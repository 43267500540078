import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import cx from "classnames";

import { TextInput } from "@components/new";

import { FormField } from "../FormField";

import styles from "./styles.module.scss";

const Component = (
  { name, disabled, type, label, onFocus, onChange, placeholder, control, error },
  _,
) => {
  const classes = cx(styles.input, !!error && styles.error);

  const handleChange = (onFieldChange) => {
    return (e) => {
      onFieldChange(e);
      onChange?.call(null, e);
    };
  };

  const render = ({ field }) => {
    const { value, onChange } = field;

    return (
      <FormField label={label} error={error}>
        <TextInput
          type={type}
          value={value}
          onFocus={onFocus}
          disabled={disabled}
          inputClassName={classes}
          placeholder={placeholder}
          onChange={handleChange(onChange)}
        />
      </FormField>
    );
  };

  return <Controller name={name} control={control} render={render} />;
};

export const FormTextInput = forwardRef(Component);
