import { useReducer } from "react";

const AMOUNT_PER_PAGE = 25;

const initials = {
  page: 1,
  pageCount: 0,
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

export const usePagination = (request) => {
  const [state, dispatch] = useReducer(reducer, initials);

  const fetch = async () => {
    const { items, pages } = await request({ page: state.page, size: AMOUNT_PER_PAGE });

    dispatch({ pageCount: pages });

    return items;
  };

  const onPageChange = (page) => {
    dispatch({ page });
  };

  return { ...state, fetch, onPageChange };
};
