import { EntityList } from "@components/new";
import { useAuth } from "@providers";
import { RoleAPI } from "@api";

import { config } from "./config";

export const RoleList = ({ userId }) => {
  const titles = {
    sectionTitle: "Roles",
    plural: "role(s)",
    unassignTitle: "Unassign Roles",
    assignTitle: "Assign Roles",
    assignModalTitle: "Assign roles to user",
  };

  const functions = {
    getAssignedItems: (userId) => RoleAPI.getRolesForUser(userId),
    getAvailableItems: () => RoleAPI.getDefined(),
    onAssign: (userId, toAssign) => RoleAPI.assignRoles(userId, toAssign),
    onUnassign: (userId, toDelete) => RoleAPI.deleteRolesForUser(userId, toDelete),
  };

  const { user, isAdmin } = useAuth();

  return (
    <EntityList
      parentId={userId}
      config={config}
      titles={titles}
      actions={functions}
      disabled={!isAdmin || userId === user?.userId}
    />
  );
};
