import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BiSupport } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";

import { BreadcrumbsContainer, PageTitle, LoadingContainer } from "@components/common";
import { Table } from "@components/common/Table/table";
import { useAuth } from "@providers";
import { UserAPI } from "@api";

import { UserForm } from "../UserForm";

import { TicketModal } from "./TicketModal";
import { mapper, columns, config } from "./config";

export const UserList = () => {
  const { user, getUser } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const [ticketModal, setTicketModal] = useState({
    open: false,
    userName: "",
    userId: "",
    isUser: true,
  });
  const toggleTicketModal = (user) => {
    setTicketModal((modal) => ({
      open: !modal.open,
      userName: user?.userName || modal.userName,
      id: user?.id || modal.id,
      isUser: true,
    }));
  };

  const [form, setForm] = useState({ open: false, type: "Add", user: null });
  const toggleForm = (type, user) => {
    setForm((form) => ({ open: !form.open, type: type || form.type, user }));
  };

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setIsLoading(true);
    try {
      const data = await UserAPI.getUsers();
      setUsers(data.map(mapper));
    } finally {
      setIsLoading(false);
    }
  };

  const onSave = async (data) => {
    try {
      if (form.type === "add") await UserAPI.createAdmin(data);
      else {
        await UserAPI.updateAdmin(form.user.id, data);
        form.user.id === user.userId && (await getUser());
      }
      toast.success("Success alert: Successfully saved");
      toggleForm("add", null);
      init();
    } catch {
      toast.error("Error alert: Something went wrong");
    }
  };

  const menuItems = [
    {
      label: "Edit",
      icon: FaEdit,
      onClick: (item) => toggleForm("Edit", item),
    },
    {
      label: "Support",
      icon: BiSupport,
      onClick: toggleTicketModal,
    },
  ];

  return (
    <>
      <LoadingContainer loading={isLoading}>
        <BreadcrumbsContainer source={"User Management"} pages={["User List"]} />
        <PageTitle text={"User List"} subtitle={`${users && users.length} users`} />
        <Table
          data={users}
          setData={setUsers}
          columns={columns}
          config={config}
          filterEnabled={false}
          primaryKey={"id"}
          menuItems={menuItems}
          addButtonLabel={"Add User"}
          csvFileName={"users"}
          onAddButtonClick={() => toggleForm("Add")}
          deleteCallback={UserAPI.deleteMultiple}
        />
      </LoadingContainer>
      <UserForm currentUser={user} {...form} onClose={() => toggleForm()} onSave={onSave} />
      <TicketModal {...ticketModal} onClose={() => toggleTicketModal()} />
    </>
  );
};
