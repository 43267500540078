export const editConfig = [
  {
    name: "userName",
    label: "User Name",
  },
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
  },
  {
    name: "email",
    label: "Email",
  },
];

export const addConfig = editConfig.concat({ name: "password", label: "Password" });
