export const columns = [
  { label: "Name", field: "name" },
  { label: "Charge Site", field: "siteNames" },
  { label: "Email", field: "email" },
];

export const filters = [
  { name: "Name", field: "name" },
  { name: "Charge Site", field: "siteNames" },
];
