import { createContext, useContext, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";

import { ChargeStationAPI, TransactionAPI } from "@api";

import { stationMapper, transactionsMapper } from "./mappers";

const TIMER = 60e3;

const initials = {
  station: null,
  images: [],
  runningTransactions: [],
  refresh: Promise.resolve,
  timer: new Date().getTime() + TIMER,
};

const StationContext = createContext(initials);

const reducer = (station, action) => ({ ...station, ...action });

export const StationDetailsProvider = ({ children }) => {
  const { stationId } = useParams();

  const [state, dispatch] = useReducer(reducer, initials);

  const fetchStationData = async () => {
    const [data, transactions] = await Promise.all([
      ChargeStationAPI.getById(stationId),
      TransactionAPI.getRunningByStation(stationId),
    ]);

    const station = stationMapper(data);
    const runningTransactions = transactionsMapper(transactions || []);

    dispatch({ station, runningTransactions });
  };

  const refresh = () => {
    fetchStationData();
    dispatch({ timer: new Date().getTime() + TIMER });
  };

  useEffect(() => {
    fetchStationData();

    const interval = setInterval(refresh, TIMER);

    return () => {
      clearInterval(interval);
    };
  }, [stationId]);

  const value = { ...state, refresh };

  return <StationContext.Provider value={value}>{children}</StationContext.Provider>;
};

export const useStationDetails = () => useContext(StationContext);
