export const reducer = (acc, { id, operationalStatus }) => {
  return acc.set(id, operationalStatus);
};

export const mapper = (item) => ({
  id: item.connectorId,
  number: item.connectorNumber,
  nominalPower: item.powerRating,
  type: item.connectorTypeName,
  operationalStatus: item.operationalStatus,
  connectorStatus: item.connectorStatus,
});

export const columns = [
  {
    label: "Connector Number",
    field: "number",
  },
  {
    label: "Nominal Power KW",
    field: "nominalPower",
  },
  {
    label: "Power Rating KWh",
    field: "powerRating",
  },
  {
    label: "Connector Type",
    field: "type",
  },
  {
    label: "Connector Status",
    field: "connectorStatus",
  },
  {
    label: "Operational Status",
    field: "operationalStatus",
  },
  {
    label: "",
    field: "buttons",
  },
];
