export const filter = (data, filters) => {
  return data.filter((item) => {
    return filters.reduce((acc, { field, options }) => {
      const value = item[field];

      if (Array.isArray(value)) {
        return value.reduce((acc, item) => acc || options[item], false);
      }
      if (field === "customDate") {
        const { startDate, endDate } = options;
        const startDateLong = startDate._d.getTime() / 1000;
        const endDateLong = endDate._d.getTime() / 1000;
        if (startDateLong <= item.startDate && item.startDate <= endDateLong) {
          return true;
        } else {
          return false;
        }
      }
      return acc && options[value];
    }, true);
  });
};

export const search = (data, text, searchFields) => {
  return data.filter((item) => {
    let entries = Object.entries(item);

    if (searchFields) {
      entries = entries.filter(([key]) => searchFields.includes(key));
    }

    return entries.some(([_, value]) => {
      return value?.toString().toLowerCase().includes(text.toLowerCase());
    });
  });
};
