import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { CommentModal, TicketCommentsModal, UpdateStatusModal } from "@components/modals";
import { Paper, SearchInput, Table } from "@components/new";
import { useTable } from "@hooks";
import { SupportAPI } from "@api";

import { columns, render, ticketMapper } from "./config";
import styles from "./styles.module.scss";

export const SupportTicketList = ({ driverId }) => {
  const { ref, invoke } = useTable();

  const [modal, setModal] = useState({
    add: false,
    edit: false,
    view: false,
    comment: false,
    ticket: undefined,
  });

  const [refresh, setRefresh] = useState(false);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    if (driverId !== undefined) {
      SupportAPI.getTicketsByDriver(driverId).then((tickets) => {
        setTickets(tickets.map(ticketMapper));
      });
    }
  }, [driverId, refresh]);

  const save = () => {
    setRefresh(!refresh);
  };

  const toggleModal = (type, ticket) => {
    return () => setModal((modal) => ({ ...modal, ticket, [type]: !modal[type] }));
  };

  const menuItems = [
    (ticket) => ({ label: "Update status", onClick: toggleModal("edit", ticket) }),
    (ticket) => ({ label: "Add Comment", onClick: toggleModal("comment", ticket) }),
    (ticket) => ({ label: "View Comments", onClick: toggleModal("view", ticket) }),
  ];

  return (
    <>
      <Paper className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>Support Tickets</Typography>
          <Box className={styles.tools}>
            <SearchInput placeholder={"Search"} onChange={invoke("search")} />
          </Box>
        </Box>
        <Table
          ref={ref}
          columns={columns}
          data={tickets}
          sortFields={"all"}
          renderItem={render}
          actions={menuItems}
        />
      </Paper>
      <UpdateStatusModal
        open={modal.edit}
        ticket={modal.ticket}
        onSave={save}
        onClose={toggleModal("edit")}
      />
      <CommentModal open={modal.comment} ticket={modal.ticket} onClose={toggleModal("comment")} />
      <TicketCommentsModal open={modal.view} ticket={modal.ticket} onClose={toggleModal("view")} />
    </>
  );
};
