export const stationMapper = (item) => ({
  id: item.id,
  name: item.name,
  chargerId: item.chargerId,
  siteId: item.site.id,
  siteName: item.site.name,
  basePrice: item.price.toFixed(2),
  modelId: item.model.id,
  modelName: item.model.name,
  vendor: item.model.vendor,
  businessId: item.business.id,
  businessName: item.business.name,
  hardwareStatus: item.hardwareStatus,
  operationalStatus: item.operationalStatus,
  powerRating: item.powerRating,
  connectors: item.connectors.map(({ connectorStatus, ...rest }) => ({
    ...rest,
    connectorStatus: item.hardwareStatus ? connectorStatus : "Unavailable",
  })),
  createdAt: item.createdAt,
  lastModified: item.lastModified,
  lastHeartBeat: item.lastHeartBeat,
});

export const transactionsMapper = (items) => {
  return items.reduce((acc, item) => acc.set(item.connectorId, item), new Map());
};
