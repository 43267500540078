import { useEffect, useState } from "react";
import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { FaEdit, FaCommentDots, FaComments } from "react-icons/fa";

import { CommentModal, TicketCommentsModal, UpdateStatusModal } from "@components/modals";
import { Button, Filter, LoadingContainer, Paper, SearchInput, Table } from "@components/new";
import { usePagination, useTable } from "@hooks";
import { SupportAPI } from "@api";

import { TicketModal } from "./TicketModal";
import { mapper, columns, render, sortFields, config } from "./config";
import { filters } from "./config";
import styles from "./styles.module.scss";

export const SupportTicket = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const { ref, invoke } = useTable();

  const [modal, setModal] = useState({
    add: false,
    edit: false,
    view: false,
    comment: false,
    ticket: undefined,
  });

  const { fetch, ...pagination } = usePagination(SupportAPI.getAllTickets);

  const toggleModal = (type) => {
    return (ticket) => setModal((modal) => ({ ...modal, ticket, [type]: !modal[type] }));
  };

  const init = async () => {
    setIsLoading(true);
    try {
      const data = await fetch();
      setTickets(data.map(mapper));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [pagination.page]);

  const actions = [
    (ticket) => ({
      label: "Update status",
      icon: FaEdit,
      onClick: () => toggleModal("edit")(ticket),
    }),
    (ticket) => ({
      label: "Add Comment",
      icon: FaCommentDots,
      onClick: () => toggleModal("comment")(ticket),
    }),
    (ticket) => ({
      label: "View Comments",
      icon: FaComments,
      onClick: () => toggleModal("view")(ticket),
    }),
  ];

  const filterConfig = useMemo(() => {
    return filters.map((item) => {
      return item;
    });
  }, [filters]);

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>Support Tickets</Typography>
          <Button variant={"contained"} text={"Add Ticket"} onClick={toggleModal("add")} />
        </Box>
        <Paper className={styles.body}>
          <Box className={styles.tools}>
            <SearchInput
              placeholder={"Search by UserName, Role, ...... "}
              onChange={invoke("search")}
            />
            <Filter data={tickets} config={filterConfig} onChange={invoke("filter")} />
          </Box>
          <Table
            ref={ref}
            data={tickets}
            columns={columns}
            renderItem={render}
            actions={actions}
            sortFields={sortFields}
            config={config}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <TicketModal open={modal.add} onClose={toggleModal("add")} onSave={init} />
      <UpdateStatusModal
        open={modal.edit}
        ticket={modal.ticket}
        onSave={init}
        onClose={toggleModal("edit")}
      />
      <CommentModal open={modal.comment} ticket={modal.ticket} onClose={toggleModal("comment")} />
      <TicketCommentsModal open={modal.view} ticket={modal.ticket} onClose={toggleModal("view")} />
    </>
  );
};
