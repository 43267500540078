export const reducer = (acc, { id, status }) => {
  return acc.set(id, status);
};

export const columns = [
  {
    label: "Username",
    field: "userName",
  },
  {
    label: "First name",
    field: "firstName",
  },
  {
    label: "Last Name",
    field: "lastName",
  },
  {
    label: "Phone Number",
    field: "phoneNumber",
  },
  {
    label: "Email",
    field: "email",
  },
  {
    label: "Registration",
    field: "enabled",
  },
  {
    label: "Registration Status",
    field: "isDeleted",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Creation Date",
    field: "createdAt",
  },
  {
    label: "Last Modified Date",
    field: "updatedAt",
  },
  {
    label: "Last Login Date",
    field: "lastLoginAt",
  },
];

export const sortFields = [
  "userName",
  "firstName",
  "lastName",
  "createdAt",
  "updatedAt",
  "lastLoginAt",
];
