import React from "react";
import { Typography } from "@mui/material";

import styles from "./styles.module.css";
import { useCountdown } from "./useCountdown";

const ShowCounter = ({ minutes, seconds }) => {
  return <Typography className={styles.value}>{`${minutes} Mins : ${seconds} Seconds`}</Typography>;
};

export const CountdownTimer = ({ targetDate }) => {
  const [minutes, seconds] = useCountdown(targetDate);
  return <ShowCounter minutes={minutes} seconds={seconds} />;
};
