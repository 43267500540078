export const fields = [
  {
    label: "RFID",
    field: "rfid",
  },
  {
    label: "Driver Name",
    field: "driverName",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Activation Date",
    field: "activationDate",
  },
  {
    label: "Expiration Date",
    field: "expirationDate",
  },
  {
    label: "Last Modified Date",
    field: "creationDate",
  },
];
