import { DateTime } from "@components/new";

export const columns = [
  {
    label: "Support Id",
    field: "id",
  },
  {
    label: "Description",
    field: "description",
  },
  {
    label: "Station Name",
    field: "station",
  },
  {
    label: "Support Status",
    field: "status",
  },
  {
    label: "Support Type",
    field: "type",
  },
  {
    label: "Creation Time",
    field: "createdAt",
  },
];

export const ticketMapper = (item) => ({
  id: item.numericId,
  description: item.description,
  status: item.supportStatus || "-",
  station: item.chargeStation?.chargeStationName || "-",
  type: item.supportType?.name || "-",
  createdAt: item.creationTime,
});

export const render = (item, key) => {
  const value = item[key];

  if (key === "createdAt") {
    return <DateTime value={value} twoLine={true} />;
  }

  return value;
};
