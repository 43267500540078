import { DateHelper } from "@utils";

export const mapper = (item) => ({
  id: item.supportReference,
  description: item.description,
  status: item.supportStatus || "-",
  station: item.chargeStation?.chargeStationName || "-",
  type: item.supportType?.name || "-",
  createdAt: item.creationTime,
});

export const render = (item, key) => {
  const value = item[key];

  if (key === "createdAt") {
    return DateHelper.formatDateToLocal(value);
  }

  return value;
};

export const columns = [
  {
    name: "SUPPORT REFERENCE",
    field: "id",
  },
  {
    name: "DESCRIPTION",
    field: "description",
  },
  {
    name: "STATION NAME",
    field: "station",
  },
  {
    name: "SUPPORT STATUS",
    field: "status",
  },
  {
    name: "SUPPORT TYPE",
    field: "type",
  },
  {
    name: "CREATION TIME",
    field: "createdAt",
  },
];

const filterConfig = [];

const csvConfig = {
  head: [
    "Support Reference",
    "Description",
    "Station Name",
    "Support Status",
    "Support Type",
    "Creation Time",
  ],
  body: (item) => [
    item.id,
    item.description,
    item.station,
    item.status,
    item.type,
    DateHelper.formatDateToLocal(item.createdAt),
  ],
};

const searchFields = columns.map(({ field }) => field);

export const config = { filterConfig, csvConfig, searchFields };
