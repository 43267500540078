import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Stack } from "@mui/material";

import { ConfirmationModal } from "@components/modals";
import { Button, Filter, LoadingContainer, Paper, SearchInput, Table, Link } from "@components/new";
import { useBusiness } from "@providers";
import { useConfirmation, usePagination, useTable } from "@hooks";
import { DateHelper, deleteHandler } from "@utils";
import { ChargeSiteAPI } from "@api";

import { FormModal } from "../FormModal";

import { columns, filters, sortFields } from "./config";
import { siteMapper } from "./mappers";
import styles from "./styles.module.scss";

const actions = {
  delete: ({ id }) => deleteHandler("Site", ChargeSiteAPI.deleteMultiple, id),
};

const texts = {
  delete: ({ siteName }) => `Are you sure you want to delete site ${siteName}?`,
};

export const SiteList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sites, setSites] = useState([]);

  const [form, setForm] = useState({ open: false, type: "", site: null });

  const navigate = useNavigate();

  const { ref, invoke } = useTable();

  const { fetch, ...pagination } = usePagination(ChargeSiteAPI.get);

  const toggleForm = (type, site) => {
    setForm((form) => ({ open: !form.open, type: type || form.type, site }));
  };

  const { options: businesses } = useBusiness();
  const { isVisible, open, ...confirmation } = useConfirmation(actions, texts);

  const init = async () => {
    setIsLoading(true);
    try {
      const sites = await fetch();
      setSites(sites.map(siteMapper));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [pagination.page]);

  const renderItem = (item, key) => {
    const value = item[key];

    switch (key) {
      case "siteName": {
        return <Link text={value} path={`${item.id}/details`} />;
      }
      case "createdAt": {
        return DateHelper.formatDateToLocal(value);
      }
      default: {
        return value;
      }
    }
  };

  const menu = [
    ({ id }) => ({
      label: "Stations",
      onClick: () => navigate(`/charger-management/charger-list?siteId=${id}`),
    }),
    (item) => ({ label: "Edit", onClick: () => toggleForm("Edit", item) }),
    ({ id }) => ({ label: "Pricing", onClick: () => navigate(`${id}/pricing`) }),
    (item) => ({ label: "Delete", onClick: () => open("delete", item, init) }),
  ];

  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography className={styles.title}>Site List</Typography>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Button variant={"contained"} text={"Add New Site"} onClick={() => toggleForm("Add")} />
          </Stack>
        </Stack>
        <Paper className={styles.paper}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <SearchInput onChange={invoke("search")} />
            <Filter data={sites} config={filters} onChange={invoke("filter")} />
          </Stack>
          <Table
            ref={ref}
            data={sites}
            actions={menu}
            columns={columns}
            sortFields={sortFields}
            renderItem={renderItem}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <ConfirmationModal open={isVisible} {...confirmation} />
      <FormModal {...form} businesses={businesses} onClose={() => toggleForm()} save={init} />
    </>
  );
};
