export const columns = [
  { label: "Charger ID", field: "chargerId" },
  { label: "Station Name", field: "stationName" },
  { label: "Site Name", field: "siteName" },
  { label: "Connector Number", field: "connectorNumber" },
  { label: "Fault Message", field: "message" },
  { label: "Current Connector Status", field: "connectorStatus" },
  { label: "Time of Fault", field: "faultTime" },
  { label: "Fault Duration", field: "faultDuration" },
];
