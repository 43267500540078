import { Box, Typography } from "@mui/material";
import cx from "classnames";

import { DateTime, Filter, Link, Paper, SearchInput, Table } from "@components/new";
import { useTable } from "@hooks";
import { DateHelper } from "@utils";

import { columns, filters } from "./config";
import styles from "./styles.module.scss";

export const Transactions = ({ transactions, pagination, className }) => {
  const classes = cx(styles.container, className);

  const { ref, invoke } = useTable();

  const renderItem = (item, field) => {
    const value = item[field];

    switch (field) {
      case "identifier": {
        const path = `/charger-management/transaction-list/${item.id}`;

        return <Link text={value} path={path} />;
      }
      case "username": {
        const path = `/user-management/driver-list/${item.userId}/details`;

        return <Link text={value} path={path} />;
      }
      case "startDate":
      case "endDate": {
        return value ? <DateTime value={value} twoLine /> : "-";
      }
      case "connectionTime": {
        return value ? DateHelper.getDurationStringFromSeconds(value) : "-";
      }
      case "chargingTime": {
        return DateHelper.getDurationString(value);
      }
      default: {
        return value;
      }
    }
  };

  return (
    <Paper className={classes}>
      <Box className={styles.header}>
        <Typography className={styles.title}>Transactions</Typography>
        <Box className={styles.tools}>
          <SearchInput placeholder={"Search"} onChange={invoke("search")} />
          <Filter
            data={transactions}
            config={filters}
            dateRange={true}
            onChange={invoke("filter")}
          />
        </Box>
      </Box>
      <Table
        ref={ref}
        columns={columns}
        data={transactions}
        sortFields={"all"}
        renderItem={renderItem}
        {...pagination}
      />
    </Paper>
  );
};
