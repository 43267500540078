export const fields = [
  {
    name: "otp",
    placeholder: "OTP",
  },
  {
    name: "newPassword",
    placeholder: "New password",
  },
  {
    name: "confirmPassword",
    placeholder: "Confirm password",
  },
];
