import { useState, useEffect, useContext, createContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthHelper } from "@utils";
import { AuthAPI, BusinessAPI } from "@api";

import { useAuth } from "./auth";

const BusinessContext = createContext({
  updateKey: null,
  business: "",
  options: [],
  select: () => {},
});

export const BusinessProvider = ({ children }) => {
  const [options, setOptions] = useState([]);
  const [business, setBusiness] = useState("");

  const [updateKey, setUpdateKey] = useState(null);

  const { user } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!user?.userId) {
      setOptions([]);
      return;
    }

    BusinessAPI.getForCurrentUser().then((data) => {
      const businesses = data.map(({ id, name }) => ({ label: name, value: id }));
      setOptions(businesses);
    });
  }, [user?.userId]);

  useEffect(() => {
    if (!options.length) return;

    const storedBusinessId = localStorage.getItem("business");
    const tokenBusinessId = AuthHelper.getBusiness(token);
    const defaultBusinessId = storedBusinessId || tokenBusinessId;

    setBusiness(defaultBusinessId);
  }, [token, options]);

  const select = async (businessId) => {
    restrictToSubSectionWhenBusinessChange(pathname);
    setBusiness(businessId);
    localStorage.setItem("business", businessId);
    await AuthAPI.refreshBusinessToken(businessId);
    setUpdateKey(Math.random());
  };

  const restrictToSubSectionWhenBusinessChange = (pathname) => {
    const pathLength = pathname.match(/\//gi).length;
    const pathArray = [];
    if (pathLength > 2) {
      const messageArray = pathname.split("/");
      let maxValue = 2;
      messageArray.forEach((name) => {
        if (name && maxValue > 0) {
          pathArray.push(name);
          maxValue--;
        }
      });
      navigate("/" + pathArray.join("/"));
    }
  };

  const value = { updateKey, business, options, select };

  return <BusinessContext.Provider value={value}>{children}</BusinessContext.Provider>;
};

export const useBusiness = () => useContext(BusinessContext);
