import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Box, List, ListItem, Typography, ClickAwayListener } from "@mui/material";

import { FormTextInput } from "@components/form/new";
import { Map } from "@components/new";
import { useMapSuggestions } from "@hooks";
import { debounce } from "@utils/debounce";

import { AddressForm } from "./AddressForm";
import styles from "./styles.module.scss";

export const CustomAddress = () => {
  const [isListShown, setIsListShown] = useState(false);

  const { suggestions, geocode, reverseGeocode, setAddress } = useMapSuggestions();
  const {
    address,
    register,
    errors,
    control,
    toggleAddress,
    setValue,
    updateAddress,
    isAddressManual,
  } = useFormContext();

  const selectAddress = async (placeId) => {
    setIsListShown(false);
    const { name, result } = await geocode(placeId);
    updateAddress(result);
    setValue("address", name);
  };

  const onMapAdjust = async (location) => {
    const { name, result } = await reverseGeocode(location);
    updateAddress(result);
    setValue("address", name);
  };

  const handleAddressChange = (onChange) => {
    return (e) => {
      onChange(e);
      setAddress(e.target.value);
    };
  };

  const setField = () => {
    const { onChange, ...rest } = register("address");

    return { onChange: debounce(handleAddressChange(onChange)), ...rest };
  };

  const renderItem = ({ place_id, description }) => {
    return (
      <ListItem
        key={place_id}
        className={styles.suggestion}
        onClick={() => selectAddress(place_id)}
      >
        <Typography className={styles.title}>{description}</Typography>
      </ListItem>
    );
  };

  const autoFill = (
    <>
      <ClickAwayListener onClickAway={() => setIsListShown(false)}>
        <Box className={styles.autofill}>
          <FormTextInput
            label={"Address"}
            control={control}
            error={!!errors.address}
            onFocus={() => setIsListShown(true)}
            {...setField()}
          />
          {isListShown && !!suggestions.length && (
            <List className={styles.suggestion_box}>{suggestions.map(renderItem)}</List>
          )}
        </Box>
      </ClickAwayListener>
      <Box className={styles.toggle} onClick={toggleAddress}>
        Enter Address Manually
      </Box>
    </>
  );

  const manualFill = (
    <>
      <Box className={styles.toggle} onClick={toggleAddress}>
        Enter Address by Suggestion
      </Box>
      <AddressForm />
    </>
  );

  return (
    <Box className={styles.container}>
      {isAddressManual ? manualFill : autoFill}
      <Map latitude={address?.latitude} longitude={address?.longitude} onAdjust={onMapAdjust} />
      <Box className={styles.container}></Box>
    </Box>
  );
};
