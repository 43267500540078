import { api } from "./axios";

export class ChargeGroupAPI {
  static getAll({ page, size }) {
    return api.get("chargegroup", { params: { page, size } });
  }

  static create(payload) {
    return api.post("chargegroup", payload);
  }

  static update(id, payload) {
    return api.put(`chargegroup/${id}`, payload);
  }

  static deleteMultiple(ids) {
    return api.delete("chargegroup", { params: { ids } });
  }
}
