import { useState } from "react";

import { checkAll, checkItem, isAllChecked } from "@utils";

export const useTableCheck = (tableData, key, setTableData) => {
  const [selectAll, setSelectAll] = useState(false);

  const toggleCheckedAll = (checked) => {
    setTableData(checkAll(tableData, checked));
    setSelectAll(checked);
  };

  const handleCheckRow = ({ target: { checked } }, element) => {
    const data = checkItem(tableData, checked, (item) => item[key] === element[key]);
    setTableData(data);
    setSelectAll(isAllChecked(data));
  };

  return { selectAll, toggleCheckedAll, handleCheckRow };
};
