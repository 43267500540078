/// <reference types="@types/google.maps" />

import { useEffect, useRef, useState } from "react";

import { AddressHelper } from "@utils/address";

export const useMapSuggestions = () => {
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const helper = useRef(new AddressHelper());

  useEffect(() => {
    if (!address) return;

    helper.current.getSuggestions(address).then(setSuggestions);
  }, [address]);

  const geocode = (placeId) => {
    return helper.current.geocode(placeId);
  };

  const reverseGeocode = (latLng) => {
    return helper.current.reverseGeocode(latLng);
  };

  return { geocode, reverseGeocode, suggestions, setAddress };
};
