import { toast } from "react-toastify";

import { FormTextInput } from "@components/form/new";
import { ModalContainer } from "@components/new";
import { useForm } from "@hooks";
import { FaultAPI } from "@api";

export const FormModal = ({ open, fault, title, onClose, onSave }) => {
  const { register, control, errors, handleSubmit } = useForm({
    open,
    defaultValues: fault || { faultName: "" },
  });

  const handleSave = async ({ faultName, genericFaultId }) => {
    try {
      const payload = { faultName };
      if (genericFaultId) {
        await FaultAPI.updateGeneric(genericFaultId, payload);
      } else {
        await FaultAPI.createGeneric(payload);
      }
      toast.success("Successfully saved!");
      onSave();
      onClose();
    } catch {
      toast.error("Something went wrong!");
    }
  };

  return (
    <ModalContainer
      open={open}
      title={title}
      onClose={onClose}
      onCancel={onClose}
      onSave={handleSubmit(handleSave)}
    >
      <FormTextInput
        label={"Fault Name"}
        control={control}
        error={errors.faultName}
        {...register("faultName")}
      />
    </ModalContainer>
  );
};
