import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { useStationDetails } from "@providers";
import { usePagination } from "@hooks";
import { DateHelper } from "@utils";
import { FaultAPI } from "@api";

import { Wrapper } from "../wrapper";

import { Connector } from "./Connector";
import { Faults } from "./Faults";
import { mapper } from "./mappers";
import styles from "./styles.module.scss";

export const PerformanceTab = () => {
  const [connectorIndex, setConnectorIndex] = useState(0);
  const [charger, setCharger] = useState(null);
  const [faults, setFaults] = useState([]);

  const { station, runningTransactions } = useStationDetails();

  const fetchFaults = (pagination) => {
    if (!charger) return Promise.resolve({ items: [] });

    const { connectorId } = charger.connectors[connectorIndex];

    return FaultAPI.getAll({ chargeStationId: charger.id, connectorId, ...pagination });
  };

  const { fetch, ...pagination } = usePagination(fetchFaults);

  useEffect(() => {
    fetch().then((faults) => {
      setFaults(faults.map(mapper));
    });
  }, [charger, connectorIndex, pagination.page]);

  useEffect(() => {
    if (!station) return;

    const connectors = station.connectors.map((connector) => {
      const transaction = runningTransactions.get(connector.connectorId);

      if (!transaction) return connector;
      const startTime = DateHelper.formatDateToLocal(transaction.startTime);
      return { ...connector, ...transaction, startTime };
    });

    setCharger({ ...station, connectors });
  }, [station, runningTransactions]);

  const renderConnector = (item, i) => {
    return <Connector key={i} connector={item} />;
  };

  return (
    <Wrapper loading={!charger}>
      <Box className={styles.connectors}>{charger?.connectors.map(renderConnector)}</Box>
      <Faults
        data={faults}
        pagination={pagination}
        connectors={charger?.connectors}
        onConnectorPress={setConnectorIndex}
      />
    </Wrapper>
  );
};
