import { useState } from "react";
import { useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

import { FormCheckbox, FormImageInput, FormSelector, FormTextInput } from "@components/form/new";
import { ModalContainer, Selector } from "@components/new";
import { useBusiness } from "@providers";
import { useBusinessSites, useForm } from "@hooks";
import { ChargeStationAPI } from "@api";

import styles from "./styles.module.scss";
import { validationSchema } from "./validation";

export const AddModal = ({ open, vendors, models, onSave, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [vendor, setVendor] = useState("");

  const { options: businesses, business } = useBusiness();

  const { register, control, errors, handleSubmit } = useForm({
    open,
    validationSchema,
    defaultValues: {
      chargerId: "",
      name: "",
      businessId: business,
      siteId: "",
      chargerStationModelId: "",
      basePrice: 0,
      operationalStatus: true,
      image: null,
    },
  });

  const businessId = useWatch({ name: "businessId", control });

  const sites = useBusinessSites(businessId);

  const handleSave = async ({ image: file, ...rest }) => {
    setIsLoading(true);
    try {
      const chargeStationId = await ChargeStationAPI.create(rest);
      if (file) {
        const image = { chargeStationId, imageType: "png", file };
        await ChargeStationAPI.createImage(image);
      }
      handleClose();
      toast.success("Charger is created");
      onSave();
    } catch (e) {
      toast.error(e.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setVendor("");
    onClose();
  };

  return (
    <ModalContainer
      open={open}
      loading={isLoading}
      title={"Add New Charger"}
      onClose={handleClose}
      onCancel={handleClose}
      onSave={handleSubmit(handleSave)}
    >
      <Box className={styles.data}>
        <FormTextInput
          control={control}
          error={errors.chargerId}
          label={"Charge Point ID"}
          {...register("chargerId")}
        />
        <FormTextInput
          control={control}
          error={errors.name}
          label={"Charger Name"}
          {...register("name")}
        />
        <FormSelector
          control={control}
          label={"Business"}
          options={businesses}
          {...register("businessId")}
        />
        <FormSelector
          label={"Site"}
          options={sites}
          control={control}
          error={errors.siteId}
          {...register("siteId")}
        />
        <Selector label={"Vendor"} value={vendor} options={vendors} onChange={setVendor} />
        <FormSelector
          label={"Model"}
          control={control}
          options={models[vendor] || []}
          error={errors.chargerStationModelId}
          {...register("chargerStationModelId")}
        />
        <FormTextInput
          control={control}
          placeholder={"0.00"}
          label={"Price per kWh"}
          error={errors.basePrice}
          {...register("basePrice")}
        />
        <FormCheckbox
          label={"Operational Status"}
          control={control}
          {...register("operationalStatus")}
        />
      </Box>
      <FormImageInput label={"Station Image"} control={control} {...register("image")} />
    </ModalContainer>
  );
};
