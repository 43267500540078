export const statuses = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "In Review",
    value: "inreview",
  },
  {
    label: "In Progress",
    value: "inprogress",
  },
  {
    label: "Closed",
    value: "closed",
  },
];
