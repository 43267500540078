import { useMemo, useState } from "react";

import { Button } from "./Button";
import { Options } from "./Options";

export const Filter = ({ data, config, onChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDateFields, setShowDateFields] = useState(false);
  const intialFilters = useMemo(() => {
    return config.reduce((acc, { field, options, ...rest }) => {
      let filterOptions = new Map();

      if (options) {
        filterOptions = options.reduce((acc, option) => acc.set(option, false), new Map());
      } else {
        const values = [...new Set(data.map((item) => item[field]))];
        filterOptions = values.reduce((acc, label) => acc.set(label, false), new Map());
      }

      return acc.set(field, { ...rest, expanded: false, options: filterOptions });
    }, new Map());
  }, [data, config]);

  const [filters, setFilters] = useState(intialFilters);
  const [anchor, setAnchor] = useState(null);

  const handleOpen = ({ currentTarget }) => {
    setAnchor(currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleToggle = (field) => {
    const { expanded, ...rest } = filters.get(field);
    const newFilters = filters.set(field, { ...rest, expanded: !expanded });
    setFilters(new Map(newFilters));
  };

  const handleChoose = (field, option) => {
    const { options, ...rest } = filters.get(field);
    const checked = options.get(option);
    const newOptions = options.set(option, !checked);
    const newFilters = filters.set(field, { ...rest, options: newOptions });
    setFilters(new Map(newFilters));
  };

  const handleApply = () => {
    const filterArray = [...filters.entries()].reduce((acc, [field, { options }]) => {
      const optionsCopy = new Map(options);
      optionsCopy.forEach((value, key) => !value && optionsCopy.delete(key));
      optionsCopy.size && acc.push({ field, options: Object.fromEntries(optionsCopy) });
      return acc;
    }, []);
    if (startDate && endDate) {
      filterArray.push({
        field: "customDate",
        options: { startDate: startDate, endDate: endDate },
      });
    }

    onChange(filterArray);
  };

  const handleClear = () => {
    const newFilters = new Map(filters);
    newFilters.forEach(({ options, ...rest }, field) => {
      const newOptions = new Map(options);
      newOptions.forEach((_, option) => newOptions.set(option, false));
      newFilters.set(field, { options: newOptions, ...rest });
    });

    setFilters(newFilters);
    onChange([]);
  };

  return (
    <>
      <Button onClick={handleOpen} />
      <Options
        anchor={anchor}
        filters={filters}
        onClose={handleClose}
        onApply={handleApply}
        onClear={handleClear}
        onToggle={handleToggle}
        onChoose={handleChoose}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        setShowDateFields={setShowDateFields}
        showDateFields={showDateFields}
      />
    </>
  );
};
