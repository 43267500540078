import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";

import { ConfirmationModal } from "@components/modals";
import { LoadingContainer, Button, Paper, SearchInput, Table, Link } from "@components/new";
import { useAuth } from "@providers";
import { useConfirmation, usePagination, useTable } from "@hooks";
import { DateHelper } from "@utils/date";
import { RfidGroupAPI } from "@api";

import { ManageModal } from "./ManageModal";
import { actions, texts } from "./action-popup";
import { columns, mapper, sortFields } from "./config.js";
import styles from "./styles.module.scss";

export const RfidGroupList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentGroup, setCurrentGroup] = useState();
  const [groups, setGroups] = useState([]);
  const { ref, invoke } = useTable();

  const fetchRfidGroups = (pagination) => {
    return RfidGroupAPI.getGroupList({ ...pagination });
  };
  const { fetch, ...pagination } = usePagination(fetchRfidGroups);

  const titles = {
    page: "RFID Group List",
    addButton: "Add RFID Group",
    managePopup: {
      add: "Add Group",
      edit: "Edit Group",
    },
  };

  useEffect(() => {
    setIsLoading(true);
    fetch()
      .then((groupList) => {
        setGroups(groupList.map(mapper));
      })
      .finally(() => setIsLoading(false));
  }, [refreshList]);

  const toggleAddModal = () => {
    setIsAddModalVisible((isVisible) => !isVisible);
    setCurrentGroup(undefined);
  };

  const closeRfidGroupModal = () => {
    setIsAddModalVisible(false);
  };

  const toggleEditModal = (user) => {
    setCurrentGroup(user);
    setIsAddModalVisible((isVisible) => !isVisible);
  };

  const { isAdmin } = useAuth();

  const renderItem = (item, field) => {
    const value = item[field];

    switch (field) {
      case "name": {
        return <Link text={value} path={`${item.id}/details`} />;
      }
      case "createdAt":
      case "updatedAt": {
        if (!value) {
          return "-";
        }
        const [date, time] = DateHelper.formatDateToLocal(value).split(", ");

        return (
          <Box className={styles.date}>
            <Box>{date}</Box>
            <Box>{time}</Box>
          </Box>
        );
      }
      default: {
        return value;
      }
    }
  };

  const { isVisible, open, ...rest } = useConfirmation(actions, texts);

  const onSave = async (data, id) => {
    try {
      if (id) {
        await RfidGroupAPI.update(id, data);
        toast.success("Rfid has been successfully updated");
      } else {
        await RfidGroupAPI.create(data);
        toast.success("Rfid has been successfully created");
      }

      updateList();
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const updateList = () => {
    setRefreshList(!refreshList);
  };
  const tableActions = [
    (group) => ({ label: "Edit", onClick: () => toggleEditModal(group), disabled: !isAdmin }),
    (group) => ({
      label: "Delete",
      onClick: () => open("delete", group, updateList),
    }),
  ];

  // fix search
  return (
    <>
      <LoadingContainer loading={isLoading} className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>{titles.page}</Typography>
          <Box className={styles.buttons}>
            <Button variant={"contained"} text={titles.addButton} onClick={toggleAddModal} />
          </Box>
        </Box>
        <Paper className={styles.body}>
          <Box className={styles.tools}>
            <SearchInput placeholder={"Search"} onChange={invoke("search")} />
          </Box>
          <Table
            ref={ref}
            data={groups}
            columns={columns}
            actions={tableActions}
            renderItem={renderItem}
            sortFields={sortFields}
            tableClassName={styles.table}
            {...pagination}
          />
        </Paper>
      </LoadingContainer>
      <ManageModal
        open={isAddModalVisible}
        group={currentGroup}
        onSave={onSave}
        onClose={closeRfidGroupModal}
        titles={titles.managePopup}
      />
      <ConfirmationModal open={isVisible} {...rest} />
    </>
  );
};
