import { Box, Switch } from "@mui/material";

import { FormLabel } from "../FormLabel";

import styles from "./styles.module.scss";

export const Switcher = ({ label, checked, onChange }) => {
  return (
    <Box className={styles.container}>
      {label && <FormLabel text={label} />}
      <Switch
        disableRipple
        checked={checked}
        onChange={onChange}
        classes={{
          root: styles.root,
          track: styles.track,
          thumb: styles.thumb,
          switchBase: styles.base,
          checked: styles.checked,
        }}
      />
    </Box>
  );
};
