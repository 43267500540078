import { TableHead, Checkbox } from "@mui/material";
import { FaTrash } from "react-icons/fa";

import { Button } from "@components/common";

import { Cell } from "../NewCell";
import { Row } from "../Row";

import classes from "./styles.module.css";

export const Head = ({
  columns,
  hasMenu,
  hasChecked,
  isChecked,
  checkedAmount,
  onCheck,
  onDelete,
  sortEnabled,
  getOrderIcon,
  sort,
  checkboxEnabled = true,
}) => {
  const renderHeadCell = ({ name, field }, index) => {
    const onClick = () => sortEnabled && sort(field);

    return (
      <Cell key={index} className={classes.cell} onClick={onClick}>
        {name}
        {sortEnabled && getOrderIcon(field)}
      </Cell>
    );
  };

  const renderDefaultRow = () => {
    return (
      <Row>
        {checkboxEnabled && (
          <Cell className={classes.cell}>
            <Checkbox color={"info"} checked={isChecked} onChange={onCheck} />
          </Cell>
        )}
        {columns.map(renderHeadCell)}
        {hasMenu && <Cell className={classes.cell} />}
      </Row>
    );
  };

  const renderCheckboxRow = () => {
    return (
      <Row>
        <Cell className={classes.cell}>
          <Checkbox color={"info"} checked={isChecked} onChange={onCheck} />
        </Cell>
        <Cell className={classes.cell}>{checkedAmount} entries selected</Cell>
        <Cell className={classes.cell}>
          <Button variant={"delete"} onClick={onDelete}>
            <FaTrash />
            Delete
          </Button>
        </Cell>
        {columns.slice(0, -1).map((_, i) => (
          <Cell key={i} className={classes.cell} />
        ))}
      </Row>
    );
  };

  return <TableHead>{hasChecked ? renderCheckboxRow() : renderDefaultRow()}</TableHead>;
};
