import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import cx from "classnames";

import { Selector } from "@components/new";

import { FormField } from "../FormField";

import styles from "./styles.module.scss";

const Component = ({ multiple, options, control, label, name, error }, _) => {
  const classes = cx(!!error && styles.error);

  const render = ({ field }) => {
    const { value, onChange } = field;

    return (
      <FormField label={label} error={error}>
        <Selector
          multiple={multiple}
          className={classes}
          value={value}
          options={options}
          onChange={onChange}
        />
      </FormField>
    );
  };

  return <Controller name={name} control={control} render={render} />;
};

export const FormSelector = forwardRef(Component);
