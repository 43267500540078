import { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";

import { ConfirmationModal } from "@components/modals";
import { Paper, Switcher } from "@components/new";
import { DateHelper } from "@utils";
import { DriverAPI } from "@api";

import { fields } from "./config";
import styles from "./styles.module.scss";

export const GeneralInfo = ({ driver }) => {
  const [status, setStatus] = useState(driver.status);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible((isVisible) => !isVisible);
  };

  const changeStatus = () => {
    DriverAPI.updateStatus(driver.id, !status);
    setStatus((status) => !status);
    setIsModalVisible(false);
  };
  //TODO add edit button
  const renderContent = (field) => {
    const value = driver[field];
    if (field === "userName" || field === "isDeleted" || !driver["isDeleted"]) {
      switch (field) {
        case "status": {
          return <Switcher checked={status} onChange={toggleModal} />;
        }
        case "registrationComplete": {
          return (
            <Typography className={styles.value}>{value ? "Finished" : "Not confirmed"}</Typography>
          );
        }
        case "createdAt":
        case "updatedAt":
        case "lastLoginAt": {
          return value ? (
            <Typography className={styles.value}>{DateHelper.formatDateToLocal(value)}</Typography>
          ) : (
            "-"
          );
        }
        case "isDeleted": {
          return <Typography className={styles.value}>{value ? "Deleted" : ""}</Typography>;
        }
        default: {
          return <Typography className={styles.value}>{value}</Typography>;
        }
      }
    }
  };

  const renderField = ({ label, field }, i) => {
    return (
      <Box key={i} className={styles.cell}>
        <Typography className={styles.label}>{label}</Typography>
        {renderContent(field)}
      </Box>
    );
  };

  const modalText = `Are you sure you want to ${status ? "disable" : "enable"} this driver?`;

  return (
    <>
      <Paper className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title}>General Info</Typography>
        </Box>
        <Box className={styles.body}>
          <Box className={styles.grid}>{fields.map(renderField)}</Box>
        </Box>
        <Divider className={styles.divider} />
      </Paper>
      <ConfirmationModal
        open={isModalVisible}
        text={modalText}
        onConfirm={changeStatus}
        onReject={toggleModal}
      />
    </>
  );
};
