import { number, object, string } from "yup";

export const validationSchema = object().shape({
  chargerId: string().required("Charge Point ID is required"),
  name: string().required("Charger Name is required"),
  siteId: string().required("Site is required"),
  chargerStationModelId: string().required("Model is required"),
  basePrice: number()
    .typeError("Should be a valid number")
    .positive("Price should be greated than 0.00")
    .required("Price is required"),
});
